import React from 'react';
import ImageCarouselCard from '../components/Image/ImageCarouselCard';
import './CarouselSlide.css';

const CarouselSlide = ({ slide, width, fallbackImage, actionButton }) => {
  const { text, image, url } = slide;

  return (
    <div className="CarouselSlide" style={{ width }}>
      <ImageCarouselCard
        text={text}
        url={url}
        image={image}
        fallbackImage={fallbackImage}
        actionButton={actionButton}
        width={width}
        rel="noopener noreferrer"
      />
    </div>
  );
};

export default CarouselSlide;
