import React, { Component } from 'react';

import './Title.scss';

export default class Title extends Component {
  render() {
    const { title } = this.props;

    return <div className="title-text TitleContainer">{title}</div>;
  }
}
