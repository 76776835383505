import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import Table from 'components/Table';
import { Button } from '@mui/material';
import InviteUserOrDepartment from './InviteUserOrDepartment';

import { setMainMenu, setSubMenu } from 'store/crm/actions';
import { selectDepartmentsIDs } from 'store/crm/selectors';

import './Departments.scss';
import './ComponentsCommonStyle.scss';
import languageMap from 'Language/Language';

const Departments = (props) => {
  const { setSubMenu, setManagedDepartment } = props;

  const [addDepartmentPopup, setAddDepartmentPopup] = useState(false);
  const [listOfDepartments, setListOfDepartments] = useState({});
  let { id: projectId } = useParams();
  projectId = Number(projectId);

  useEffect(() => {
    getDepartments();
  }, [addDepartmentPopup, listOfDepartments?.data?.length]);

  const getDepartments = async () => {
    await axios
      .get('/internal_api/department/get_departments', {
        params: {
          project_id: projectId,
        },
      })
      .then((response) => {
        let auxDataSet = response?.data?.departments;
        let listOfDepartmentsObject = {};
        listOfDepartmentsObject.dataResponse = auxDataSet;
        listOfDepartmentsObject['columns'] = [
          languageMap.department,
          languageMap.users,
          languageMap.actions,
        ];
        listOfDepartmentsObject['data'] = [];
        listOfDepartmentsObject.id = [];
        let temporaryObject = {};

        for (let i = 0; i < auxDataSet?.length; i++) {
          let numberOfUsers = 0;
          for (let j = 0; j < auxDataSet[i].agents.length; j++) {
            if (auxDataSet[i].agents[j].agent_id !== -1 && auxDataSet[i].agents[j].agent_id !== 0) {
              numberOfUsers++;
            }
          }
          listOfDepartmentsObject.data.push([
            (temporaryObject.Department = auxDataSet[i].department_name),
            (temporaryObject.Users = numberOfUsers),
            (temporaryObject.Actions = manageButton(i)),
          ]);
          listOfDepartmentsObject.id.push(auxDataSet[i]?.department_id);
        }
        setListOfDepartments(listOfDepartmentsObject);
      });
  };

  const addDepartmnets = () => {
    setAddDepartmentPopup(true);
  };

  const manageButton = (index) => {
    const changeSubmenu = () => {
      setManagedDepartment(listOfDepartments?.dataResponse?.[index]);
      setSubMenu('departmentManagement');
    };
    return (
      <div className="button-as-link" onClick={changeSubmenu}>
        {languageMap.manage}
      </div>
    );
  };

  return (
    <div className="Departments">
      {addDepartmentPopup && (
        <InviteUserOrDepartment
          titleText={languageMap.typeInDepartmentName}
          addButtonText={languageMap.confirm}
          cancelButtonText={languageMap.cancel}
          userOrDepartment="department"
          setPopup={setAddDepartmentPopup}
          update={getDepartments}
        />
      )}
      <div className="header dm-sans-500 title-text">{languageMap.departments}</div>
      <Button className="invite-user-button blue-button-style" onClick={addDepartmnets}>
        <div className="button-text">{languageMap.newDepartment}</div>
      </Button>
      <div className="list">
        <Table tableData={listOfDepartments} />
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  setMenu: setMainMenu,
  setSubMenu: setSubMenu,
};

const mapStateToProps = (state) => ({ myDepartment: selectDepartmentsIDs(state) });

export default connect(mapStateToProps, mapDispatchToProps)(Departments);
