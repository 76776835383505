import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import debounce from 'lodash/debounce';

import MenuItem from '@mui/material/MenuItem';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay';

import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';

import clsx from 'clsx';

import {
  getUsersSuggestions,
  resetFilteredData,
  resetSearch,
  setFilteredData,
  setFilteredDataAdd,
  setSearchResultsWarning,
  setSearchStatus,
  setStartFromConversationIndex,
  setSearchFilters,
} from 'store/crm/actions';
import {
  selectAgents,
  selectConversationsStatusFilter,
  selectCurrentUser,
  selectDepartments,
  selectFilters,
  selectIsMobile,
  selectSearch,
  selectStartFromConversationIndex,
} from 'store/crm/selectors';

import {
  getOption,
  getParams,
  mainMenuItems,
  secondaryMenuDates,
  secondaryMenuStatuses,
} from 'utils/crm';

import './ConversationsSearchContainer.scss';
import ListIcon from '@mui/icons-material/List';
import IconButton from '@mui/material/IconButton';

const ConversationsSearchContainer = ({
  filtersStore: { channels, custom_filters },
  departments,
  agents,
  getUsersSuggestionsAction,
  setFilteredDataAction,
  setFilteredDataAddAction,
  resetFilteredDataAction,
  search: { usersSuggestions, filteredData, isSearching },
  resetSearchAction,
  setSearchResultsWarningAction,
  startFromConversationIndex,
  isMobile,
  toggleDrawer,
  setSearchStatusAction,
  setStartFromConversationIndex,
  setSearchFiltersAction,
}) => {
  const { id: projectId } = useParams();

  const [searchText, setSearchText] = useState('');
  const [isPrimaryMenu, setIsPrimaryMenu] = useState(true);
  const [isSecondaryMenu, setIsSecondaryMenu] = useState(false);
  const [secondaryMenuItems, setSecondaryMenuItems] = useState([]);
  const [currentFilter, setCurrentFilter] = useState(null);
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    if (isMobile) {
      setIsPrimaryMenu(false);
    }
  }, []);
  useEffect(() => {
    if (usersSuggestions.length > 0) {
      const formattedUserSuggestions = usersSuggestions.map((suggestion, index) => {
        return {
          id: index,
          name: suggestion,
        };
      });

      setSecondaryMenuItems(formattedUserSuggestions);
    }
  }, [usersSuggestions]);

  useEffect(() => {
    if (filters.length) {
      const params = getParams(filters, projectId, startFromConversationIndex);

      setFilteredDataAction(params);
      setSearchFiltersAction(filters);
    }
  }, [filters]);

  useEffect(() => {
    if (filters.length) {
      const params = getParams(filters, projectId, startFromConversationIndex);
      setFilteredDataAddAction(params);
    }
  }, [startFromConversationIndex]);

  let customFilters =
    custom_filters?.map((item) => {
      return {
        id: item.id,
        icon: <CalendarViewDayIcon className="icon" />,
        name: item.name,
        type: 'custom',
      };
    }) || [];

  useEffect(() => {
    if (filteredData.length === 0) {
      setSearchResultsWarningAction('No results');
    }
  }, [filteredData, setSearchResultsWarningAction]);

  const handleCancelSearch = () => {
    setSearchText('');
    setFilters([]);
    setCurrentFilter(null);
    resetFilteredDataAction();

    setIsPrimaryMenu(false);

    setIsSecondaryMenu(false);
    setSecondaryMenuItems([]);
    setStartFromConversationIndex(0);
    setSearchStatusAction(false);
  };

  const handleMenuClose = () => {
    if (isPrimaryMenu) setIsPrimaryMenu(false);
    if (isSecondaryMenu) setIsSecondaryMenu(false);
    if (currentFilter) setCurrentFilter(null);
    setStartFromConversationIndex(0);
  };

  const handleBackspacePress = useCallback(
    (e) => {
      try {
        if (searchText === '' && filters.length === 0) return;

        if (e.key === 'Backspace') {
          if (currentFilter !== null && !currentFilter?.shouldHighlight) {
            setCurrentFilter({ ...currentFilter, shouldHighlight: true });
          }

          if (currentFilter !== null && currentFilter?.shouldHighlight) {
            setCurrentFilter(null);
            setIsSecondaryMenu(false);
            setSecondaryMenuItems(false);
            setIsPrimaryMenu(true);
          }

          const lastItem = filters.length - 1;
          let newFilters = [...filters];
          if (currentFilter === null && !filters[lastItem]?.shouldHighlight) {
            newFilters[lastItem].shouldHighlight = true;
          } else if (currentFilter === null && filters[lastItem]?.shouldHighlight) {
            newFilters.pop();
          }
          setFilters(newFilters);
        }
      } catch (error) {}
    },
    [currentFilter, filters, searchText]
  );

  const handleEnterPress = async (e) => {
    if (currentFilter?.type === 'custom' && e.key === 'Enter') {
      const currentFilterLocal = { ...currentFilter, label: e.target.value };

      setFilters([...filters, currentFilterLocal]);
      setCurrentFilter(null);
      setIsSecondaryMenu(false);
      setIsPrimaryMenu(!isMobile);
    }
    if (e.key === 'Enter' && filters.length) {
      const params = getParams(filters, projectId, startFromConversationIndex);

      await setFilteredDataAction(params);

      setIsPrimaryMenu(false);
      setIsSecondaryMenu(false);
      setSecondaryMenuItems([]);
    }
  };

  const handleKeyDown = (e) => {
    handleBackspacePress(e);
    handleEnterPress(e);
  };

  const handleInputClick = () => {
    if (isMobile && !isSearching) {
      setStartFromConversationIndex(0);
      setSearchStatusAction(true);
    }

    if (!isPrimaryMenu && !isSecondaryMenu) setIsPrimaryMenu(true);
  };

  const debouncedGetUsersSuggestions = useCallback(
    debounce((searchText) => {
      getUsersSuggestionsAction({ projectId, searchText });
    }, 500),
    [searchText]
  );

  const renderCompleteFilters = useCallback(() => {
    const handleRemoveFilter = (item) => {
      const newFilters = [...filters].filter((filter) => filter !== item);
      setFilters(newFilters);
    };
    if (filters.length > 0) {
      return filters.map((item) => (
        <span
          key={item?.id}
          className={`search-pill dm-sans-400 ${item?.shouldHighlight && 'highlight'}`}
          style={isMobile ? { padding: '10px', background: 'white' } : {}}
        >
          {`${item?.name}: ${item?.label}`}
          <HighlightOffIcon
            className="delete-button cancel-filter"
            onClick={() => handleRemoveFilter(item)}
          />
        </span>
      ));
    }
  }, [filters]);

  const renderCurrentFilter = useCallback(() => {
    const handleCurrentFilterKeyDown = (e) => {
      handleBackspacePress(e);
      handleEnterPress(e);
    };

    const handleSearchInputChange = (e) => {
      setSearchText(e.target.value);
      setCurrentFilter({ ...currentFilter, value: e.target.value, shouldHighlight: false });
      if (currentFilter?.name === 'User') {
        debouncedGetUsersSuggestions(e.target.value);
      }
    };

    const checkIfInputIsNeeded = () => {
      if (currentFilter?.name === 'User' || currentFilter?.type === 'custom') {
        return true;
      }
    };

    return (
      currentFilter !== null && (
        <span
          className={`search-pill dm-sans-400 current-filter ${
            currentFilter?.shouldHighlight && 'highlight'
          }`}
          style={isMobile ? { padding: '10px', background: 'white' } : {}}
        >
          {`${currentFilter?.name}: `}
          {checkIfInputIsNeeded() && (
            <input
              className="search-input current-filter"
              style={isMobile ? { padding: '10px', background: 'white' } : {}}
              id="current-filter-input"
              onChange={handleSearchInputChange}
              onKeyDown={handleCurrentFilterKeyDown}
              autoFocus
            />
          )}
        </span>
      )
    );
  }, [currentFilter, debouncedGetUsersSuggestions, handleBackspacePress]);

  const renderMainMenuItems = useCallback(() => {
    let filteredItems = [...mainMenuItems, ...customFilters];

    const checkFilterExists = (filterName) => {
      let isFilterUsed = false;

      filters.forEach((item) => {
        if (item.name === filterName) {
          isFilterUsed = true;
        }
      });
      return isFilterUsed;
    };

    const loadSecondaryMenuItems = async (item) => {
      if (item === 'User') {
        const searchText = '';
        const users = await getUsersSuggestionsAction({ projectId, searchText });
        if (users.length > 0) setSecondaryMenuItems(users);
      }
      if (item === 'Status') {
        setSecondaryMenuItems(secondaryMenuStatuses);
      }
      if (item === 'Department') {
        setSecondaryMenuItems(departments);
      }
      if (item === 'Agent') {
        setSecondaryMenuItems(agents);
      }
      if (item === 'Channel') {
        setSecondaryMenuItems(channels);
      }
      if (item === 'Date') {
        setSecondaryMenuItems(secondaryMenuDates);
      }
    };

    const handleMainMenuClick = (item) => {
      const { name, id, type } = item;
      setCurrentFilter({ ...currentFilter, name, id, type });

      loadSecondaryMenuItems(name);

      setIsPrimaryMenu(false);
      setIsSecondaryMenu(true);
    };

    const isStatusSelected = checkFilterExists('Status');
    const isDateSelected = checkFilterExists('Date');

    if (isStatusSelected) {
      filteredItems = filteredItems.filter((item) => item.name !== 'Status');
    }
    if (isDateSelected) {
      filteredItems = filteredItems.filter((item) => item.name !== 'Date');
    }

    return (
      <MenuList id="main-search-menu" className="menu-list main-menu">
        {filteredItems.map((item) => {
          return (
            <MenuItem key={`item-${item?.id}`} onClick={() => handleMainMenuClick(item)}>
              {item.icon}
              {item.name}
            </MenuItem>
          );
        })}
      </MenuList>
    );
  }, [currentFilter, filters, agents, channels, departments, projectId, getUsersSuggestionsAction]);

  const renderSecondaryMenuItems = useCallback(() => {
    const addToFilterList = (item) => {
      let newFilters = [...filters];
      newFilters.push({
        name: item.name,
        value: item.value,
        id: item.id,
      });

      const lastItem = newFilters.length - 1;

      if (currentFilter.name === 'Date') {
        const { min_date, max_date } = item;

        newFilters[lastItem].min_date = min_date;
        newFilters[lastItem].max_date = max_date;
      }

      if (item.label === undefined || item.label === '') {
        newFilters[lastItem].label = item.name;
      } else {
        newFilters[lastItem].label = item.label;
      }

      setFilters(newFilters);
    };

    const handleSecondaryMenuClick = (item) => {
      addToFilterList(item);

      setCurrentFilter(null);
      setIsSecondaryMenu(false);
      setIsPrimaryMenu(!isMobile);
    };

    return (
      <MenuList id="secondary-search-menu" className="menu-list secondary-menu">
        {secondaryMenuItems?.map((item) => {
          const option = getOption(item, currentFilter);
          return (
            <MenuItem
              key={`secondary-menu-option${option?.id}`}
              onClick={() => handleSecondaryMenuClick(option)}
            >
              {option?.label ? option.label : option.name}
            </MenuItem>
          );
        })}
      </MenuList>
    );
  }, [currentFilter, secondaryMenuItems, filters]);

  const renderSearchInputAssembly = () => {
    const clearSearch = () => {
      resetSearchAction();
      setSearchText('');
      setFilters([]);
      setCurrentFilter(null);
      resetFilteredDataAction();
      setIsPrimaryMenu(false);
      setIsSecondaryMenu(false);
      setSecondaryMenuItems([]);
      setStartFromConversationIndex(0);
    };

    return (
      <div className={clsx('search-input-wrapper', isMobile && 'search-input-wrapper-isMobile')}>
        {isMobile && !isSearching && (
          <IconButton style={{ position: 'relative', left: 4 }} size="small" onClick={toggleDrawer}>
            <ListIcon className="list-icon" fontSize="large" />
          </IconButton>
        )}

        {isMobile && isSearching && (
          <IconButton style={{ position: 'relative', left: 4 }} size="small" onClick={clearSearch}>
            <ArrowBackIcon className="list-icon" fontSize="large" />
          </IconButton>
        )}
        <div
          className={clsx('search-input-assembly', isMobile && 'search-input-assembly-isMobile')}
        >
          {renderCompleteFilters()}
          {renderCurrentFilter()}
          {!currentFilter && (
            <input
              className="search-input dm-sans-500"
              style={isMobile ? { background: 'white' } : {}}
              id="search-input"
              type="text"
              placeholder="Search"
              onKeyDown={handleKeyDown}
              onClick={handleInputClick}
              autoFocus={!isMobile}
            />
          )}
        </div>
        {isSearching && <HighlightOffIcon className="delete-button" onClick={handleCancelSearch} />}
      </div>
    );
  };

  return (
    <div className="ConversationsSearchContainer" style={isMobile ? { width: '100%' } : {}}>
      <div
        className={clsx('search-input-container', isMobile && 'search-input-container-isMobile')}
        style={isMobile ? { paddingLeft: '10px', paddingRight: '10px' } : {}}
      >
        <ClickAwayListener onClickAway={handleMenuClose}>
          <div
            id="search-input-component"
            className="search-input-component"
            style={isMobile ? { minHeight: '40px' } : {}}
          >
            {renderSearchInputAssembly()}
            {isPrimaryMenu && renderMainMenuItems()}
            {isSecondaryMenu && renderSecondaryMenuItems()}
          </div>
        </ClickAwayListener>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  conversationStatus: selectConversationsStatusFilter(state),
  currentUser: selectCurrentUser(state),
  filtersStore: selectFilters(state),
  departments: selectDepartments(state),
  agents: selectAgents(state),
  search: selectSearch(state),
  startFromConversationIndex: selectStartFromConversationIndex(state),
  isMobile: selectIsMobile(state),
});

const mapDispatchToProps = {
  getUsersSuggestionsAction: getUsersSuggestions,
  setFilteredDataAction: setFilteredData,
  setFilteredDataAddAction: setFilteredDataAdd,
  setSearchStatusAction: setSearchStatus,
  resetFilteredDataAction: resetFilteredData,
  resetSearchAction: resetSearch,
  setSearchResultsWarningAction: setSearchResultsWarning,
  setStartFromConversationIndex: setStartFromConversationIndex,
  setSearchFiltersAction: setSearchFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConversationsSearchContainer);
