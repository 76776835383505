import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

function createAsyncThunkHelper(actionName, url_suffix, method) {
  const url = '/internal_api/qna/' + url_suffix;

  return createAsyncThunk(actionName, async (params) => {
    let response = null;

    if (method === 'get') {
      response = await axios.get(url, { params });
    } else if (method === 'delete') {
      response = await axios.delete(url, { params });
    } else {
      response = await axios.post(url, params);
    }

    return response.data;
  });
}

export const setSelectedLanguage = createAction('qna/SET_SELECTED_LANGUAGE');
export const clearState = createAction('qna/CLEAR_STATE');

// GET
export const fetchInitialState = createAsyncThunkHelper(
  'qna/FETCH_INITIAL_STATE',
  'get_initial_state',
  'get'
);
export const fetchEntries = createAsyncThunkHelper('qna/FETCH_ENTRIES', 'get_entries', 'get');

// POST
export const createFlow = createAsyncThunkHelper('qna/CREATE_FLOW', 'create_flow', 'post');

export const createLink = createAsyncThunkHelper('qna/CREATE_LINK', 'create_link', 'post');
export const updateLinkText = createAsyncThunk('qna/UPDATE_LINK_TEXT', async (params) => {
  const URL = `/internal_api/qna/update_link_text?translate=${params.doTranslate}`;
  delete params.doTranslate;
  let response = await axios.post(URL, params);
  return response.data;
});

export const updateMessage = createAction('qna/CHANGE_MESSAGE');

export const reorderLinks = createAsyncThunkHelper('qna/REORDER_LINKS', 'reorder_links', 'post');
export const deleteLink = createAsyncThunkHelper('qna/DELETE_LINK', 'delete_link', 'delete');

export const deleteEntry = createAsyncThunkHelper('qna/DELETE_ENTRY', 'delete_entry', 'delete');
export const createEntry = createAsyncThunkHelper('qna/CREATE_ENTRY', 'create_entry', 'post');
export const ignoreConflict = createAsyncThunk(
  'qna/IGNORE_CONFLICT',
  async ({ projectId, intentId_1, intentId_2 }) => {
    const response = await axios.post('/internal_api/qna/conflicts/ignore_conflict', null, {
      params: {
        project_id: projectId,
        intent_id_1: intentId_1,
        intent_id_2: intentId_2,
      },
    });
    return response.data;
  }
);

export const conflictsCount = createAsyncThunkHelper(
  'qna/CONFLICTS_COUNT',
  'conflicts/count',
  'get'
);

export const updateEntry = createAction('qna/UPDATE_ENTRY');

export const importQna = createAsyncThunk('qna/IMPORT', async (params) => {
  let data = new FormData();
  data.set('project_id', params.projectId);
  data.set('language', params.language);
  data.set('get_all_entries', params.getAllEntries);
  data.append('file', params.file);

  const response = await axios.post('/internal_api/qna/import', data, {
    'Content-Type': 'multipart/form-data',
  });

  return response.data;
});
