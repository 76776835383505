import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import BurgerMenu from 'components/BurgerMenu';
import AvatarWrapper from 'components/Avatar';
import AddIcon from '@mui/icons-material/Add';
import DialogForm from 'components/DialogForm';
import languageMap from 'Language/Language';

import {
  setAdmin,
  unsetAdmin,
  removeAgentFromGroup,
  removeDepartmentFromGroup,
  getChatHistory,
} from 'store/crm/actions';

import {
  selectAgents,
  selectConversationCurrentParticipants,
  selectDepartments,
  selectCurrentConversation,
  selectCurrentUser,
  selectIsMobile,
} from 'store/crm/selectors';

import { isCurrentUserAdmin } from 'utils/utils';

import './Participants.scss';

const Participants = ({
  agents,
  departments,
  participants,
  currentConversation: { user_id, conversation_id },
  currentUser,
  isMobile,

  ...props
}) => {
  const participantOptions = [
    { label: languageMap.makeAdmin, value: 'set_admin' },
    { label: languageMap.removeAdmin, value: 'unset_admin' },
    { label: languageMap.removeFromGroup, value: 'remove_from_group' },
  ];

  const [shouldSelectParticipant, setShouldSelectParticipant] = useState(false);
  const [standardAgents, setStandardAgents] = useState([]);
  const [standardDepartments, setStandardDepartments] = useState([]);
  const { id: projectId } = useParams();

  useEffect(() => {
    const standardisedAgents = agents.map(({ agent_id, agent_name }) => ({
      label: agent_name,
      value: agent_id,
    }));

    setStandardAgents(standardisedAgents);
  }, [agents]);

  useEffect(() => {
    const standardDepartments = departments.map(({ department_id, department_name }) => ({
      label: department_name,
      value: department_id,
    }));
    setStandardDepartments(standardDepartments);
  }, [departments]);

  const getParticipantOptions = (item) => {
    let options = participantOptions;
    if (item.is_admin === 1) {
      options = options.filter((item) => item.value !== 'set_admin');
    }
    if (item.is_admin === 0) {
      options = options.filter((item) => item.value !== 'unset_admin');
    }
    return options;
  };

  const handleAvatarClick = () => {
    setShouldSelectParticipant(true);
  };

  const handleDialogClose = () => {
    setShouldSelectParticipant(false);
  };

  const handleParticipantClick = async ({ item, option }) => {
    const participant = item.participant_id;
    const params = {
      project_id: projectId,
      conversation_id,
      participant_id: participant,
    };

    if (option.value === 'set_admin' && item.is_admin === 0) await props.setAdmin(params);
    if (option.value === 'unset_admin' && item.is_admin === 1) await props.unsetAdmin(params);
    if (option.value === 'remove_from_group') {
      if (item.type === 'user') {
        await props.removeAgentFromGroup(params);
      } else if (item.type === 'department') {
        await props.removeDepartmentFromGroup(params);
      }
    }
    await props.getChatHistory({ user_id, project_id: projectId, conversation_id });
  };

  const capitalize = (name, lower = false) =>
    (lower ? name.toLowerCase() : name).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
      match.toUpperCase()
    );

  return (
    <div className="Participants">
      {participants?.map((item) => (
        <div
          key={`participant-${item.name}`}
          className="participant"
          style={{ justifyContent: 'space-between' }}
        >
          <div className="participant_and_patron">
            <div className="name">{capitalize(item.name ? item.name : 'Unknown user')}</div>
            {item?.added_by && (
              <div className="patron">{`${item?.is_admin === 1 ? 'Admin · a' : 'A'}dded by ${
                item?.added_by
              }`}</div>
            )}
          </div>
          <div className="options">
            {isCurrentUserAdmin(participants, currentUser) && (
              <BurgerMenu
                options={getParticipantOptions(item)}
                item={item}
                onItemClick={handleParticipantClick}
              />
            )}
          </div>
        </div>
      ))}
      {isCurrentUserAdmin(participants, currentUser) && (
        <div className="add-participant participant">
          <div className="add-button">
            <AvatarWrapper>
              <AddIcon onClick={handleAvatarClick} />
            </AvatarWrapper>
            <DialogForm
              title={languageMap.addPeople}
              isOpen={shouldSelectParticipant}
              onClose={handleDialogClose}
              agents={standardAgents}
              departments={standardDepartments}
            />
          </div>
          <div className="add-button-name">{languageMap.addPeople}</div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  agents: selectAgents(state),
  departments: selectDepartments(state),
  currentConversation: selectCurrentConversation(state),
  participants: selectConversationCurrentParticipants(state),
  currentUser: selectCurrentUser(state),
  isMobile: selectIsMobile(state),
});

const mapDispatchToProps = {
  setAdmin,
  unsetAdmin,
  removeAgentFromGroup,
  removeDepartmentFromGroup,
  getChatHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(Participants);
