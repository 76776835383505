import React from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InfoCard from 'components/InfoCard';

import './CollapsibleInfoCard.scss';

const CollapsibleInfoCard = ({
  collapsible = false,
  open = true,
  onSetOpen,
  cardName,
  title,
  children,
}) => {
  const handleCollapseButtonClick = () => {
    if (collapsible == true) {
      return onSetOpen(!open);
    }
  };

  const renderCollapsibleButton = () => {
    return (
      <div className="collapsible-button" onClick={handleCollapseButtonClick}>
        {open ? <ExpandLess /> : <ExpandMore />}
      </div>
    );
  };

  return (
    <div className="CollapsibleInfoCard">
      <InfoCard cardName={cardName} title={title}>
        {children}
        {collapsible && renderCollapsibleButton()}
      </InfoCard>
    </div>
  );
};

export default CollapsibleInfoCard;
