import React, { useEffect, useState } from 'react';
import { formatDistanceToNow } from 'date-fns';

import { Tooltip } from '@mui/material';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import languageMap from 'Language/Language';

import './MetaCard.scss';
import { v4 } from 'uuid';
import Axios from 'axios';

const KEY = 'key';
const MetaCard = ({
  inboxCustomData,
  userId,
  email,
  channel,
  languageName,
  firstSeen,
  shareLink,
  conversationId,
}) => {
  const [linkStatus, setLinkStatus] = useState('Copy');
  const [shareLinkValue, setCopyLinkValue] = useState('');

  useEffect(() => {
    setCopyLinkValue(shareLink);
  }, [shareLink, setCopyLinkValue]);

  const handleCopyLink = async () => {
    await navigator.clipboard.writeText(`${shareLinkValue}`);

    setLinkStatus('Copied to clipboard');
  };

  let translatedInboxCustomData = [];
  if (!inboxCustomData) {
    translatedInboxCustomData = [
      { key: languageMap.firstName },
      { key: languageMap.lastName },
      { key: languageMap.telephone },
    ];
  } else {
    inboxCustomData.forEach((data) => {
      let translatedData = { ...data };
      translatedData['original_key'] = data[KEY];
      const lowerValue = data[KEY].toLowerCase().replace(' ', '');
      if (lowerValue in languageMap) {
        translatedData[KEY] = languageMap[lowerValue];
      }
      translatedInboxCustomData.push(translatedData);
    });
  }

  const arrayOfInfo = [
    ...translatedInboxCustomData,
    { key: languageMap.channel, value: channel },
    { key: languageMap.conversationLanguage, value: languageName },
    { key: languageMap.email, value: email },
    {
      key: languageMap.firstSeen,
      value: firstSeen && formatDistanceToNow(firstSeen, { addSuffix: true }),
    },
    {
      key: languageMap.shareLink,
      value: userId,
      tooltip: linkStatus,
      icon: <FileCopyIcon className="file-copy-icon" onClick={handleCopyLink} />,
    },
  ];

  const Row = ({ row, index }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState(row.value);

    const handleInputChange = (event) => {
      setInputValue(event.target.value);
    };

    const handleButtonClick = async () => {
      if (isEditing) {
        const response = await Axios.patch(
          '/internal_api/wisevoice_crm/update_editable_custom_data',
          {
            inbox_custom_data_key: row.original_key ? row.original_key : row.key,
            inbox_custom_data_value: inputValue,
            conversation_id: conversationId,
            user_id: userId,
          }
        );

        if (!response.ok) {
          console.error('Failed to update data');
        }
      }
      setIsEditing(!isEditing);
    };

    return (
      <div className="MetaCard_row" key={index}>
        <div className="MetaCard_row_key regular-text">{row.key}</div>
        <div className="contetn-wrapper">
          {isEditing ? (
            <input
              type="text"
              value={inputValue}
              className="input regular-text"
              onChange={handleInputChange}
            />
          ) : (
            <div className="MetaCard_row_value regular-text">{inputValue}</div>
          )}
          {row.tooltip && (
            <Tooltip title={row.tooltip} placement="right" arrow>
              <button className="MetaCard_row_icon copy_icon">{row.icon}</button>
            </Tooltip>
          )}
          {row?.editable === true && (
            <button className="edit-button" onClick={handleButtonClick}>
              {isEditing ? languageMap.save : languageMap.edit}
            </button>
          )}
        </div>
      </div>
    );
  };
  return (
    <>
      {arrayOfInfo.map((row, index) => (
        <Row row={row} index={index} key={v4()} />
      ))}
    </>
  );
};
export default MetaCard;
