import React, { useEffect, useState } from 'react';

import OptionCheckbox from 'pages/Flow/components/Message/MessageBlocks/components/OptionCheckbox';

import './SimpleTextBlock.scss';
import languageMap from 'Language/Language';
import autosize from 'autosize/dist/autosize';

const SimpleTextBlock = ({ block, onBlockChange, voiceInteraction }) => {
  const { text, tts_on, alternative_text_on, alternative_text } = block;
  const [textAreaId] = useState(Math.random());
  const [textAreaAlternativeId] = useState(Math.random());
  useEffect(() => {
    if (textAreaId) {
      autosize(document.getElementById(textAreaId));
    }
    if (textAreaAlternativeId) {
      autosize(document.getElementById(textAreaAlternativeId));
    }
  }, [textAreaId, textAreaAlternativeId]);

  const handleCheckboxChange = (checkboxToChange) => {
    onBlockChange({ ...block, ...checkboxToChange });
  };

  const renderPrimaryTextArea = () => {
    return (
      <textarea
        id={textAreaId}
        className="SimpleTextBlock_TextArea"
        defaultValue={text}
        onBlur={(e) => {
          onBlockChange({ ...block, text: e.target.value });
        }}
      />
    );
  };

  const renderTTSOption = () => {
    return (
      voiceInteraction && (
        <OptionCheckbox
          checked={tts_on || false}
          onChange={() => handleCheckboxChange({ tts_on: !tts_on })}
          title={languageMap.ttsOnLabel}
        />
      )
    );
  };

  const renderAltTextOption = () => {
    return (
      voiceInteraction &&
      tts_on && (
        <OptionCheckbox
          checked={alternative_text_on || false}
          onChange={() =>
            handleCheckboxChange({
              alternative_text_on: !alternative_text_on,
            })
          }
          title={languageMap.alternativeText}
        />
      )
    );
  };

  const renderOptionalSettings = () => {
    return (
      <div className="SimpleTextBlock_OptionalSettings">
        {renderTTSOption()}
        {renderAltTextOption()}
      </div>
    );
  };

  const renderAlternativeTextArea = () => {
    return (
      voiceInteraction &&
      tts_on &&
      alternative_text_on && (
        <textarea
          id={textAreaAlternativeId}
          className="SimpleTextBlock_TextArea"
          defaultValue={alternative_text}
          onBlur={(e) => {
            onBlockChange({ ...block, alternative_text: e.target.value });
          }}
        />
      )
    );
  };

  return (
    <div className="SimpleTextBlock">
      {renderPrimaryTextArea()}
      {renderOptionalSettings()}
      {renderAlternativeTextArea()}
    </div>
  );
};

export default SimpleTextBlock;
