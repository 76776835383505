/* eslint eqeqeq: "off" */

import React from 'react';
import './FileMessage.scss';
import ImageMessage from 'components/common/MessageType/ImageMessage';
import DocumentMessage from 'components/common/MessageType/DocumentMessage';
import AudioMessage from 'components/common/MessageType/AudioMessage';
import FacebookDocumentMessage from 'components/common/MessageType/FacebookDocumentMessage';

/* eslint eqeqeq: "off"*/

const getFileEnding = (fileUrl) => {
  if (fileUrl == undefined || fileUrl == null) {
    return null;
  }

  return fileUrl
    .toString()
    .toLowerCase()
    .slice(fileUrl.toString().lastIndexOf('.') + 1);
};

const getFileType = (fileUrl) => {
  let fileType = null;
  let fileEnding = null;

  if (fileUrl !== undefined) {
    fileEnding = getFileEnding(fileUrl);
  }

  if (fileEnding == 'png' || fileEnding == 'jpg') {
    fileType = 'image';
  } else if (fileEnding == 'wav' || fileEnding == 'mp3') {
    fileType = 'audio_recording';
  } else if (
    fileEnding == 'doc' ||
    fileEnding == 'docx' ||
    fileEnding == 'txt' ||
    fileEnding == 'pdf' ||
    fileEnding == 'xls' ||
    fileEnding == 'xlsx'
  ) {
    fileType = 'document';
  }

  return fileType;
};

// file types: audio_recording, document, image
const FileMessage = ({ fileUrl, fileType }) => {
  let isDocumentFromFacebook = false;
  let forbiddenFileTypes = ['exe', 'sh', 'rar', 'zip', 'tar', 'gz', 'iso', 'dmg', 'bz2', 'tgz'];
  if (fileUrl != null) {
    let fileEnding = getFileEnding(fileUrl);
    if (forbiddenFileTypes.includes(fileEnding.toString().toLowerCase())) {
      // if (fileEnding == "exe" || fileEnding == "sh") {
      return null;
    }
  }

  if (fileType != null) {
    // facebook case
    switch (fileType) {
      case 'image/jpeg':
        fileType = 'image';
        break;
      case 'application/pdf':
        fileType = 'document';
        isDocumentFromFacebook = true;
        break;
      case 'text/plain':
        fileType = 'document';
        isDocumentFromFacebook = true;
        break;
      case 'audio/mpeg':
        fileType = 'audio_recording';
        break;
      default:
        break;
    }
  }

  if (fileType == null && fileUrl != null) {
    fileType = getFileType(fileUrl);
  }

  if (!fileUrl && !fileType) {
    return null;
  }

  switch (fileType) {
    case 'audio_recording':
      return <AudioMessage fileUrl={fileUrl} />;
    case 'image':
      return <ImageMessage fileUrl={fileUrl} />;
    case 'document':
      if (!isDocumentFromFacebook) {
        return <DocumentMessage fileUrl={fileUrl} />;
      }
      return <FacebookDocumentMessage fileUrl={fileUrl} />;
    default:
      return null;
  }
};

export default FileMessage;
