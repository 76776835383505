import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import { StyledEngineProvider } from '@mui/system';

import { Input } from 'semantic-ui-react';
import Button from '@mui/material/Button';
import debounce from 'lodash/debounce';

import Modal from 'pages/Flow/components/Modal';
import FlowFilter from './components/FlowFilter';
import { objectThemes as themes, ThemeContext } from '../Flow/utils/Theme';
import IntentDialog from '../Flow/components/Canvas/components/NodeSettingsProvider/components/IntentDialog';
import { setLoading } from '../../store/global/actions';
import Retrain, { RetrainStatusProvider } from '../Flow/components/Retrain';
import CollectionIntents from './containers/CollectionIntents';
import CollectionEntities from './containers/CollectionEntities';
import EntityDialog from '../Flow/components/Canvas/components/NodeSettingsProvider/components/EntityDialog';
import CollectionEntityTypes from './containers/CollectionEntityTypes';
import EntityTypeDialog from '../Flow/components/Canvas/components/NodeSettingsProvider/components/EntityTypeDialog';
import MessageDialog from '../Flow/components/Canvas/components/NodeSettingsProvider/components/MessageDialog';
import CollectionMessages from './containers/CollectionMessages';
import CollectionScripts from './containers/CollectionScripts';
import ScriptDialog from '../Flow/components/Script/ScriptDialog';
import ServiceDialog from '../Flow/components/Canvas/components/NodeSettingsProvider/components/ServiceDialog';
import CollectionServices from './containers/CollectionServices';
import languageMap from 'Language/Language';
import {
  COLLECTION_ENTITIES,
  COLLECTION_ENTITY_TYPES,
  COLLECTION_INTENTS,
  COLLECTION_MESSAGES,
  COLLECTION_SCRIPTS,
  COLLECTION_SERVICES,
} from '../../utils/constants';

import extractLanguageInfo from '../../utils/extractLanguageInfo';
import mainTheme from '../../mainTheme';

const labels = {
  intents: {
    title: [languageMap.intents],
    create: [languageMap.createIntent],
  },
  entities: {
    title: [languageMap.entities],
    create: [languageMap.createEntity],
  },
  entity_types: {
    title: [languageMap.entityTypes],
    create: [languageMap.createEntityType],
  },
  messages: {
    title: [languageMap.messages],
    create: [languageMap.createMessage],
  },
  scripts: {
    title: [languageMap.scripts],
    create: [languageMap.createScript],
  },
  services: {
    title: [languageMap.services],
    create: [languageMap.createService],
  },
};
const Collection = ({ dispatch, projectId, collection, languageOptions, selectedLanguage }) => {
  const [search, setSearch] = useState(undefined);
  const [appId, setAppId] = useState('');
  const [appData, setAppData] = useState('');
  const [settings, setObjectSettings] = useState(null);
  const [listCounter, setListCounter] = useState(0);
  const [selectedFlows, setSelectedFlows] = useState([]);
  const onChangeSearch = useCallback(
    debounce((value) => {
      setSearch(value);
    }, 300),
    []
  );

  const onCreateGeneric = (objectClass) => async () => {
    dispatch(setLoading(true));
    try {
      let response = await Axios.get(`/internal_api/project/${projectId}/flow/${appId}/data`);
      setAppData(response.data);
      response = await Axios.post('/internal_api/' + objectClass, { appId, projectId });
      if (response.data) {
        const { [objectClass + '_id']: id, [objectClass + '_name']: name } = response.data;
        // console.log(id, name);
        setObjectSettings({ objectClass, objectId: id, objectName: name, nodeId: '' });
        setListCounter(listCounter + 1);
      }
      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setLoading(false));
    }
  };

  const onDeleteGeneric = (objectClass) => async (id) => {
    dispatch(setLoading(true));
    try {
      const response = await Axios.delete(`/internal_api/${objectClass}`, { data: { id } });
      if (response.data) {
        if (response.data.status === 'error') {
          // Informing user of special failure cases when we don't delete (e.g. entity type is instantiated by entities in project).
          alert(response.data.message);
        } else {
          setListCounter(listCounter + 1);
        }
        dispatch(setLoading(false));
      }
    } catch (e) {
      dispatch(setLoading(false));
    }
  };

  let Component, onCreate, onDelete;
  switch (collection) {
    case COLLECTION_ENTITIES:
      Component = CollectionEntities;
      onCreate = onCreateGeneric('entity');
      onDelete = onDeleteGeneric('entity');
      break;
    case COLLECTION_ENTITY_TYPES:
      Component = CollectionEntityTypes;
      onCreate = onCreateGeneric('entity_type');
      onDelete = onDeleteGeneric('entity_type');
      break;
    case COLLECTION_MESSAGES:
      Component = CollectionMessages;
      onCreate = onCreateGeneric('message');
      onDelete = onDeleteGeneric('message');
      break;
    case COLLECTION_SCRIPTS:
      Component = CollectionScripts;
      onCreate = onCreateGeneric('script');
      onDelete = onDeleteGeneric('script');
      break;
    case COLLECTION_SERVICES:
      Component = CollectionServices;
      onCreate = onCreateGeneric('service');
      onDelete = onDeleteGeneric('service');
      break;
    case COLLECTION_INTENTS:
    default:
      Component = CollectionIntents;
      onCreate = onCreateGeneric('intent');
      onDelete = onDeleteGeneric('intent');
  }

  const closeEdit = () => {
    setObjectSettings(null);
  };

  const onNameChange = (name) => {
    setObjectSettings({ ...settings, objectName: name });
    setListCounter(listCounter + 1);
  };

  const updateObjectScript = (objectClass, object) => {
    const stateKey = objectClass + 's';
    const currentObjects = appData[stateKey];
    if (currentObjects == null) {
      return;
    }
    const newObjects = currentObjects.map((row) => {
      if (row[`${objectClass}_id`] === object[`${objectClass}_id`]) {
        return object;
      }
      return row;
    });

    setAppData({ ...appData, [stateKey]: newObjects });
  };

  const renderSettingsDrawer = () => {
    const { objectId, objectClass, objectName } = settings;

    switch (objectClass) {
      case 'intent':
        return (
          <IntentDialog
            appId={appId}
            nodeId={objectId}
            objectId={objectId}
            entities={appData.entities}
            onNameChange={onNameChange}
            objectName={objectName}
            hideConnections
            onClose={closeEdit}
            languageOptions={languageOptions}
            selectedLanguage={selectedLanguage}
            deleteNode={onDelete}
          />
        );
      case 'message':
        return (
          <MessageDialog
            appId={appId}
            projectId={projectId}
            messageId={objectId}
            nodeId={objectId}
            // objectModel={objectFromNode}
            hideConnections
            onNameChange={onNameChange}
            objectName={objectName}
            onClose={closeEdit}
            languageOptions={languageOptions}
            selectedLanguage={selectedLanguage}
            deleteNode={onDelete}
          />
        );
      case 'entity':
        return (
          <EntityDialog
            appId={appId}
            nodeId={objectId}
            entities={appData.entities}
            projectId={projectId}
            entityTypes={appData.entity_types}
            entityId={objectId}
            onNameChange={onNameChange}
            objectName={objectName}
            hideConnections
            onClose={closeEdit}
            languageOptions={languageOptions}
            selectedLanguage={selectedLanguage}
            deleteNode={onDelete}
          />
        );
      case 'entity_type':
        return (
          <EntityTypeDialog
            onClose={closeEdit}
            id={objectId}
            projectId={projectId}
            onNameChange={onNameChange}
            languageOptions={languageOptions}
            selectedLanguage={selectedLanguage}
            deleteNode={onDelete}
            nodeId={objectId}
          />
        );
      case 'script':
        return (
          <ScriptDialog
            nodeId={objectId}
            script={
              appData.scripts.find((script) => script.script_id === objectId) || {
                script_id: objectId,
              }
            }
            appId={appId}
            projectId={projectId}
            onClose={closeEdit}
            onNameChange={onNameChange}
            objectName={objectName}
            updateObject={updateObjectScript}
            deleteNode={onDelete}
            hideConnections
          />
        );
      case 'service':
        return (
          <ServiceDialog
            appId={appId}
            serviceId={objectId}
            nodeId={objectId}
            onClose={closeEdit}
            onNameChange={onNameChange}
            objectName={objectName}
            hideConnections
            deleteNode={onDelete}
          />
        );
      default:
        break;
    }
  };
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: 'auto',
          maxWidth: 1024,
          height: '100%',
        }}
      >
        <div
          style={{ display: 'flex', marginTop: 40, paddingBottom: 20, justifyContent: 'center' }}
        >
          <Input
            placeholder={languageMap.search}
            icon="search"
            style={{ height: '41px', width: '300px' }}
            onChange={(e) => onChangeSearch(e.target.value)}
          />

          <FlowFilter
            disabled={collection === 'entity_types'}
            projectId={projectId}
            selectedFlows={selectedFlows}
            setAppId={setAppId}
            onChange={(flows) => setSelectedFlows(flows)}
          />

          <Button
            style={{ marginLeft: 16 }}
            variant="outlined"
            color="primary"
            onClick={onCreate}
            disabled={!appId}
          >
            {labels[collection].create}
          </Button>
          <Retrain style={{ marginRight: 5, marginLeft: 'auto' }} />
        </div>
        <div style={{ flexGrow: 1, overflow: 'auto' }}>
          <Component
            search={search}
            selectedFlows={selectedFlows}
            projectId={projectId}
            setObjectSettings={setObjectSettings}
            setAppData={setAppData}
            setAppId={setAppId}
            appId={appId}
            listCounter={listCounter}
            onDelete={onDelete}
          />
        </div>
        {settings && (
          <Modal onHide={closeEdit}>
            <ThemeContext.Provider value={themes[settings.objectClass]}>
              <RetrainStatusProvider>
                <StyledEngineProvider injectFirst>
                  <MuiThemeProvider
                    theme={createTheme({
                      ...mainTheme,
                      palette: {
                        ...mainTheme.palette,
                        primary: {
                          main: themes[settings.objectClass].main,
                          contrastText: '#FFF',
                        },
                      },
                    })}
                  >
                    {renderSettingsDrawer()}
                  </MuiThemeProvider>
                </StyledEngineProvider>
              </RetrainStatusProvider>
            </ThemeContext.Provider>
          </Modal>
        )}
      </div>
    </>
  );
};

export default connect(extractLanguageInfo)(Collection);
