import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { useResolvedPath } from 'react-router-dom';
import { twin } from 'utils/twin';

import ConversationRatings from '../Widgets/ConversationRatings';
import Table from '../../../../../../../components/Table';
import TableAvatar from '../Widgets/TableAvatar';

import languageMap from 'Language/Language';

import {
  getConversationRating,
  getTableFeedback,
  getRemarks,
  getFeedbackScore,
} from '../../../../../../../store/analytics/selectors';
import {
  setConversationRating,
  setTableFeedback,
  setRemarks,
  setFeedbackScore,
} from '../../../../../../../store/analytics/actions';

const Feedback = ({
  projectId,
  startTimestamp,
  endTimestamp,
  selectedTimeInterval,
  currentTimezone,
  setConversationRating,
  setTableFeedback,
  setRemarks,
  setFeedbackScore,
  conversationRatings,
  tableFeedback,
  remarks,
  feedbackScore,
  forPrint,
}) => {
const url = useResolvedPath("").pathname;
  const clean = url.substring(0, url.indexOf('Feedback'));

  useEffect(() => {
    const populateGetConversationRating = async () => {
      const response = await axios.get('/internal_api/analytics/get_coversation_ratings', {
        params: {
          projectId: projectId,
          timezone: currentTimezone,
          startTimestamp,
          endTimestamp,
        },
      });

      let auxDataSet = response.data;
      let conversationRatingsObject = {};

      conversationRatingsObject.prettifiedDays = auxDataSet.prettified_days;
      conversationRatingsObject.averages = [];

      for (let i = 1; i < Object.keys(auxDataSet.averages).length + 1; i++) {
        conversationRatingsObject.averages.push(auxDataSet.averages[i]);
      }
      conversationRatingsObject.info = languageMap.infoReportsConversationRatings;
      conversationRatingsObject.colorBreakdown = auxDataSet.breakdown_color;
      conversationRatingsObject.title = auxDataSet.chart_name;
      conversationRatingsObject.comparisonText = auxDataSet.comparison_text;
      conversationRatingsObject.comparisonValue = auxDataSet.comparison_value;
      conversationRatingsObject.data = auxDataSet.data;
      conversationRatingsObject.feedbackAverage = auxDataSet.feedback_average;

      setConversationRating(conversationRatingsObject);
    };

    const populateTableFeedback = async () => {
      const response = await axios.get('/internal_api/analytics/get_customer_feedback', {
        params: {
          projectId: projectId,
          timeInterval: selectedTimeInterval,
          startTimestamp,
          endTimestamp,
          timezone: currentTimezone,
          remarksOnly: remarks === true ? 1 : 0,
          feedbackScore: feedbackScore,
        },
      });
      let tableFeedbackObject = {};
      let auxDataSet = response.data;
      auxDataSet.table_column_header.push(true);
      tableFeedbackObject.info = languageMap.infoReportsCustomerFeedback;
      tableFeedbackObject.title = auxDataSet.chart_name;
      tableFeedbackObject.columns = auxDataSet.table_column_header;
      tableFeedbackObject.data = [];
      tableFeedbackObject.userAvatarLink = [];
      tableFeedbackObject.agentAvatarLink = [];

      tableFeedbackObject.conversationLink = [];
      for (let i = 0; i < auxDataSet.data.length; i++) {
        const temporaryDataObject = {};
        tableFeedbackObject.userAvatarLink.push(
          `${
            auxDataSet.data?.[i]?.['client_avatar'] ? auxDataSet.data?.[i]?.['client_avatar'] : ''
          } `
        );
        temporaryDataObject[auxDataSet?.table_column_header?.[0]] = `${
          auxDataSet.data?.[i]?.['client_name']
            ? auxDataSet.data?.[i]?.['client_name']
            : 'Unknown user'
        }`;

        tableFeedbackObject.agentAvatarLink.push(
          ` ${
            auxDataSet.data?.[i]?.['agent_avatar'] ? auxDataSet.data?.[i]?.['agent_avatar'] : ''
          } `
        );
        temporaryDataObject[auxDataSet?.table_column_header?.[1]] = `${
          auxDataSet.data?.[i]?.['agent_name'] ? auxDataSet.data?.[i]?.['agent_name'] : 'Unassigned'
        }`;
        temporaryDataObject[auxDataSet?.table_column_header?.[2]] = `${
          auxDataSet.data?.[i]?.['rating'] ? auxDataSet.data?.[i]?.['rating'] : 'n/a'
        }`;
        temporaryDataObject[auxDataSet?.table_column_header?.[3]] = `${
          auxDataSet.data?.[i]?.['remark'] ? auxDataSet.data?.[i]?.['remark'] : 'n/a'
        }`;
        tableFeedbackObject.conversationLink.push(
          `${clean}inbox/${auxDataSet.data?.[i]?.['user_id']}_${auxDataSet.data?.[i]?.['conversation_id']}`
        );

        tableFeedbackObject.data.push(temporaryDataObject);
      }

      setTableFeedback(tableFeedbackObject);
    };
    populateGetConversationRating();
    populateTableFeedback();
  }, [selectedTimeInterval, startTimestamp, endTimestamp, projectId, remarks, feedbackScore]);

  const setRemarksValue = (value) => {
    setRemarks(value);
  };

  const setFeedbackScoreValue = (value) => {
    setFeedbackScore(value);
  };
  const dataEnhancment = (tableFeedback) => {
    for (let i = 0; i < tableFeedback?.data?.length; i++) {
      tableFeedback.data[i]['User'] = (
        <TableAvatar
          link={tableFeedback.userAvatarLink[i]}
          user={tableFeedback.data[i]['User']}
        ></TableAvatar>
      );
      tableFeedback.data[i]['Agent'] = (
        <TableAvatar
          link={tableFeedback.agentAvatarLink[i]}
          user={tableFeedback.data[i]['Agent']}
        ></TableAvatar>
      );
      tableFeedback.data[i]['true'] = (
        <a href={tableFeedback.conversationLink[i]}>{languageMap.conversation}</a>
      );
    }
    return tableFeedback;
  };

  return (
    <div>
      <div className="graph-container" style={{ pageBreakAfter: 'always' }}>
        <div className="row-container" style={{ height: 'auto' }}>
          <div className="graphs-row">
            <ConversationRatings
              conversationRatingsData={conversationRatings}
            ></ConversationRatings>
          </div>
        </div>
      </div>

      <div className="table-container">
        <div className="row-container-table">
          <div className="table-row">
            <Table
              tableData={dataEnhancment(twin(tableFeedback))}
              remarks={true}
              className="table-element"
              setRemarksValue={setRemarksValue}
              setFeedbackScoreValue={setFeedbackScoreValue}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  conversationRatings: getConversationRating(state),
  tableFeedback: getTableFeedback(state),
  remarks: getRemarks(state),
  feedbackScore: getFeedbackScore(state),
});
const mapDispatchToProps = {
  setConversationRating: setConversationRating,
  setTableFeedback: setTableFeedback,
  setRemarks: setRemarks,
  setFeedbackScore: setFeedbackScore,
};

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
