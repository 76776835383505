import React, { Component } from 'react';

export default class InputText extends Component {
  onValueChanged = (event) => {
    this.props.onValueChanged(event.target.name, event.target.value);
  };

  render() {
    // eslint-disable-next-line no-unused-vars
    const { title, style, type, name, shouldBeColumn, disabled, onValueChanged, ...otherProps } =
      this.props;

    const containerStyle = {
      display: 'flex',
      flexDirection: shouldBeColumn ? 'column' : 'row',
      justifyContent: title ? 'space-between' : 'normal',
    };

    const inputStyle = {
      backgroundColor: '#f6f6f6',
      color: 'black',
      border: 'none',
      padding: '8px 20px 8px 20px',
      fontSize: 14,
      height: 37,
      borderRadius: 4,
      ...style,
    };

    let input = (
      <input
        type={type || 'text'}
        style={inputStyle}
        onChange={this.onValueChanged}
        name={name || ''}
        disabled={disabled}
        {...otherProps}
      />
    );

    if (title) {
      return (
        <div style={containerStyle}>
          <div className="Title">{title}</div>
          {input}
        </div>
      );
    } else {
      return input;
    }
  }
}
