import React from 'react';
import { ThemeContext } from '../../utils/Theme';

import Axios from 'axios';
import InputText from '../../../../components/common/input/InputText';

export default class NameInput extends React.Component {
  static contextType = ThemeContext;

  constructor(props) {
    super(props);

    this.state = {
      serverValue: this.props.value,
      value: this.props.value,
    };
  }

  handleChange = (event) => {
    const value = event.target.value;
    this.setState({ value: value });
  };

  handleBlur = (event) => {
    if (this.state.value === this.state.serverValue) return;
    if (!this.props.appId) {
      this.props.onNameChange(this.props.nodeId, this.state.value);
      return;
    }
    const params = {
      appId: this.props.appId,
      id: this.props.id,
      type: this.props.type,
      name: this.state.value,
    };

    Axios.post('/internal_api/update_object_name', params).then((response) => {
      if (response.data.status === 'ok') {
        this.setState({
          serverValue: response.data.value,
          value: response.data.value,
        });

        this.props.onNameChange(this.props.nodeId, response.data.value);
      } else {
        this.setState({ value: this.state.serverValue });
        alert('Encountered error while updating object name : ' + response.data.message);
      }
    });
  };

  render() {
    const input_style = {
      flex: '1 0 auto',
      height: 37,
      borderRadius: 4,
    };

    return (
      <InputText
        style={input_style}
        autoComplete="off"
        type="text"
        value={this.state.value}
        // onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        disabled={this.props.disabled || false}
      />
    );
  }
}
