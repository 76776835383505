import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box } from '@mui/material';
import languageMap from 'Language/Language';
import Documents from './components/Documents/Documents';
import Sites from './components/Sites/Sites';
import { UPLOAD_SCOPE_GEN_AI } from '../../../../utils/constants';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const GenerativeAI = ({ projectId }) => {
  const [tab, setTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <div style={{ marginRight: 15, marginLeft: 15, height: '100%' }}>
      <div style={{ maxWidth: 1024, margin: 'auto', height: '100%' }}>
        <Box style={{ borderBottom: 1, borderColor: '#334bfa', marginTop: 20 }}>
          <Tabs indicatorColor="primary" value={tab} onChange={handleChange}>
            <Tab key={languageMap.documents} label={languageMap.documents} {...a11yProps(1)} />
            <Tab key={languageMap.sites} label={languageMap.sites} {...a11yProps(2)} />
          </Tabs>
        </Box>
        {tab === 0 && <Documents projectId={projectId} scope={UPLOAD_SCOPE_GEN_AI} />}
        {tab === 1 && <Sites projectId={projectId} />}
      </div>
    </div>
  );
};

export default GenerativeAI;
