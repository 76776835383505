import React from 'react';

const LoginPanel = (props) => (
  <svg
    width="724"
    height="839"
    viewBox="0 0 724 839"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_702_1198"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="724"
      height="839"
    >
      <rect width="724" height="839" rx="25" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_702_1198)">
      <mask
        id="mask1_702_1198"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="869"
        height="1066"
      >
        <rect width="868.98" height="1065.63" rx="25" fill="url(#paint0_linear_702_1198)" />
      </mask>
      <g mask="url(#mask1_702_1198)">
        <path
          d="M2436.88 -53.6516H-52.4429C-66.25 -53.6516 -77.4429 -42.4587 -77.4429 -28.6516V1120.18C-77.4429 1133.99 -66.2501 1145.18 -52.4429 1145.18H2436.88C2450.68 1145.18 2461.88 1133.99 2461.88 1120.18V-28.6516C2461.88 -42.4587 2450.68 -53.6516 2436.88 -53.6516Z"
          fill="url(#paint1_linear_702_1198)"
        />
        <mask
          id="mask2_702_1198"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="-78"
          y="-54"
          width="2540"
          height="1198"
        >
          <path
            d="M2436.88 -53.4661H-52.0762C-65.8833 -53.4661 -77.0762 -42.2732 -77.0762 -28.4661V1118.31C-77.0762 1132.12 -65.8833 1143.31 -52.0762 1143.31H2436.88C2450.68 1143.31 2461.88 1132.12 2461.88 1118.31V-28.4661C2461.88 -42.2732 2450.68 -53.4661 2436.88 -53.4661Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask2_702_1198)">
          <path
            d="M147.342 262.112L275.076 486.913C286.802 507.485 315.648 507.378 327.27 486.806L361.097 426.582C366.493 416.989 366.493 405.264 361.097 395.671L269.473 231.094C264.077 221.394 254.116 215.531 243.324 215.531H173.387C150.04 215.531 135.617 241.54 147.342 262.112Z"
            fill="white"
          />
          <path
            d="M319.793 262.113L447.527 486.914C459.252 507.486 488.099 507.379 499.721 486.807L533.548 426.583C538.944 416.99 538.944 405.265 533.548 395.672L441.924 231.095C436.528 221.395 426.567 215.532 415.775 215.532H345.838C322.491 215.532 308.067 241.541 319.793 262.113Z"
            fill="white"
          />
          <path
            d="M493.389 261.793C506.152 284.923 518.915 308.054 531.678 331.291C543.196 352.182 572.457 352.289 584.079 331.504C597.05 308.373 610.02 285.243 622.991 262.006C634.613 241.327 620.085 215.532 596.842 215.532H519.641C496.502 215.532 481.975 241.114 493.389 261.793Z"
            fill="white"
          />
          <path
            d="M526.916 1289.07C701.324 986.857 607.17 605.819 316.617 437.997C26.0635 270.176 -350.862 379.12 -525.271 681.331C-699.679 983.543 -605.525 1364.58 -314.972 1532.4C-24.4182 1700.22 352.508 1591.28 526.916 1289.07Z"
            fill="url(#paint2_linear_702_1198)"
          />
        </g>
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_702_1198"
        x1="0"
        y1="0"
        x2="1043.84"
        y2="851.206"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#868CFF" />
        <stop offset="1" stopColor="#4318FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_702_1198"
        x1="-77.4429"
        y1="545.757"
        x2="2461.89"
        y2="545.757"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.05" stopColor="#00CCFF" />
        <stop offset="1" stopColor="#0600F6" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_702_1198"
        x1="282.402"
        y1="1483.23"
        x2="-631.481"
        y2="-40.7786"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.05" stopColor="#00CCFF" />
        <stop offset="0.81" stopColor="#051CF7" />
        <stop offset="1" stopColor="#0600F6" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default LoginPanel;
