import React from 'react';

import getAssetPath from '../../utils/getAssetPath';
export const TectuLogo = (props) => {
  return (
    <div {...props}>
      <img src={getAssetPath('tectuLogo.png')} style={{ width: '100%' }} />
    </div>
  );
};
