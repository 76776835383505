import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Popup } from 'semantic-ui-react';
import ProfileIcon from 'assets/menuIcons/Profile';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PeopleIcon from '@mui/icons-material/People';
import RecentActorsIcon from '@mui/icons-material/RecentActors';

import { setSubMenu } from 'store/crm/actions';
import { selectCurrentUser, selectMainMenu, selectSubMenu } from 'store/crm/selectors';
import UserProfile from 'pages/CRM/Profile/UserProfile';
import ProjectSettings from 'pages/ProjectSettings/ProjectSettings';

import Users from './components/Users';
import Departments from './components/Departments';
import UserManagement from './components/UserManagement';
import DepartmentManagement from './components/DepartmentManagement';

import { MAIN_COLOR } from '../../mainTheme';
import { hasAccess } from 'services/userPermissions';
import languageMap from 'Language/Language';

import './SettingsMenu.scss';
import { PROFILE, PROJECT_SETTINGS, SETTINGS } from '../../utils/constants';

const SettingsMenu = (props) => {
  const { currentUser, mainMenu, setSubMenu, subMenu } = props;

  const [managedUser, setManagedUser] = useState({});
  const [managedDepartment, setManagedDepartment] = useState({});

  const { id: projectId } = useParams();
  const { permissions } = currentUser;
  const navigate = useNavigate();

  const unselectedColor = '#A3AED0';
  const selectedColor = '#0530F9';
  const profileIcon = <ProfileIcon fill={subMenu === PROFILE ? selectedColor : unselectedColor} />;
  const hasPermissions = permissions[projectId].length > 0;

  const shouldRenderMenu = (menu) => {
    if (projectId && hasPermissions) {
      return hasAccess(projectId, permissions, menu);
    }
    return false;
  };

  const onClick = (subMenu) => () => {
    navigate('/project/' + projectId + '/crm/' + subMenu);
    setSubMenu(subMenu);
  };

  const isSelected = (menu) => {
    return mainMenu === SETTINGS && menu === subMenu;
  };

  const selectionMenuSettings = [
    {
      title: languageMap.profile,
      value: PROFILE,
      icon: profileIcon,
      onClick,
    },
  ];

  if (hasAccess(projectId, permissions, 'bot')) {
    selectionMenuSettings.push({
      title: languageMap.projectSettings,
      value: PROJECT_SETTINGS,
      icon: <AccountTreeIcon style={{ color: 'rgb(163, 174, 208)' }} />,
      onClick,
    });
  }
  if (hasAccess(projectId, permissions, 'userManagement')) {
    selectionMenuSettings.push(
      {
        title: languageMap.users,
        value: 'users',
        icon: <PeopleIcon style={{ color: 'rgb(163, 174, 208)' }} />,
        onClick,
      },
      {
        title: languageMap.departments,
        value: 'departments',
        icon: <RecentActorsIcon style={{ color: 'rgb(163, 174, 208)' }} />,
        onClick,
      }
    );
  }

  return (
    <div className="SettingsMenu">
      <div className="settings-menu">
        <div className="settings-header title-text">{languageMap.settings}</div>
        {selectionMenuSettings.map(
          (section) =>
            (section.value !== SETTINGS || shouldRenderMenu(SETTINGS)) && (
              <Popup
                key={`${section.value}_section`}
                style={{ color: MAIN_COLOR }}
                trigger={
                  <div key={`${section.value}_section`} className="MenuReportsComponent">
                    <div
                      className="settings-menu-lines-container"
                      onClick={section.onClick(section.value)}
                    >
                      <div className="settings-menu-lines">
                        {
                          <div className={'icon ' + (isSelected(section.value) ? 'selected' : '')}>
                            {section.icon}
                          </div>
                        }
                        <div className="sub-section-name dm-sans-500 title">{section.title}</div>
                      </div>
                      {isSelected(section.value) && <div className="selected-bar" />}
                    </div>
                  </div>
                }
                content={section.title}
                size="mini"
                position="right center"
              />
            )
        )}
      </div>
      <div className="sub-menu-container">
        {mainMenu === SETTINGS && subMenu === 'users' && <Users setManagedUser={setManagedUser} />}
        {mainMenu === SETTINGS && subMenu === 'userManagement' && (
          <UserManagement managedUser={managedUser} />
        )}
        {mainMenu === SETTINGS && subMenu === 'departments' && (
          <Departments setManagedDepartment={setManagedDepartment} />
        )}
        {mainMenu === SETTINGS && subMenu === 'departmentManagement' && (
          <DepartmentManagement managedDepartment={managedDepartment} />
        )}

        {mainMenu === SETTINGS && subMenu === PROFILE && projectId && (
          <UserProfile projectId={projectId} currentUser={currentUser} />
        )}
        {mainMenu === SETTINGS && subMenu === PROJECT_SETTINGS && (
          <ProjectSettings projectId={projectId} />
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  mainMenu: selectMainMenu(state),
  subMenu: selectSubMenu(state),
  currentUser: selectCurrentUser(state),
});

const mapDispatchToProps = {
  setSubMenu: setSubMenu,
};
export default connect(mapStateToProps, mapDispatchToProps)(SettingsMenu);
