import { Navigate } from 'react-router-dom';
import React from 'react';
import { selectCurrentUser } from '../../store/crm/selectors';
import { connect } from 'react-redux';
const RequireAuth = ({ children, currentUser }) => {
  if (!currentUser) {
    return <Navigate to={'/login'} replace />;
  }
  return children;
};
const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
});
export default connect(mapStateToProps, null)(RequireAuth);
