import React, { createContext, memo, useContext, useMemo } from 'react';
import entries from 'lodash/entries';
import { NodeUpdater } from 'pages/Flow/utils/canvas-contexts';
import {
  computeLineProps,
  SVG_MARGIN,
} from 'pages/Flow/components/Canvas/utils/nodeCharacteristics';
import './Line.scss';
import getAssetPath from '../../../../../../utils/getAssetPath';

const LinesContext = createContext({});

// applied on pairs of form [key, value]
export const orderPositionSortFunction = (a, b) => {
  const first = a[1].order_position;
  const second = b[1].order_position;
  if (first === 0) {
    return 1;
  }
  if (second === 0) {
    return -1;
  }
  return first - second;
};

const LinesRenderer = memo(({ children }) => {
  const { nodes } = useContext(NodeUpdater);

  const svg_style = useMemo(
    () => ({
      width: 200000,
      height: 200001,
      position: 'relative',
      left: -SVG_MARGIN,
      top: -SVG_MARGIN,
      backgroundImage: `url(${getAssetPath('background.png')})`,
      backgroundRepeat: 'repeat',
    }),
    []
  );

  const lines = useMemo(() => {
    let values = [];
    entries(nodes).forEach(([startNodeId, node]) => {
      entries(node.next)
        .sort(orderPositionSortFunction)
        .forEach(([endNodeId, connection], index) => {
          const newLine = {
            id: `${startNodeId}_${endNodeId}`,
            startNodeId: startNodeId,
            endNodeId,
            offset: index,
            order_position: connection.order_position,
          };

          endNodeId !== '0' && endNodeId !== '-2' && values.push(newLine);
        });
    });

    return values;
  }, [nodes]);

  return (
    <LinesContext.Provider value={{}}>
      <svg style={svg_style}>
        {lines.map((lineItem) => {
          const startNode = nodes[lineItem.startNodeId];
          const endNode = nodes[lineItem.endNodeId];
          return (
            <ConnectionLine
              key={lineItem.id}
              startNode={startNode}
              endNode={endNode}
              offset={lineItem.offset}
            />
          );
        })}

        {children}
      </svg>
    </LinesContext.Provider>
  );
});

const ConnectionLine = memo(({ startNode, endNode, offset }) => {
  const lineProps = computeLineProps(startNode, endNode, offset);

  return <path className="Line" strokeWidth="2" fill="none" {...lineProps} />;
});

export default LinesRenderer;
