import React from 'react';
import MaterialCheckbox from '@mui/material/Checkbox';
import WvIcon from 'assets/svg/WvIcon';
import WvCheckedIcon from 'assets/svg/WvCheckedIcon';

const Checkbox = React.forwardRef((props, ref) => {
  return (
    <MaterialCheckbox
      color="default"
      icon={<WvIcon />}
      checkedIcon={<WvCheckedIcon />}
      style={props.style}
      name={props.name}
      checked={props.checked}
      onChange={props.onChange}
      ref={ref}
      {...props}
    />
  );
});

export default Checkbox;
