import React, { useState } from 'react';
import { v4 } from 'uuid';
import omit from 'lodash/omit';

import { AddButton } from 'components/common/Button/Buttons';
import CarouselEditor from './CarouselEditor';
import { uuidKey } from 'pages/Flow/components/Message/MessageBlocks/blocks-config';
import './CarouselBlock.scss';
import languageMap from 'Language/Language';
import InputText from '../../../../../../components/common/input/InputText';
import HelperTooltip from '../../../../../../components/HelperTooltip/HelperTooltip';
import FormGroup from '../../../../../../components/common/FormGroup/FormGroup';
import OptionCheckbox from '../components/OptionCheckbox';

const CarouselBlock = ({ block, onBlockChange, projectId }) => {
  const { carousel_elements, dataSource } = block;

  const emptySlide = {
    [uuidKey]: v4(),
    url: '',
    text:
      '<div style="font-family: Inter, sans-serif; font-size: 16px;">\n' +
      `<div style="font-weight: 600; text-align: center">${languageMap.title}</div>\n` +
      `<div style="margin-top: 5px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap">${languageMap.shortDescription}</div>\n` +
      '<div style="font-weight: bold;">20$</div>\n' +
      '</div>',
    image: '',
  };

  const injectUuidIntoSlides = (slides) => slides.map((slide) => ({ ...slide, [uuidKey]: v4() }));

  const removeUuidFromSlides = (slides) => slides.map((slide) => omit(slide, uuidKey));

  const [slides, setSlides] = useState(injectUuidIntoSlides(carousel_elements));
  const [fallbackImage, setFallbackImage] = useState(block.fallbackImage || '');
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentDataSource, setCurrentDataSource] = useState(dataSource);
  const [actionButton, setActionButton] = useState(block.actionButton);

  const onSlideChange = (value) => {
    setCurrentSlide(value);
  };

  const onDataSourceSave = () => {
    onBlockChange({
      ...block,
      dataSource: currentDataSource,
    });
  };

  const onDataSourceChange = (name, value) => {
    setCurrentDataSource(value);
  };

  const onFallbackImageSave = () => {
    onBlockChange({
      ...block,
      fallbackImage,
    });
  };
  const onActionButtonChange = (event) => {
    let value = event.target.checked;
    setActionButton(event.target.checked);
    onBlockChange({
      ...block,
      actionButton: value,
    });
  };

  const setSlidesAndEmitChange = (newSlides) => {
    if (newSlides.length === 1) {
      setCurrentDataSource('');
    }
    onBlockChange({
      ...block,
      dataSource: newSlides.length === 1 ? '' : currentDataSource,
      carousel_elements: removeUuidFromSlides(newSlides),
    });
    setSlides(newSlides);
  };

  const addSlide = () => {
    let new_slides = [...slides];
    new_slides.push(emptySlide);
    const lastSlideIndex = new_slides.length - 1;
    setCurrentSlide(lastSlideIndex);

    setSlidesAndEmitChange(new_slides);
  };

  const renderAddSlideButton = () => {
    return (
      <div className="CarouselEditorBlock_AddSlideButton">
        <AddButton
          onClick={addSlide}
          text={slides.length ? languageMap.addElement : languageMap.addManually}
        />
      </div>
    );
  };

  const renderBlockFooter = () => {
    return (
      <div className="CarouselEditorBlock_Footer">
        <FormGroup labelText={'Fallback image'}>
          <InputText
            className="CarouselEditorBlock_Image"
            defaultValue={fallbackImage}
            onValueChanged={(name, value) => setFallbackImage(value)}
            onBlur={onFallbackImageSave}
          />
        </FormGroup>
        {renderAddSlideButton()}
      </div>
    );
  };

  return (
    <div className="CarouselEditorBlock">
      {slides.length === 0 ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <InputText
            value={currentDataSource}
            title={languageMap.dataSource}
            onValueChanged={onDataSourceChange}
            onBlur={onDataSourceSave}
            style={{
              marginLeft: 10,
            }}
          />
          <HelperTooltip
            style={{ marginLeft: 10, height: 25, width: 25 }}
            title={languageMap.dataSourceInfo}
          />
        </div>
      ) : (
        <CarouselEditor
          projectId={projectId}
          slides={slides}
          fallbackImage={fallbackImage}
          actionButton={actionButton}
          setSlidesAndEmitChange={setSlidesAndEmitChange}
          currentSlide={currentSlide}
          setCurrentSlide={setCurrentSlide}
          onSlideChange={onSlideChange}
        />
      )}
      <OptionCheckbox
        checked={actionButton || false}
        onChange={onActionButtonChange}
        title={languageMap.buyButton}
      />
      {renderBlockFooter()}
    </div>
  );
};

export default CarouselBlock;
