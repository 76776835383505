import React, { useCallback, useEffect, useState } from 'react';
import Axios from 'axios';
import _ from 'lodash';

import { AddButton } from 'components/common/Button/Buttons';
import languageMap from 'Language/Language';
import { setLoading } from 'store/global/actions';
import { FlexColumn, FlexRow } from './Misc';
import Utterance from './Utterance';
import { connect } from 'react-redux';
import LoadingOverlay from '../common/LoadingOverlay/LoadingOverlay';
import { useParams } from 'react-router-dom';

/* props : appId, intentId, entityId, entities (db rows)
               utterances example :
                  {31356: {
                      tokens: [
                          {
                              type: 'text',
                              value: 'vreau'
                          },
                          {
                              type: 'entity',
                              entity_id: 999,
                              entity_name: 'fructe',
                              value: 'mere'
                          }
                      ]
                      }
                      autocomplete_visibility: true,
                      language: 'ro',
                  }
    */
function Utterances(props) {
  const [utterances, setUtterances] = useState(props.utterances);
  const [isLoading, setIsLoading] = useState(false);
  const filteredEntities = props.entities.filter((entity) => entity.app_id == props.appId);
  const { dispatch, conflictsPage } = props;

  const { id: projectId } = useParams();

  useEffect(() => {
    setUtterances(props.utterances);
  }, [props.selectedLanguage]);

  const handleToggleVisibility = useCallback(
    (key) => () => {
      const newUtterances = { ...utterances };
      newUtterances[key] = {
        ...newUtterances[key],
        autocomplete_visibility: !newUtterances[key].autocomplete_visibility,
      };
      const id = conflictsPage ? newUtterances[key].utterance_id : key;
      Axios.post('/internal_api/toggle_utterance_visibility', {
        id,
        value: newUtterances[key].autocomplete_visibility,
      });
      props.updateUtterances(newUtterances);
      setUtterances(newUtterances);
    },
    [utterances, setUtterances]
  );

  const handleDelete = useCallback(
    (key) => async () => {
      setIsLoading(true);
      const newUtterances = { ...utterances };
      const id = conflictsPage ? newUtterances[key].utterance_id : key;
      await Axios.delete('/internal_api/delete_utterance', { data: { id } });
      delete newUtterances[key];
      props.updateUtterances(newUtterances);
      setUtterances(newUtterances);
      setIsLoading(false);
    },
    [utterances, setUtterances]
  );

  const handleAdd = useCallback(() => {
    setIsLoading(true);
    Axios.post('/internal_api/create_utterance', {
      app_id: props.appId,
      text: '',
      intent_id: props.intentId,
      entity_id: props.entityId,
      autocomplete_visibility: true,
      language: props.selectedLanguage,
    })
      .then((response) => {
        if (Number.isInteger(response.data)) {
          let newUtterances = { ...utterances };

          newUtterances[response.data] = {
            tokens: [{ type: 'text', value: '' }],
            is_disabled: false,
            autocomplete_visibility: true,
            language: props.selectedLanguage,
            utterance_id: response.data,
          };

          props.updateUtterances(newUtterances);

          setUtterances(newUtterances);
        } else {
          alert('Could not create new utterance');
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  }, [utterances, setUtterances, props.appId, props.intentId, props.selectedLanguage]);

  const handleLoadMore = useCallback(() => {
    let url = '/internal_api/get_all_utterances';
    dispatch(setLoading(true));

    let params = {
      app_id: props.appId,
      intent_id: props.intentId,
    };

    if (conflictsPage) {
      url = '/internal_api/qna/conflicts/get_all_utterances';
      params.intent_id_1 = conflictsPage[0];
      params.intent_id_2 = conflictsPage[1];
      params.project_id = projectId;
    }
    Axios.get(url, {
      params,
    })
      .then((response) => {
        dispatch(setLoading(false));
        const utterances_by_language = response.data;
        const utterances = utterances_by_language[props.selectedLanguage]?.utterances;
        props.updateUtterances(utterances);
        setUtterances(utterances);
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  }, [setUtterances, props.appId, props.intentId, props.selectedLanguage]);

  const saveUtterance = useCallback(
    (key) => async (newTokens) => {
      let text = '';
      let tokens = newTokens || utterances[key].tokens;
      tokens = _.cloneDeep(tokens);
      tokens.forEach((token) => {
        if (text && text[text.length - 1] !== ' ') {
          text += ' ';
        }
        if (token.type === 'text') {
          text += token.value;
        } else if (token.type === 'entity') {
          text += '{' + token.entity_id + ':' + token.value + '}';
        }
      });
      const newUtterances = { ...utterances };
      newUtterances[key] = { ...newUtterances[key], tokens };

      setUtterances(newUtterances);
      setIsLoading(true);
      const id = conflictsPage ? newUtterances[key].utterance_id : key;
      try {
        await Axios.post('/internal_api/update_utterance', { id, value: text });
      } catch (e) {
        setIsLoading(false);
        return;
      }
      props.updateUtterances({ ...utterances });
      setIsLoading(false);
    },
    [utterances, setIsLoading, props.updateUtterances]
  );

  // code adds key as property
  let orderedUtterances = [];
  if (utterances) {
    Object.keys(utterances).forEach((key) => {
      orderedUtterances.push({
        ...utterances[key],
        key,
      });
    });
  }
  // on conflicts page, the keys are already sorted
  if (!conflictsPage) {
    orderedUtterances = orderedUtterances.sort((a, b) => b.key - a.key);
  }

  function renderUtterances(utterances) {
    return (
      <FlexColumn>
        {utterances.map((utterance) => {
          const key = utterance.key;
          return (
            <Utterance
              entities={filteredEntities}
              key={key}
              onToggleVisibility={handleToggleVisibility(key)}
              onDelete={handleDelete(key)}
              onSave={saveUtterance(key)}
              data={utterance}
              language={props.selectedLanguage}
            />
          );
        })}
      </FlexColumn>
    );
  }

  return (
    <FlexColumn style={{ ...props.style, position: 'relative' }}>
      <LoadingOverlay isLoading={isLoading} />
      {renderUtterances(orderedUtterances)}

      <FlexRow>
        <AddButton
          icon="add"
          text={languageMap.addNewTrainingPhrase}
          onClick={handleAdd}
          style={{ marginTop: 10, marginBottom: 10 }}
        />
        {props.hasMore && orderedUtterances.length <= 15 && (
          <AddButton
            text={languageMap.loadMore}
            onClick={handleLoadMore}
            style={{ marginBottom: 10, marginTop: 10, marginLeft: 5 }}
          />
        )}
      </FlexRow>
    </FlexColumn>
  );
}

export default connect(null)(Utterances);
