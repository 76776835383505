const onAssign = (data, props) => {
  const {
    inboxFolder,
    conversations,
    currentConversation,
    myDepartmentsIDs,
    currentUser,
    conversationStatusFilter,
  } = props;

  const {
    conversation_meta,
    conversation_meta: { conversation_id, conversation_status },
    previous_department_id_assigned,
    department_id_assigned,
    previous_assignee,
    assignee,
  } = data;

  const isCurrentFilter = conversation_status === conversationStatusFilter;
  const didDepartmentChange = department_id_assigned !== previous_department_id_assigned;
  const didAgentChange = assignee !== previous_assignee;
  const previousDepartmentBelongsToMe = myDepartmentsIDs.includes(previous_department_id_assigned);
  const currentDepartmentBelongsToMe = myDepartmentsIDs.includes(department_id_assigned);
  const isUnassigned = assignee === -1;
  const wasUnassigned = previous_assignee === -1;
  const isFolderYou = inboxFolder.submenu === 'External' && inboxFolder.file.value === 'you';
  const isFolderUnassigned =
    inboxFolder.submenu === 'External' && inboxFolder.file.value === 'unassigned';
  const isFolderAll =
    inboxFolder.submenu === 'External' && inboxFolder.file.value === 'external_all';

  const isAssignedToMe = assignee === currentUser.user_id;
  const wasAssignedToMe = previous_assignee === currentUser.user_id;
  const conversationIsInList = conversations.some(
    (item) => item.conversation_id === conversation_id
  );
  const currentlySelected = currentConversation.conversation_id === conversation_id;

  if (!isCurrentFilter) {
    // we do not care for conversations outside the filter
    return;
  }

  // COMPUTE COUNTS
  // It is possible to have both a department change and agent change at the same time (when changing departments
  // the conversation becomes unassigned)
  if (didDepartmentChange) {
    if (previousDepartmentBelongsToMe && currentDepartmentBelongsToMe) {
      // If we change between two owned departments -> we still increment unread
      props.addToExternalAllUnread({ conversation_id });
    } else {
      if (previousDepartmentBelongsToMe) {
        if (wasUnassigned) {
          props.decrementUnassigned();
          props.removeFromUnassignedUnread({ conversation_id });
        }
        props.decrementAll();
        props.removeFromExternalAllUnread({ conversation_id });
      }
      if (currentDepartmentBelongsToMe) {
        if (isUnassigned) {
          props.incrementUnassigned();
          props.addToUnassignedUnread({ conversation_id });
        }
        props.incrementAll();
        props.addToExternalAllUnread({ conversation_id });
      }
    }
  }
  // we don't need to check for agent change here because dept change means unassigned
  if (wasAssignedToMe) {
    props.decrementYou();
    props.removeFromYouUnread({ conversation_id });
  }
  if (isAssignedToMe) {
    props.incrementYou();
    props.addToYouUnread({ conversation_id });
  }

  if (didAgentChange && currentDepartmentBelongsToMe) {
    if (isUnassigned && !didDepartmentChange) {
      // already incremented in department zone
      props.incrementUnassigned();
      props.addToUnassignedUnread({ conversation_id });
    }
    if (!isUnassigned && wasUnassigned) {
      props.decrementUnassigned();
      props.removeFromUnassignedUnread({ conversation_id });
    }
    // after the assign event (which increases the counters) a message event will come
    // and it will decrease the counters if conversation is selected
    props.addToExternalAllUnread({ conversation_id });
  }

  // Update conversations you folder
  if (isFolderYou) {
    if (isAssignedToMe) {
      props.setNewConversation({ conversation_id, conversation_meta });
    }
    if (wasAssignedToMe && conversationIsInList) {
      props.removeConversation({ conversation_id });
    }
  }

  // Update conversations unassigned folder
  if (isFolderUnassigned) {
    if (didAgentChange || didDepartmentChange) {
      if (isUnassigned && currentDepartmentBelongsToMe) {
        props.setNewConversation({ conversation_id, conversation_meta });
      }
      if (!isUnassigned || previousDepartmentBelongsToMe) {
        props.removeConversation({ conversation_id });
      }
    }
  }

  // Update conversations all folder folder
  if (isFolderAll && didDepartmentChange) {
    if (currentDepartmentBelongsToMe && previousDepartmentBelongsToMe) {
      // ignore
    } else {
      if (currentDepartmentBelongsToMe) {
        props.setNewConversation({ conversation_id, conversation_meta });
      }
      if (previousDepartmentBelongsToMe) {
        props.removeConversation({ conversation_id });
      }
    }
  }

  // CURRENTLY SELECTED CONVERSATION
  if (currentDepartmentBelongsToMe) {
    props.updateConversationInList({ conversation_id, conversation_meta });
    if (currentlySelected) {
      props.setCurrentConversation(conversation_meta);
    }
  }
};

export default onAssign;
