import React from 'react';
import Retrain from 'pages/Flow/components/Retrain';
import Back from 'assets/svg/Back';
import { CurrentUser } from 'containers/CurrentUser';
import Link from './components/Link';

import './NavBar.scss';
export default class NavBar extends React.Component {
  constructor(props) {
    super(props);

    let win = window;
    let doc = document;
    let docElem = doc.documentElement;
    let body = doc.getElementsByTagName('body')[0];
    let x = win.innerWidth || docElem.clientWidth || body.clientWidth;

    this.state = {
      hasRightMenu: this.props.hasRightMenu,
      hasRightButtons: this.props.hasRightButtons,
      showMenu: false,
      menuX: x - 165,
      menuY: 62,
    };
  }

  renderRightMenu() {
    const { hasRightMenu } = this.state;

    return (
      <div className="menu-right">{hasRightMenu && <Retrain style={{ marginRight: 12 }} />}</div>
    );
  }

  renderBack() {
    const { onBackClick, shouldHardRedirect, backPath } = this.props;
    if (!backPath && !onBackClick) {
      return null;
    }
    if (onBackClick) {
      return <Back className="back-button" onClick={onBackClick} />;
    } else {
      return (
        <Link shouldHardRedirect={shouldHardRedirect} to={{ pathname: this.props.backPath }}>
          <Back className="back-button" />
        </Link>
      );
    }
  }

  render() {
    return (
      <div className="NavBar sticky">
        <div className="menu-left">{this.renderBack()}</div>

        {this.props.replaceTitle ? (
          this.props.replaceTitle
        ) : (
          <span className="title">{this.props.title}</span>
        )}
        {this.renderRightMenu()}
      </div>
    );
  }
}

NavBar.contextType = CurrentUser;
