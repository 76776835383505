import React from 'react';

const Expand = (props) => (
  <svg
    width="39"
    height="41"
    viewBox="0 0 39 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M39 31C39 36.5229 34.5228 41 29 41L10 41C4.47715 41 3.91405e-07 36.5228 8.74228e-07 31L2.71011e-06 10C3.19293e-06 4.47715 4.47716 7.96614e-07 10 1.27944e-06L29 2.94047e-06C34.5229 3.42329e-06 39 4.47716 39 10L39 31Z"
      fill="white"
    />
    <path
      d="M38.5 31C38.5 36.2467 34.2467 40.5 29 40.5L10 40.5C4.7533 40.5 0.5 36.2467 0.500001 31L0.500003 10C0.500003 4.7533 4.7533 0.500001 10 0.500001L29 0.500003C34.2467 0.500003 38.5 4.7533 38.5 10L38.5 31Z"
      stroke="#A3AED0"
      strokeOpacity="0.4"
    />
    <path
      d="M16.3554 24.8546L21.0691 19.9937L16.3554 15.1329C15.8816 14.6443 15.8816 13.855 16.3554 13.3664C16.8292 12.8779 17.5945 12.8779 18.0683 13.3664L23.6446 19.1168C24.1185 19.6054 24.1185 20.3946 23.6446 20.8832L18.0683 26.6336C17.5945 27.1221 16.8292 27.1221 16.3554 26.6336C15.8937 26.145 15.8815 25.3432 16.3554 24.8546Z"
      fill="#A3AED0"
    />
  </svg>
);

export default Expand;
