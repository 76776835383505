import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, styled } from '@mui/material';
import { AddButton } from '../common/Button';
import languageMap from '../../Language/Language';
import HelperTooltip from '../../components/HelperTooltip/HelperTooltip';
import InputText from '../common/input/InputText';

// =====================================================================================================================
//  D E C L A R A T I O N S
// =====================================================================================================================
const Container = styled('div')(({ theme }) => ({
  marginTop: 25,
  marginLeft: 30,
}));

const TitleRow = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& .regular-text': {
    // Add styles for .regular-text here if necessary
  },
}));

const InputRow = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: 15,
}));

const RemoveButton = styled(Button)(({ theme }) => ({
  marginLeft: 4,
  padding: 4,
  minWidth: 35,
}));

// =====================================================================================================================
//  C O M P O N E N T
// =====================================================================================================================
class RegexList extends React.PureComponent {
  render() {
    const { values } = this.props;
    return (
      <Container>
        <TitleRow>
          <div className="regular-text">{languageMap.matchTypeRegex}</div>
          <HelperTooltip
            sx={{ marginLeft: 2, height: 25, width: 25 }}
            title={languageMap.qnaRegexHelp}
          />
          <AddButton
            sx={{
              marginLeft: 1.25,
              minWidth: 26,
              padding: 0.5,
            }}
            onClick={this.onAdd}
            icon="add"
          />
        </TitleRow>
        {values.map((value, index) => (
          <InputRow key={index}>
            <InputText
              sx={{ marginTop: 0 }}
              value={value}
              onChange={(event) => this.onChange(index, event)}
              placeholder={languageMap.matchTypeRegex}
              name="flowRegex"
            />
            <RemoveButton onClick={() => this.onRemoveClick(index)}>
              <DeleteIcon />
            </RemoveButton>
          </InputRow>
        ))}
      </Container>
    );
  }
  // -----------------------------------------------------------------------------------------------------------------
  //  I N T E R N A L
  // -----------------------------------------------------------------------------------------------------------------
  /**
   *
   */

  onAdd = () => {
    const { onChange, values } = this.props;
    onChange([...values, '']);
  };

  onChange = (index, event) => {
    const { onChange, values } = this.props;
    const newValues = [...values];
    newValues[index] = event.target.value;
    onChange(newValues);
  };

  onRemoveClick = (itemIndex) => {
    const { onChange, values } = this.props;
    const newValues = [...values];
    newValues.splice(itemIndex, 1);
    onChange(newValues);
  };
}

// =====================================================================================================================
//  E X P O R T
// =====================================================================================================================

export default RegexList;
