import React, { useEffect, useState, useMemo } from 'react';
import Axios from 'axios';
import { Dropdown } from 'semantic-ui-react';
import languageMap from '../../../Language/Language';

const FlowFilter = ({ onChange, selectedFlows, projectId, setAppId, disabled }) => {
  const [flows, setFlows] = useState([]);
  /**
   * Fetch nodes
   */
  useEffect(() => {
    const getFlows = () => {
      Axios.get('/internal_api/project/' + projectId).then((response) => {
        setFlows(
          response.data.apps.map((app) => {
            return {
              name: app.app_name,
              id: app.app_id,
              language: app.language,
            };
          })
        );
        setAppId(response.data.apps[0]?.app_id);
      });
    };
    getFlows();
  }, [projectId, setAppId]);

  const flowsOptions = useMemo(() => {
    const options = flows.map(({ id, name }, index) => {
      return {
        key: `${id}+${index}`,
        text: name,
        value: id,
      };
    });

    // return [{ key: 0, text: 'All', value: 'all' }, ...options];
    return options;
  }, [flows]);

  return (
    <Dropdown
      style={{ marginLeft: 16 }}
      placeholder={languageMap.flows}
      multiple
      selection
      search
      disabled={disabled}
      options={flowsOptions}
      onChange={(e, data) => {
        onChange(data.value);
      }}
      value={selectedFlows}
      loading={false}
    />
  );
};

export default FlowFilter;
