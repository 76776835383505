import React from 'react';

const Collapse = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.2 22L9.8 22C13 22 15 20 15 16.8L15 12.75L8.75 12.75C8.34 12.75 8 12.41 8 12C8 11.59 8.34 11.25 8.75 11.25L15 11.25L15 7.2C15 4 13 2 9.8 2L7.21 2C4.01 2 2.01 4 2.01 7.2L2.01 16.8C2 20 4 22 7.2 22Z"
      fill="#A3AED0"
    />
    <path
      d="M19.44 12.75L17.37 14.82C17.22 14.97 17.15 15.16 17.15 15.35C17.15 15.54 17.22 15.74 17.37 15.88C17.66 16.17 18.14 16.17 18.43 15.88L21.78 12.53C22.07 12.24 22.07 11.76 21.78 11.47L18.43 8.12C18.14 7.83 17.66 7.83 17.37 8.12C17.08 8.41 17.08 8.89 17.37 9.18L19.44 11.25L15 11.25L15 12.75L19.44 12.75Z"
      fill="#A3AED0"
    />
  </svg>
);

export default Collapse;
