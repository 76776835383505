import React from 'react';
import { v4 as uuid } from 'uuid';
import HelperTooltip from 'components/HelperTooltip/HelperTooltip';

import './HeatMapWidget.scss';

const HeatMapWidget = ({
  title,
  info = 'info',
  data,
  oxLabels,
  oyLabels,
  displayInDays,
  forPrint,
}) => {
  let minValue = 0;
  let maxValue = 0;
  let stepValue = 0;
  let colouredValue = 0;
  let internalTextColor = '';

  const replaceSeconds = (value) => {
    if (value >= 86400) {
      return `${Math.trunc(value / 86400)}d`;
    } else if (value >= 3600) {
      return `${Math.trunc(value / 3600)}h`;
    } else if (value >= 60) {
      return `${Math.trunc(value / 60)}m`;
    } else if (value === 0) {
      return value;
    } else {
      return `${value}s`;
    }
  };
  const displayValues = () => {
    let conversionArray = [];

    for (let i = 0; i < oyLabels.length; i++) {
      conversionArray.push(...data?.[i]);
    }
    minValue = Math.min(...conversionArray);
    maxValue = Math.max(...conversionArray);
    stepValue = (maxValue - minValue) / 10;

    return conversionArray.map((value) => {
      colouredValue = 0;
      if (minValue <= value && value <= minValue + stepValue) {
        colouredValue = 0.1;
        internalTextColor = 'black';
      } else if (minValue + stepValue < value && value <= minValue + 2 * stepValue) {
        colouredValue = 0.2;
        internalTextColor = 'black';
      } else if (minValue + 2 * stepValue < value && value <= minValue + 3 * stepValue) {
        colouredValue = 0.3;
        internalTextColor = 'black';
      } else if (minValue + 3 * stepValue < value && value <= minValue + 4 * stepValue) {
        colouredValue = 0.4;
        internalTextColor = 'black';
      } else if (minValue + 4 * stepValue < value && value <= minValue + 5 * stepValue) {
        colouredValue = 0.5;
        internalTextColor = 'black';
      } else if (minValue + 5 * stepValue < value && value <= minValue + 6 * stepValue) {
        colouredValue = 0.6;
        internalTextColor = 'white';
      } else if (minValue + 6 * stepValue < value && value <= minValue + 7 * stepValue) {
        colouredValue = 0.7;
        internalTextColor = 'white';
      } else if (minValue + 7 * stepValue < value && value <= minValue + 8 * stepValue) {
        colouredValue = 0.8;
        internalTextColor = 'white';
      } else if (minValue + 8 * stepValue < value && value <= minValue + 9 * stepValue) {
        colouredValue = 0.9;
        internalTextColor = 'white';
      } else if (minValue + 9 * stepValue < value && value <= minValue + 10 * stepValue) {
        colouredValue = 1;
        internalTextColor = 'white';
      } else {
        colouredValue = 0;
        internalTextColor = 'black';
      }

      replaceSeconds(value);
      const style = {
        backgroundColor: `rgba(0, 45, 150, ${colouredValue})`,
        color: `${internalTextColor}`,
      };
      const styleForPrint = {
        backgroundColor: `rgba(0, 45, 150, ${colouredValue})`,
        color: `${internalTextColor}`,
        minWidth: '10px',
      };
      return (
        <div className="grid-item " style={forPrint ? styleForPrint : style} key={uuid()}>
          {displayInDays ? replaceSeconds(value) : value}
        </div>
      );
    });
  };

  const displayOyLabels = () => {
    return oyLabels.map((valueY) => {
      return (
        <div className="y-axis-label regular-text" key={uuid()}>
          {valueY}
        </div>
      );
    });
  };

  const displayOxLabels = () => {
    return oxLabels.map((valueX) => {
      return (
        <div className="x-axis-label regular-text" key={uuid()}>
          {valueX}
        </div>
      );
    });
  };

  return (
    <div className="HeatMapWidget">
      <div className="title-container">
        <div className="title title-text">{title}</div>
        {info && <HelperTooltip title={info} placement="top" arrow />}
      </div>
      <div className="heat-map-wrapper">
        <div className="y-axis-wrapper">{oyLabels && displayOyLabels()}</div>
        <div className="grid-container">{data && oxLabels && displayValues()}</div>
      </div>
      <div className="x-axis-wrapper">{oxLabels && displayOxLabels()}</div>
      <div className="colour-legend">
        {minValue}
        <div className="gradient-stripe"></div>
        {displayInDays ? replaceSeconds(maxValue) : maxValue}
      </div>
    </div>
  );
};

export default HeatMapWidget;
