import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import Conversation from 'components/Conversation';
import ConversationLoader from 'components/ConversationLoader';
import languageMap from 'Language/Language';
import SearchIcon from 'assets/svg/SearchIcon';
import ConversationsSearchContainer from 'containers/ConversationsSearchContainer';

import clsx from 'clsx';

import {
  setInboxFolder,
  getChatHistory,
  getConversations,
  clearConversations,
  setConversationsStatusFilter,
  setCurrentConversation,
  createNewInternalConversation,
  setStartFromConversationIndex,
  resetFilteredData,
  setMainMenu,
  setSearchStatus,
  resetSearch,
} from 'store/crm/actions';

import {
  selectInboxFolder,
  selectConversations,
  selectConversationsStatusFilter,
  selectSearch,
  selectCurrentUser,
  selectFolderTotals,
  selectStartFromConversationIndex,
  selectIsMobile,
  selectCurrentConversation,
  selectFilters,
  selectSearchFilters,
  selectMainMenu,
} from 'store/crm/selectors';

import './ConversationInboxListContainer.scss';

const ConversationInboxListContainer = (props) => {
  const {
    inboxFolder,
    search: { isSearching, filteredData, filteredDataLength },
    conversationsStatus,
    setConversationsDispatch,
    conversationsInRedux,
    clearConversations,
    setStartFromConversationIndex,
    startFromConversationIndex,
    isMobile,
    currentConversation,
    handleNewInternalConversation,
    mainMenu,
    setMenu,
    setStartFromConversationIndexAction,
    resetSearchAction,
    setSearchStatusAction,
  } = props;
  const { id: projectId } = useParams();
  const [filteredConversations, setFilteredConversations] = useState([]);
  const [loading, setLoading] = useState(true);
  const listInnerRef = useRef();
  const [previousFilteredData, setPreviousFilteredData] = useState([]);

  useEffect(() => {
    clearConversations();
    setFilteredConversations([]);
    setPreviousFilteredData([]);
    setStartFromConversationIndex(0);
    resetFilteredData();
  }, [inboxFolder, props.conversationsStatus, projectId, isSearching]);

  useEffect(() => {
    refreshConversations();
  }, [
    inboxFolder,
    conversationsStatus,
    projectId,
    startFromConversationIndex,
    isSearching,
    filteredData,
  ]);

  useEffect(() => {
    if (isSearching) {
      const unique = filteredData.filter(
        (v, i, a) => a.findIndex((t) => t.conversation_id === v.conversation_id) === i
      );
      setFilteredConversations(unique);

      setLoading(false);
    } else {
      const unique = conversationsInRedux.filter(
        (v, i, a) => a.findIndex((t) => t.conversation_id === v.conversation_id) === i
      );
      setFilteredConversations(unique);
    }
  }, [isSearching, filteredData, conversationsInRedux]);

  const getListHeader = () => {
    if (isSearching) {
      return `Search Results: ${filteredDataLength}`;
    } else if (mainMenu === 'inbox' && !inboxFolder.file.title) {
      return languageMap.you;
    } else {
      return inboxFolder.file.title;
    }
  };

  const refreshConversations = async () => {
    if (inboxFolder.file.value) {
      setLoading(true);

      await setConversationsDispatch({
        folder: inboxFolder.file.value,
        conversation_status: conversationsStatus,
        project_id: projectId,
        start_from_conversation_index: startFromConversationIndex,
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleOpenClick = async () => {
    props.setConversationsStatus('open');
  };
  const handleCloseClick = async () => {
    props.setConversationsStatus('closed');
  };

  const onScroll = (loading) => {
    if (loading) {
      return;
    }
    const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
    if (scrollTop + clientHeight + 5 >= scrollHeight) {
      if (!isSearching && filteredConversations.length > previousFilteredData.length) {
        setStartFromConversationIndex(conversationsInRedux.length);
        setPreviousFilteredData(filteredConversations);
      } else if (isSearching && filteredConversations.length > previousFilteredData.length) {
        setStartFromConversationIndex(filteredConversations.length);
        setPreviousFilteredData(filteredConversations);
      }
    }
  };

  const displayMessageList = () => {
    if (filteredConversations.length > 0) {
      return (
        <div className="message-list">
          {filteredConversations?.map((conversation, index) => (
            <Conversation
              key={conversation.conversation_id}
              index={index}
              conversation={conversation}
              loading={loading}
            />
          ))}

          <ConversationLoader key="loader" loading={loading} />
        </div>
      );
    } else if (loading) {
      return (
        <div className="message-list">
          <ConversationLoader key="loader" loading={loading} />
        </div>
      );
    } else {
      return (
        <div className="message-list" style={{ height: '100%' }}>
          <div className="empty-folder-note regular-text">{languageMap.thisFolderIsEmpty}</div>
        </div>
      );
    }
  };

  const handleSearchClick = () => {
    checkIfInbox();
    if (isSearching) {
      resetSearchAction();
    } else {
      setStartFromConversationIndexAction(0);
      setSearchStatusAction(!isSearching);
    }
  };

  const handleSearchWrapperClick = () => {
    if (!isSearching) {
      checkIfInbox();
      setSearchStatusAction(true);
    }
  };

  const checkIfInbox = () => {
    if (mainMenu !== 'inbox') {
      setMenu('inbox');
      setInboxFolder({
        submenu: 'External',
        file: { title: languageMap.you, value: 'you' },
      });
    }
  };

  let styleDesktop = {
    height: 'unset',
    borderBottom: 'unset',
  };
  return (
    <div
      className={clsx(
        'ConversationInboxListContainer',
        isMobile && 'ConversationInboxListContainer-is-mobile'
      )}
    >
      <div className={clsx(isMobile ? 'list-header-wraper-isMobile' : 'list-header-wraper')}>
        <div className="list-header" style={!isMobile ? styleDesktop : { height: '65px' }}>
          <span className="list-header" style={isMobile ? { fontSize: '15px' } : {}}>
            {getListHeader()}
          </span>
          {!isMobile && inboxFolder.file.value === 'internal_all' && (
            <Button
              className="new-conversation-button small-text"
              onClick={handleNewInternalConversation}
            >
              {languageMap.newConversation}
            </Button>
          )}
        </div>

        <div
          className="list-filter"
          style={
            isMobile
              ? { justifyContent: 'flex-end', borderBottom: 'none' }
              : { justifyContent: 'space-between' }
          }
        >
          {!isSearching && (
            <>
              <Button
                className={`open-close-button ${
                  props.conversationsStatus === 1 ? 'active' : 'inactive'
                }`}
                onClick={handleOpenClick}
              >
                {languageMap.open}
              </Button>
              <Button
                className={`open-close-button ${
                  props.conversationsStatus === 0 ? 'active' : 'inactive'
                }`}
                onClick={handleCloseClick}
              >
                {languageMap.closed}
              </Button>
            </>
          )}
        </div>
      </div>

      {!isMobile && (
        <>
          <div className="search-wrapper" onClick={handleSearchWrapperClick}>
            <button className="search-button" onClick={handleSearchClick}>
              <SearchIcon className="search-button-icon" />
            </button>
            {isSearching && <ConversationsSearchContainer />}
          </div>
        </>
      )}

      <div
        className={clsx(
          'list',
          isMobile && !currentConversation.conversation_id && 'list list-is-mobile'
        )}
        onScroll={() => onScroll(loading)}
        ref={listInnerRef}
      >
        {displayMessageList()}
        <div className="post-message-block" />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  inboxFolder: selectInboxFolder(state),
  conversationsStatus: selectConversationsStatusFilter(state),
  conversationsInRedux: selectConversations(state),
  search: selectSearch(state),
  currentUser: selectCurrentUser(state),
  numberOfConversations: selectFolderTotals(state),
  startFromConversationIndex: selectStartFromConversationIndex(state),
  isMobile: selectIsMobile(state),
  currentConversation: selectCurrentConversation(state),
  filtersStore: selectFilters(state),
  searchFilters: selectSearchFilters(state),
  mainMenu: selectMainMenu(state),
});

const mapDispatchToProps = {
  setInboxFolder,
  setConversationsDispatch: getConversations,
  clearConversations: clearConversations,
  setConversationsStatus: setConversationsStatusFilter,
  setChatHistory: getChatHistory,
  setCurrentConversation,
  createNewInternalConversation,
  setStartFromConversationIndex,
  resetFilteredData,
  setSearchStatusAction: setSearchStatus,
  resetSearchAction: resetSearch,
  setMenu: setMainMenu,
  setStartFromConversationIndexAction: setStartFromConversationIndex,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConversationInboxListContainer);
