import React from 'react';

const WvCheckedIcon = (props) => {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
      <g id="Checkbox-On" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-Copy-8">
          <g id="checkbox-checked">
            <rect
              id="Rectangle-3-Copy"
              fill="#333333"
              x="0"
              y="0"
              width="20"
              height="20"
              rx="6"
            ></rect>
            <polyline
              id="Path-3"
              stroke="#FFFFFF"
              strokeWidth="2"
              points="6 9.69089949 8.94668812 13 14 7"
            ></polyline>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default WvCheckedIcon;
