import React, { useState } from 'react';
import languageMap from 'Language/Language';
import InputText from '../../../../../../components/common/input/InputText';
import HelperTooltip from '../../../../../../components/HelperTooltip/HelperTooltip';

const GraphBlock = ({ block, onBlockChange }) => {
  const { graph_datasource } = block;

  const [currentDataSource, setCurrentDataSource] = useState(graph_datasource);

  const onDataSourceSave = () => {
    onBlockChange({
      ...block,
      graph_datasource: currentDataSource,
    });
  };

  const onDataSourceChange = (name, value) => {
    setCurrentDataSource(value);
  };

  return (
    <div className="CarouselEditorBlock">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <InputText
          value={currentDataSource}
          title={languageMap.dataSource}
          onValueChanged={onDataSourceChange}
          onBlur={onDataSourceSave}
          style={{
            marginLeft: 10,
          }}
        />
        <HelperTooltip
          style={{ marginLeft: 10, height: 25, width: 25 }}
          title={languageMap.dataSourceInfo}
        />
      </div>
    </div>
  );
};

export default GraphBlock;
