import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import Axios from 'axios';
import { produce } from 'immer';

import { FlowListElement } from 'pages/ProjectDetails/components/FlowListElement';
import { AddButton } from 'components/common/Button/Buttons';
import CreateNewFlow from 'pages/ProjectDetails/components/CreateNewFlow';
import languageMap from 'Language/Language';
import FlowSettingsDialog from '../ProjectDetails/components/FlowSettingsDialog';

class QnAList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFlowId: '',
      selectedFlowName: '',
      selectedQnaRegex: '',
      showCreateFlowMenu: false,
      qnaList: [],
    };
  }

  componentDidMount() {
    this.getQnAList();
  }

  editQna = (flowId, flowName, qnaRegex) => {
    this.setState({
      selectedFlowId: flowId,
      selectedFlowName: flowName,
      selectedQnaRegex: qnaRegex,
      showFlowSettingsDialog: true,
    });
  };

  getQnAList = () => {
    this.handleCloseFlowSettingsDialog();
    Axios.get('/internal_api/project/' + this.props.projectId).then((response) => {
      this.setState(
        produce((draft) => {
          draft.qnaList =
            response.data.qna_apps?.map((app) => {
              return {
                name: app.app_name,
                id: app.app_id,
                regex: app.url_prefixes,
              };
            }) || [];

          return draft;
        })
      );
    });
  };

  handleNewFlow = (flow) => {
    this.setState(
      produce((draft) => {
        draft.qnaList.splice(0, 0, flow);
      })
    );
  };

  handleShowFlowSettingsDialog = () => {
    this.setState({ showFlowSettingsDialog: true });
  };

  handleCloseFlowSettingsDialog = () => {
    this.setState({ showFlowSettingsDialog: false });
  };

  handleMenuClose = () => {
    this.setState({
      showCreateFlowMenu: false,
    });
  };

  handleOnNewFlowButtonClick = () => {
    this.setState({
      showCreateFlowMenu: true,
    });
  };

  render() {
    const { showCreateFlowMenu, showFlowSettingsDialog, qnaList } = this.state;
    const { projectId, style } = this.props;
    let createFlowMenu = null;
    let flowSettingsDialog;

    if (showFlowSettingsDialog) {
      flowSettingsDialog = (
        <FlowSettingsDialog
          onHide={this.handleCloseFlowSettingsDialog}
          projectId={projectId}
          flowId={this.state.selectedFlowId}
          flowName={this.state.selectedFlowName}
          qnaRegex={this.state.selectedQnaRegex}
          onFlowChanged={this.getQnAList}
          isQna={true}
        />
      );
    }

    if (showCreateFlowMenu) {
      createFlowMenu = (
        <CreateNewFlow
          isQna
          projectId={projectId}
          close={this.handleMenuClose}
          onNewFlow={this.handleNewFlow}
        />
      );
    }

    return (
      <div className="Flows" style={style}>
        {
          <>
            {flowSettingsDialog}

            <div className="Flows_Header">
              <AddButton
                icon="add"
                text={languageMap.createNewQna}
                onClick={this.handleOnNewFlowButtonClick}
              />
            </div>
            <div className="Flows_ListContainer">
              {_.clone(qnaList)
                .sort((a, b) => b.id - a.id)
                .map((qna) => {
                  return (
                    <FlowListElement
                      key={qna.id}
                      flowId={qna.id}
                      flowTitle={qna.name}
                      qnaRegex={qna.regex}
                      projectId={projectId}
                      onFlowChanged={this.getQnAList}
                      editFlow={this.editQna}
                      pathName={'/project/' + projectId + '/qna/' + qna.id}
                      isQna
                    />
                  );
                })}
            </div>
          </>
        }
        {createFlowMenu}
      </div>
    );
  }
}

export default connect(null)(QnAList);
