import { createReducer } from '@reduxjs/toolkit';

import { setLoading } from './actions';
import { fetchEntries, importQna, fetchInitialState } from '../qna/actions';

const initialState = {
  isLoading: false,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(setLoading, (state, action) => {
    state.isLoading = action.payload;
  });
  builder.addCase(importQna.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(importQna.fulfilled, (state) => {
    state.isLoading = false;
  });
  builder.addCase(importQna.rejected, (state) => {
    state.isLoading = false;
  });
  builder.addCase(fetchEntries.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(fetchEntries.fulfilled, (state) => {
    state.isLoading = false;
  });
  builder.addCase(fetchEntries.rejected, (state) => {
    state.isLoading = false;
  });
  builder.addCase(fetchInitialState.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(fetchInitialState.fulfilled, (state) => {
    state.isLoading = false;
  });
  builder.addCase(fetchInitialState.rejected, (state) => {
    state.isLoading = false;
  });
  // .addDefaultCase((state, action) => { });
});
