import React, { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const loading_style = {
  width: '100%',
  height: '100%',
  backgroundColor: 'white',
  position: 'absolute',
  opacity: 0.85,
  right: 0,
  top: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 3,
};
export default function LoadingOverlay(props) {
  const { isLoading, style } = props;

  useEffect(() => {
    if (isLoading) {
      Array.from(document.querySelectorAll('input')).forEach((el) => el.blur());
    }
  }, [isLoading]);

  if (!isLoading) {
    return null;
  }
  return (
    <div style={loading_style}>
      <CircularProgress style={style} />
    </div>
  );
}
