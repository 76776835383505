import React from 'react';

import Checkbox from 'components/common/LabeledCheckbox/Checkbox';
import './OptionCheckbox.scss';

const OptionCheckbox = ({ style, checked, onChange, title }) => {
  return (
    <div className="OptionCheckbox" style={style}>
      <Checkbox checked={checked} onChange={onChange} />
      <span className="OptionCheckbox_CheckboxTitle regular-text">{title}</span>
    </div>
  );
};

export default OptionCheckbox;
