import React from 'react';

const NewLogo = (props) => (
  <svg
    width="198"
    height="43"
    viewBox="0 0 198 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M65.1374 25.3791L68.3481 14.074H72.0831L66.9401 30.4573H63.4643L60.0592 19.4173L56.6188 30.4573H53.143L48 14.074H51.735L54.9104 25.3791L58.3508 14.074H61.7264L65.1315 25.3791H65.1374Z"
      fill="black"
    />
    <path
      d="M78.0451 10.9634C77.6092 11.3993 77.0849 11.6173 76.4722 11.6173C75.8595 11.6173 75.3234 11.3993 74.8639 10.9634C74.4279 10.5039 74.21 9.96779 74.21 9.35511C74.21 8.74242 74.4279 8.21811 74.8639 7.78216C75.2998 7.32265 75.8359 7.0929 76.4722 7.0929C77.1084 7.0929 77.6386 7.32265 78.0805 7.78216C78.5164 8.21811 78.7344 8.74242 78.7344 9.35511C78.7344 9.96779 78.5046 10.5039 78.0451 10.9634ZM78.2395 30.4573H74.6989V14.0739H78.2395V30.4573Z"
      fill="black"
    />
    <path
      d="M85.4204 18.5984C85.4204 19.0991 85.6855 19.5115 86.2098 19.8297C86.7341 20.1478 87.5 20.4482 88.5015 20.731C89.2674 20.9077 89.9331 21.0904 90.4986 21.2907C91.0642 21.4851 91.6651 21.7796 92.3013 22.1567C92.9376 22.5396 93.4206 23.0403 93.7623 23.6648C94.104 24.2893 94.2572 25.0139 94.2395 25.8445C94.2395 27.4175 93.6268 28.6546 92.4015 29.5501C91.1761 30.4455 89.6621 30.8933 87.8476 30.8933C86.2275 30.8933 84.8431 30.5516 83.6884 29.88C82.5337 29.2084 81.6913 28.2658 81.167 27.064L84.2127 25.2967C84.7606 26.8696 85.9683 27.6531 87.8476 27.6531C89.7269 27.6531 90.6341 27.0404 90.6341 25.8151C90.6341 24.9196 89.5973 24.2186 87.5236 23.7178C86.74 23.5234 86.0743 23.3231 85.5441 23.1287C85.008 22.9343 84.4189 22.6456 83.7768 22.2745C83.1346 21.9033 82.6398 21.4144 82.304 20.8017C81.9623 20.189 81.8091 19.4821 81.8268 18.6691C81.8268 17.1609 82.3924 15.9474 83.5294 15.0342C84.6899 14.1152 86.1156 13.6557 87.824 13.6557C89.179 13.6557 90.3867 13.9561 91.4471 14.557C92.5075 15.1579 93.3205 15.9945 93.886 17.0667L90.9051 18.7398C90.3572 17.4967 89.3322 16.8723 87.824 16.8723C87.123 16.8723 86.5515 17.0255 86.1038 17.3318C85.6561 17.6381 85.4322 18.0623 85.4322 18.6102L85.4204 18.5984Z"
      fill="black"
    />
    <path
      d="M112.744 23.7767H99.7007C99.9658 25.0021 100.561 25.9506 101.486 26.6281C102.417 27.3055 103.565 27.6413 104.944 27.6413C106.8 27.6413 108.208 26.9639 109.168 25.6089L112.084 27.3114C110.487 29.6915 108.096 30.8815 104.908 30.8815C102.263 30.8815 100.113 30.0744 98.4517 28.4543C96.814 26.8166 95.9951 24.7547 95.9951 22.2627C95.9951 19.7707 96.8022 17.7972 98.4223 16.1359C100.019 14.4746 102.092 13.6439 104.649 13.6439C107.029 13.6439 108.997 14.4864 110.546 16.1653C112.096 17.8502 112.873 19.8886 112.873 22.2922C112.873 22.7281 112.826 23.223 112.744 23.765V23.7767ZM99.6712 20.896H109.303C109.062 19.6058 108.52 18.6161 107.683 17.915C106.841 17.214 105.822 16.8664 104.62 16.8664C103.306 16.8664 102.216 17.2258 101.344 17.9504C100.472 18.6691 99.9128 19.6529 99.6712 20.9018V20.896Z"
      fill="black"
    />
    <path
      d="M121.887 26.5279L126.441 14.074H130.305L123.914 30.4573H119.819L113.427 14.074H117.292L121.881 26.5279H121.887Z"
      fill="black"
    />
    <path
      d="M139.614 30.8874C137.186 30.8874 135.148 30.0568 133.487 28.3954C131.802 26.7106 130.965 24.6722 130.965 22.2686C130.965 19.865 131.808 17.8208 133.487 16.1418C135.148 14.4805 137.192 13.6498 139.614 13.6498C142.035 13.6498 144.079 14.4805 145.74 16.1418C147.419 17.8267 148.262 19.865 148.262 22.2686C148.262 24.6722 147.419 26.7165 145.74 28.3954C144.079 30.0568 142.035 30.8874 139.614 30.8874ZM134.5 22.2686C134.5 23.7532 134.989 24.9904 135.973 25.9742C136.957 26.958 138.17 27.447 139.608 27.447C141.045 27.447 142.265 26.958 143.243 25.9742C144.226 24.9904 144.715 23.7591 144.715 22.2686C144.715 20.7782 144.22 19.5469 143.243 18.5631C142.259 17.5793 141.045 17.0903 139.608 17.0903C138.17 17.0903 136.951 17.5851 135.973 18.5631C134.995 19.541 134.5 20.7782 134.5 22.2686Z"
      fill="black"
    />
    <path
      d="M154.819 10.9634C154.383 11.3993 153.859 11.6173 153.246 11.6173C152.633 11.6173 152.097 11.3993 151.638 10.9634C151.202 10.5039 150.984 9.96779 150.984 9.35511C150.984 8.74242 151.202 8.21811 151.638 7.78216C152.074 7.32265 152.61 7.0929 153.246 7.0929C153.882 7.0929 154.413 7.32265 154.854 7.78216C155.29 8.21811 155.508 8.74242 155.508 9.35511C155.508 9.96779 155.279 10.5039 154.819 10.9634ZM155.013 30.4573H151.473V14.0739H155.013V30.4573Z"
      fill="black"
    />
    <path
      d="M166.843 30.8874C164.374 30.8874 162.312 30.0568 160.651 28.3954C159.013 26.7106 158.194 24.6722 158.194 22.2686C158.194 19.865 159.013 17.7796 160.651 16.1418C162.312 14.4805 164.374 13.6498 166.843 13.6498C168.439 13.6498 169.888 14.0328 171.202 14.7986C172.516 15.5645 173.494 16.5895 174.154 17.8797L171.108 19.6471C170.737 18.8576 170.171 18.245 169.417 17.7972C168.663 17.3495 167.797 17.1256 166.813 17.1256C165.37 17.1256 164.156 17.6146 163.178 18.5984C162.218 19.6058 161.735 20.8253 161.735 22.2686C161.735 23.712 162.218 24.9314 163.178 25.9388C164.162 26.9227 165.376 27.4116 166.813 27.4116C167.773 27.4116 168.645 27.1878 169.417 26.74C170.189 26.2923 170.778 25.6737 171.173 24.8902L174.218 26.6281C173.517 27.9418 172.51 28.9787 171.19 29.7386C169.871 30.5045 168.421 30.8874 166.848 30.8874H166.843Z"
      fill="black"
    />
    <path
      d="M192.334 23.7767H179.291C179.556 25.0021 180.151 25.9506 181.076 26.6281C182.006 27.3055 183.155 27.6413 184.534 27.6413C186.389 27.6413 187.797 26.9639 188.758 25.6089L191.674 27.3114C190.077 29.6915 187.685 30.8815 184.498 30.8815C181.853 30.8815 179.703 30.0744 178.042 28.4543C176.404 26.8166 175.585 24.7547 175.585 22.2627C175.585 19.7707 176.392 17.7972 178.012 16.1359C179.609 14.4746 181.682 13.6439 184.239 13.6439C186.619 13.6439 188.587 14.4864 190.136 16.1653C191.686 17.8502 192.463 19.8886 192.463 22.2922C192.463 22.7281 192.416 23.223 192.334 23.765V23.7767ZM179.261 20.896H188.893C188.652 19.6058 188.11 18.6161 187.273 17.915C186.431 17.214 185.411 16.8664 184.21 16.8664C182.896 16.8664 181.806 17.2258 180.934 17.9504C180.062 18.6691 179.503 19.6529 179.261 20.9018V20.896Z"
      fill="black"
    />
    <path
      d="M193.842 6.23291C193.942 6.23291 194.036 6.23291 194.137 6.23291C194.148 6.2388 194.154 6.23291 194.166 6.23291C194.343 6.2388 194.519 6.26237 194.696 6.29771C195.598 6.48034 196.34 6.93396 196.905 7.65269C197.353 8.21824 197.606 8.86627 197.677 9.5791C197.718 10.0092 197.689 10.4392 197.577 10.8516C197.318 11.8236 196.758 12.5777 195.904 13.102C195.185 13.538 194.402 13.7029 193.571 13.6146C193.212 13.5792 192.87 13.4849 192.54 13.3436C191.939 13.0843 191.438 12.7014 191.044 12.1771C190.602 11.5998 190.354 10.94 190.295 10.2153C190.295 10.1682 190.295 10.1211 190.284 10.0798C190.284 9.9797 190.284 9.87955 190.284 9.7794C190.284 9.76762 190.284 9.75583 190.284 9.74405C190.295 9.46128 190.343 9.18439 190.419 8.9134C190.755 7.75284 191.621 6.83381 192.752 6.43321C193.035 6.33306 193.329 6.26826 193.63 6.2388C193.701 6.23291 193.771 6.22702 193.836 6.22113L193.842 6.23291ZM191.032 9.94435C191.032 11.5762 192.351 12.8958 193.983 12.9017C195.615 12.9017 196.941 11.5762 196.941 9.94435C196.941 8.3125 195.621 6.98698 193.989 6.98698C192.357 6.98698 191.032 8.30661 191.032 9.94435Z"
      fill="black"
    />
    <path
      d="M194.626 9.98556C194.626 9.98556 194.679 10.0032 194.702 10.0091C194.885 10.0739 195.014 10.2035 195.12 10.3567C195.226 10.5099 195.303 10.6748 195.386 10.8398C195.539 11.1461 195.692 11.4525 195.845 11.7588C195.845 11.7706 195.857 11.7824 195.857 11.7941C195.845 11.7941 195.833 11.7941 195.827 11.7941C195.497 11.7941 195.162 11.7941 194.832 11.7941C194.802 11.7941 194.791 11.7824 194.779 11.7588C194.62 11.4289 194.455 11.1049 194.296 10.7809C194.249 10.6925 194.195 10.6041 194.131 10.5275C194.036 10.4156 193.907 10.3508 193.76 10.3331C193.63 10.3155 193.5 10.3331 193.377 10.3272C193.365 10.3272 193.353 10.3272 193.359 10.3449C193.359 10.3508 193.359 10.3626 193.359 10.3685C193.359 10.828 193.359 11.2934 193.359 11.7529C193.359 11.7804 193.345 11.7941 193.318 11.7941C193.011 11.7941 192.705 11.7941 192.393 11.7941C192.363 11.7941 192.352 11.7941 192.352 11.7588C192.352 10.4863 192.352 9.21971 192.352 7.94721C192.352 7.92365 192.352 7.91187 192.381 7.91187C192.905 7.91187 193.436 7.91187 193.96 7.91187C194.213 7.91187 194.466 7.94132 194.708 8.0238C194.867 8.07682 195.008 8.1534 195.126 8.27712C195.25 8.41261 195.315 8.57168 195.344 8.7543C195.374 8.9546 195.374 9.14901 195.315 9.34342C195.238 9.60263 195.073 9.77937 194.832 9.89719C194.767 9.93254 194.696 9.9561 194.62 9.97967L194.626 9.98556ZM193.359 9.14312C193.359 9.30218 193.359 9.45535 193.359 9.61441C193.359 9.63798 193.359 9.64387 193.388 9.64387C193.536 9.64387 193.677 9.64387 193.824 9.64387C193.901 9.64387 193.978 9.63209 194.054 9.61441C194.207 9.57318 194.307 9.48481 194.349 9.33164C194.378 9.20792 194.378 9.08421 194.349 8.96049C194.313 8.825 194.231 8.73074 194.095 8.6895C194.036 8.67183 193.978 8.66004 193.913 8.65415C193.736 8.64237 193.559 8.65415 193.383 8.64826C193.359 8.64826 193.359 8.66004 193.359 8.67772C193.359 8.83678 193.359 8.98995 193.359 9.14901V9.14312Z"
      fill="black"
    />
    <path
      d="M21.4026 42.8053C33.223 42.8053 42.8053 33.223 42.8053 21.4026C42.8053 9.58229 33.223 0 21.4026 0C9.58228 0 0 9.58229 0 21.4026C0 33.223 9.58228 42.8053 21.4026 42.8053Z"
      fill="url(#paint0_linear_425_1601)"
    />
    <mask
      id="mask0_425_1601"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="43"
      height="43"
    >
      <path
        d="M21.4026 42.8053C33.223 42.8053 42.8053 33.223 42.8053 21.4026C42.8053 9.58229 33.223 0 21.4026 0C9.58228 0 0 9.58229 0 21.4026C0 33.223 9.58228 42.8053 21.4026 42.8053Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_425_1601)">
      <path
        d="M71.5093 13.74C78.3224 1.93946 74.6447 -12.9388 63.295 -19.4916C51.9453 -26.0444 37.2214 -21.7902 30.4084 -9.98964C23.5953 1.81091 27.273 16.6892 38.6227 23.242C49.9725 29.7947 64.6963 25.5406 71.5093 13.74Z"
        fill="url(#paint1_linear_425_1601)"
      />
    </g>
    <mask
      id="mask1_425_1601"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="43"
      height="43"
    >
      <path
        d="M21.4026 42.8053C33.223 42.8053 42.8053 33.223 42.8053 21.4026C42.8053 9.58229 33.223 0 21.4026 0C9.58228 0 0 9.58229 0 21.4026C0 33.223 9.58228 42.8053 21.4026 42.8053Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_425_1601)">
      <path
        d="M16.3565 54.3787C22.9418 42.9726 18.714 28.2031 6.91347 21.39C-4.88708 14.577 -19.7917 18.3004 -26.377 29.7064C-32.9623 41.1125 -28.7345 55.882 -16.934 62.6951C-5.13346 69.5081 9.77121 65.7847 16.3565 54.3787Z"
        fill="url(#paint2_linear_425_1601)"
      />
      <path
        d="M7.90041 17.1493L15.1524 29.5738C15.8181 30.7108 17.4559 30.7049 18.1157 29.5679L20.0362 26.2394C20.3426 25.7091 20.3426 25.0611 20.0362 24.5309L14.8343 15.4349C14.528 14.8988 13.9624 14.5748 13.3497 14.5748H9.37909C8.05358 14.5748 7.2347 16.0123 7.90041 17.1493Z"
        fill="white"
      />
      <path
        d="M17.6914 17.1493L24.9435 29.5738C25.6092 30.7108 27.2469 30.7049 27.9067 29.5679L29.8272 26.2394C30.1336 25.7092 30.1336 25.0612 29.8272 24.531L24.6253 15.435C24.319 14.8989 23.7534 14.5749 23.1408 14.5749H19.1701C17.8446 14.5749 17.0257 16.0123 17.6914 17.1493Z"
        fill="white"
      />
      <path
        d="M27.547 17.1317C28.2716 18.41 28.9962 19.6884 29.7208 20.9727C30.3747 22.1274 32.0361 22.1333 32.6959 20.9845C33.4323 19.7061 34.1687 18.4277 34.9051 17.1434C35.5649 16.0006 34.7401 14.5749 33.4205 14.5749H29.0374C27.7237 14.5749 26.8989 15.9888 27.547 17.1317Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_425_1601"
        x1="-1.26071"
        y1="41.5033"
        x2="46.4166"
        y2="-0.777633"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.05" stopColor="#00CCFF" />
        <stop offset="1" stopColor="#0600F6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_425_1601"
        x1="61.8615"
        y1="33.8948"
        x2="26.1828"
        y2="-25.6283"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.05" stopColor="#00CCFF" />
        <stop offset="0.81" stopColor="#051CF7" />
        <stop offset="1" stopColor="#0600F6" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_425_1601"
        x1="-26.4571"
        y1="29.8451"
        x2="16.2765"
        y2="54.5173"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.05" stopColor="#00CCFF" />
        <stop offset="1" stopColor="#0600F6" />
      </linearGradient>
    </defs>
  </svg>
);

export default NewLogo;
