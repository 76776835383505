import React from 'react';
import './FormGroup.scss';

const FormGroup = ({ children, labelText }) => {
  return (
    <div className="FormGroup">
      {labelText && <label className="FormGroup_Label">{labelText}</label>}
      {children}
    </div>
  );
};

export default FormGroup;
