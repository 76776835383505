import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useParams, useResolvedPath, useNavigate } from 'react-router-dom';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import languageMap from 'Language/Language';

import { setCurrentConversation, getChatHistory } from 'store/crm/actions';

import { processMessage } from 'utils/crm';

import './ConversationTab.scss';

import { selectAgents, selectIsMobile } from 'store/crm/selectors';
import { memoize } from 'lodash';

const ConversationTab = (props) => {
  const { conversation, index, setChatHistory, setCurrentConversation, agents } = props;
  const { conversation_status, assignee, last_message, user_id, conversation_id } = conversation;
  const { id: projectId } = useParams();
const url = useResolvedPath("").pathname;
  const navigate = useNavigate();

  const [lastMessage, setLastMessage] = useState('');
  const agentsInfo = memoAgents(agents);

  useEffect(() => {
    const { parsedMessage } = processMessage(last_message);
    setLastMessage(parsedMessage);
  }, [lastMessage, last_message, setLastMessage]);

  const handleClick = useCallback(async () => {
    await setChatHistory({ conversation_id, user_id, project_id: projectId });

    setCurrentConversation(conversation);
    const clean = url.substring(0, url.indexOf('inbox') + 5);
    const conversationPath = `${clean}/${user_id}_${conversation_id}`;
    navigate(conversationPath);
  }, [projectId, setCurrentConversation, setChatHistory, user_id, conversation]);

  return (
    <div
      className={`ConversationTab ${index === 0 ? 'firstConversation' : ''}`}
      onClick={handleClick}
    >
      <div className="ConversationTab_Header">
        <AccountCircleIcon />
        <div className="ConversationTab_Header_Title regular-text">{`${
          conversation_status === 1 ? languageMap.openedWith : languageMap.closedBy
        } ${assignee === -1 ? 'Unassigned' : assignee === 0 ? 'Bot' : agentsInfo[assignee]}`}</div>
      </div>
      <div className="ConversationTab_Content">{lastMessage}</div>
    </div>
  );
};

const memoAgents = memoize((agents) => {
  const agentsMap = {};
  agents.forEach((agent) => (agentsMap[agent.agent_id] = agent.agent_name));
  return agentsMap;
});

const mapStateToProps = (state) => ({
  isMobile: selectIsMobile(state),
  agents: selectAgents(state),
});

const mapDispatchToProps = {
  setCurrentConversation,
  setChatHistory: getChatHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConversationTab);
