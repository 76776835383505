import React, { createContext, useEffect } from 'react';
import Axios from 'axios';
import useCurrentUser from 'utils/hooks/useCurrentUser';
import Loader from 'components/common/Loader';

import './CurrentUser.scss';
import { connect } from 'react-redux';
import { getCurrentUser } from '../../store/crm/actions';
import { getAllLanguages } from '../../store/crm/actions';
import store from '../../store';

export const CurrentUser = createContext();

const CurrentUserProvider = ({ children, setUserAction }) => {
  const [currentUser, loading, setCurrentUser] = useCurrentUser(setUserAction);

  useEffect(() => {
    if (currentUser) {
      store.dispatch(getAllLanguages());
    }
  }, [currentUser]);

  const logout = async () => {
    await Axios.get('/internal_api/logout');
    localStorage.clear(); // We clear the user language
    await setCurrentUser(null);
  };

  if (loading) {
    return (
      <div className="CurrentUser_Loading">
        <Loader />
      </div>
    );
  }

  if (!currentUser) {
    return <>{children}</>;
  }

  window.localStorage.setItem('currentUserLanguage', currentUser.language);

  return <CurrentUser.Provider value={{ logout }}>{children}</CurrentUser.Provider>;
};
const mapDispatchToProps = {
  setUserAction: getCurrentUser,
};
export default connect(null, mapDispatchToProps)(CurrentUserProvider);
