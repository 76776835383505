import React from 'react';

import languageMap from 'Language/Language';

import './InternalConversationCard.scss';

const InternalConversationCard = (props) => {
  const { elapsedTime, isConversationRead, lastMessage, conversationSubject, isActive } = props;

  const displayLastMessageContent = () => {
    if (
      lastMessage &&
      typeof lastMessage === 'string' &&
      (lastMessage.includes('<html') || lastMessage.includes('DOCTYPE'))
    ) {
      return 'Email html content';
    } else if (lastMessage) {
      return lastMessage;
    } else {
      return languageMap.startAConversation;
    }
  };

  return (
    <div className={isActive ? 'InternalConversationCard active' : 'InternalConversationCard'}>
      <div className="conversation-item-header-container">
        <div className="conversation-item-header">
          <div className="conversation-item-header-name-container">
            <div
              className={
                isConversationRead === 0
                  ? 'conversation-item-header-name-unread regular-text'
                  : 'conversation-item-header-name regular-text'
              }
            >
              {conversationSubject ? conversationSubject : languageMap.insertSubject}
            </div>
          </div>
          <div className="conversation-item-header-time regular-text">{elapsedTime}</div>
          <div className="conversation-item-header-burger-menu"></div>
        </div>
        <div
          className={
            isConversationRead === 0
              ? 'conversation-item-body-unread regular-text'
              : 'conversation-item-body regular-text'
          }
        >
          <span className="truncated-line">{displayLastMessageContent()}</span>
        </div>
      </div>
    </div>
  );
};

export default InternalConversationCard;
