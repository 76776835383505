import Axios from 'axios';

const TokenKeyPrefix = 'Wisevoice-Token-';
let clientKey = '';

export const authenticate = async (projectId) => {
  await Axios.get('/internal_api/msteams/clientKey', {
    params: { projectId },
  })
    .then((response) => {
      clientKey = response.data.clientKey;
    })
    .catch((error) => {
      console.error('Response MsTeams error', error);
    });

  if (localStorage.getItem(TokenKeyPrefix + projectId) !== null) {
    return Promise.resolve(localStorage.getItem(TokenKeyPrefix + projectId));
  } else {
    const response = await Axios.get(
      'https://' +
        process.env.REACT_APP_DOMAIN_NAME +
        '/api/registerAnonymous?channel=msteams&clientKey=' +
        clientKey +
        '&projectId=' +
        projectId
    );
    localStorage.setItem(TokenKeyPrefix + projectId, response.data.token);
    return response.data.token;
  }
};
