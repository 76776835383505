import React, { useState, useCallback } from 'react';
import { Icon, Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';
import axios from 'axios';
import MessageBlocks from 'pages/Flow/components/Message/MessageBlocks';
import VerticalOrderedList from 'pages/Flow/components/Message/VerticalOrderedList';
import InboundLinksList from '../InboundLinksList';
import './Entry.scss';
import languageMap from 'Language/Language';
import Delete from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { getEntities } from '../../../../store/qna/selectors';
import { FlexRow, Input } from '../../../../components/Utterances/Misc';
import Utterances from '../../../../components/Utterances';
import updateMessage from '../../../../services/updateMessage';
import { updateMessage as updateMessageAction } from '../../../../store/qna/actions';
import DeleteConfirmationModal from '../../../../components/ConfirmationModal/DeleteConfirmationModal';
import LoadingOverlay from '../../../../components/common/LoadingOverlay/LoadingOverlay';
import { useHandleEntryLanguageChange } from '../LanguageOptions/ComputeLanguageOptions';

function Entry(props) {
  const {
    utterances_by_language,
    languageOptions,
    selectedLanguage,
    message_properties,
    entryToLanguage,
    setEntryToLanguage,
    conflictsPage,
  } = props;

  const [title, setTitle] = useState(props.title);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [automaticTranslation, setAutomaticTranslation] = useState(
    message_properties?.automaticTranslation || false
  );

  const optionsByLanguage = message_properties?.responseOptionsByLanguage;
  const currentResponseOptions = optionsByLanguage[selectedLanguage];
  const utterancesData = utterances_by_language[selectedLanguage] || {};
  const utterances = utterancesData.utterances || {};

  const onChangeAutomaticTranslation = (value) => {
    setAutomaticTranslation(value);
    axios.put('/internal_api/message', {
      messageId: props.message_id,
      properties: { ...message_properties, automaticTranslation: value },
      selectedLanguage,
    });
    props.updateEntry(props.intent_id, utterances_by_language, value);
  };

  const handleLanguageChange = useHandleEntryLanguageChange(
    selectedLanguage,
    props.message_id,
    entryToLanguage,
    setEntryToLanguage
  );

  let syncResponseOptions = async (responseOptions) => {
    const newMessageProperties = {
      ...props.message_properties,
      automaticTranslation,
      responseOptionsByLanguage: {
        ...optionsByLanguage,
        [selectedLanguage]: responseOptions,
      },
    };
    setIsLoading(true);
    const response = await updateMessage(
      newMessageProperties,
      props.message_id,
      props.projectId,
      automaticTranslation
    );
    props.dispatch(
      updateMessageAction({
        message_id: props.message_id,
        message_properties: response?.data?.message_details?.message_properties,
      })
    );
    setIsLoading(false);
  };

  const syncTitle = useCallback((title) => {
    axios.post('/internal_api/qna/update_entry_title', {
      title,
      message_id: props.message_id,
      intent_id: props.intent_id,
    });
  }, []);

  let handleChangeTitle = (title) => {
    setTitle(title);
  };
  const saveTitle = () => {
    syncTitle(title);
  };

  function deleteEntry() {
    props.deleteEntry({
      message_id: props.message_id,
      intent_id: props.intent_id,
      app_id: props.appId,
    });
  }

  const updateUtterances = (utterances, has_more) => {
    const utterancesData = {
      has_more:
        has_more !== undefined ? has_more : utterances_by_language[selectedLanguage]?.has_more,
      utterances,
    };

    props.updateEntry(
      props.intent_id,
      {
        ...utterances_by_language,
        [selectedLanguage]: utterancesData,
      },
      automaticTranslation
    );
  };

  function toggleCollapse() {
    setIsCollapsed(!isCollapsed);
  }

  return (
    <div className="QnaEntryContainer">
      <div style={{ width: '100%', backgroundColor: '#EAEAE8' }}>
        <FlexRow>
          <FlexRow style={{ width: '50%', marginBottom: 0 }}>
            <div style={{ flexGrow: 1 }}>
              <Input
                style={{
                  fontSize: 14,
                  marginLeft: 4,
                }}
                value={title}
                onChange={handleChangeTitle}
                onBlur={saveTitle}
              />
            </div>
          </FlexRow>
          <DeleteConfirmationModal onDelete={deleteEntry} trigger={<EntryDeleteButton />} />
          {languageOptions.length > 1 && (
            <Dropdown
              style={{ margin: '8px 15px 8px 0px' }}
              selection
              value={selectedLanguage || ''}
              options={languageOptions}
              onChange={(event, data) => {
                handleLanguageChange(data.value);
              }}
            />
          )}
          <Icon
            name={'angle ' + (isCollapsed ? 'down' : 'up')}
            style={{ marginBottom: 7, marginRight: 12, cursor: 'pointer' }}
            onClick={toggleCollapse}
          />
        </FlexRow>
      </div>

      {!isCollapsed && (
        <div className="QnaEntryDataContainer">
          {/* Left */}
          <div className="QnaEntryLeft">
            {props.inbound_links?.length > 0 && (
              <FlexRow style={{ flexFlow: 'row wrap' }}>
                <span style={{ fontWeight: 700, fontSize: 12, marginRight: 5 }}>
                  {languageMap.inboundLinks}
                </span>
                <InboundLinksList links={props.inbound_links} selectedLanguage={selectedLanguage} />
              </FlexRow>
            )}

            {props.intent_id && (
              <>
                <Utterances
                  conflictsPage={conflictsPage}
                  hasMore={utterancesData.has_more}
                  utterances={utterances}
                  appId={props.appId}
                  intentId={props.intent_id}
                  entities={props.entities}
                  selectedLanguage={selectedLanguage}
                  updateUtterances={updateUtterances}
                />
              </>
            )}
          </div>

          {/* Right */}
          <div className="QnaEntryRight">
            <LoadingOverlay isLoading={isLoading} />
            <MessageBlocks
              loadingChange={(value) => setIsLoading(value)}
              options={currentResponseOptions}
              onChange={syncResponseOptions}
              automaticTranslation={automaticTranslation}
              onChangeAutomaticTranslation={onChangeAutomaticTranslation}
              messageId={props.message_id}
              projectId={props.projectId}
              selectedLanguage={selectedLanguage}
            />
            <VerticalOrderedList
              isQna={true}
              style={{ marginTop: 5 }}
              title={languageMap.links}
              elements={props.outbound_links}
              sourceNodeId={props.message_node_id}
              selectedLanguage={selectedLanguage}
              isProjectLanguage={props.isProjectLanguage}
            />
          </div>
        </div>
      )}
    </div>
  );
}

function EntryDeleteButton(props) {
  const style = {
    marginRight: 10,
    marginLeft: 'auto',
    padding: 6,
  };

  return (
    <IconButton style={style} onClick={props.onClick} size="large">
      <Delete style={{ width: '30px', height: '30px' }} />
    </IconButton>
  );
}

const mapStateToProps = (state) => ({
  entities: getEntities(state),
});
export default connect(mapStateToProps)(Entry);
