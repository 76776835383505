import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { produce } from 'immer';
import { connect } from 'react-redux';

import { selectAllLanguages, selectCurrentUser } from '../../../store/crm/selectors';
import CreateNewProject from 'pages/ProjectList/components/CreateNewProject';
import ListIcon from '@mui/icons-material/List';
import IconButton from '@mui/material/IconButton';
import { AddButton } from '../../../components/common/Button';
import Loader from '../../../components/common/Loader';

import languageMap from 'Language/Language';

const RenderProjects = ({ languages }) => {
  const [projects, setProjects] = useState([]);
  const [canCreateProjects, setCanCreateProjects] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get('/internal_api/projects')
      .then((response) => setProjects(response.data))
      .catch((error) => {
        console.error('Error while fetching the projects. ', error);
      })
      .finally(() => setLoading(false));

    axios
      .get('/internal_api/projects/permissions')
      .then((response) => setCanCreateProjects(Boolean(response.data[0].can_create_project)))
      .catch((error) => {
        console.error('Error while fetching permissions. ', error);
      });
  }, []);

  const handleProjectCreate = (project) => {
    setProjects(
      produce(projects, (draft) => {
        draft.splice(0, 0, project);
      })
    );
  };
  return (
    <div id="ProjectsContainer">
      {loading && <Loader style={{ width: '100%', height: '100%' }} />}
      {!loading && (
        <>
          {canCreateProjects && (
            <AddButton
              icon="add"
              text={languageMap.createNewProject}
              className="CreateProjectButton dm-sans-500"
              onClick={() => setShowCreateModal(true)}
            />
          )}
          {showCreateModal && (
            <CreateNewProject
              close={() => setShowCreateModal(false)}
              onProjectCreate={handleProjectCreate}
              languages={languages}
            />
          )}
          <div id="ProjectsList">
            <div id="MobileHeader">
              <IconButton style={{ position: 'relative', left: 4 }} size="small">
                <ListIcon className="list-icon" fontSize="large" />
              </IconButton>

              <span className="dm-sans-700 title">Projects</span>
            </div>

            {projects.map(({ project_id, project_name }) => (
              <Link key={project_id} to={`/project/${project_id}/crm/inbox`}>
                <div className="ProjectCard">
                  <span className="title dm-sans-700">{project_name}</span>
                </div>
              </Link>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  languages: selectAllLanguages(state),
  currentUser: selectCurrentUser(state),
});

export default connect(mapStateToProps)(RenderProjects);
