import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Axios from 'axios';
import { Logo } from 'assets/logo/Logo';
import { checkSession } from '../../utils/utils';
import LoginPanel from 'assets/svg/LoginPanel';

import './Auth.scss';
import AllRights from '../../components/AllRights/AllRights';
import { CurrentUser } from '../../containers/CurrentUser';
import Loader from '../../components/common/Loader';

const Login = () => {
  const navigate = useNavigate();
  const domainName = window.location.hostname;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { currentUser } = useContext(CurrentUser) || {};
  useEffect(() => {
    if (currentUser) {
      navigate('/projects');
      return;
    }
    Axios.get('/internal_api/get_sso_domain', {
      params: {
        wisevoice_domain: domainName,
      },
    })
      .then((response) => {
        if (response.data && response.data.sso_domain) {
          let sso_domain = response.data.sso_domain;
          window.location.href = sso_domain;
        }
      })
      .catch((error) => {
        console.log('Error: ', error);
      });
  }, []);

  const handleLogin = useCallback(
    (event) => {
      event.preventDefault();
      setError('');
      if (!email || !password) {
        setError('Login failed. Missing credentials');
      }
      const params = {
        email,
        password,
      };
      setLoading(true);
      Axios.post('/internal_api/login_attempt', params)
        .then(async (response) => {
          setLoading(false);
          if (response.data === 'ok') {
            const userData = await checkSession();
            if (userData) {
              window.localStorage.setItem('currentUserLanguage', userData.language);
              window.location.href = '/projects';
              return;
            }
          }

          if (response.data === 'not confirmed') {
            setError('Login failed. Please confirm your email');
          } else {
            setError('Login failed. Please check your credentials');
          }
        })
        .catch((error) => {
          setError(JSON.stringify(error));
          setLoading(false);
        });
    },
    [email, password]
  );

  const handleForgotPassword = () => {
    navigate('/forgot_password', { replace: true });
  };

  return (
    <div className="Auth">
      <div className="Container">
        <Logo className="Logo" />
        <span className="Title dm-sans-700">Sign In</span>
        <span className="Subtitle dm-sans-400">Enter your email and password to sign in!</span>
        <form className="Container" onSubmit={handleLogin}>
          <div>
            <span className="FormLabel dm-sans-500">Email</span>
            <span className="FormLabelRequired dm-sans-500">*</span>
          </div>
          <input
            name="email"
            autoComplete="email"
            className="Input dm-sans-400"
            placeholder="Your email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div>
            <span className="FormLabel dm-sans-500">Password</span>
            <span className="FormLabelRequired dm-sans-500">*</span>
          </div>
          <input
            name="password"
            autoComplete="current-password"
            className="Input dm-sans-400"
            placeholder="Your password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span className="SpanButton dm-sans-500" onClick={handleForgotPassword}>
            Forgot password?
          </span>
          {error && <div className="Error dm-sans-400">{error}</div>}
          {loading && <Loader style={{ width: '100%', height: '100%', marginTop: 25 }} />}
          <button className="Submit dm-sans-700" type="submit" disabled={loading}>
            Sign In
          </button>
        </form>
        <div className="CreateAccountContainer">
          <span className="NotRegistered dm-sans-400">Not registered yet?</span>
          <Link to="/register">
            <span className="SpanButton dm-sans-500">Create an Account</span>
          </Link>
        </div>
        <AllRights />
      </div>

      {document.title !== 'Tectu' && <LoginPanel className="ShowOnlyOnDesktop" />}
    </div>
  );
};

export default Login;
