import { useEffect } from 'react';

const useComponentDidMount = (cb, unmountCb) => {
  useEffect(() => {
    cb();
    if (unmountCb) {
      return unmountCb;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useComponentDidMount;
