import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, MenuItem, Select, styled } from '@mui/material';
import { AddButton } from '../../components/common/Button';
import languageMap from '../../Language/Language';
import HelperTooltip from '../../components/HelperTooltip/HelperTooltip';

// =====================================================================================================================
//  D E C L A R A T I O N S
// =====================================================================================================================
const StyledContainer = styled('div')(() => ({
  marginTop: 15,
}));

const TitleRow = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  '.regular-text': {},
}));

const InputRow = styled('div')(() => ({
  display: 'flex',
  marginTop: 4,
}));

const RemoveButton = styled(Button)(() => ({
  marginLeft: 4,
  padding: 4,
}));

// =====================================================================================================================
//  C O M P O N E N T
// =====================================================================================================================
class LanguagesEnum extends React.PureComponent {
  render() {
    const { values, list } = this.props;
    return (
      <StyledContainer>
        <TitleRow>
          <div className="regular-text">{languageMap.projectDetailsSecondaryLanguages}</div>

          <HelperTooltip
            sx={{ marginLeft: 2, height: 25, width: 25 }}
            title={languageMap.helperTextSecondaryLanguages}
          />

          <AddButton
            sx={{
              marginLeft: 1.25,
              minWidth: 26,
              padding: 0.5,
            }}
            onClick={this.onAdd}
            icon="add"
          />
        </TitleRow>
        {values.map((value, index) => (
          <InputRow key={index}>
            <Select
              disabled={!!value}
              value={value}
              onChange={this.onChange(index)}
              displayEmpty // to allow empty value
            >
              {list.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
            <RemoveButton onClick={this.onRemoveClick(index)}>
              <DeleteIcon />
            </RemoveButton>
          </InputRow>
        ))}
      </StyledContainer>
    );
  }

  // -----------------------------------------------------------------------------------------------------------------
  //  I N T E R N A L
  // -----------------------------------------------------------------------------------------------------------------
  /**
   *
   */
  onAdd = () => {
    const { onChange } = this.props;
    onChange([...this.props.values, '']);
  };

  onChange = (index) => (event) => {
    const { onChange, values } = this.props;
    const value = event.target.value;
    if (values.includes(value)) {
      return;
    }
    const newValues = [...values];
    newValues[index] = value;
    onChange(newValues);
  };

  onRemoveClick = (itemIndex) => () => {
    const { onChange } = this.props;
    const newValues = [...this.props.values];
    newValues.splice(itemIndex, 1);
    onChange(newValues);
  };
}

// =====================================================================================================================
//  E X P O R T
// =====================================================================================================================
export default LanguagesEnum;
