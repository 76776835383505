import React from 'react';

import './DeleteButton.scss';
import { WhiteButton } from '../../../../../../../../../components/common/Button/Buttons';
import DeleteConfirmationModal from '../../../../../../../../../components/ConfirmationModal/DeleteConfirmationModal';

const DeleteButton = (props) => {
  return (
    <DeleteConfirmationModal
      onDelete={props.onClick}
      trigger={<WhiteButton style={{ margin: 18 }} text={props.title} />}
    />
  );
};

export default DeleteButton;
