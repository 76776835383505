import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';

import MenuContainer from 'containers/MenuContainer';
import ConversationsInboxContainer from 'containers/ConversationsInboxContainer';
import ConversationsSearchContainer from 'containers/ConversationsSearchContainer';
import AnalyticsContainer from './components/AnalyticsContainer';
import MacrosContainer from './components/MacrosContainer';
import ConversationDetails from 'pages/CRM/components/ConversationDetails';
import Drawer from '@mui/material/Drawer';
import NewInternalChatIcon from '@mui/icons-material/Forum';
import { Tooltip } from '@mui/material';
import { Fab } from '@mui/material';
import languageMap from 'Language/Language';

import {
  setCurrentConversation,
  setIsMobile,
  createNewInternalConversation,
  setSearchStatus,
  resetSearch,
  setStartFromConversationIndex,
  getProjectLanguageData,
} from 'store/crm/actions';
import {
  selectCurrentConversation,
  selectCurrentUser,
  selectInboxFolder,
  selectMainMenu,
  selectSearch,
  selectIsMobile,
} from 'store/crm/selectors';

import { hasAccess } from 'services/userPermissions';

import './CRMDashboard.scss';
import ProjectDetails from '../../../ProjectDetails';
import SettingsMenu from '../../../SettingsMenu/SettingsMenu';
import { SETTINGS } from '../../../../utils/constants';

const CRMDashboard = ({
  mainMenu,
  inboxFolder,
  currentConversation,
  currentConversation: { conversation_id: conversationId },
  currentUser,
  isMobile,
  setIsMobile,
  resetSearchAction,
  setSearchStatusAction,
  setStartFromConversationIndexAction,
  getProjectLanguageData,
  ...props
}) => {
  const { id: projectId } = useParams();
  const { permissions } = currentUser;
  const hasPermissions = permissions[projectId].length > 0;

  const [conversationDetails, setConversationDetails] = useState(false);

  const [drawerState, setDrawerState] = useState(false);

  const [collapsed, setCollapse] = useState(false);

  const toggleCollapse = () => {
    setCollapse(!collapsed);
  };

  useEffect(() => {
    setConversationDetails(JSON.parse(window.localStorage.getItem('conversationDetails')));
    getProjectLanguageData(projectId);
  }, []);

  useEffect(() => {
    setDrawerState(false);
  }, [inboxFolder]);

  useEffect(() => {
    window.localStorage.setItem('conversationDetails', conversationDetails);
  }, [conversationDetails]);

  const shouldRenderMenu = (menu) => {
    if (projectId && hasPermissions) {
      return mainMenu === menu && hasAccess(projectId, permissions, menu);
    }
    return false;
  };

  const toggleDrawer = () => {
    setDrawerState(!drawerState);
  };

  const handleNewInternalConversation = async () => {
    const params = { projectId };
    await props.createNewInternalConversation(params);
  };

  const handleConversationDetailsButtonClick = () => {
    setConversationDetails(!conversationDetails);
  };

  return (
    <div className={clsx('CRMDashboard', collapsed && 'DashboardCollapsed')}>
      {!isMobile && (
        <MenuContainer
          headerTitle={languageMap.inbox}
          projectId={projectId}
          permissions={permissions}
          collapsed={collapsed}
          toggleCollapse={toggleCollapse}
        />
      )}
      {shouldRenderMenu('inbox') && (
        <div className={clsx('MainContent', isMobile && 'is-mobile-modifier-CRM-Dashboard')}>
          <div className="Containers" style={isMobile ? { width: '100%' } : {}}>
            {isMobile && !conversationId && (
              <ConversationsSearchContainer toggleDrawer={toggleDrawer} />
            )}
            {isMobile && (
              <Drawer
                classes={{ paper: 'left-bar-mobile-menu-drawer' }}
                anchor="left"
                open={drawerState}
                onClose={toggleDrawer}
              >
                <div className="hamburger-menu-mobile-container">
                  <MenuContainer
                    headerTitle="Inbox"
                    projectId={projectId}
                    permissions={permissions}
                    toggleCollapse={toggleCollapse}
                    collapsed={collapsed}
                  />
                </div>
              </Drawer>
            )}
            <ConversationsInboxContainer
              conversationDetails={conversationDetails}
              handleConversationDetailsButtonClick={handleConversationDetailsButtonClick}
              handleNewInternalConversation={handleNewInternalConversation}
            />
            {!isMobile && conversationId && (
              <div
                className="ConversationInboxSupportInfo"
                style={conversationDetails ? { width: '23%' } : { width: '0%' }}
              >
                <ConversationDetails
                  handleConversationDetailsButtonClick={handleConversationDetailsButtonClick}
                />
              </div>
            )}
            {isMobile && inboxFolder.file.value === 'internal_all' && (
              <Fab className="float-button" onClick={handleNewInternalConversation}>
                <Tooltip title={languageMap.newConversation} placement="left" arrow>
                  <NewInternalChatIcon className="new-conversation-image" />
                </Tooltip>
              </Fab>
            )}
          </div>
        </div>
      )}

      {mainMenu === 'macros' && (
        <div className="MainContent">
          <MacrosContainer projectId={projectId} />
        </div>
      )}
      {shouldRenderMenu('bot') && <ProjectDetails projectId={projectId} />}

      {mainMenu === SETTINGS && <SettingsMenu projectId={projectId} />}

      {shouldRenderMenu('reports') && (
        <div className="MainContent">
          <AnalyticsContainer projectId={projectId} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  mainMenu: selectMainMenu(state),
  inboxFolder: selectInboxFolder(state),
  currentConversation: selectCurrentConversation(state),
  currentUser: selectCurrentUser(state),
  search: selectSearch(state),
  isMobile: selectIsMobile(state),
});

const mapDispatchToProps = {
  setCurrentConversation,
  createNewInternalConversation,
  setIsMobile,
  getProjectLanguageData,
  setSearchStatusAction: setSearchStatus,
  resetSearchAction: resetSearch,
  setStartFromConversationIndexAction: setStartFromConversationIndex,
};

export default connect(mapStateToProps, mapDispatchToProps)(CRMDashboard);
