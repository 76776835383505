import React from 'react';
import { Link as ReactLink } from 'react-router-dom';

const Link = ({ shouldHardRedirect, ...props }) => {
  if (shouldHardRedirect) {
    const url = `${window.location.origin}${props.to.pathname}`;

    return (
      <a href={url} alt="redirect link">
        {props.children}
      </a>
    );
  }
  return <ReactLink {...props} />;
};

export default Link;
