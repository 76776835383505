import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import ArrowDropUpTwoToneIcon from '@mui/icons-material/ArrowDropUpTwoTone';
import HelperTooltip from 'components/HelperTooltip/HelperTooltip';

import './ListWidget.scss';

const ListWidget = ({ listOfPages, title, tableHeader, info }) => {
  return (
    <div>
      <div className="ListWidget">
        <div className="title title-text">
          {title} {info && <HelperTooltip title={info} placement="top" arrow></HelperTooltip>}
        </div>
        <div className="list">
          <div className="list-header">
            <div>{tableHeader?.[0]}</div>
            <div>{tableHeader?.[1]}</div>
          </div>
          {listOfPages &&
            listOfPages.map(({ pageURl, numberOfConversations, difference }) => (
              <div className="lineItemContainer" key={uuidv4()}>
                <div className="url regular-text">{pageURl}</div>
                <div className="valuesContainer">
                  <div className="numberOfConversations regular-text">{numberOfConversations}</div>
                  <div
                    className="difference"
                    style={
                      difference > 0
                        ? { color: 'rgba(26,176,87,255)' }
                        : { color: 'rgb(253, 110, 110)' }
                    }
                  >
                    {difference > 0 && <ArrowDropUpTwoToneIcon className="arrow-icon" />}
                    {difference <= 0 && <ArrowDropUpTwoToneIcon className="arrow-icon-down" />}

                    {difference}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ListWidget;
