import React from 'react';

const Collapse = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#A3AED0"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M19 8C20.6569 8 22 6.65685 22 5C22 3.34315 20.6569 2 19 2C17.3431 2 16 3.34315 16 5C16 6.65685 17.3431 8 19 8Z" />
    <path d="M20.34 12.72H17.03C16.1 12.72 15.27 13.24 14.85 14.06L14.05 15.64C13.86 16.02 13.48 16.25 13.06 16.25H9.95C9.66 16.25 9.23 16.19 8.96 15.63L8.17 14.06C7.75 13.23 6.92 12.71 5.99 12.71H2.66C2.3 12.72 2 13.02 2 13.38V16.47C2 19.94 4.07 22 7.53 22H15.49C18.75 22 20.76 20.21 21 17.04V13.38C21 13.02 20.7 12.72 20.34 12.72Z" />
    <path d="M21 10.4V11.33C20.79 11.26 20.57 11.22 20.34 11.22H17.03C15.53 11.22 14.18 12.05 13.52 13.39L12.82 14.76H10.19L9.51 13.4C8.83 12.05 7.49 11.22 5.99 11.22H2.66C2.43 11.22 2.21 11.26 2 11.33V8.52C2 5.47 4.47 3 7.52 3H13.6C14.24 3 14.7 3.58 14.58 4.2C14.42 5 14.49 5.87 14.88 6.79C15.33 7.83 16.17 8.67 17.21 9.12C18.13 9.51 19 9.58 19.8 9.42C20.42 9.3 21 9.76 21 10.4Z" />
  </svg>
);

export default Collapse;
