import { useEffect, useRef } from 'react';

import he from 'he';
import Axios from 'axios';
import { format } from 'date-fns';
import store from '../store';
import { setLoading } from '../store/global/actions';
import { CHECK_SESSION } from './constants';

export function normalizeString(str) {
  return str.normalize('NFKD').replace(/[\u0300-\u036f]/g, '');
}

export function decode_attribute(attr) {
  let data = attr.value;
  data = he.decode(data);
  return JSON.parse(data);
}

export function mapRows(rows, columnName) {
  return rows.reduce((obj, row) => Object.assign(obj, { [row[columnName]]: row }), {});
}

export function replaceSeconds(value) {
  if (value >= 86400) {
    return `${Math.trunc(value / 86400)}d`;
  } else if (value >= 3600) {
    return `${Math.trunc(value / 3600)}h`;
  } else if (value >= 60) {
    return `${Math.trunc(value / 60)}min`;
  } else if (value === 0) {
    return value;
  } else {
    return `${value}s`;
  }
}

export function downloadFileFromUrl(url, contentType, fileName, params = {}) {
  store.dispatch(setLoading(true));
  Axios.get(url, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': contentType,
    },
    params,
  })
    .then((response) => {
      store.dispatch(setLoading(false));
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      store.dispatch(setLoading(false));
      console.log(error);
    });
}

export const getTimestamp = (timestamp) => {
  return format(new Date(timestamp), 'EEEE hh:mmaaa');
};

export const usePrevious = (value) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

export const getConversationTemplate = ({
  user_id,
  conversation_id,
  subject = null,
  email = null,
  is_conversation_read = 1,
  conversation_status = 0,
  department_id_assigned = null,
  department_name = '',
  assignee,
  channel = '',
  first_seen,
  translated_message = null,
  ...props
}) => ({
  user_id,
  conversation_id,
  subject,
  email,
  is_conversation_read,
  conversation_status,
  department_id_assigned,
  department_name,
  assignee,
  channel,
  translated_message,
  ...props,
  last_message: {
    timestamp: first_seen,
    message: null,
  },
});

export const getConversationHistoryTemplate = ({
  history = [],
  first_seen = 0,
  conversations = [],
  participants = [],
}) => {
  return {
    history,
    first_seen,
    conversation_data: {
      conversation_notes: [],
    },
    conversations,
    allowed: true,
    participants,
  };
};

export const isCurrentUserAdmin = (participants, currentUser) => {
  if (participants && currentUser) {
    const filterCurrentUser = participants?.filter(
      ({ participant_id }) => participant_id === currentUser.user_id
    );

    if (filterCurrentUser[0]?.is_admin === 1) {
      return true;
    }
  }
  return false;
};

export const checkSession = () => {
  return new Promise((resolve, reject) => {
    Axios.get(CHECK_SESSION)
      .then(({ data }) => {
        if (!data.user_id) {
          return reject('Unauthorized');
        }
        resolve(data);
      })
      .catch(reject);
  });
};

export const srcToFile = (src, fileName, mimeType) => {
  return fetch(src)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], fileName, { type: mimeType });
    });
};
