import React from 'react';

const SeparatorV2 = (props) => {
  const style = {
    width: 'auto',
    height: 2,
    borderRadius: 1,
    backgroundColor: '#d8d8d8',
    margin: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
  };

  if (props.left !== undefined) style.marginLeft = props.left;

  if (props.right !== undefined) style.marginRight = props.right;

  if (props.top !== undefined) style.marginTop = props.top;

  if (props.bottom !== undefined) style.marginBottom = props.bottom;

  if (props.width !== undefined) style.width = props.width;

  return <div style={style} />;
};

export default SeparatorV2;
