import React, { useRef, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FileDownload from '@mui/icons-material/CloudDownload';
import Button from '@mui/material/Button';
import axios from 'axios';
import { connect } from 'react-redux';
import { setLoading } from '../../../../../../../store/global/actions';
import {
  getConversationsGraph,
  getUniqueUsers,
  getRepliesSent,
  getBusinessHourConversation,
  getConversationsByBreakdownChannel,
  getConversationsByBreakdownLanguage,
  getCustomerSatisfactionScore,
  getInboundConversations,
  getContainmentGraph,
  getHeatMapData,
  getDialogType,
  getIntents,
  getExcludedIntents,
  getTopAnswers,
  getTopAnswersByHandoff,
  getWiseAgentRating,
  getGraphContainmentRateByBreakdownChannelId,
  getGraphContainmentRateByBreakdownLanguage,
  getGraphWiseAgentRatingByBreakdownChannelId,
  getGraphWiseAgentRatingByBreakdownLanguage,
  getDetectionRate,
  getConversationsByBreakdown,
  getAverageResponseTime,
  getHeatMapTeamPerformance,
  getAgentPerformance,
  getConversationRating,
  getTableFeedback,
  getRemarks,
  getFeedbackScore,
} from '../../../../../../../store/analytics/selectors';
import { CSV_OPTION, PDF_OPTION, XLSX_OPTION } from '../../../../../../../utils/constants';
import languageMap from 'Language/Language';

const ANCHOR_ORIGIN = { vertical: 'bottom', horizontal: 'left' };
const iconStyle = { marginRight: '5px' };

const base64ToArrayBuffer = (data) => {
  const bString = window.atob(data);
  const bLength = bString.length;
  const bytes = new Uint8Array(bLength);
  for (let i = 0; i < bLength; i++) {
    bytes[i] = bString.charCodeAt(i);
  }
  return bytes;
};

const ExportOptions = ({
  tab,
  projectId,
  startTimestamp,
  endTimestamp,
  selectedTimeInterval,
  dispatch,
  conversationsGraph,
  containmentGraph,
  conversationsByBreakdownChannel,
  conversationsByBreakdownLanguage,
  customerSatisfactionScore,
  uniqueUsers,
  repliesSent,
  businessHourConversation,
  inboundConversations,
  heatMapData,
  topAnswers,
  topAnswersByHandoff,
  wiseAgentRating,
  graphContainmentRateByBreakdownChannelId,
  graphContainmentRateByBreakdownLanguage,
  graphWiseAgentRatingByBreakdownChannelId,
  graphWiseAgentRatingByBreakdownLanguage,
  detectionRate,
  conversationsByBreakdown,
  averageResponseTime,
  heatMapTeamPerformance,
  agentPerformance,
  tableFeedback,
  conversationRatings,
  timezone
}) => {
  const [anchorEl, setAnchor] = useState(null);
  const [withoutTimeStart] = new Date(startTimestamp).toISOString().split('T');
  const [withoutTimeEnd] = new Date(endTimestamp).toISOString().split('T');
  const dateName = withoutTimeStart + ' to ' + withoutTimeEnd;
  const rootRef = useRef();
  /**
   *
   */
  const onLauncherClick = () => {
    setAnchor(rootRef.current);
  };

  const close = () => {
    setAnchor(null);
  };

  const onDownload = async (option, all) => {
    dispatch(setLoading(true));

    const URL =
      process.env.NODE_ENV === 'development'
        ? `http://${process.env.REACT_APP_EXPORT_HOST}:${process.env.REACT_APP_EXPORT_PORT}/export-analytics`
        : `${process.env.REACT_APP_EXPORT_HOST}:${process.env.REACT_APP_EXPORT_PORT}/export-analytics`;

    const type = all ? 'All' : tab;
    let name = all ? `Wisevoice Report ${dateName}` : `${tab} Report ${dateName}`;

    const dataObject = {
      tab: type,
      projectId,
      requestFormat: option,
      startTimestamp,
      endTimestamp,
      timezone,
      selectedTimeInterval,
    };
    if (option !== PDF_OPTION) {
      if (type === 'Overview') {
        dataObject.conversationsGraphTitle = conversationsGraph.title;
        dataObject.conversationsGraphHeader = [languageMap.date, languageMap.number];
        dataObject.conversationsGraph = conversationsGraph.data[0].data || [];
        dataObject.conversationsGraphLabels = conversationsGraph.labels || [];
        dataObject.conversationsByBreakdownChannel = {
          ...conversationsByBreakdownChannel,
          labels: [languageMap.channel, languageMap.percent],
        };
        dataObject.conversationsByBreakdownLanguage = {
          ...conversationsByBreakdownLanguage,
          labels: [languageMap.defaultDisplayLanguage, languageMap.percent],
        };
        dataObject.uniqueUsersData = { ...uniqueUsers, valueHeader: languageMap.value } || {};
        dataObject.repliesSent = repliesSent || {};
        dataObject.businessHourConversation = businessHourConversation || {};
        dataObject.customerSatisfactionScore = {
          ...customerSatisfactionScore,
          header: [languageMap.rating, languageMap.surveys],
        };
        dataObject.inboundConversations = inboundConversations || {};
        dataObject.containmentGraph = {
          ...containmentGraph,
          header: [languageMap.date, languageMap.percent],
        };
        dataObject.heatMapData = heatMapData || {};
      }
      if (type === 'Wise Agent') {
        dataObject.containmentGraph = {
          ...containmentGraph,
          header: [languageMap.date, languageMap.percent],
        };
        dataObject.topAnswers = {
          ...topAnswers,
          header: [languageMap.intentName, languageMap.matchRate],
        };
        dataObject.topAnswersByHandoff = {
          ...topAnswersByHandoff,
          header: [languageMap.intentName, languageMap.matchRate],
        };
        dataObject.wiseAgentRating = wiseAgentRating || {};
        dataObject.wiseAgentRatingHeader = [languageMap.rating, languageMap.surveys] || [];
        dataObject.graphContainmentRateByBreakdownChannelId =
          {
            ...graphContainmentRateByBreakdownChannelId,
            headers: [languageMap.profileLanguage, languageMap.percent],
          } || {};
        dataObject.graphContainmentRateByBreakdownHeaders =
          [languageMap.channel, languageMap.percentage] || [];

        dataObject.graphContainmentRateByBreakdownLanguage =
          graphContainmentRateByBreakdownLanguage || {};
        dataObject.graphWiseAgentRatingByBreakdownChannelId =
          {
            ...graphWiseAgentRatingByBreakdownChannelId,
            headers: [languageMap.profileLanguage, languageMap.rating],
          } || {};
        dataObject.graphWiseAgentRatingByBreakdownLanguage =
          graphWiseAgentRatingByBreakdownLanguage || {};
        dataObject.detectionRate =
          {
            ...detectionRate,
            headers: [languageMap.date, languageMap.percentage],
          } || {};
      }

      if (type === 'Team Performance') {
        dataObject.conversationsByBreakdown =
          { ...conversationsByBreakdown, headers: [languageMap.team, languageMap.value] } || [];
        dataObject.conversationsByBreakdownData = [languageMap.team, languageMap.number] || [];
        dataObject.agentPerformance = agentPerformance || [];
        dataObject.heatMapTeamPerformance =
          { ...heatMapTeamPerformance, headers: [languageMap.dayHour] } || [];
        dataObject.averageResponseTime =
          { ...averageResponseTime, headers: [languageMap.teamDay] } || [];
      }
      if (type === 'Feedback') {
        dataObject.tableFeedback = tableFeedback || [];
        dataObject.tableFeedbackTitle = languageMap.customerFeedback || '';
        dataObject.conversationRatingsTitle = languageMap.dayRating || '';
        dataObject.conversationRatings = conversationRatings || [];
      }
    }

    try {
      const axiosInstance = axios.create();
      axiosInstance.defaults.baseURL = '/';

      const response = await axiosInstance({
        method: 'post',
        url: URL,
        data: dataObject,
        responseType: option === 'xlsx' ? 'blob' : undefined,
      });
      let bufferArray;
      const link = document.createElement('a');
      let url;
      if (response.data) {
        switch (option) {
          case 'pdf':
            bufferArray = base64ToArrayBuffer(response.data);
            url = window.URL.createObjectURL(new Blob([bufferArray], { type: 'application/pdf' }));
            name += '.pdf';
            break;
          case 'xlsx':
            url = window.URL.createObjectURL(new Blob([response.data]));
            name += '.xlsx';
            break;
          case 'csv':
            bufferArray = base64ToArrayBuffer(response.data);
            url = window.URL.createObjectURL(new Blob([bufferArray], { type: 'application/zip' }));
            name += '.zip';
            break;
          default:
            // eslint-disable-next-line no-console
            console.log('Bad request');
        }

        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        dispatch(setLoading(false));
      }
    } catch (e) {
      dispatch(setLoading(false));
    }
  };

  const onOptionClick = (event) => {
    close();
    const { option, all } = event.target.dataset;
    onDownload(option, all);
  };

  return (
    <div className="export-options">
      <Button variant="contained" onClick={onLauncherClick} ref={rootRef}>
        <FileDownload />
        &nbsp; {languageMap.export?.toUpperCase()}
        <div className="arrow" />
      </Button>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={ANCHOR_ORIGIN}
        getContentAnchorEl={null}
        open={!!anchorEl}
        onClose={close}
        disableAutoFocusItem={true}
      >
        <MenuItem onClick={onOptionClick} data-option={PDF_OPTION}>
          <FileDownload style={iconStyle} />
          Export PDF
        </MenuItem>

        <MenuItem onClick={onOptionClick} data-option={PDF_OPTION} data-all={true}>
          <FileDownload style={iconStyle} />
          Export PDF All
        </MenuItem>

        <MenuItem onClick={onOptionClick} data-option={CSV_OPTION}>
          <FileDownload style={iconStyle} />
          Export CSV
        </MenuItem>

        {/* <MenuItem onClick={onOptionClick} data-option={CSV_OPTION} data-all={true}>*/}
        {/*  <FileDownload style={iconStyle} />*/}
        {/*  Export CSV All*/}
        {/* </MenuItem>*/}

        <MenuItem onClick={onOptionClick} data-option={XLSX_OPTION}>
          <FileDownload style={iconStyle} />
          Export XLSX
        </MenuItem>

        {/* <MenuItem onClick={onOptionClick} data-option={XLSX_OPTION} data-all={true}>*/}
        {/*  <FileDownload style={iconStyle} />*/}
        {/*  Export XLSX All*/}
        {/* </MenuItem>*/}
      </Menu>
    </div>
  );
};

const mapStateToProps = (state) => ({
  conversationsGraph: getConversationsGraph(state),
  uniqueUsers: getUniqueUsers(state),
  repliesSent: getRepliesSent(state),
  businessHourConversation: getBusinessHourConversation(state),
  conversationsByBreakdownChannel: getConversationsByBreakdownChannel(state),
  conversationsByBreakdownLanguage: getConversationsByBreakdownLanguage(state),
  customerSatisfactionScore: getCustomerSatisfactionScore(state),
  inboundConversations: getInboundConversations(state),
  containmentGraph: getContainmentGraph(state),
  heatMapData: getHeatMapData(state),
  dialogType: getDialogType(state),
  allIntents: getIntents(state),
  excludedIntents: getExcludedIntents(state),
  topAnswers: getTopAnswers(state),
  topAnswersByHandoff: getTopAnswersByHandoff(state),
  wiseAgentRating: getWiseAgentRating(state),
  detectionRate: getDetectionRate(state),
  graphContainmentRateByBreakdownChannelId: getGraphContainmentRateByBreakdownChannelId(state),
  graphContainmentRateByBreakdownLanguage: getGraphContainmentRateByBreakdownLanguage(state),
  graphWiseAgentRatingByBreakdownChannelId: getGraphWiseAgentRatingByBreakdownChannelId(state),
  graphWiseAgentRatingByBreakdownLanguage: getGraphWiseAgentRatingByBreakdownLanguage(state),
  conversationsByBreakdown: getConversationsByBreakdown(state),
  averageResponseTime: getAverageResponseTime(state),
  heatMapTeamPerformance: getHeatMapTeamPerformance(state),
  agentPerformance: getAgentPerformance(state),
  conversationRatings: getConversationRating(state),
  tableFeedback: getTableFeedback(state),
  remarks: getRemarks(state),
  feedbackScore: getFeedbackScore(state),
});

export default connect(mapStateToProps)(ExportOptions);
