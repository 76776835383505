import React from 'react';

const Annotations = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.97998 13H6.38998C6.76998 13 7.10998 13.21 7.27998 13.55L8.44998 15.89C8.78998 16.57 9.47998 17 10.24 17H13.77C14.53 17 15.22 16.57 15.56 15.89L16.73 13.55C16.9 13.21 17.25 13 17.62 13H21.98"
      stroke="#0530F9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.3 13H17.82C16.84 13 15.97 13.54 15.53 14.42L14.69 16.08C14.49 16.48 14.09 16.73 13.65 16.73H10.37C10.06 16.73 9.62 16.66 9.33 16.08L8.49 14.43C8.05 13.56 7.17 13.01 6.2 13.01H2.7C2.31 13.01 2 13.32 2 13.71V16.97C2 20.6 4.18 22.77 7.82 22.77H16.2C19.63 22.77 21.74 20.89 22 17.55V13.7C22 13.32 21.69 13 21.3 13Z"
      fill="#0530F9"
    />
    <path
      d="M7 4.13C3.46 4.65 2 6.73 2 11V15C2 20 4 22 9 22H15C20 22 22 20 22 15V11C22 6.73 20.54 4.65 17 4.13"
      stroke="#0530F9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 2L10 6"
      stroke="#0530F9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 6L10 2"
      stroke="#0530F9"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Annotations;
