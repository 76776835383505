import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import languageMap from 'Language/Language';

import SmallStatsWidget from '../Widgets/SmallStatsWidget';
import LargeStatsWidget from '../Widgets/LargeStatsWidget';
import ListWidget from '../Widgets/ListWidget';
import HeatMapWidget from '../Widgets/HeatMapWidget';
import Graph from '../../../../../../../components/Graphs/Graph/Graph';

import {
  getConversationsGraph,
  getUniqueUsers,
  getRepliesSent,
  getBusinessHourConversation,
  getConversationsByBreakdownChannel,
  getConversationsByBreakdownLanguage,
  getCustomerSatisfactionScore,
  getInboundConversations,
  getContainmentGraph,
  getHeatMapData,
} from '../../../../../../../store/analytics/selectors';
import {
  setConversationsGraph,
  setUniqueUsers,
  setRepliesSent,
  setBusinessHourConversation,
  setConversationsByBreakdownChannel,
  setConversationsByBreakdownLanguage,
  setCustomerSatisfactionScore,
  setInboundConversations,
  setContainmentGraph,
  setHeatMapData,
} from '../../../../../../../store/analytics/actions';

const Overview = ({
  projectId,
  startTimestamp,
  endTimestamp,
  selectedTimeInterval,
  currentTimezone,
  setConversationsGraph,
  setUniqueUsers,
  setRepliesSent,
  setBusinessHourConversation,
  setConversationsByBreakdownChannel,
  setConversationsByBreakdownLanguage,
  setCustomerSatisfactionScore,
  setInboundConversations,
  setContainmentGraph,
  setHeatMapData,
  conversationsGraph,
  uniqueUsers,
  repliesSent,
  businessHourConversation,
  conversationsByBreakdownChannel,
  conversationsByBreakdownLanguage,
  customerSatisfactionScore,
  inboundConversations,
  containmentGraph,
  heatMapData,
}) => {
  useEffect(() => {
    const populateGraphConversations = async () => {
      const response = await axios.get('/internal_api/analytics/get_conversations', {
        params: {
          projectId: projectId,
          timezone: currentTimezone,
          startTimestamp,
          endTimestamp,
        },
      });
      let conversationsGraphObject = {};
      let auxDataSet = response.data;
      let dataSet = [];
      let labels = auxDataSet.prettified_days;
      let conversationsList = auxDataSet.conversations;
      let comparisonValue = auxDataSet.comparison_value;
      let comparisonText = auxDataSet.comparison_text;
      for (let i = 0; i < auxDataSet?.data.length; i++) {
        let temporaryData = [];
        const temporaryDataset = auxDataSet.data[i];
        for (let j = 0; j < temporaryDataset?.length; j++) {
          temporaryData = [...temporaryData, temporaryDataset[j]['no_breakdown']];
        }
        const entry = {
          data: temporaryData,
          borderColor: '#1a90ff',
          fill: false,
          backgroundColor: '#1a90ff',
          maxBarThickness: 14,
          borderRadius: 14,
        };
        dataSet.push(entry);

        conversationsGraphObject.labels = labels;
        conversationsGraphObject.data = dataSet;
        conversationsGraphObject.title = auxDataSet?.chart_name;
        conversationsGraphObject.conversationsList = conversationsList;
        conversationsGraphObject.comparisonValue = comparisonValue;
        conversationsGraphObject.comparisonText = comparisonText;
        conversationsGraphObject.info = languageMap.infoReportsConversations;

        setConversationsGraph(conversationsGraphObject);
      }
    };
    const populateGetUniqueUsers = async () => {
      const response = await axios.get('/internal_api/analytics/get_unique_users', {
        params: {
          projectId: projectId,
          timezone: currentTimezone,
          startTimestamp,
          endTimestamp,
        },
      });

      let uniqueUsersObject = {};
      let auxDataSet = response.data;
      uniqueUsersObject.title = auxDataSet.chart_name;
      uniqueUsersObject.content = auxDataSet.data;
      uniqueUsersObject.info = languageMap.infoReportsUniqueUsers;
      uniqueUsersObject.change = auxDataSet.comparison_value;
      uniqueUsersObject.comparisonText = auxDataSet.comparison_text;

      setUniqueUsers(uniqueUsersObject);
    };

    const populateGetRepliseSent = async () => {
      const response = await axios.get('/internal_api/analytics/get_replies_sent', {
        params: {
          projectId: projectId,
          timezone: currentTimezone,
          startTimestamp,
          endTimestamp,
        },
      });
      let repliseSentObject = {};
      let auxDataSet = response.data;
      repliseSentObject.title = auxDataSet.chart_name;
      repliseSentObject.content = auxDataSet.data;
      repliseSentObject.info = languageMap.infoReportsRepliesSent;
      repliseSentObject.change = auxDataSet.comparison_value;
      repliseSentObject.comparisonText = auxDataSet.comparison_text;

      setRepliesSent(repliseSentObject);
    };

    const populateGetBusinessHourForStartingConversations = async () => {
      const response = await axios.get(
        '/internal_api/analytics/get_busiest_hour_for_starting_conversations',
        {
          params: {
            projectId: projectId,
            timezone: currentTimezone,
            startTimestamp,
            endTimestamp,
          },
        }
      );
      let businessHourForStartingConversationsObject = {};
      let auxDataSet = response.data;
      businessHourForStartingConversationsObject.title = auxDataSet.chart_name;
      businessHourForStartingConversationsObject.content = `${auxDataSet.data['day_name']} ${auxDataSet.data['hour']}`;
      businessHourForStartingConversationsObject.info =
        languageMap.infoReportsBusiestHourForStartingConversations;
      businessHourForStartingConversationsObject.data = auxDataSet.number_of_conversations;

      setBusinessHourConversation(businessHourForStartingConversationsObject);
    };

    const populateGetConversationsByBreakdownChannel = async () => {
      const response = await axios.get('/internal_api/analytics/get_conversations_by_breakdown', {
        params: {
          projectId: projectId,
          timezone: currentTimezone,
          startTimestamp,
          endTimestamp,
          breakdown: 'channel_id',
          data_representation: 'rate',
        },
      });
      let conversationsByBreakdownChannelObject = {};
      let auxDataSet = response.data;
      conversationsByBreakdownChannelObject.list = [];
      conversationsByBreakdownChannelObject.title = auxDataSet.chart_name;
      conversationsByBreakdownChannelObject.info = languageMap.infoReportsConversationsByChannel;

      let temoporaryArrayForExtraction = [];
      for (const key in auxDataSet.data[0]) {
        let temporaryObjectForExtraction = {};
        temporaryObjectForExtraction.lineItem = key;
        temporaryObjectForExtraction.value = auxDataSet.data[0][key];
        temoporaryArrayForExtraction.push(temporaryObjectForExtraction);
      }
      conversationsByBreakdownChannelObject.list = [...temoporaryArrayForExtraction];

      setConversationsByBreakdownChannel(conversationsByBreakdownChannelObject);
    };

    const populateGetConversationsByBreakdownLanguage = async () => {
      const response = await axios.get('/internal_api/analytics/get_conversations_by_breakdown', {
        params: {
          projectId: projectId,
          timezone: currentTimezone,
          startTimestamp,
          endTimestamp,
          breakdown: 'language',
          data_representation: 'rate',
        },
      });
      let conversationsByBreakdownLanguageObject = {};
      let auxDataSet = response.data;
      conversationsByBreakdownLanguageObject.list = [];
      conversationsByBreakdownLanguageObject.title = auxDataSet.chart_name;
      conversationsByBreakdownLanguageObject.info = languageMap.infoReportsConversationsByLanguage;

      let temoporaryArrayForExtraction = [];
      for (const key in auxDataSet.data[0]) {
        let temporaryObjectForExtraction = {};
        temporaryObjectForExtraction.lineItem = key;
        temporaryObjectForExtraction.value = auxDataSet.data[0][key];
        temoporaryArrayForExtraction.push(temporaryObjectForExtraction);
      }
      conversationsByBreakdownLanguageObject.list = [...temoporaryArrayForExtraction];

      setConversationsByBreakdownLanguage(conversationsByBreakdownLanguageObject);
    };

    const populateGetCustomerSatisfactionScore = async () => {
      const response = await axios.get('/internal_api/analytics/get_customer_satisfaction_score', {
        params: {
          projectId: projectId,
          timezone: currentTimezone,
          startTimestamp,
          endTimestamp,
          breakdown: 'language',
        },
      });
      let customerSatisfactionScoreObject = {};
      let auxDataSet = response.data;

      customerSatisfactionScoreObject.list = [];
      customerSatisfactionScoreObject.title = auxDataSet.chart_name;
      customerSatisfactionScoreObject.info = languageMap.infoReportsCustomerSatisfactionScore;
      customerSatisfactionScoreObject.comparisonText = auxDataSet.data.comparison_text;
      customerSatisfactionScoreObject.surveyText = auxDataSet.data.surveys_total_text;
      customerSatisfactionScoreObject.content = auxDataSet.data.satisfaction_score || 0.01;
      customerSatisfactionScoreObject.percentage = auxDataSet.data.comparison_value;

      setCustomerSatisfactionScore(customerSatisfactionScoreObject);
    };

    const populateInboundConversations = async () => {
      const response = await axios.get(
        '/internal_api/analytics/get_top_pages_for_new_inbound_conversations',
        {
          params: {
            projectId: projectId,
            timezone: currentTimezone,
            startTimestamp,
            endTimestamp,
          },
        }
      );
      let auxDataSet = response.data;
      let inboundConversationsObject = {};
      let title = auxDataSet.chart_name;
      let columnHeader = auxDataSet.table_column_header;
      let temporaryAuxDataArray = [];
      let tableHeader = auxDataSet.table_column_header;

      for (const key in auxDataSet.data) {
        let temporaryObjectForExtraction = Object.values(auxDataSet.data[key]);
        let temporaryObject = {};
        temporaryObject.pageURl = key;
        temporaryObject.numberOfConversations = temporaryObjectForExtraction[0];
        temporaryObject.difference = temporaryObjectForExtraction[1];
        temporaryAuxDataArray.push(temporaryObject);
      }
      inboundConversationsObject.data = temporaryAuxDataArray;
      inboundConversationsObject.title = title;
      inboundConversationsObject.columnHeader = columnHeader;
      inboundConversationsObject.tableHeader = tableHeader;
      inboundConversationsObject.info = languageMap.infoReportstopPagesForNewInboundConversations;

      setInboundConversations(inboundConversationsObject);
    };

    const populateGraphContainmentRate = async () => {
      const response = await axios.get('/internal_api/analytics/get_containment_rate', {
        params: {
          projectId: projectId,
          timezone: currentTimezone,
          startTimestamp,
          endTimestamp,
        },
      });

      let auxDataSet = response.data;
      let containmentGraphObject = {};
      let dataSet = [];
      let legend = auxDataSet.chart_legend;
      let info = languageMap.infoReportsConversationsContained;
      let title = auxDataSet.chart_name;
      let containmentRate = `${auxDataSet.containment_rate}%`;
      let labels = auxDataSet.prettified_days;
      let conversationsList = auxDataSet.conversations;
      let comparisonValue = auxDataSet.comparison_value;
      let comparisonText = auxDataSet.comparison_text;

      let randomeColor;

      const generateColor = (i) => {
        if (i === 0) {
          randomeColor = '#1a90ff';
        } else if (i === 1) {
          randomeColor = '#bfc1c6';
        } else {
          // eslint-disable-next-line no-bitwise
          randomeColor = '#' + (((1 << 24) * Math.random()) | 0).toString(16).padStart(6, '0');
        }
        return randomeColor;
      };

      for (let i = 0; i < auxDataSet.data.length; i++) {
        let temporaryAuxDataArray = [...Object.values(auxDataSet.data[i])];
        let temporaryDataArray = [];
        for (let j = 0; j < temporaryAuxDataArray[0].length; j++) {
          temporaryDataArray = [
            ...temporaryDataArray,
            ...Object.values(temporaryAuxDataArray[0][j]),
          ];
        }
        generateColor(i);
        const entry = {
          label: legend[i],
          data: temporaryDataArray,
          borderColor: randomeColor,
          fill: false,
          backgroundColor: randomeColor,
        };
        dataSet.push(entry);
      }
      containmentGraphObject.labels = labels;
      containmentGraphObject.data = dataSet;
      containmentGraphObject.title = title;
      containmentGraphObject.conversationsList = conversationsList;
      containmentGraphObject.comparisonValue = comparisonValue;
      containmentGraphObject.comparisonText = comparisonText;
      containmentGraphObject.info = info;
      containmentGraphObject.legend = legend;
      containmentGraphObject.containmentRate = containmentRate;
      containmentGraphObject.unit = '%';
      setContainmentGraph(containmentGraphObject);
    };

    const populateHeatMapOverview = async () => {
      const response = await axios.get('/internal_api/analytics/get_conversations_heatmap', {
        params: {
          projectId: projectId,
          timezone: currentTimezone,
          startTimestamp,
          endTimestamp,
          metric: 'started_conversations',
        },
      });

      let auxDataSet = response.data;
      let heatMapDataObject = {};
      heatMapDataObject.title = auxDataSet.chart_name;
      heatMapDataObject.oxLabels = auxDataSet.ox_labels;
      heatMapDataObject.oyLabels = auxDataSet.oy_labels;
      heatMapDataObject.data = auxDataSet.data;
      heatMapDataObject.info = languageMap.infoReportsConversationsStarted;

      setHeatMapData(heatMapDataObject);
    };

    populateGraphConversations();

    populateGetUniqueUsers();
    populateGetCustomerSatisfactionScore();
    populateGetBusinessHourForStartingConversations();
    populateGetRepliseSent();
    populateGetConversationsByBreakdownChannel();
    populateGetConversationsByBreakdownLanguage();

    populateInboundConversations();
    populateGraphContainmentRate();
    populateHeatMapOverview();
  }, [selectedTimeInterval, startTimestamp, endTimestamp, projectId]);

  // const labelTranslation = (label) => {
  //   let camalizedLabel = label
  //     .toLowerCase()
  //     .replace(/[^a-zA-Z0-9%]+(.)/g, (m, chr) => chr.toUpperCase());

  let listOfSmallWidgets = [uniqueUsers, repliesSent, businessHourConversation];

  let listOfLargeWidgets = [
    conversationsByBreakdownChannel,
    conversationsByBreakdownLanguage,
    customerSatisfactionScore,
  ];

  const displaySmallWidget = () => {
    return listOfSmallWidgets
      .filter((widget) => widget && widget.title)
      .map(({ title, content, info, change, comparisonText }) => (
        <SmallStatsWidget
          key={uuidv4()}
          title={title}
          content={content}
          info={info}
          change={change}
          comparisonText={comparisonText}
        />
      ));
  };

  const displayLargeWidget = () => {
    return listOfLargeWidgets
      .filter((widget) => widget && widget.title)
      .map(({ title, content, list, info, percentage, surveyText, comparisonText }) => (
        <LargeStatsWidget
          key={uuidv4()}
          title={title}
          content={content}
          list={list}
          info={info}
          percentage={percentage}
          surveyText={surveyText}
          comparisonText={comparisonText}
        />
      ));
  };

  return (
    <div>
      <div className="graph-container">
        <div className="row-container" style={{ background: 'white' }}>
          <div className="graphs-row">
            <Graph
              data={conversationsGraph?.data}
              labels={conversationsGraph?.labels}
              title={conversationsGraph?.title}
              value={conversationsGraph?.conversationsList}
              comparisonValue={conversationsGraph?.comparisonValue}
              comparisonText={conversationsGraph?.comparisonText}
              info={conversationsGraph?.info}
              type="bar"
            />
          </div>
        </div>
      </div>
      <div className="statistics-widgets ">{listOfSmallWidgets && displaySmallWidget()}</div>
      <div className="statistics-widgets ">{listOfLargeWidgets && displayLargeWidget()}</div>
      <div
        className="graph-container"
        style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}
      >
        <div className="row-container" style={{ background: 'white' }}>
          <div className="graphs-row">
            <ListWidget
              title={inboundConversations?.title}
              listOfPages={inboundConversations?.data}
              tableHeader={inboundConversations?.tableHeader}
            />
          </div>
        </div>
      </div>
      <div className="graph-container">
        <div className="row-container" style={{ background: 'white' }}>
          <div className="graphs-row">
            <Graph
              data={containmentGraph?.data}
              labels={containmentGraph?.labels}
              title={containmentGraph?.title}
              legend={containmentGraph?.legend}
              info={containmentGraph?.info}
              comparisonText={containmentGraph?.comparisonText}
              comparisonValue={containmentGraph?.comparisonValue}
              value={containmentGraph?.containmentRate}
              type="line"
              unit={containmentGraph?.unit}
            />
          </div>
        </div>
      </div>
      <div className="graph-container" style={{ pageBreakAfter: 'always' }}>
        <div className="row-container" style={{ background: 'white' }}>
          <div className="graphs-row">
            <HeatMapWidget
              forPrint
              title={heatMapData?.title}
              info={heatMapData?.info}
              data={heatMapData?.data}
              oxLabels={heatMapData?.oxLabels}
              oyLabels={heatMapData?.oyLabels}
              displayInDays={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  conversationsGraph: getConversationsGraph(state),
  uniqueUsers: getUniqueUsers(state),
  repliesSent: getRepliesSent(state),
  businessHourConversation: getBusinessHourConversation(state),
  conversationsByBreakdownChannel: getConversationsByBreakdownChannel(state),
  conversationsByBreakdownLanguage: getConversationsByBreakdownLanguage(state),
  customerSatisfactionScore: getCustomerSatisfactionScore(state),
  inboundConversations: getInboundConversations(state),
  containmentGraph: getContainmentGraph(state),
  heatMapData: getHeatMapData(state),
});
const mapDispatchToProps = {
  setConversationsGraph: setConversationsGraph,
  setUniqueUsers: setUniqueUsers,
  setRepliesSent: setRepliesSent,
  setBusinessHourConversation: setBusinessHourConversation,
  setConversationsByBreakdownChannel: setConversationsByBreakdownChannel,
  setConversationsByBreakdownLanguage: setConversationsByBreakdownLanguage,
  setCustomerSatisfactionScore: setCustomerSatisfactionScore,
  setInboundConversations: setInboundConversations,
  setContainmentGraph: setContainmentGraph,
  setHeatMapData: setHeatMapData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
