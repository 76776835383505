import React from 'react';

const DetailsCloseButton = (props) => (
  <svg width="26" height="26" viewBox="0 0 28 28" fill="none" {...props}>
    <rect x="0.5" y="0.5" width="25" height="25" rx="8.5" stroke="#D9D9D9" />
    <rect
      x="9.12131"
      y="10.5355"
      width="2"
      height="8"
      transform="rotate(-45 9.12131 10.5355)"
      fill="#56595F"
    />
    <rect
      x="10.5355"
      y="16.1924"
      width="2"
      height="8"
      transform="rotate(-135 10.5355 16.1924)"
      fill="#56595F"
    />
  </svg>
);

export default DetailsCloseButton;
