import React, { useState } from 'react';

import InfoCard from 'components/InfoCard';
import CollapsibleInfoCard from 'components/CollapsibleInfoCard';
import MetaCard from 'pages/CRM/components/ConversationDetails/ExternalUserProfile/components/MetaCardWidget';
import LatestConversationsCard from './components/LatestConversationsCardWidget';
import NotesCard from './components/NotesCardWidget';
import MediaAndFilesWidget from '../MediaAndFilesWidget';
import Collapse from '@mui/material/Collapse';
import languageMap from 'Language/Language';

import { getShareLink } from 'utils/crm';

const ExternalUserProfile = (props) => {
  const {
    conversation: { user_id, email, channel, language_name, conversation_id },
    firstSeen,
    inboxCustomData,
  } = props;

  const handleShareLink = () => {
    return getShareLink(user_id, conversation_id);
  };
  const [mediaAndFilesOpen, setMediaAndFilesOpen] = useState(true);

  return (
    <>
      <InfoCard cardName="MetaCard" title={languageMap.userData}>
        <MetaCard
          channel={channel}
          languageName={language_name}
          email={email}
          userId={user_id}
          firstSeen={firstSeen}
          shareLink={handleShareLink}
          inboxCustomData={inboxCustomData}
          conversationId={conversation_id}
        />
      </InfoCard>
      <InfoCard cardName="LatestConversationsCard" title={languageMap.latestConversations}>
        <LatestConversationsCard />
      </InfoCard>
      <InfoCard cardName="NotesCard" title={languageMap.conversationNotes}>
        <NotesCard />
      </InfoCard>
      <CollapsibleInfoCard
        cardName="MediaAndFilesWidget"
        title={languageMap.mediaFiles}
        collapsible
        open={mediaAndFilesOpen}
        onSetOpen={setMediaAndFilesOpen}
      >
        <Collapse in={mediaAndFilesOpen} timeout="auto" unmountOnExit>
          <MediaAndFilesWidget />
        </Collapse>
      </CollapsibleInfoCard>
    </>
  );
};

export default ExternalUserProfile;
