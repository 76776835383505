import React from 'react';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import './Button.css';
import { grey } from '@mui/material/colors';

const Button = ({ type, onClick }) => {
  return (
    <div className="CarouselButton" onClick={onClick}>
      {type === 'left' && <ChevronLeftRoundedIcon fontSize="small" style={{ fill: grey[700] }} />}
      {type === 'right' && <ChevronRightRoundedIcon fontSize="small" style={{ fill: grey[700] }} />}
    </div>
  );
};

export default { ...React.memo(Button) };
