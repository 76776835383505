import React, { useState } from 'react';
import Axios from 'axios';
import { Dropdown } from 'semantic-ui-react';
import Loader from 'components/common/Loader';
import './Auth.scss';
import { useNavigate } from 'react-router-dom';

const ConfirmInvitationForm = (props) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [language, setLanguage] = useState('en');
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onChangeLanguage = (e, { value }) => {
    setLanguage(value);
  };

  const handleConfirm = () => {
    setErrors({});

    if (!isValidName(firstName)) {
      alert('First Name is required');
      return;
    }
    if (!isValidName(lastName)) {
      alert('Last Name is required');
      return;
    }
    if (!isValidName(displayName)) {
      alert('Display Name is required');
      return;
    }
    if (!isValidPassword(password)) {
      alert('Invalid password');
      return;
    }
    if (password != confirmedPassword) {
      alert('Passwords do not match');
      return;
    }

    setLoading(true);

    Axios.post('/internal_api/confirm_user_and_update_data', {
      first_name: firstName,
      name: lastName,
      display_name: displayName,
      language: language,
      password: password,
      password_confirmation: confirmedPassword,
      confirmation_code: props.confirmation_code,
    })
      .then(() => navigate('/login', { replace: true }))
      .catch((error) => {
        console.log('eroare: ' + error);
        setErrors(error.response?.data?.errors || {});
      })
      .finally(() => setLoading(false));
  };

  const isValidName = (name) => {
    if (name.length > 0) return true;
    return false;
  };

  const isValidPassword = (password) => {
    if (password.length < 8) {
      return false;
    }
    return true;
  };

  let errorsObject = null;
  if (Object.keys(errors).length > 0) {
    errorsObject = (
      <ul style={{ color: 'red' }}>
        {Object.keys(errors).map((key) => (
          <li>{errors[key]}</li>
        ))}
      </ul>
    );
  }

  return (
    <>
      <div className="FormRow">
        <div className="RegisterFormLabel dm-sans-500">
          <span className="FormLabel dm-sans-500">First name</span>
          {!isValidName(firstName) && (
            <span className="FormLabel dm-sans-500" style={{ color: '#0530F9' }}>
              * (required)
            </span>
          )}
        </div>
        <input
          name="first_name"
          className="Input RegisterInput dm-sans-400"
          placeholder="First name"
          type="text"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      <div className="FormRow">
        <div className="RegisterFormLabel dm-sans-500">
          <span className="FormLabel dm-sans-500">Last name</span>
          {!isValidName(lastName) && (
            <span className="FormLabel dm-sans-500" style={{ color: '#0530F9' }}>
              * (required)
            </span>
          )}
        </div>
        <input
          name="last_name"
          className="Input RegisterInput dm-sans-400"
          placeholder="Last name"
          type="text"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>
      <div className="FormRow">
        <div className="RegisterFormLabel dm-sans-500">
          <span>Display name</span>
          {!isValidName(displayName) && <span className="FormLabelRequired">* (required)</span>}
        </div>
        <input
          name="display_name"
          className="Input RegisterInput dm-sans-400"
          placeholder="Display name"
          type="text"
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
        />
      </div>
      <div className="FormRow">
        <span className="FormLabel dm-sans-500">Language</span>
        <Dropdown
          className="Dropdown"
          selection
          value={language}
          options={[
            {
              key: 'en',
              text: 'English',
              value: 'en',
            },
            {
              key: 'ro',
              text: 'Romanian',
              value: 'ro',
            },
          ]}
          onChange={onChangeLanguage}
        />
      </div>
      <div className="FormRow">
        <div className="RegisterFormLabel dm-sans-500">
          <span className="FormLabel dm-sans-500">New password</span>
          {!isValidPassword(password) && (
            <span className="FormLabel dm-sans-500" style={{ color: '#0530F9' }}>
              * (required)
            </span>
          )}
        </div>
        <input
          name="password"
          autoComplete="current-password"
          className="Input RegisterInput dm-sans-400"
          placeholder="Your password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className="FormRow">
        <div className="RegisterFormLabel dm-sans-500">
          <span className="FormLabel dm-sans-500">Confirm Password</span>
          {password != confirmedPassword && (
            <span className="FormLabel dm-sans-500" style={{ color: '#0530F9' }}>
              * (invalid)
            </span>
          )}
        </div>
        <input
          name="confirm_password"
          autoComplete="current-password"
          className="Input RegisterInput dm-sans-400"
          placeholder="Your password"
          type="password"
          value={confirmedPassword}
          onChange={(e) => setConfirmedPassword(e.target.value)}
        />
      </div>

      {errorsObject}

      {loading && <Loader style={{ width: '100%', height: '100%', marginTop: 25 }} />}
      {!loading && (
        <button className="Submit dm-sans-700" onClick={handleConfirm}>
          Confirm
        </button>
      )}
    </>
  );
};

export default ConfirmInvitationForm;
