import React, { Component } from 'react';
import Checkbox from 'components/common/LabeledCheckbox/Checkbox';

export default class Integration extends Component {
  handleValueChange = (event) => {
    this.props.onChange(this.props.integrationTypeId, event.target.checked);
  };

  handleIntegrationClick = () => {
    this.props.onClick(this.props.integrationTypeId);
  };

  render() {
    const containerStyle = {
      display: 'flex',
      flexDirection: 'row',
      padding: '10px',
      alignItems: 'center',
      justifyContent: 'space-between',
    };

    const titleStyle = {
      width: '46px',
      height: '16px',
      fontSize: '14px',
      fontWeight: 'bold',
      color: '#56595f',
    };

    if (this.props.configurable) {
      titleStyle.cursor = 'pointer';
    }

    return (
      <div style={containerStyle}>
        <div style={titleStyle} onClick={this.handleIntegrationClick}>
          {this.props.title}
        </div>
        <Checkbox checked={this.props.active} onChange={this.handleValueChange} />
      </div>
    );
  }
}
