import React from 'react';
import PaginationC from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { styled } from '@mui/material/styles';

const StyledPaginationContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
}));

const StyledPaginationItem = styled(PaginationItem, {
  shouldForwardProp: (prop) => prop !== 'itemSelected' && prop !== 'prev' && prop !== 'next',
})(({ theme, itemSelected, prev, next }) => ({
  backgroundColor: theme.palette.primary.main + ' !important',
  border: `solid 1px ${theme.palette.primary.main} !important`,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    color: 'black',
    backgroundColor: 'white !important',
  },
  '&.Mui-disabled': {
    opacity: '0.7 !important',
    color: 'black',
    backgroundColor: 'white !important',
    border: 'none !important',
  },
  ...(itemSelected && {
    color: 'black',
    border: 'solid 1px black !important',
    backgroundColor: 'white !important',
  }),
  ...(prev && {
    '&:after': {
      content: '""',
    },
  }),
  ...(next && {
    '&:before': {
      content: '""',
    },
  }),
}));

class Pagination extends React.PureComponent {
  render() {
    const { skip, pageCount, total } = this.props;
    const numberOfPages = Math.ceil(total / pageCount) || 1;
    const currentPage = Math.ceil(skip / pageCount) + 1;
    return (
      <StyledPaginationContainer>
        <PaginationC
          page={currentPage}
          count={numberOfPages}
          variant="outlined"
          shape="rounded"
          onChange={this.onPageChange}
          renderItem={(item) => this.renderItem(item)}
        />
      </StyledPaginationContainer>
    );
  }

  onPageChange = (event, page) => {
    const { pageCount, setPage } = this.props;
    setPage((Math.ceil(page) - 1) * pageCount);
  };

  renderItem = (params) => {
    return (
      <StyledPaginationItem
        {...params}
        itemSelected={params.type === 'page' && params.selected}
        prev={params.type === 'previous'}
        next={params.type === 'next'}
        size="large"
      />
    );
  };
}

export default Pagination;
