import React from 'react';
import { connect } from 'react-redux';
import Linkify from 'react-linkify';

import { selectCurrentConversation } from 'store/crm/selectors';

import './SimpleIncomingMessage.scss';
import { MAIN_COLOR } from '../../../../../../../../mainTheme';

const SimpleIncomingMessage = ({ message, senderID }) => {
  const { id, content, alternativeText } = message;
  return (
    <div key={id} className="SimpleIncomingMessage regular-text">
      <Linkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
          <a
            target="blank"
            href={decoratedHref}
            key={key}
            rel="noopener noreferrer"
            style={{ color: MAIN_COLOR, whiteSpace: 'pre-wrap' }}
            className="regular-text"
          >
            {decoratedText}
          </a>
        )}
      >
        {content || alternativeText}
      </Linkify>
    </div>
  );
};

const mapStateToProps = (state) => ({
  conversation: selectCurrentConversation(state),
});

export default connect(mapStateToProps, null)(SimpleIncomingMessage);
