import React, { Component } from 'react';

import MessageResponseOption from '../MessageResponseOption';
import { AddButton } from '../../../../../components/common/Button';
import languageMap from 'Language/Language';

import './MessageOptions.scss';
import HelperTooltip from '../../../../../components/HelperTooltip/HelperTooltip';
import { uuidKey } from '../MessageBlocks/blocks-config';
import { v4 } from 'uuid';
import omit from 'lodash/omit';

const grid_style = {
  marginTop: 10,
};

const titleStyle = {
  fontSize: '18px',
  fontWeight: 'bold',
  color: 'black',
  margin: 0,
};

const titleContainer = {
  display: 'flex',
  alignItems: 'center',
};

const descriptionStyle = {
  marginLeft: 8,
};

const addButtonStyle = {
  marginTop: 15,
};

const injectUuidIntoBlocks = (blocks) => blocks.map((b) => ({ ...b, [uuidKey]: v4() }));

const removeUuidFromBlocks = (blocks) => blocks.map((b) => omit(b, uuidKey));

export default class MessageOptions extends Component {
  state = {
    options: injectUuidIntoBlocks(this.props.options || []),
  };

  add = () => {
    const { onChange } = this.props;
    let newValue = [...this.state.options];
    newValue.splice(0, 0, {
      templateName: 'SimpleTextTemplate',
      text: '',
      [uuidKey]: v4(),
    });
    this.setState({ options: newValue });
    onChange(removeUuidFromBlocks(newValue));
  };

  onRemoved = (index) => () => {
    const { onChange } = this.props;
    let newValue = [...this.state.options];
    newValue.splice(index, 1);
    this.setState({ options: newValue });
    onChange(removeUuidFromBlocks(newValue));
  };

  handleValueChange = (index, value) => {
    const { onChange } = this.props;
    const { options } = this.state;
    let newValue = [...options];
    newValue[index] = { ...options[index], text: value };

    this.setState({ options: newValue });
    onChange(removeUuidFromBlocks(newValue));
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selectedLanguage !== this.props.selectedLanguage) {
      this.setState({ options: injectUuidIntoBlocks(this.props.options || []) });
    }
  }

  render() {
    const { options } = this.state;
    const { title, description } = this.props;
    return (
      <div>
        <div style={titleContainer}>
          <p style={titleStyle}>{title}</p>
          <HelperTooltip style={descriptionStyle} title={description} />
        </div>

        <AddButton
          style={addButtonStyle}
          onClick={this.add}
          text={languageMap.addNewMessage}
          icon="add"
        />
        <div style={grid_style}>
          {options?.map((option, index) => (
            <MessageResponseOption
              key={option[uuidKey]}
              index={index}
              value={option}
              onTextChange={this.handleValueChange}
              onRemoved={this.onRemoved(index)}
            />
          ))}
        </div>
      </div>
    );
  }
}
