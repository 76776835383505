import React from 'react';
import { NOTIFICATION_TYPES, NotificationsContext } from 'containers/Notifications';

function withNotifications(WrappedComponent, selectData) {
  return class extends React.Component {
    render() {
      return (
        <NotificationsContext.Consumer>
          {(showNotification) => {
            const showSuccess = (message, title) => {
              showNotification(NOTIFICATION_TYPES.SUCCESS, message, title);
            };

            const showError = (message, title) => {
              showNotification(NOTIFICATION_TYPES.ERROR, message, title);
            };

            const wrapperProps = { showSuccess, showError };

            return <WrappedComponent {...wrapperProps} {...this.props} />;
          }}
        </NotificationsContext.Consumer>
      );
    }
  };
}

export default withNotifications;
