/**
 * https://stackoverflow.com/a/49071358
 */
const dispatchMouseEvent = (element, type) => {
  let event;
  if (typeof Event === 'function') {
    event = new MouseEvent(type);
  } else {
    event = document.createEvent('MouseEvent');
    event.initEvent(type, true, true);
  }
  element.dispatchEvent(event);
};

export default dispatchMouseEvent;
