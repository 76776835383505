import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import Table from 'components/Table';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InviteUserOrDepartment from './InviteUserOrDepartment';
import { setUserName } from './ComponentsCommonFunctions.js';

import { setSubMenu } from 'store/crm/actions';

import './DepartmentManagement.scss';
import './ComponentsCommonStyle.scss';
import languageMap from 'Language/Language';

const DepartmentManagement = (props) => {
  const { setSubMenu, managedDepartment } = props;
  let { id: projectId } = useParams();
  projectId = Number(projectId);
  const [inviteUserPopup, setInviteUserPopup] = useState(false);
  const [listOfUsers, setListOfUsers] = useState({});
  const [editDepartmentName, setEditDepartmentName] = useState(false);
  const [departmentName, setDepartmentName] = useState(managedDepartment?.department_name);

  useEffect(() => {
    getDepartmnetUsers();
  }, [listOfUsers?.data?.length]);

  const getDepartmnetUsers = async () => {
    const response = await axios.get('/internal_api/department/get_department_users', {
      params: {
        project_id: projectId,
        department_id: managedDepartment?.department_id,
      },
    });
    let auxDataSet = response?.data;
    let listOfUsersObject = {};
    listOfUsersObject.columns = [languageMap.user, true];
    listOfUsersObject.data = [];
    listOfUsersObject.dataResponse = auxDataSet;
    listOfUsersObject.ids = [];
    let temporaryObject = {};

    for (let i = 0; i < auxDataSet?.length; i++) {
      if (auxDataSet[i].display_name != 'Assistant' && auxDataSet[i].display_name != 'Unassigned') {
        temporaryObject.User = setUserName(auxDataSet, i);

        listOfUsersObject.data.push([
          temporaryObject.User,
          (temporaryObject.Actions = deleteButton(auxDataSet[i].id)),
        ]);
        listOfUsersObject.ids.push(auxDataSet[i]?.id);
      }
    }
    setListOfUsers(listOfUsersObject);
  };

  const deleteDepartmnet = () => {
    axios
      .delete('/internal_api/department/delete_department', {
        data: {
          project_id: projectId,
          department_id: managedDepartment?.department_id,
        },
      })
      .catch((err) => {
        console.log(err);
      });

    setSubMenu('departments');
  };

  const deleteButton = (userId) => {
    let listOfDepartmentsIds = [];

    const getUserDepartments = async () => {
      await axios
        .get('/internal_api/department/get_user_departments', {
          params: {
            project_id: projectId,
            user_id: userId,
          },
        })
        .then((response) => {
          let auxDataSet = response.data;
          for (let i = 0; i < auxDataSet.length; i++) {
            if (auxDataSet[i].department_id !== managedDepartment?.department_id) {
              listOfDepartmentsIds.push(Number(auxDataSet[i].department_id));
            }
          }
          updateListOfDepartments();
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const updateListOfDepartments = async () => {
      await axios
        .post('/internal_api/department/update_user_departments', {
          project_id: projectId,
          user_id: userId,
          department_ids: listOfDepartmentsIds,
        })
        .then(() => {
          getDepartmnetUsers();
        })
        .catch((e) => {
          console.log(e);
        });
    };

    const deleteUser = () => {
      getUserDepartments();
    };

    return (
      <div className="button-as-link" onClick={deleteUser}>
        {languageMap.delete}
      </div>
    );
  };

  const setUsersSubMenu = () => {
    setSubMenu('departments');
  };

  return (
    <div className="DepartmentManagement">
      {inviteUserPopup && (
        <InviteUserOrDepartment
          titleText={languageMap.addUserToDepartment}
          addButtonText={languageMap.addUser}
          cancelButtonText={languageMap.cancel}
          userOrDepartment="addUserToDepartment"
          setPopup={setInviteUserPopup}
          departmentId={managedDepartment?.department_id}
          update={getDepartmnetUsers}
        />
      )}
      {editDepartmentName && (
        <InviteUserOrDepartment
          titleText={languageMap.typeNewDepartmentName}
          addButtonText={languageMap.confirm}
          cancelButtonText={languageMap.cancel}
          userOrDepartment="editName"
          setPopup={setEditDepartmentName}
          departmentId={managedDepartment?.department_id}
          returnValue={setDepartmentName}
        />
      )}

      <div className="manage-department-title-wrapper">
        <Button className="back-button blue-button-style" onClick={setUsersSubMenu}>
          <ArrowBackIcon> </ArrowBackIcon>
        </Button>
        <div className="header dm-sans-500 title-text">{departmentName}</div>
      </div>

      <div className="departement-management-buttons">
        <Button className="blue-button-style" onClick={() => setEditDepartmentName(true)}>
          {languageMap.editName}
        </Button>
        <Button className="red-button-style" onClick={deleteDepartmnet}>
          {languageMap.deleteDepartment}
        </Button>
      </div>
      <div className="agent-management">
        <div className="agent-management-header dm-sans-500 title-text">{languageMap.users}</div>
        <div className="add-agent-button">
          <Button className="blue-button-style" onClick={() => setInviteUserPopup(true)}>
            {languageMap.addUser}
          </Button>
        </div>

        <div className="users-list">
          <Table tableData={listOfUsers} />
        </div>
      </div>
    </div>
  );
};
const mapDispatchToProps = {
  setSubMenu: setSubMenu,
};
export default connect(null, mapDispatchToProps)(DepartmentManagement);
