/* eslint-disable eqeqeq */
import React from 'react';
import { Dropdown } from 'semantic-ui-react';

const DropDown = ({ options, value, onSelect, defaultText }) => {
  const textOptions = options.map(({ value, label }) => ({
    value,
    text: label,
  }));

  const onChange = (e, data) => {
    if (data.value !== value) {
      onSelect(data.value);
    }
  };
  return (
    <Dropdown
      selection
      placeholder={defaultText}
      value={value}
      onChange={onChange}
      options={textOptions}
    />
  );
};

export default DropDown;
