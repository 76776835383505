export const getRoundedCanvas = (sourceCanvas) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const width = sourceCanvas.width;
  const height = sourceCanvas.height;

  canvas.width = width;
  canvas.height = height;
  context.imageSmoothingEnabled = true;
  context.drawImage(sourceCanvas, 0, 0, width, height);
  context.globalCompositeOperation = 'destination-in';
  context.beginPath();
  context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
  context.fill();
  return canvas;
};

export const resizeImage = (originalUri) => {
  return new Promise((resolve) => {
    const img = document.createElement('img');

    // Or, reference existing image on page
    // const img = document.querySelector('#my-image');

    img.addEventListener('load', () => {
      // Initialize the canvas and it's size
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Set width and height
      canvas.width = 200;
      canvas.height = 200;

      // Draw image and export to a data-uri
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      // Do something with the result, like overwrite original
      resolve(canvas);
    });

    // Set the image source
    // (can also be a data-uri, extracted from form input)
    img.src = originalUri;
  });
};
