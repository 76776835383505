import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';

import '../CRM/containers/CRMDashboard/components/AnalyticsContainer/AnalyticsContainer.scss';
import { Input } from 'semantic-ui-react';
import debounce from 'lodash/debounce';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import Pagination from '../../components/Pagination/Pagination';
import languageMap from 'Language/Language';

const headers = [
  languageMap.date,
  languageMap.item,
  languageMap.change,
  languageMap.user,
  languageMap.iP,
];
const keys = ['created', 'target_name', 'operation_type', 'user', 'ip_address'];

const containerStyle = {
  overflow: 'auto',
  marginLeft: 15,
  marginRight: 15,
  height: '100%',
};

const table = {
  marginTop: 10,
  width: '100%',
  paddingBottom: 10,
};
const tableCellHeader = {
  paddingLeft: 6,
};

const tableCell = {
  padding: '15px 6px',
};
const PAGE_COUNT = 20;
const History = ({ projectId }) => {
  const [search, setSearch] = useState(undefined);
  const [data, setData] = useState([]);
  const [skip, setSkip] = useState(0);
  const [total, setTotal] = useState();

  const onChangeSearch = useCallback(
    debounce((value) => {
      setSearch(value);
      setSkip(0);
    }, 300),
    []
  );

  const allChanges = useCallback(async () => {
    const response = await Axios.get('/internal_api/changes_history_total', {
      params: {
        project_id: projectId,
        search: search ? search : undefined,
      },
    });
    if (response.data !== total) {
      setTotal(response.data);
    }
  }, [search, projectId, total]);

  const listChanges = useCallback(async () => {
    const response = await Axios.get('/internal_api/changes_history', {
      params: {
        project_id: projectId,
        search: search ? search : undefined,
        skip,
        limit: PAGE_COUNT,
      },
    });
    const entities = response.data;
    setData(entities);
  }, [search, projectId, skip]);

  useEffect(() => {
    listChanges();
  }, [listChanges]);

  useEffect(() => {
    allChanges();
  }, [allChanges]);

  return (
    <div style={containerStyle}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: 'auto',
          maxWidth: 1024,
          height: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            marginTop: 40,
            paddingBottom: 20,
            justifyContent: 'center',
          }}
        >
          <Input
            placeholder={languageMap.search}
            icon="search"
            style={{ height: '38px', width: '300px' }}
            onChange={(e) => onChangeSearch(e.target.value)}
          />
        </div>
        <div style={{ height: 'calc(100% - 146px)', overflow: 'auto' }}>
          <Table style={table}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableCell key={header} style={tableCellHeader}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((info) => (
                <TableRow className="tableRow" key={info.log_id}>
                  {keys.map((key) => (
                    <TableCell padding="none" style={tableCell} key={key}>
                      <div>{info[key]}</div>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <Pagination total={total} skip={skip} setPage={setSkip} pageCount={PAGE_COUNT} />
      </div>
    </div>
  );
};

export default connect(null)(History);
