import React from 'react';

const AttachFile = (props) => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.9768 7.18738C14.1229 7.0083 14.1045 6.75182 13.9337 6.59406C13.763 6.4363 13.4957 6.42863 13.3154 6.57616L5.04814 13.3367L0.682475 11.7139C0.427849 11.6201 0.22047 11.4361 0.103372 11.1995C-0.0139584 10.963 -0.0321452 10.6921 0.0525807 10.4431C0.137308 10.1941 0.318517 9.98598 0.558505 9.8617L18.6828 0.102631C18.8735 0.00392111 19.0953 -0.0248578 19.3064 0.0218281C19.5178 0.0683045 19.7041 0.187265 19.831 0.356118C19.9579 0.525183 20.0164 0.732617 19.996 0.940046L18.4062 16.8697C18.3738 17.201 18.1808 17.4982 17.8854 17.6715C17.5897 17.8448 17.2266 17.874 16.9052 17.75L11.5921 15.7726L8.25647 18.7769C8.09146 18.9251 7.87254 19.0053 7.64676 18.9997C7.42075 18.9944 7.20627 18.9038 7.04927 18.7479C6.89201 18.5919 6.80507 18.3827 6.80662 18.1657V15.8063L13.9768 7.18738Z"
      fill="white"
    />
  </svg>
);

export default AttachFile;
