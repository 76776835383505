import React, { Component } from 'react';
import MessageRenderer from '../MessageRenderer';

import './OutgoingMessage.scss';

class OutgoingMessage extends Component {
  render() {
    const { message, mouseHoverProps, children, ...props } = this.props;
    const { content: messageContent, meta } = message;
    const { message_block_type } = meta;

    return messageContent ? (
      <div className={`OutgoingMessage ${message_block_type} regular-text`} {...mouseHoverProps}>
        {children}

        <MessageRenderer message={message} {...props} />
      </div>
    ) : null;
  }
}

export default OutgoingMessage;
