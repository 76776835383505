import React from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import DocumentRow from './DocumentRow';
import languageMap from 'Language/Language';

const table = {
  marginTop: 10,
  width: '100%',
  paddingBottom: 10,
  overflowY: 'scroll',
};

const tableCellHeader = {
  paddingLeft: 6,
};

function formatBytes(bytes) {
  if (bytes === undefined) {
    return '';
  }

  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];

  if (bytes === 0) return '0 B';

  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  const formattedValue = Math.round(bytes / Math.pow(1024, i));

  return `${formattedValue} ${sizes[i]}`;
}

export const DocumentTable = ({ data, languages, onDelete, onDownload }) => {
  const language_mapping = {};
  languages.forEach((item) => {
    language_mapping[item.value] = item.label;
  });
  return (
    <div style={{ display: 'flex', flexDirection: 'column', maxHeight: '80%', overflow: 'auto' }}>
      <Table style={table}>
        <TableHead>
          <TableRow>
            <TableCell style={tableCellHeader}>{languageMap.fileName}</TableCell>
            <TableCell style={tableCellHeader}>{languageMap.fileLanguage}</TableCell>
            <TableCell style={tableCellHeader}>{languageMap.fileSize}</TableCell>
            <TableCell style={tableCellHeader}>{languageMap.fileUploaded}</TableCell>
            <TableCell style={tableCellHeader}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(({ id, name, language, size, created_at }) => (
            <DocumentRow
              id={id}
              key={id}
              name={name}
              language={language_mapping[language]}
              size={formatBytes(size)}
              created_at={created_at}
              onDelete={onDelete}
              onDownload={onDownload}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
