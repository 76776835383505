import React, { Component } from 'react';
import Axios from 'axios';
import _ from 'lodash';
import trim from 'lodash/trim';
import styles from 'components/common/Dialog.module.css';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { produce } from 'immer';
import { Dropdown } from 'semantic-ui-react';

import Dialog from '../../../../../Dialog';
import { objectThemes, ThemeContext } from '../../../../../../utils/Theme';
import Loader from 'components/common/Loader';
import { AddButton } from '../../../../../../../../components/common/Button';
import LabeledCheckbox from 'components/common/LabeledCheckbox';
import LabeledInput from 'components/common/input/LabeledInput';
import DeleteButton from '../components/DeleteButton';
import NameInput from '../../../../../NameInput';
import HelperTooltip from '../../../../../../../../components/HelperTooltip/HelperTooltip';
import languageMap from 'Language/Language';

const MATCHING_TYPES = [
  'match_type',
  'match_sensitivity',
  'regex',
  'match_common_prefix',
  'fuzzy_matching',
];
export default class EntityTypeDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedLanguage: '',
      settings: {},
      values: {},
      loading: true,
      newValue: null,
      newValueWarn: false,
      newSynonymParent: null,
      newSynonym: null,
      newSynonymWarn: false,
    };

    this.debouncedSync = _.debounce(this.syncSettings, 500);
    this.theme = objectThemes.entity_type;
  }

  processValues = (rows) => {
    let values = {};
    let synonyms = {};

    rows.forEach((row) => {
      let valueId = row.entity_type_value_id;
      let synonym_id = row.entity_type_synonym_id;

      if (!(valueId in values)) {
        values[valueId] = {
          serverValue: row.value,
          value: row.value,
          synonyms: [],
          warn: false,
          language: row.language,
        };
      }

      if (synonym_id) {
        values[valueId].synonyms.push(synonym_id);
        synonyms[synonym_id] = {
          valueId,
          serverValue: row.synonym,
          value: row.synonym,
          warn: false,
        };
      }
    });

    return [values, synonyms];
  };

  componentDidMount() {
    Axios.get('/internal_api/entity_type', {
      params: { id: this.props.id },
    }).then((response) =>
      this.setState(
        produce((draft) => {
          draft.settings = response.data.settings;
          draft.settings.match_sensitivity = String(draft.settings.match_sensitivity);
          [draft.values, draft.synonyms] = this.processValues(response.data.values);
          draft.loading = false;
          draft.selectedLanguage = this.props.selectedLanguage;
        })
      )
    );
  }

  onChangeLanguage = (e, { value }) => {
    this.setState({ selectedLanguage: value });
  };

  syncSettings = () => {
    return Axios.put('/internal_api/entity_type', { settings: this.state.settings });
  };

  nameChanged = (nodeId, newName) => {
    this.setState(
      produce((draft) => {
        draft.settings.entity_type_name = newName;
      }),
      async () => {
        await this.syncSettings();
        this.props.onNameChange(newName);
      }
    );
  };

  handleSettingsChange = (e) => {
    const target = e.target.name;
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    if (MATCHING_TYPES.includes(target)) {
      this.setState(
        produce((draft) => {
          draft.settings[target] = value;
        }),
        this.debouncedSync
      );
    }
  };

  handleAddNewValue = () => {
    this.setState(
      produce((draft) => {
        if (draft.newValue === null) draft.newValue = ' ';
      })
    );
  };

  handleCreateNewValue = () => {
    if (!trim(this.state.newValue)) {
      return;
    }

    Axios.post('/internal_api/entity_type/value', {
      project_id: this.props.projectId,
      entityTypeId: this.props.id,
      value: this.state.newValue,
      language: this.state.selectedLanguage,
    }).then((response) => {
      if (response.data.status === 'error') {
        alert('Could not create value. There probably already exists a value with that name');
        this.setState({
          newValueWarn: true,
        });
      } else {
        this.setState(
          produce((draft) => {
            draft.values[response.data.id] = {
              serverValue: draft.newValue,
              value: draft.newValue,
              language: this.state.selectedLanguage,
              synonyms: [],
            };
            draft.newValue = null;
          })
        );
      }
    });
  };

  handleCreateNewSynonym = () => {
    if (!trim(this.state.newSynonym)) {
      return;
    }

    Axios.post('/internal_api/entity_type/synonym', {
      project_id: this.props.projectId,
      entityTypeId: this.props.id,
      entityTypeValueId: this.state.newSynonymParent,
      value: this.state.newSynonym,
      language: this.state.selectedLanguage,
    }).then((response) => {
      if (response.data.status === 'error') {
        alert('Could not create synonym. There already exists one with that name');
        this.setState({
          newSynonymWarn: true,
        });
      } else {
        this.setState(
          produce((draft) => {
            draft.values[draft.newSynonymParent].synonyms.push(response.data.id);
            draft.synonyms[response.data.id] = {
              serverValue: draft.newSynonym,
              value: draft.newSynonym,
              valueId: draft.newSynonymParent,
              warn: false,
            };

            draft.newSynonymParent = null;
            draft.newSynonym = null;
          })
        );
      }
    });
  };

  handleValueChange = (id) => (newValue) => {
    this.setState(
      produce((draft) => {
        draft.values[id].value = newValue.toLowerCase();
      })
    );
  };

  handleValueDelete = (id) => () => {
    Axios.delete('/internal_api/entity_type/value', { data: { id } });

    this.setState(
      produce((draft) => {
        draft.values[id].synonyms.forEach((id) => {
          delete draft.synonyms[id];
        });

        delete draft.values[id];
      })
    );
  };

  handleSynonymChange = (id) => (newValue) => {
    this.setState(
      produce((draft) => {
        draft.synonyms[id].value = newValue.toLowerCase();
      })
    );
  };

  handleSynonymDelete = (id) => () => {
    Axios.delete('/internal_api/entity_type/synonym', { data: { id } });

    this.setState(
      produce((draft) => {
        let valueId = draft.synonyms[id].valueId;
        draft.values[valueId].synonyms = _.without(draft.values[valueId].synonyms, id);
        delete draft.synonyms[id];
      })
    );
  };

  handleValueSync = (id, syncType) => () => {
    const value = this.state[syncType][id].value;

    // eslint-disable-next-line eqeqeq
    if (value == this.state[syncType][id].serverValue) return;

    if (!value) {
      alert('Invalid empty value, reverting. If you wish to delete it use the delete button');
      this.setState(
        produce((draft) => {
          draft[syncType][id].value = draft[syncType][id].serverValue;
          draft[syncType][id].warn = true;
        })
      );
      return;
    }

    Axios.put('/internal_api/entity_type/value', {
      id,
      value,
      syncType,
      project_id: this.props.projectId,
    }).then((response) => {
      if (response.data.status === 'ok') {
        this.setState(
          produce((draft) => {
            draft[syncType][id].serverValue = response.data.value;
          })
        );
      } else {
        this.setState(
          produce((draft) => {
            draft[syncType][id].value = response.data.value;
            draft[syncType][id].serverValue = response.data.value;
            draft[syncType][id].warn = true;

            alert('Duplicate values are not allowed, reverted change');
          })
        );
      }
    });
  };

  handleAddNewSynonym = (valueId) => () => {
    if (this.state.newSynonym) {
      this.setState({
        newSynonymParent: valueId,
        newSynonym: this.state.newSynonym,
      });
    } else {
      this.setState({
        newSynonymParent: valueId,
        newSynonym: '',
      });
    }
  };

  handleRemoveNewSynonym = () => {
    this.setState({
      newSynonymParent: null,
      newSynonym: '',
    });
  };

  handleNewSynonymUpdate = (value) => {
    this.setState({ newSynonym: value.toLowerCase() });
  };

  handleRemoveNewSynonymWarning = () => {
    this.setState({ newSynonymWarn: false });
  };

  handleNewValueUpdate = (value) => {
    this.setState({ newValue: value.toLowerCase() });
  };

  handleRemoveNewValue = () => {
    this.setState({ newValue: null });
  };

  handleRemoveNewValueWarning = () => {
    this.setState({ newValueWarn: false });
  };

  renderNewValue = () => {
    if (this.state.newValue === null) return;

    const style = {
      backgroundColor: 'green',
      margin: 10,
      marginLeft: 0,
      height: 26,
    };

    return (
      <LabeledInput
        focused
        warn={this.state.newValueWarn}
        style={style}
        value={this.state.newValue}
        onValueChanged={this.handleNewValueUpdate}
        onBlur={this.handleCreateNewValue}
        onDelete={this.handleRemoveNewValue}
        removeWarning={this.handleRemoveNewValueWarning}
      />
    );
  };

  renderValueRow = ([valueId, row]) => {
    let newSynonym = null;
    const synonyms = this.state.synonyms;
    const rowStyle = {
      display: 'flex',
      flexFlow: 'nowrap',
      alignItems: 'center',
      marginBottom: 10,
    };

    const valueStyle = {
      backgroundColor: objectThemes.entity_type.main,
      margin: 10,
      marginLeft: 0,
      marginRight: 50,
      flex: '0 0 auto',
    };

    const synStyle = {
      margin: 10,
      backgroundColor: '#888',
      flex: '0 0 auto',
    };

    // eslint-disable-next-line eqeqeq
    if (this.state.newSynonymParent == valueId) {
      newSynonym = (
        <LabeledInput
          focused
          warn={this.state.newSynonymWarn}
          style={{ ...synStyle, backgroundColor: 'green' }}
          value={this.state.newSynonym}
          onValueChanged={this.handleNewSynonymUpdate}
          onBlur={this.handleCreateNewSynonym}
          onDelete={this.handleRemoveNewSynonym}
          removeWarning={this.handleRemoveNewSynonymWarning}
        />
      );
    }

    return (
      <div key={valueId} style={rowStyle}>
        <LabeledInput
          style={valueStyle}
          warn={row.warn}
          value={row.value}
          onValueChanged={this.handleValueChange(valueId)}
          onBlur={this.handleValueSync(valueId, 'values')}
          onDelete={this.handleValueDelete(valueId)}
          removeWarning={this.handleRemoveWarning(valueId, 'values')}
        />

        <>
          <AddButton
            style={{ margin: 10, padding: 2, minWidth: 26 }}
            icon="add"
            onClick={this.handleAddNewSynonym(valueId)}
          />
          {newSynonym}
          {row.synonyms
            .slice()
            .sort()
            .reverse()
            .map((synId) => (
              <LabeledInput
                key={synId}
                warn={synonyms[synId].warn}
                style={synStyle}
                value={synonyms[synId].value}
                onValueChanged={this.handleSynonymChange(synId)}
                onBlur={this.handleValueSync(synId, 'synonyms')}
                onDelete={this.handleSynonymDelete(synId)}
                removeWarning={this.handleRemoveWarning(synId, 'synonyms')}
              />
            ))}
        </>
      </div>
    );
  };

  handleRemoveWarning = (id, type) => () => {
    this.setState(
      produce((draft) => {
        draft[type][id].warn = false;
      })
    );
  };

  handleDelete = () => {
    this.props.deleteNode();
    this.props.onClose();
  };

  render() {
    const { settings, loading, selectedLanguage, values } = this.state;
    const { onDelete, languageOptions, onClose } = this.props;
    const contentViewStyle = {
      flex: 1,
      minHeight: 0,
      padding: 30,
      display: 'flex',
      alignItems: 'flex-start',
      flexFlow: 'column nowrap',
      width: '100%',
      height: '100%',
      overflowY: 'scroll',
    };

    const regexStyle = {
      backgroundColor: '#f3f3f3',
      fontSize: 20,
      color: '#333',
      border: 'none',
      padding: '10px 20px',
    };

    const valueContainerStyle = {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'flex-start',
    };

    return (
      <ThemeContext.Provider value={this.theme}>
        <Dialog
          onClose={onClose}
          headerView={
            !loading && (
              <>
                <NameInput
                  autoComplete="nope"
                  type="entity_type"
                  value={settings.entity_type_name}
                  onNameChange={this.nameChanged}
                />
                {languageOptions.length > 1 && (
                  <Dropdown
                    className="languageDropdown"
                    selection
                    value={selectedLanguage}
                    options={languageOptions}
                    onChange={this.onChangeLanguage}
                  />
                )}
                <DeleteButton
                  title={languageMap.deleteEntityType}
                  className={styles.headerButton + ' ' + styles.deleteButton}
                  onClick={onDelete ? this.handleDelete : null}
                />
              </>
            )
          }
          contentView={
            loading ? (
              <Loader />
            ) : (
              <div style={contentViewStyle}>
                <FormControl component="fieldset">
                  <FormLabel>{languageMap.matchType}</FormLabel>
                  <RadioGroup
                    aria-label="match type"
                    name="match_type"
                    value={settings.match_type}
                    onChange={this.handleSettingsChange}
                  >
                    <FormControlLabel
                      value="regex"
                      control={<Radio color="default" />}
                      label={languageMap.matchTypeRegex}
                    />
                    <FormControlLabel
                      value="value_based"
                      control={<Radio color="default" />}
                      label={languageMap.matchTypeValue}
                    />
                  </RadioGroup>
                </FormControl>

                <div style={{ marginBottom: 50 }} />

                {settings.match_type === 'regex' && (
                  <input
                    name="regex"
                    placeholder="e.g. 0 ?[7|3][ ?\d]{8}"
                    style={regexStyle}
                    onChange={this.handleSettingsChange}
                    value={settings.regex}
                  />
                )}

                {settings.match_type === 'value_based' && (
                  <>
                    <FormControl component="fieldset">
                      <FormLabel>{languageMap.matchSensitivity}</FormLabel>
                      <RadioGroup
                        aria-label="match sensitivity"
                        name="match_sensitivity"
                        value={settings.match_sensitivity}
                      >
                        <FormControlLabel
                          style={{ pointerEvents: 'none' }}
                          value="1"
                          control={
                            <Radio
                              color="default"
                              style={{ pointerEvents: 'auto' }}
                              onChange={this.handleSettingsChange}
                            />
                          }
                          label={languageMap.matchPredefined}
                        />
                        <FormControlLabel
                          style={{ pointerEvents: 'none' }}
                          value="2"
                          control={
                            <Radio
                              color="default"
                              style={{ pointerEvents: 'auto' }}
                              onChange={this.handleSettingsChange}
                            />
                          }
                          label={languageMap.matchPredefinedAndVariations}
                        />
                        <FormControlLabel
                          style={{ pointerEvents: 'none' }}
                          value="3"
                          control={
                            <Radio
                              color="default"
                              style={{ pointerEvents: 'auto' }}
                              onChange={this.handleSettingsChange}
                            />
                          }
                          label={languageMap.matchSemantics}
                        />
                        <FormControlLabel
                          style={{ pointerEvents: 'none' }}
                          value="4"
                          control={
                            <Radio
                              color="default"
                              style={{ pointerEvents: 'auto' }}
                              onChange={this.handleSettingsChange}
                            />
                          }
                          label={languageMap.matchEverything}
                        />
                      </RadioGroup>
                    </FormControl>

                    <div style={{ marginBottom: 50 }} />

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <AddButton
                        icon="add"
                        text={languageMap.addNewValue}
                        onClick={this.handleAddNewValue}
                      />

                      <HelperTooltip
                        title={languageMap.helperTextEntityType}
                        style={{ height: 25, width: 25 }}
                      />
                      <LabeledCheckbox
                        label={languageMap.fuzzyMatching}
                        name="fuzzy_matching"
                        checked={Boolean(settings.fuzzy_matching)}
                        onChange={this.handleSettingsChange}
                      />
                      <LabeledCheckbox
                        label={languageMap.matchCommonPrefix}
                        name="match_common_prefix"
                        checked={Boolean(settings.match_common_prefix)}
                        onChange={this.handleSettingsChange}
                      />
                    </div>

                    <div style={{ marginBottom: 25 }} />

                    <div style={valueContainerStyle}>
                      {this.renderNewValue()}
                      {Object.entries(values)
                        .filter(([, value]) => value.language === selectedLanguage)
                        .sort((a, b) => b[0] - a[0])
                        .map((value) => this.renderValueRow(value))}
                    </div>
                  </>
                )}
              </div>
            )
          }
        />
      </ThemeContext.Provider>
    );
  }
}
