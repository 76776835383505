/* eslint-disable */
import React, { Component } from 'react';
import toLowerCase from 'lodash/toLower';
import {produce} from 'immer';
import InputText from '../common/input/InputText';

import styles from './ContextMenu.module.css';

export default class ContextMenu extends Component {
  constructor(props) {
    super(props);

    var heightOfContextMenu = 15 + this.props.options?.length * 30;

    var win = window,
      doc = document,
      docElem = doc.documentElement,
      body = doc.getElementsByTagName('body')[0],
      x = win.innerWidth || docElem.clientWidth || body.clientWidth,
      y = win.innerHeight || docElem.clientHeight || body.clientHeight;

    var newTop = this.props.top;

    if (newTop + heightOfContextMenu > y) {
      newTop = Math.max(0, y - heightOfContextMenu);
    }

    this.state = {
      selection: [],
      lists: null,
      filteredLists: null,
      filterArray: {},
      top: newTop,
    };
  }

  changeSelection = (selection, e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ selection });
  };

  returnValue = (data, e) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.onSelect(data);
  };

  handleOnScroll = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  onWheel(e) {
    e.stopPropagation();
  }

  handleSearchChange = (selection, search) => {
    this.setState(
      produce((draft) => {
        draft.filterArray[selection] = search;
        draft.selection = selection;
        return draft;
      })
    );
  };

  isElementFiltered = (selection, element) => {
    if (this.state.filterArray[selection] === undefined) {
      return true;
    }

    return toLowerCase(element.label).includes(toLowerCase(this.state.filterArray[selection]));
  };

  createList = (options, selection) => {
    let shouldDisplayInput = false;
    let listStyle = {
      color: 'white',
      listStyle: 'none',
      textAlign: 'left',
      margin: 0,
      padding: 0,
      position: 'relative',
      height: '100%',
      width: '100%',
      maxHeight: '315px',
      overflowY: 'auto',
      overflowX: 'hidden',
      borderRadius: '5px',
      backgroundColor: '#C0C0C0',
    };

    let liStyle = {
      height: '30px',
      paddingLeft: '10px',
      paddingRight: '10px',
      minWidth: '150px',
      maxWidth: '400px',
    };

    let inputTextStyle = {
      position: 'relative',
      margin: '0px',
      width: '100%',
      height: '40px',
      borderBottom: '1px solid #C0C0C0',
      borderBottomLeftRadius: '0',
      borderBottomRightRadius: '0',
    };

    const bigDiv = {
      marginLeft: 10,
      boxShadow: '0px 1px 5px 1px gray',
      borderRadius: '5px',
      border: '1px solid #C0C0C0',
    };

    if (options?.length > 5 && this.props.hasSearch) {
      shouldDisplayInput = true;
      listStyle = {
        ...listStyle,
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0',
      };
    }

    if (shouldDisplayInput) {
      return (
        <div key={selection} style={bigDiv}>
          <InputText
            style={inputTextStyle}
            onValueChanged={(name, value) => this.handleSearchChange(selection, value)}
            placeholder="Search... "
          />
          <ul style={listStyle}>
            {options.map((option, index) => {
              if (
                this.isElementFiltered(selection, option) ||
                toLowerCase(option?.label).includes('create new')
              ) {
                let clickHandler = this.returnValue.bind(this, option?.returnValue);
                if (option?.children) {
                  clickHandler = this.changeSelection.bind(this, selection.concat(index));
                }
                return (
                  <li style={liStyle} className={styles.option} key={index} onClick={clickHandler}>
                    {option?.label}
                    {option?.additionalLabel && (
                      <span style={{ color: '#a2a2a2' }}> [{option?.additionalLabel}]</span>
                    )}
                  </li>
                );
              }
            })}
          </ul>
        </div>
      );
    } else {
      return (
        <div key={selection} style={bigDiv}>
          <ul style={listStyle}>
            {options?.map((option, index) => {
              if (
                this.isElementFiltered(selection, option) ||
                toLowerCase(option.label).includes('create new')
              ) {
                let clickHandler = this.returnValue.bind(this, option.returnValue);
                if (option?.children) {
                  clickHandler = this.changeSelection.bind(this, selection.concat(index));
                }
                return (
                  <li style={liStyle} className={styles.option} key={index} onClick={clickHandler}>
                    {option.label}
                    {option.additionalLabel && (
                      <span style={{ color: '#a2a2a2' }}>[{option.additionalLabel}]</span>
                    )}
                  </li>
                );
              }
            })}
          </ul>
        </div>
      );
    }
  };

  render() {
    let bigContainerStyle = {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'flex-start',
      position: 'absolute',
      left: this.props.left,
      top: this.state.top,
      height: 'auto',
    };
    let containerStyle = {
      display: 'flex',
      flexFlow: 'row',
      alignItems: 'flex-start',
      minHeight: '30px',
      maxHeight: '315px',
      height: 'auto',
      width: '100%',
    };

    this.state.lists = [
      // always show first level
      this.createList(this.props?.options, []),
    ];

    let { options } = this.props;

    this.state.selection.forEach((child_index, idx) => {
      // advance in the tree
      options = options[child_index]?.children;

      if (options) {
        options = options.filter((option) => option.label !== this.props.nodeName);
      }

      // required selection to produce this level
      let selection = this.state.selection.slice(0, idx + 1);

      this.state.lists.push(this.createList(options, selection));
    });

    this.state.filteredLists = this.state.lists;

    return (
      <div style={bigContainerStyle} onMouseDown={(e) => e.stopPropagation()}>
        <div
          style={containerStyle}
          onMouseDown={(e) => e.stopPropagation()}
          onScroll={this.handleOnScroll}
          onWheel={this.onWheel.bind(this)}
        >
          {this.state.filteredLists}
        </div>
      </div>
    );
  }
}
