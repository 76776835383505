import React, { Component } from 'react';
import AutoInput from '../../../AutoInput/AutoInput';
export default class LabeledInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
    };

    this.width = this.props.width !== undefined || 150;
  }

  onValueChanged = (event) => {
    if (this.props.onValueChanged) this.props.onValueChanged(event.target.value);
  };

  onDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onDelete();
  };

  toggleFocus = () => {
    if (!this.state.focused && this.props.warn && this.props.removeWarning) {
      this.props.removeWarning();
    }

    if (this.props.disableEdit) {
      if (this.props.onClick) {
        this.props.onClick();
      }
      return;
    }

    if ((this.props.focused || this.state.focused) && this.props.onBlur) {
      this.props.onBlur();
    }

    this.setState((state) => {
      return {
        focused: !state.focused,
      };
    });
  };

  onFocus = (e) => {
    // TODO : Fix move cursor to end of text on focus
    const target = e.target;
    const length = target.value.length;
    var value = target.value;
    target.value = '';
    target.value = value;
    target.selectionStart = length;
    target.selectionEnd = length;
  };

  renderUnfocused = () => {
    const style = {
      color: 'white',
      padding: '10px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      minHeight: 23,
      ...this.props.style,
    };

    if (this.props.warn) style.backgroundColor = 'orange';

    const iconStyle = {
      marginLeft: 2,
      flex: '0 0 auto',
      cursor: 'pointer',
    };

    return (
      <div style={style} onClick={this.toggleFocus}>
        <div className="regular-text" style={{ color: 'white' }}>
          {this.props.value}
        </div>
        {this.props.onDelete && <WvCloseLabel style={iconStyle} onClick={this.onDelete} />}
      </div>
    );
  };

  renderFocused = () => {
    const style = {
      resize: 'none',
      color: 'white',
      border: 0,
      borderRadius: 10,
      padding: '10px',
      ...this.props.style,
    };

    return (
      <AutoInput
        autoFocus
        className="regular-text"
        style={style}
        value={this.props.value}
        onChange={this.onValueChanged}
        onSave={this.toggleFocus}
        onKeyUp={(e) => {
          if (e.keyCode === 13) {
            this.toggleFocus();
          }
        }}
        onBlur={() => {
          this.toggleFocus();
        }}
        placeholder={this.props.placeholder}
      />
    );
  };

  render() {
    if (this.state.focused || this.props.focused) {
      return this.renderFocused();
    } else {
      return this.renderUnfocused();
    }
  }
}

const WvCloseLabel = (props) => {
  return (
    <svg
      {...props}
      width="14px"
      height="14px"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="close-cookie" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        {/* <rect fill="#000000" x="0" y="0" width="14" height="14"></rect> */}
        <path
          d="M7,0 C7.55228475,-1.01453063e-16 8,0.44771525 8,1 L8,6 L13,6 C13.5522847,6 14,6.44771525 14,7 C14,7.55228475 13.5522847,8 13,8 L8,8 L8,13 C8,13.5522847 7.55228475,14 7,14 C6.44771525,14 6,13.5522847 6,13 L6,8 L1,8 C0.44771525,8 0,7.55228475 0,7 C0,6.44771525 0.44771525,6 1,6 L6,6 L6,1 C6,0.44771525 6.44771525,1.01453063e-16 7,0 Z"
          id="Combined-Shape"
          fill="#FFFFFF"
          transform="translate(7.000000, 7.000000) rotate(45.000000) translate(-7.000000, -7.000000) "
        ></path>
      </g>
    </svg>
  );
};
