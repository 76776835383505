import React, { Component } from 'react';

import VerticalOrderedList from '../VerticalOrderedList';
import languageMap from 'Language/Language';
import './MessageSuggestions.scss';

export default class MessageSuggestions extends Component {
  render() {
    const { suggestions, selectedLanguage, onSuggestionsChange } = this.props;
    return (
      <div style={{ marginTop: 20 }}>
        <VerticalOrderedList
          title={languageMap.suggestionsLabel}
          elements={suggestions}
          selectedLanguage={selectedLanguage}
          onChange={onSuggestionsChange}
        />
      </div>
    );
  }
}
