import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { hasAccess } from 'services/userPermissions';

import {
  setMacros,
  setSelectedMacro,
  createMacro,
  deleteMacro,
  editMacro,
} from 'store/crm/actions';
import { selectMacros, selectSelectedMacro, selectCurrentUser } from 'store/crm/selectors';

import './MacrosContainer.scss';

const MacrosContainer = ({ macros, selectedMacro, currentUser, ...props }) => {
  const { setMacros, setSelectedMacro, createMacro, deleteMacro, editMacro } = props;
  const [insertingNewMacro, setInsertingNewMacro] = useState(false);
  const [temporaryTitle, setTemporaryTitle] = useState('');
  const [temporaryText, setTemporaryText] = useState('');
  const [detailsInvalid, setDetailsInvalid] = useState(false);
  const [sessionMacros, setSessionMacros] = useState([]);
  const [hasAccessToSharedMacros, setHasAccessToSharedMacros] = useState(false);

  useEffect(() => {
    const refreshMacros = async () => {
      setMacros(props.projectId).then((data) => {
        setSessionMacros(data.payload);
      });
    };

    refreshMacros();
    setHasAccessToSharedMacros(
      hasAccess(props.projectId, currentUser.permissions, 'macros', false)
    );
  }, [currentUser.permissions, props.projectId, setMacros]);

  function selectElement(valueToSelect) {
    document.getElementById('availableSelect').value = valueToSelect;
  }

  const handleClick = async (macro) => {
    await setSelectedMacro(macro);
    setTemporaryTitle(macro.macro_title);
    setTemporaryText(macro.macro_content);
    if (macro.agent_id !== null) {
      selectElement('me');
    } else {
      selectElement('everyone');
    }
  };

  const addMacro = () => {
    resetState();
    setInsertingNewMacro(true);
  };

  const generateMacroList = (macroArray, personalMacro) => {
    let listItems = macroArray
      .filter(
        (macro) => (personalMacro && macro.agent_id) || (!personalMacro && macro.agent_id == null)
      )
      .map((row) => {
        return (
          <li
            key={row.macro_id}
            onClick={() => {
              handleClick(row);
            }}
          >
            {row.macro_title}
          </li>
        );
      });
    return <ul className="macrosList">{listItems}</ul>;
  };

  const handleTextChanged = (newTextValue) => {
    setTemporaryText(newTextValue);
  };

  const handleTitleChanged = (newTextValue) => {
    setTemporaryTitle(newTextValue);
  };

  const onSaveMacro = async () => {
    let agentId = null;
    let response, auxMacros;

    if (temporaryTitle.length < 1 || temporaryText.length < 1) {
      setDetailsInvalid(true);
    } else {
      if (document.getElementById('availableSelect').value === 'me') {
        agentId = currentUser.user_id;
      }

      if (insertingNewMacro) {
        response = await createMacro({
          project_id: props.projectId,
          macro_title: temporaryTitle,
          macro_content: temporaryText,
          agent_id: agentId,
        });

        if (response.type === 'crm/CREATE_MACRO/fulfilled') {
          auxMacros = [];
          sessionMacros.forEach((sm) => {
            auxMacros.push(sm);
          });
          auxMacros.push({
            project_id: props.projectId,
            macro_id: response.payload.slice(response.payload.lastIndexOf(': ') + 1),
            macro_title: temporaryTitle,
            macro_content: temporaryText,
            agent_id: agentId,
          });
          setSessionMacros(auxMacros);
        }
      } else {
        response = await editMacro({
          project_id: props.projectId,
          macro_id: selectedMacro.macro_id,
          macro_title: temporaryTitle,
          macro_content: temporaryText,
          agent_id: agentId,
        });
        if (response.type === 'crm/EDIT_MACRO/fulfilled') {
          auxMacros = [];
          let newMacro = response.meta.arg;
          sessionMacros.forEach((sm) => {
            if (sm.macro_id === newMacro.macro_id) {
              auxMacros.push({
                project_id: sm.project_id,
                macro_id: sm.macro_id,
                macro_title: newMacro.macro_title,
                macro_content: newMacro.macro_content,
                agent_id: newMacro.agent_id,
              });
            } else {
              auxMacros.push(sm);
            }
          });

          setSessionMacros(auxMacros);
        }
      }
      resetState();
    }
  };

  const onDeleteMacro = async () => {
    if (!insertingNewMacro) {
      let response = await deleteMacro({ macro_id: selectedMacro.macro_id });
      if (response.type === 'crm/DELETE_MACRO/fulfilled') {
        setSessionMacros(
          sessionMacros.filter((sessionMacro) => {
            return sessionMacro.macro_id !== selectedMacro.macro_id;
          })
        );
      }
    }
    resetState();
  };

  const resetState = () => {
    setSelectedMacro(null);
    setTemporaryText('');
    setTemporaryTitle('');
    setInsertingNewMacro(false);
  };

  let disabled = selectedMacro == null && !insertingNewMacro;
  return (
    <div className="macrosContainer">
      <div className="macrosMenu">
        <div className="addMacroContainer">
          <button onClick={addMacro}>Add Macro</button>
        </div>
        <div className="macrosPersonal">
          <div className="macrosListTitleContainer">
            <label>Personal Macros</label>
          </div>
          {generateMacroList(sessionMacros, true)}
        </div>
        {hasAccessToSharedMacros ? (
          <div className="macrosShared">
            <div className="macrosListTitleContainer">
              <label>Shared Macros</label>
            </div>

            {generateMacroList(sessionMacros, false)}
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="macrosContent">
        {selectedMacro || insertingNewMacro ? (
          <>
            <div className="macrosContentHeader">
              <div className="macrosContentHeaderTitle">
                {selectedMacro || insertingNewMacro ? (
                  <input
                    id="titleMacro"
                    name="titleMacro"
                    onChange={(e) => handleTitleChanged(e.target.value)}
                    value={selectedMacro || insertingNewMacro ? temporaryTitle : ''}
                    disabled={disabled}
                  />
                ) : (
                  <>Select / Add macro</>
                )}
              </div>
              <div className="macrosContentHeaderActions">
                <button
                  className={disabled ? 'macrosActionDeleteDisabled' : 'macrosActionDelete'}
                  onClick={onDeleteMacro}
                  disabled={disabled}
                >
                  Delete
                </button>
                <button
                  className={disabled ? 'macrosActionSaveDisabled' : 'macrosActionSave'}
                  onClick={onSaveMacro}
                  disabled={disabled}
                >
                  Save
                </button>
              </div>
            </div>
            <div className="macrosContentBody">
              <textarea
                id="textareaMacro"
                name="textareaMacro"
                rows="8"
                placeholder="Add your text here..."
                className="textareaMacro"
                value={selectedMacro || insertingNewMacro ? temporaryText : ''}
                onChange={(e) => handleTextChanged(e.target.value)}
                disabled={disabled}
              />
            </div>
            <div className="macrosContentFooter">
              <div className="macrosContentFooterLabel">Available for:</div>
              <select
                name="color"
                className="availableSelect"
                id="availableSelect"
                disabled={disabled}
              >
                {hasAccessToSharedMacros ? <option value="everyone">Everyone</option> : <></>}
                <option value="me">Me</option>
              </select>
            </div>
            {!detailsInvalid ? (
              <></>
            ) : (
              <div className="macrosError">
                <p>Campurile de Titlu si Continut sunt obligatorii!</p>
              </div>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  macros: selectMacros(state),
  selectedMacro: selectSelectedMacro(state),
  currentUser: selectCurrentUser(state),
});

const mapDispatchToProps = {
  setMacros: setMacros,
  setSelectedMacro: setSelectedMacro,
  createMacro: createMacro,
  deleteMacro: deleteMacro,
  editMacro: editMacro,
};

export default connect(mapStateToProps, mapDispatchToProps)(MacrosContainer);
