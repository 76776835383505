import React from 'react';
import './ImageMessage.scss';
import { openImageModal } from 'pages/Flow/components/Modal/ImageModal';

const ImageMessage = ({ fileUrl }) => {
  return (
    <div className="ImageFileMessage" onClick={() => openImageModal(fileUrl)}>
      <img className="ImageFileMessage_Image" alt="Message File" src={fileUrl} />
    </div>
  );
};

export default ImageMessage;
