import { NOTIFICATION_TYPES, NotificationsContext } from 'containers/Notifications';
import { useContext } from 'react';

const useNotifications = () => {
  const showNotification = useContext(NotificationsContext);

  const showSuccess = (message, title) => {
    showNotification(NOTIFICATION_TYPES.SUCCESS, message, title);
  };

  const showError = (message, title) => {
    showNotification(NOTIFICATION_TYPES.ERROR, message, title);
  };

  return { showSuccess, showError };
};

export default useNotifications;
