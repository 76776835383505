import React from 'react';
import { Input } from 'semantic-ui-react';
import FormGroup from 'components/common/FormGroup';
import languageMap from 'Language/Language';

import './LinkTextBlock.scss';

const LinkTextBlock = ({ block, onBlockChange }) => {
  const handleChange = (fieldToChange) => {
    onBlockChange({ ...block, ...fieldToChange });
  };

  const { url, linkText, description } = block;

  return (
    <div className="LinkTextBlock">
      <FormGroup labelText="URL">
        <Input
          defaultValue={url}
          className="LinkTextBlock_Input"
          onBlur={(e) => handleChange({ url: e.target.value })}
        />
      </FormGroup>
      <FormGroup labelText={languageMap.linkText}>
        <Input
          defaultValue={linkText}
          className="LinkTextBlock_Input"
          onBlur={(e) => handleChange({ linkText: e.target.value })}
        />
      </FormGroup>
      <FormGroup labelText={languageMap.description}>
        <Input
          defaultValue={description}
          className="LinkTextBlock_Input"
          onBlur={(e) => handleChange({ description: e.target.value })}
        />
      </FormGroup>
    </div>
  );
};

export default LinkTextBlock;
