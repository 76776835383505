export const getEntries = (state) => {
  return state.qna.entries;
};

export const getEntriesTotal = (state) => {
  return state.qna.entriesTotal;
};

export const getEntities = (state) => {
  return state.qna.entities;
};

export const getProjectId = (state) => {
  return state.qna.projectId;
};

export const getSelectedFlowId = (state) => {
  return state.qna.flow_id;
};

export const getHasInitialState = (state) => {
  return state.qna.hasInitialState;
};

export const getConflictsCount = (state) => {
  return state.qna.conflictsCount;
};
