import React from 'react';
import { MAIN_COLOR } from '../../../../mainTheme';
import { v4 } from 'uuid';

export default function InboundLinksList(props) {
  const containerStyle = {
    display: 'flex',
    flexFlow: 'row wrap',
  };

  return (
    <div style={containerStyle}>
      {props.links.map((link) => (
        <Link
          text={
            link.link_text_by_language ? link.link_text_by_language[props.selectedLanguage] : ''
          }
          key={v4()}
        />
      ))}
    </div>
  );
}

function Link(props) {
  const containerStyle = {
    // width: "149px",
    marginRight: 5,
    height: 24,
    borderRadius: 10,
    color: 'white',
    paddingLeft: 12,
    paddingRight: 12,
    display: 'flex',
    justifyContent: 'space-between',
    flex: '0 0 auto',
    backgroundColor: MAIN_COLOR,
  };

  const textStyle = {
    // marginLeft: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent',
    border: 'none',
    textAlign: 'left',
    resize: 'none',
    outline: 'none',
    fontSize: '14px',
    fontWeight: '500',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#ffffff',
  };

  return (
    <div style={containerStyle}>
      <span style={textStyle}>{props.text}</span>
    </div>
  );
}
