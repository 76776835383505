import React, { Component } from 'react';
import './TokenEntryRow.scss';

export default class TokenEntryRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDelete: false,
    };
  }

  handlePageNameChange = (event) => {
    const { onChange, pageId, pageAccessToken } = this.props;
    onChange(event.target.value, pageId, pageAccessToken);
  };

  handlePageIdChange = (event) => {
    const { onChange, pageName, pageAccessToken } = this.props;
    onChange(pageName, event.target.value, pageAccessToken);
  };

  handlePageAccessTokenChange = (event) => {
    const { onChange, pageName, pageId } = this.props;
    onChange(pageName, pageId, event.target.value);
  };

  handleOnMouseOver = () => {
    this.setState({
      showDelete: true,
    });
  };

  handleOnMouseOut = () => {
    this.setState({
      showDelete: false,
    });
  };

  handleRemove = () => {
    this.props.remove();
  };

  render() {
    const { pageName, pageId, pageAccessToken } = this.props;

    return (
      <div
        className="TokenEntryRow"
        onMouseOver={this.handleOnMouseOver}
        onMouseOut={this.handleOnMouseOut}
      >
        <input placeholder="Page Name" value={pageName} onChange={this.handlePageNameChange} />
        <input placeholder="Page Id" value={pageId} onChange={this.handlePageIdChange} />
        <input
          placeholder="Page Access Token"
          value={pageAccessToken}
          onChange={this.handlePageAccessTokenChange}
        />
        {this.state.showDelete ? (
          <div className="TokenEntryRow_Delete" onClick={this.handleRemove}>
            X
          </div>
        ) : (
          <div className="TokenEntryRow_Delete"></div>
        )}
      </div>
    );
  }
}
