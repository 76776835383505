import React from 'react';
import './FacebookDocumentMessage.scss';

const FacebookDocumentMessage = ({ fileUrl }) => {
  return (
    <div className="IncomingMessage DocumentFacebookFileMessage">
      <a href={fileUrl}>
        <img
          className="DocumentFacebookFileMessage_Img"
          src="https://img.icons8.com/material-outlined/24/000000/document--v1.png"
          alt=""
        />
        <span className="DocumentFacebookFileMessage_Span">Download file</span>
      </a>
    </div>
  );
};

export default FacebookDocumentMessage;
