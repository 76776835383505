import React, { memo } from 'react';
import Node from 'pages/Flow/components/Canvas/components/NodesRenderer/Node';
import { NodeUpdater } from 'pages/Flow/utils/canvas-contexts';
import { SettingsDrawerContext } from 'pages/Flow/components/Canvas/components/NodeSettingsProvider';

const NodesRenderer = memo(({ scale }) => {
  return (
    <SettingsDrawerContext.Consumer>
      {({ openSettingsDrawer, closeSettingsDrawer }) => {
        return (
          <NodeUpdater.Consumer>
            {({ nodes, updateOrCreateNode, persistNodes }) => {
              return Object.entries(nodes).map(([node_id, node]) => {
                if (['endOfDialog', 'returnToParentFlow'].includes(node.object_class)) return null;

                return (
                  <Node
                    key={node_id}
                    node_id={node_id}
                    node={node}
                    scale={scale}
                    updateNode={updateOrCreateNode}
                    persistNodes={persistNodes}
                    objectIndex={node.objectIndex}
                    openSettingsDrawer={openSettingsDrawer}
                    closeSettingsDrawer={closeSettingsDrawer}
                  />
                );
              });
            }}
          </NodeUpdater.Consumer>
        );
      }}
    </SettingsDrawerContext.Consumer>
  );
});

export default NodesRenderer;
