import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import FileInput from '../FileInput';

import { SEND_MESSAGE_ENDPOINT, UPLOAD_SCOPE_CHAT_FILES } from 'utils/constants/index';
import languageMap from 'Language/Language';

import * as Sentry from '@sentry/react';

import SendButtonIcon from 'assets/svg/SendFileIcon';
import Cancel from 'assets/svg/Cancel';
import { TextareaAutosize } from '@mui/material';

import './ConversationConsole.scss';
import { uploadFile } from '../../../../../../../../utils/uploadFile';

const ConversationConsole = (props) => {
  const {
    disabled,
    token,
    macros,
    setMacros,
    currentConversation: { user_id, conversation_id },
  } = props;

  const [inputValue, setInputValue] = useState('');

  const [attachedFiles, setAttachedFiles] = useState([]);

  const [displayMacros, setDisplayMacros] = useState(false);
  const [filteredMacros, setFilteredMacros] = useState([]);

  const { id: projectId } = useParams();

  useEffect(() => {
    setInputValue('');
    setAttachedFiles([]);
  }, [conversation_id]);

  useEffect(() => {
    setMacros(projectId);
  }, [projectId, setMacros]);

  const handleUploadFiles = async () => {
    let filesMetaData = [];
    const attachedFilesPromises = attachedFiles.map(async (attachedFile) => {
      const response = await uploadFile(
        attachedFile,
        projectId,
        token,
        null,
        UPLOAD_SCOPE_CHAT_FILES
      );
      if (!response) {
        return null;
      }
      let fileMetaData = {
        name: attachedFile.name,
        url: response.data?.link,
      };

      filesMetaData.push(fileMetaData);

      if (filesMetaData.length === attachedFiles.length) {
        try {
          const messageResponse = await axios.post(SEND_MESSAGE_ENDPOINT, {
            destinationUserId: user_id,
            conversationId: conversation_id,
            projectId,
            messageText: inputValue,
            attachedDocuments: filesMetaData,
          });
          if (messageResponse?.data == 'nok') {
            return null;
          }
        } catch (e) {
          Sentry.captureException(`FAILURE - SEND FILE MESSAGE - ${e}`);
          return null;
        }
      }
      return fileMetaData;
    });

    return await Promise.all(attachedFilesPromises);
  };

  const sendTextMessage = async () => {
    const currentInputValue = inputValue;
    setInputValue('');

    await axios.post(SEND_MESSAGE_ENDPOINT, {
      destinationUserId: user_id,
      conversationId: conversation_id,
      projectId,
      messageText: currentInputValue,
    });
  };

  const sendMessage = async () => {
    // eslint-disable-next-line
    if (!inputValue && attachedFiles.length === 0) {
      return;
    }

    let response;

    if (attachedFiles.length > 0) {
      response = await handleUploadFiles();
    } else {
      response = await sendTextMessage();
    }

    if (!response || response?.includes(null)) {
      return;
    }
    setInputValue('');
    setAttachedFiles([]);
    if (document.getElementById('attach-documents-form').value) {
      document.getElementById('attach-documents-form').reset();
    } // this resets the form's inner memory which we are bypassing with our logic
  };

  const sendAttempt = (event) => {
    if (event.key === 'Enter') {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        sendMessage();
      }
    }
  };

  const deleteFile = (fileToDelete) => () => {
    const newAttachedFiles = attachedFiles;
    const filePosition = newAttachedFiles.indexOf(fileToDelete);
    newAttachedFiles.splice(filePosition, 1);
    setAttachedFiles([...newAttachedFiles]);
  };

  const handleFileAttach = (file) => {
    const shouldAttach = attachedFiles.filter((item) => item.name === file.name);

    if (shouldAttach.length === 0) {
      setAttachedFiles([...attachedFiles, file]);
    }
  };

  const checkDisplayMacros = (value) => {
    if (value.startsWith('#')) {
      setDisplayMacros(true);
      const lookingFor = value.substring(1).toLowerCase();
      let macrosStarting = macros.filter((macro) => {
        return (
          macro.macro_title.toLowerCase().startsWith(lookingFor) ||
          macro.macro_content.toLowerCase().startsWith(lookingFor)
        );
      });

      let macrosContaining = macros.filter((macro) => {
        return (
          macro.macro_title.toLowerCase().substring(1).includes(lookingFor) ||
          macro.macro_content.toLowerCase().substring(1).includes(lookingFor)
        );
      });

      setFilteredMacros([...new Set([...macrosStarting, ...macrosContaining])]);
    } else {
      setDisplayMacros(false);
    }
  };

  const handleInputChange = (e) => {
    const newVal = e.target.value;

    setInputValue(newVal);
    checkDisplayMacros(newVal);
  };

  const handleMacroClick = (row) => {
    setInputValue(row.macro_content);
    setDisplayMacros(false);
  };

  const displayFilteredMacros = () => {
    let listItems = filteredMacros.map((row) => {
      return (
        <li
          key={'' + row.macro_id + row.macro_title + row.macro_content}
          onClick={() => {
            handleMacroClick(row);
          }}
        >
          <div className="macroTitle">{row.macro_title}</div>
          <div className="macroContent">{row.macro_content}</div>
        </li>
      );
    });
    if (listItems.length === 0) {
      listItems.push(<li>No macros match the filter.</li>);
    }

    return <ul className="macrosList">{listItems.slice(0, 5)}</ul>;
  };

  return (
    <>
      {displayMacros ? displayFilteredMacros() : <></>}

      <div className="ConversationConsole">
        <>
          <div className="conversation-composer">
            <TextareaAutosize
              minRows={2}
              maxRows={7}
              className="MessageInput dm-sans-400"
              value={inputValue}
              placeholder={`${languageMap.typeHereToReply}...`}
              onChange={handleInputChange}
              onKeyPress={sendAttempt}
              disabled={disabled}
            />
            <div className="conversation-controls">
              <form
                id="attach-documents-form"
                name="attach-documents-form"
                className="attach-documents-form"
              >
                <div className="left">
                  <FileInput onSelect={handleFileAttach} />
                </div>
                <div className="right">
                  <SendButtonIcon
                    className="send-button-icon"
                    onClick={sendMessage}
                    onKeyPress={sendAttempt}
                  />
                </div>
              </form>
            </div>
          </div>

          {attachedFiles?.length > 0 && (
            <div className="AttachedFile_wrapper">
              {attachedFiles.map((attachedFile) => (
                <div className="AttachedFile" key={attachedFile.name + attachedFiles.lastModified}>
                  <span>{attachedFile.name}</span>
                  <Cancel className="deleteIcon" onClick={deleteFile(attachedFile)} />
                </div>
              ))}
            </div>
          )}
        </>
      </div>
    </>
  );
};

export default ConversationConsole;
