import Axios from 'axios';

export default function createObject(objectClass, appId, projectId) {
  switch (objectClass) {
    case 'event': {
      const request = {
        url: '/internal_api/event',
        params: {
          appId: appId,
        },
      };

      return Axios.post(request.url, request.params).then((response) => {
        return {
          category: 'events',
          objectName: response.data.event_name,
          objectId: response.data.event_id,
          object: response.data,
        };
      });
    }
    case 'message': {
      const request = {
        url: '/internal_api/message',
        params: {
          appId: appId,
        },
      };

      return Axios.post(request.url, request.params).then((response) => {
        return {
          category: 'messages',
          objectName: response.data.message_name,
          objectId: response.data.message_id,
          object: response.data,
        };
      });
    }
    case 'intent': {
      const request = {
        url: '/internal_api/intent',
        params: {
          appId: appId,
        },
      };

      return Axios.post(request.url, request.params).then((response) => {
        return {
          category: 'intents',
          objectName: response.data.intent_name,
          objectId: response.data.intent_id,
          object: response.data,
        };
      });
    }
    case 'entity': {
      const request = {
        url: '/internal_api/entity',
        params: {
          appId: appId,
        },
      };

      return Axios.post(request.url, request.params).then((response) => {
        return {
          category: 'entities',
          objectName: response.data.entity_name,
          objectId: response.data.entity_id,
          object: response.data,
        };
      });
    }
    case 'service': {
      const request = {
        url: '/internal_api/service',
        params: {
          appId: appId,
        },
      };

      return Axios.post(request.url, request.params).then((response) => {
        return {
          category: 'services',
          objectName: response.data.service_name,
          objectId: response.data.service_id,
          object: response.data,
        };
      });
    }
    case 'script': {
      const request = {
        url: '/internal_api/script',
        params: {
          appId: appId,
        },
      };

      return Axios.post(request.url, request.params).then((response) => {
        return {
          category: 'scripts',
          objectName: response.data.script_name,
          objectId: response.data.script_id,
          object: response.data,
        };
      });
    }
    case 'flow_connector': {
      const request = {
        url: '/internal_api/flow_connector',
        params: {
          projectId,
        },
      };

      return Axios.post(request.url, request.params).then((response) => {
        return {
          category: 'flow_connectors',
          objectName: response.data.flow_connector_name,
          objectId: response.data.flow_connector_id,
          object: response.data,
        };
      });
    }
    case 'parent_connector': {
      const request = {
        url: '/internal_api/parent_connector',
        params: {
          projectId,
          appId,
        },
      };

      return Axios.post(request.url, request.params).then((response) => {
        return {
          category: 'parent_connectors',
          objectName: response.data.parent_connector_name,
          objectId: response.data.parent_connector_id,
          object: response.data,
        };
      });
    }
    default:
      return Promise.reject(new Error('Wrong element: ' + objectClass));
  }
}
