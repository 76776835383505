import React from 'react';
import useAudio from 'utils/hooks/useAudio';
import './AudioMessage.scss';

export default function AudioMessage(props) {
  const { fileUrl } = props;

  const [, toggle] = useAudio(fileUrl);
  return (
    <div className={'AudioOutgoingFileMessage'}>
      <button className={'audio-outgoing-file-message-play'} onClick={toggle} />
      <span> Audio Message</span>
    </div>
  );
}
