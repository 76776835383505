import React, { Component } from 'react';
import Checkbox from 'components/common/LabeledCheckbox/Checkbox';

export default class WebchatSettings extends Component {
  render() {
    const { children, ...otherProps } = this.props;
    return (
      <div className="WebchatConfigurationDialog_Content_Options_ColumnLeft_OptionContainer">
        <div className="WebchatConfigurationDialog_Content_Options_ColumnLeft_OptionTitle">
          {children}
        </div>
        <Checkbox {...otherProps} width={30} height={30} />
      </div>
    );
  }
}
