import { createReducer } from '@reduxjs/toolkit';
import { getConversationTemplate, getConversationHistoryTemplate } from 'utils/utils';
import {
  getAgents,
  getDepartments,
  getConversations,
  clearConversations,
  setStartFromConversationIndex,
  getChatHistory,
  setMainMenu,
  setSubMenu,
  setReportsTab,
  setInboxFolder,
  setConversationsStatusFilter,
  setCurrentConversation,
  resetCurrentConversation,
  editConversationSubject,
  refreshConversationCardSubject,
  getMediaAndFiles,
  setConversationStatus,
  getCurrentUser,
  setConversationNotes,
  updateLastMessage,
  updateMessageTimestamp,
  setNewConversation,
  removeConversation,
  setToConversationHistory,
  incrementYou,
  decrementYou,
  addToYouUnread,
  removeFromYouUnread,
  incrementUnassigned,
  decrementUnassigned,
  addToUnassignedUnread,
  removeFromUnassignedUnread,
  incrementAll,
  decrementAll,
  addToExternalAllUnread,
  removeFromExternalAllUnread,
  incrementInternalAll,
  decrementInternalAll,
  addToInternalAllUnread,
  removeFromInternalAllUnread,
  updateConversationInList,
  createNewInternalConversation,
  setConversationReadStatus,
  setSelectedMacro,
  setMacros,
  setSearchStatus,
  resetSearch,
  setFilters,
  setSearchFilters,
  getUsersSuggestions,
  setFilteredData,
  setFilteredDataAdd,
  resetFilteredData,
  setSearchResultsWarning,
  setBotTab,
  setIsMobile,
  getAllLanguages,
  getProjectLanguageData,
  updateProjectInfo,
  refreshConversationChannel,
} from './actions';

const initialSearchState = {
  isSearching: false,
  isMainMenu: false,
  isSecondaryMenu: false,
  secondaryMenuItems: [],
  currentFilter: null,
  searchFilters: [],
  filteredData: [],
  filteredDataLength: '',
  usersSuggestions: [],
  searchWarning: '',
  startFromConversationIndex: 0,
};

const initialState = {
  agents: [],
  departments: [],
  allLanguages: [],
  secondaryLanguages: [],
  projectLanguage: '',

  conversations: {
    conversations: [],
    folder_conversations: {
      you: 0,
      unassigned: 0,
      internal_all: 0,
      external_all: 0,
    },
    unread_conversations: {
      you: [],
      unassigned: [],
      external_all: [],
      internal_all: [],
    },
  },
  conversationHistory: {
    history: [],
    first_seen: null,

    conversations: [],
    allowed: false,
    participants: {
      all_participants: [],
      current_participants: [],
    },
    conversation_data: {
      conversation_notes: [],
    },
  },
  currentConversation: {
    user_id: null,
    conversation_id: null,
    conversation_status: null,
    mediaAndFiles: [],
    subject: null,
    last_message: { file: null },
  },
  currentUser: null,
  filters: {},
  search: initialSearchState,
  mainMenu: 'inbox',
  subMenu: '',
  inboxFolder: {
    submenu: '',
    file: {
      title: '',
      value: '',
    },
  },
  conversationsStatusFilter: 1,
  macros: [],
  selectedMacro: null,
  isMobile: false,
  reportsTab: 'Conversations',
  botTab: 'Flows',
};

// This reducer uses immer to wrap the changes
export default createReducer(initialState, (builder) => {
  builder
    .addCase(getAgents.fulfilled, (state, action) => {
      return { ...state, agents: action.payload };
    })
    .addCase(getDepartments.fulfilled, (state, action) => {
      return { ...state, departments: action.payload };
    })
    .addCase(getConversations.fulfilled, (state, action) => {
      state.conversations = {
        ...action.payload,
        conversations: [...state.conversations.conversations, ...action.payload.conversations],
      };
    })
    .addCase(clearConversations, (state) => {
      state.conversations.conversations = initialState.conversations.conversations;
    })

    .addCase(getAllLanguages.fulfilled, (state, action) => {
      return {
        ...state,
        allLanguages: action.payload,
      };
    })
    .addCase(getProjectLanguageData.fulfilled, (state, action) => {
      return {
        ...state,
        secondaryLanguages: action.payload.secondary_languages,
        projectLanguage: action.payload.projectLanguage || action.payload.project_language,
      };
    })
    .addCase(updateProjectInfo.fulfilled, (state, action) => {
      return {
        ...state,
        secondaryLanguages: action.payload.secondaryLanguages,
        projectLanguage: action.payload.projectLanguage,
      };
    })

    .addCase(getChatHistory.fulfilled, (state, action) => {
      state.conversationHistory = action.payload;
      return state;
    })
    .addCase(setMainMenu, (state, action) => {
      return {
        ...state,
        mainMenu: action.payload,
      };
    })
    .addCase(setSubMenu, (state, action) => {
      return {
        ...state,
        subMenu: action.payload,
      };
    })
    .addCase(setInboxFolder, (state, action) => {
      state.inboxFolder = action.payload;
      state.search = initialSearchState;
      state.currentConversation = initialState.currentConversation;
      return state;
    })
    .addCase(setReportsTab, (state, action) => {
      return {
        ...state,
        reportsTab: action.payload,
      };
    })
    .addCase(setBotTab, (state, action) => {
      return {
        ...state,
        botTab: action.payload,
      };
    })
    .addCase(setConversationsStatusFilter, (state, action) => {
      state.conversationsStatusFilter = action.payload === 'open' ? 1 : 0;
    })
    .addCase(getMediaAndFiles.fulfilled, (state, action) => {
      state.currentConversation.mediaAndFiles = action.payload;
      return state;
    })
    .addCase(editConversationSubject.fulfilled, (state, action) => {
      state.currentConversation.subject = action.payload;
      return state;
    })
    .addCase(refreshConversationCardSubject, (state, action) => {
      const { conversation_id, subject } = action.payload;
      const index = state.conversations.conversations.findIndex(
        (conversation) => conversation.conversation_id === conversation_id
      );
      state.conversations.conversations[index].subject = subject;
      return state;
    })
    .addCase(refreshConversationChannel.fulfilled, (state, action) => {
      const { conversation_id, channel } = action.payload;

      const index = state.conversations.conversations.findIndex(
        (conversation) => conversation.conversation_id === conversation_id
      );
      if (index !== -1) {
        state.conversations.conversations[index].channel = channel;
        if (state.currentConversation.conversation_id == conversation_id) {
          state.currentConversation = {
            ...state.currentConversation,
            channel: channel,
          };
        }
      }
      return state;
    })
    .addCase(resetCurrentConversation, (state) => {
      state.currentConversation = initialState.currentConversation;
    })
    .addCase(setConversationStatus.fulfilled, (state, action) => {
      state.currentConversation.conversation_status = action.payload;
    })
    .addCase(getCurrentUser, (state, action) => {
      state.currentUser = action.payload;
    })
    .addCase(setConversationNotes.fulfilled, (state, action) => {
      if (state.conversationHistory?.conversation_data) {
        state.conversationHistory.conversation_data.conversation_notes = action.payload.notes;
      }
    })
    .addCase(updateLastMessage, (state, action) => {
      const { conversation_id, last_message } = action.payload;
      const index = state.conversations.conversations.findIndex(
        (item) => item.conversation_id === conversation_id
      );
      if (index !== -1) {
        state.conversations.conversations[index].last_message = last_message;
      }
    })
    .addCase(updateMessageTimestamp, (state, action) => {
      const { conversation_id, last_event_timestamp } = action.payload;

      const index = state.conversations.conversations.findIndex(
        (item) => item.conversation_id === conversation_id
      );
      if (index !== -1) {
        state.conversations.conversations[index].last_message.timestamp = last_event_timestamp;
      }
      return state;
    })
    .addCase(updateConversationInList, (state, action) => {
      const { conversation_meta, conversation_id } = action.payload;
      const index = state.conversations.conversations.findIndex(
        (item) => item.conversation_id === conversation_id
      );

      if (index !== -1) {
        state.conversations.conversations.splice(index, 1);
        state.conversations.conversations.splice(0, 0, conversation_meta);
      }
    })
    .addCase(setCurrentConversation, (state, action) => {
      if (action.payload && action.payload.user_id && action.payload.conversation_id) {
        state.currentConversation = action.payload;
      } else {
        state.currentConversation = initialState.currentConversation;
      }
      return state;
    })
    .addCase(createNewInternalConversation.fulfilled, (state, action) => {
      const { first_seen, participants, conversation_data } = action.payload;

      const conversation = getConversationTemplate({
        first_seen,
        ...conversation_data,
      });

      state.conversations.conversations = [...state.conversations.conversations, conversation];
      state.currentConversation = conversation;
      state.conversationHistory = getConversationHistoryTemplate({ first_seen, participants });

      return state;
    })
    .addCase(setNewConversation, (state, action) => {
      const { conversation_meta } = action.payload;
      state.conversations.conversations.splice(0, 0, conversation_meta);
      return state;
    })
    .addCase(removeConversation, (state, action) => {
      const index = state.conversations.conversations.findIndex(
        (item) => item.conversation_id === action.payload.conversation_id
      );
      if (index !== -1) {
        state.conversations.conversations.splice(index, 1);
      }

      return state;
    })
    .addCase(setToConversationHistory, (state, action) => {
      if (state.conversationHistory.history) {
        state.conversationHistory.history.splice(0, 0, action.payload.message);
      }
      return state;
    })
    .addCase(incrementYou, (state) => {
      state.conversations.folder_conversations.you++;
    })
    .addCase(decrementYou, (state) => {
      if (state.conversations.folder_conversations.you > 0) {
        state.conversations.folder_conversations.you--;
      }
    })
    .addCase(addToYouUnread, (state, action) => {
      const { conversation_id } = action.payload;
      const index = state.conversations.unread_conversations.you.findIndex(
        (id) => id === conversation_id
      );

      if (index === -1) {
        state.conversations.unread_conversations.you.push(conversation_id);
      }

      return state;
    })
    .addCase(removeFromYouUnread, (state, action) => {
      const { conversation_id } = action.payload;
      const index = state.conversations.unread_conversations.you.findIndex(
        (id) => id === conversation_id
      );
      if (index !== -1) {
        state.conversations.unread_conversations.you.splice(index, 1);
      }
    })
    .addCase(incrementUnassigned, (state) => {
      state.conversations.folder_conversations.unassigned++;
    })
    .addCase(decrementUnassigned, (state) => {
      if (state.conversations.folder_conversations.unassigned > 0) {
        state.conversations.folder_conversations.unassigned--;
      }
    })
    .addCase(addToUnassignedUnread, (state, action) => {
      const { conversation_id } = action.payload;
      const index = state.conversations.unread_conversations.unassigned.findIndex(
        (id) => id === conversation_id
      );
      if (index === -1) {
        state.conversations.unread_conversations.unassigned.push(conversation_id);
      }
    })
    .addCase(removeFromUnassignedUnread, (state, action) => {
      const { conversation_id } = action.payload;
      const index = state.conversations.unread_conversations.unassigned.findIndex(
        (id) => id === conversation_id
      );
      if (index !== -1) {
        state.conversations.unread_conversations.unassigned.splice(index, 1);
      }
    })
    .addCase(incrementAll, (state) => {
      state.conversations.folder_conversations.external_all++;
    })
    .addCase(decrementAll, (state) => {
      if (state.conversations.folder_conversations.external_all > 0) {
        state.conversations.folder_conversations.external_all--;
      }
    })
    .addCase(addToExternalAllUnread, (state, action) => {
      const { conversation_id } = action.payload;
      const index = state.conversations.unread_conversations.external_all.findIndex(
        (id) => id === conversation_id
      );
      if (index === -1) {
        state.conversations.unread_conversations.external_all.push(conversation_id);
      }
    })
    .addCase(removeFromExternalAllUnread, (state, action) => {
      const { conversation_id } = action.payload;
      const index = state.conversations.unread_conversations.external_all.findIndex(
        (id) => id === conversation_id
      );
      if (index !== -1) {
        state.conversations.unread_conversations.external_all.splice(index, 1);
      }
    })
    .addCase(incrementInternalAll, (state) => {
      state.conversations.folder_conversations.internal_all++;
    })
    .addCase(decrementInternalAll, (state) => {
      state.conversations.folder_conversations.internal_all--;
    })
    .addCase(addToInternalAllUnread, (state, action) => {
      const { conversation_id } = action.payload;
      const index = state.conversations.unread_conversations.internal_all.findIndex(
        (id) => id === conversation_id
      );
      if (index === -1) {
        state.conversations.unread_conversations.internal_all.push(conversation_id);
      }
    })
    .addCase(removeFromInternalAllUnread, (state, action) => {
      const { conversation_id } = action.payload;
      const index = state.conversations.unread_conversations.internal_all.findIndex(
        (id) => id === conversation_id
      );
      if (index !== -1) {
        state.conversations.unread_conversations.internal_all.splice(index, 1);
      }
    })
    .addCase(setConversationReadStatus.fulfilled, (state, action) => {
      const index = state.conversations.conversations.findIndex(
        (item) => item.conversation_id === action.payload.conversation_id
      );
      if (index !== -1) {
        state.conversations.conversations[index].is_conversation_read = 1;
        state.currentConversation.is_conversation_read = 1;
      }
    })
    .addCase(setSelectedMacro, (state, action) => {
      state.selectedMacro = action.payload;
    })
    .addCase(setFilters, (state, action) => {
      state.filters = action.payload;
    })
    .addCase(setMacros.fulfilled, (state, action) => {
      state.macros = action.payload;
    })
    .addCase(setSearchStatus, (state, action) => {
      state.search.isSearching = action.payload;
    })
    .addCase(setSearchResultsWarning, (state, action) => {
      state.search.searchWarning = action.payload;
    })
    .addCase(resetSearch, (state) => {
      state.search = initialSearchState;
    })
    .addCase(setSearchFilters, (state, action) => {
      state.search.searchFilters = [...action.payload];
    })

    .addCase(getUsersSuggestions.fulfilled, (state, action) => {
      state.search.usersSuggestions = action.payload;
    })
    .addCase(setFilteredData.fulfilled, (state, action) => {
      state.search.filteredData = action.payload.conversations;
      state.search.filteredDataLength = action.payload.conversations_counter;
    })

    .addCase(setFilteredDataAdd.fulfilled, (state, action) => {
      state.search.filteredData = [...state.search.filteredData, ...action.payload.conversations];
      state.search.filteredDataLength = action.payload.conversations_counter;
    })

    .addCase(resetFilteredData, (state) => {
      state.search.filteredData = [];
    })
    .addCase(setStartFromConversationIndex, (state, action) => {
      state.search.startFromConversationIndex = action.payload;
    })
    .addCase(setIsMobile, (state, action) => {
      state.isMobile = action.payload;
    })

    .addDefaultCase(() => {});
});
