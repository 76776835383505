import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import Back from '../../../../assets/svg/Back';
import languageMap from 'Language/Language';
import HelperTooltip from '../../../../components/HelperTooltip/HelperTooltip';
import Pagination from '../../../../components/Pagination/Pagination';
import Retrain from '../../../Flow/components/Retrain';

import LanguageOptions from '../LanguageOptions/LanguageOptions';
import Entry from '../../components/Entry';

import { useComputeLanguageOptions } from '../LanguageOptions/ComputeLanguageOptions';

import axios from 'axios';
import { connect } from 'react-redux';

import { getProjectLanguageData } from 'store/crm/actions';

import {
  clearState,
  createEntry,
  createFlow,
  deleteEntry,
  fetchEntries,
  updateEntry,
  conflictsCount,
} from 'store/qna/actions';

import { getEntries, getEntriesTotal, getConflictsCount } from 'store/qna/selectors';
import {
  selectAllLanguages,
  selectProjectLanguage,
  selectSecondaryLanguages,
} from '../../../../store/crm/selectors';

import './QnAConflicts.scss';
import ConflictsWrapper from '../ConflictsWrapper/ConflictsWrapper';
import { v4 } from 'uuid';

const QnAConflicts = ({
  languages,
  secondaryLanguages,
  selectedLanguage,
  getProjectLanguageData,
  conflictsCount,
  conflictsCountAction,
}) => {
  const [skip, setSkip] = useState(0);
  const [conflictedEntries, setConflictedEntries] = useState([]);
  const [entryToLanguage, setEntryToLanguage] = useState({});
  const [selectedTopLanguage, setSelectedTopLanguage] = useState(selectedLanguage);

  const [trigger, setTrigger] = useState(false);

  const PAGE_COUNT = 3;

  const navigate = useNavigate();
  const { id: projectId } = useParams();

  useEffect(() => {
    if (!selectedLanguage) {
      getProjectLanguageData(projectId);
    }
    getConflictState();
  }, [projectId, skip, trigger, conflictedEntries?.length]);

  useEffect(() => {
    setSelectedTopLanguage(selectedLanguage);
  }, [selectedLanguage]);

  useEffect(() => {
    conflictsCountAction({ project_id: projectId });
  }, [conflictedEntries?.length]);

  const getConflictState = async () => {
    try {
      const response = await axios.get('/internal_api/qna/conflicts/get_state', {
        params: {
          project_id: projectId,
          skip,
          limit: PAGE_COUNT,
        },
      });
      if (response.status !== 200) {
        Sentry.captureException('FAIL GENERATE TOKEN CRM - ' + response);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = response.data;
      setConflictedEntries(data?.entries);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const languageOptions = useComputeLanguageOptions({
    languages,
    secondaryLanguages,
    selectedLanguage,
  });

  return (
    <div className="QnAConflicts">
      <div className="title-wrapper">
        <Back
          className="back-button"
          onClick={() => {
            const currentUrl = window.location.pathname;
            const urlWithoutLastSegment = currentUrl.substring(0, currentUrl.lastIndexOf('/'));
            navigate(urlWithoutLastSegment);
          }}
        />
        <div className="title title-text ">{languageMap.qnaConflicts}</div>
        <HelperTooltip
          style={{ marginRight: 10, height: 30, width: 30 }}
          title={languageMap.confilctsWarning}
        />
        <LanguageOptions
          setEntryToLanguage={setEntryToLanguage}
          languageOptions={languageOptions}
          setSelectedTopLanguage={setSelectedTopLanguage}
        />

        <Retrain style={{ marginRight: 12, marginLeft: 10 }} />
      </div>
      <div className="content">
        <div className="options"></div>
        <div id="QnaEntriesContainer">
          <div id="QnaEntriesHeader">
            <span>{languageMap.questions}</span>
            <span>{languageMap.answers}</span>
          </div>
          {conflictedEntries &&
            conflictedEntries.map((entry, index) => {
              return (
                <ConflictsWrapper
                  key={v4()}
                  projectId={projectId}
                  appId_1={entry?.intent_1?.flow_id}
                  message_1_id={entry?.intent_1?.message_id}
                  intentId_1={entry?.intent_1?.intent_id}
                  intent_title_1={entry?.intent_1?.title}
                  appId_2={entry?.intent_2?.flow_id}
                  message_2_id={entry?.intent_2?.message_id}
                  intentId_2={entry?.intent_2?.intent_id}
                  intent_title_2={entry?.intent_2?.title}
                  setTrigger={setTrigger}
                >
                  <div id="EntryWrapper">
                    <Entry
                      appId={entry?.intent_1?.flow_id}
                      conflictsPage={[entry?.intent_1?.intent_id, entry?.intent_2?.intent_id]}
                      deleteEntry={deleteEntry}
                      languageOptions={languageOptions}
                      selectedLanguage={
                        entryToLanguage[entry?.intent_1?.message_id] || selectedTopLanguage
                      }
                      entryToLanguage={entryToLanguage}
                      setEntryToLanguage={setEntryToLanguage}
                      isProjectLanguage={Boolean(
                        (entryToLanguage[entry?.intent_1?.message_id] || selectedLanguage) ===
                          selectedLanguage
                      )}
                      updateEntry={updateEntry}
                      {...entry?.intent_1}
                    />
                    <Entry
                      appId={entry?.intent_2?.flow_id}
                      conflictsPage={[entry?.intent_1?.intent_id, entry?.intent_2?.intent_id]}
                      deleteEntry={deleteEntry}
                      languageOptions={languageOptions}
                      selectedLanguage={
                        entryToLanguage[entry?.intent_2?.message_id] || selectedTopLanguage
                      }
                      entryToLanguage={entryToLanguage}
                      setEntryToLanguage={setEntryToLanguage}
                      isProjectLanguage={Boolean(
                        (entryToLanguage[entry?.intent_2?.message_id] || selectedLanguage) ===
                          selectedLanguage
                      )}
                      updateEntry={updateEntry}
                      {...entry?.intent_2}
                    />
                  </div>
                </ConflictsWrapper>
              );
            })}
        </div>
        <Pagination total={conflictsCount} skip={skip} setPage={setSkip} pageCount={PAGE_COUNT} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  languages: selectAllLanguages(state),
  secondaryLanguages: selectSecondaryLanguages(state),
  selectedLanguage: selectProjectLanguage(state),
  entries: getEntries(state),
  entriesTotal: getEntriesTotal(state),
  conflictsCount: getConflictsCount(state),
});

const mapDispatchToProps = {
  getProjectLanguageData,
  createEntry,
  updateEntry,
  deleteEntry,
  fetchEntries,
  createFlow,
  clearState,
  conflictsCountAction: conflictsCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(QnAConflicts);
