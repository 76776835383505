import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Dropdown } from 'semantic-ui-react';

import {
  selectAllLanguages,
  selectProjectLanguage,
  selectSecondaryLanguages,
} from '../../../../store/crm/selectors';

import './LanguageOptions.scss';

const LanguageOptions = (props) => {
  const { languageOptions, setEntryToLanguage, setSelectedTopLanguage } = props;
  const [selectedLanguage, setSelectedLanguage] = useState(props.selectedLanguage);

  useEffect(() => {
    setSelectedLanguage(props.selectedLanguage);
  }, [props.selectedLanguage]);

  const handleLanguageChange = (event, data) => {
    setEntryToLanguage(data.value);
    setSelectedLanguage(data.value);
    setSelectedTopLanguage(data.value);
  };

  return (
    <div className="LanguageOptions">
      <Dropdown
        selection
        value={selectedLanguage}
        options={languageOptions}
        onChange={handleLanguageChange}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  languages: selectAllLanguages(state),
  secondaryLanguages: selectSecondaryLanguages(state),
  selectedLanguage: selectProjectLanguage(state),
});

export default connect(mapStateToProps, null)(LanguageOptions);
