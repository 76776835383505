import React from 'react';
import { NodeUpdater } from 'pages/Flow/utils/canvas-contexts';

const withNode = (nodeIdProvider, WrappedComponent) => {
  return class extends React.Component {
    render() {
      const nodeId = nodeIdProvider(this.props);

      return (
        <NodeUpdater.Consumer>
          {({ nodes }) => {
            return <WrappedComponent node={nodes[nodeId]} {...this.props} />;
          }}
        </NodeUpdater.Consumer>
      );
    }
  };
};

export default withNode;
