import React from 'react';

// =====================================================================================================================
//  D E C L A R A T I O N S
// =====================================================================================================================
const MIN_WIDTH = 20;
const MAX_WIDTH = 256;
const NUMERIC_STEPPER_ADJUSTMENT = 16;

// =====================================================================================================================
//  C O M P O N E N T
// =====================================================================================================================
class AutoInput extends React.PureComponent {
  ref = React.createRef();
  hasChangedValue = false;

  /**
   *
   */
  render() {
    // eslint-disable-next-line no-unused-vars
    const { onSave, ...other } = this.props;

    delete other.onChange;
    delete other.minWidth;
    delete other.maxWidth;
    return (
      <input
        ref={this.ref}
        {...other}
        onChange={this.onChange}
        onBlur={this.onSave}
        onFocus={this.onFocus}
      />
    );
  }

  /**
   *
   */
  componentDidMount() {
    this.autoSize(true);
  }

  /**
   *
   */
  componentDidUpdate() {
    this.autoSize();
  }

  onFocus = () => {
    this.hasChangedValue = false;
  };

  onSave = () => {
    const { onBlur, onSave } = this.props;
    if (this.hasChangedValue) {
      onSave();
    } else {
      if (this.props.onBlur) {
        onBlur();
      }
    }
  };

  /**
   *
   */
  autoSize = (isMount) => {
    const { placeholder, type, inputRef, value } = this.props;
    const input = (inputRef || this.ref).current;
    if (!input.offsetWidth) {
      // the input is not displayed
      input.style.width = MIN_WIDTH + 'px';
      if (value && isMount) {
        this.forceUpdate(); // In case of initial value it will need resize
      }
      return;
    }
    const isPlaceholder = !input.value && placeholder;
    input.style.width = '0';
    if (isPlaceholder) {
      input.value = placeholder;
    }
    const w = input.scrollWidth + (type === 'number' ? NUMERIC_STEPPER_ADJUSTMENT : 0);
    if (isPlaceholder) {
      input.value = '';
    }
    const minWidth = this.props.minWidth || MIN_WIDTH;
    const maxWidth = this.props.maxWidth || MAX_WIDTH;
    input.style.width = Math.min(Math.max(w, minWidth), maxWidth) + 'px';
  };

  /**
   *
   */
  onChange = (event) => {
    event.stopPropagation(); // we're handling the change manually and this event collides with us upstream
    this.autoSize();
    const { onChange } = this.props;
    if (onChange) {
      this.hasChangedValue = true;
      onChange(event);
    }
  };
}

// =====================================================================================================================
//  D E F I N I T I O N
// =====================================================================================================================
export default AutoInput;
