import Axios from 'axios';
import {
  EDIT_USER_INFO,
  OWN_CONVERSATIONS_ENDPOINT,
  GENERATE_RESET_PASSWORD_TOKEN,
} from 'utils/constants';

export const fetchCRMConversations = async (projectId) => {
  return await Axios.get(OWN_CONVERSATIONS_ENDPOINT, {
    params: { projectId },
  });
};

export const editUserInfo = async (token, project_id, user_id, data) => {
  return await Axios.post(EDIT_USER_INFO, data, {
    params: { user_id, project_id },
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
};

export const generateResetPasswordToken = (token, email) => {
  return Axios.get(GENERATE_RESET_PASSWORD_TOKEN, {
    params: { email },
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
};
