export const MAIN_COLOR = '#0530F9';
export default {
  palette: {
    primary: {
      light: '#42A5F5',
      // main: '#0530F9',
      main: '#0530F9',
      dark: '#0012db',
    },
    secondary: {
      light: '#FF4081',
      main: '#F50057',
      dark: '#AB003C',
    },
    error: {
      main: '#FF0000',
    },
    // contrastThreshold: 3,
    // tonalOffset: 0.2,
  },
  typography: {
    button: {
      textTransform: 'none',
    },
    fontFamily: "Inter, 'sans-serif'",
    fontSize: 14,
    lineHeight: 15,
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 14,
        // wordBreak: 'break-word',
      },
      popper: {
        pointerEvents: 'none',
      },
    },
    MuiRadio: {
      root: {
        color: 'black',
      },
    },
    MuiFormLabel: {
      root: {
        color: 'black !important',
        fontWeight: 'bold',
      },
    },
    MuiFormControlLabel: {
      root: {
        color: 'black',
      },
    },
    // MuiTooltip-popper
  },
};
