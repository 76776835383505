import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Button } from '@mui/material';

import withNotifications from 'components/withNotifications';

import './InviteUserOrDepartment.scss';
import './ComponentsCommonStyle.scss';
import languageMap from 'Language/Language';

const InviteUserOrDepartment = (props) => {
  const {
    titleText,
    addButtonText,
    cancelButtonText,
    userOrDepartment,
    setPopup,
    departmentId,
    returnValue,
    update,
    showError,
  } = props;

  const [inputValue, setInputValue] = useState('');
  const [listOfUsers, setListOfUsers] = useState([]);
  const [userDepartments, setUserDepartments] = useState([]);

  let { id: projectId } = useParams();
  projectId = Number(projectId);

  useEffect(() => {
    getUsers();
  }, []);

  const handleInputFieldChange = (e) => {
    setInputValue(e.target.value);
  };

  const onCancelClick = () => {
    setPopup(false);
    setInputValue('');
  };

  const validateIfEmail = (inputValue) => {
    return String(inputValue)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const getUsers = async () => {
    const response = await axios.get('/internal_api/user/get_project_users', {
      params: {
        project_id: projectId,
      },
    });

    let auxDataSet = response.data;
    let temporaryObject = {};
    let temporaryArray = [];
    temporaryObject.email = '';
    let arrayOfAvailableDepartments = [];

    for (let i = 0; i < auxDataSet?.length; i++) {
      arrayOfAvailableDepartments = auxDataSet[i]?.available_departments;

      if (arrayOfAvailableDepartments.includes(departmentId)) {
        temporaryArray.push({ email: auxDataSet[i]?.email, id: auxDataSet[i]?.id });
      }
    }
    setListOfUsers(temporaryArray);
  };

  const onConfirmClick = () => {
    if (userOrDepartment === 'addUserToDepartment') {
      const updateListOfDepartments = async () => {
        try {
          await axios
            .post('/internal_api/department/update_user_departments', {
              project_id: projectId,
              user_id: inputValue,
              department_ids: userDepartments,
            })
            .then(() => {
              update();
            });
        } catch (e) {
          console.log(e);
        }
      };

      updateListOfDepartments();

      setPopup(false);
      return;
    }
    if (!inputValue) {
      alert('Please enter a value');
    } else if (userOrDepartment === 'user' && !validateIfEmail(inputValue)) {
      alert('Please enter a valid email');
    } else if (userOrDepartment === 'user') {
      axios
        .post('/internal_api/user/invite_user', {
          project_id: projectId,
          user_email: inputValue,
        })
        .then((res) => {
          update();
          alert(res.data.text);
        })
        .catch((error) => {
          if (error.message === 'Request failed with status code 400') {
            showError('User already on the list', 'Error');
          } else {
            showError(error.message, 'Error');
          }
        });

      setPopup(false);
    } else if (userOrDepartment === 'department') {
      axios
        .post('/internal_api/department/add_department', {
          project_id: projectId,
          department_name: inputValue,
        })
        .then(() => {
          update();
        })
        .catch((err) => {
          console.log(err);
        });
      setPopup(false);
    } else if (userOrDepartment === 'editName') {
      returnValue(inputValue);
      axios
        .post('/internal_api/department/edit_department_name', {
          project_id: projectId,
          department_id: departmentId,
          department_name: inputValue,
        })
        .catch((err) => {
          console.log(err);
        });

      setPopup(false);
    } else {
      setPopup(false);
    }
  };

  const handeEnterPress = (e) => {
    if (e.key === 'Enter') {
      onConfirmClick();
    } else if (e.key === 'Escape') {
      onCancelClick();
    }
  };

  const addUserToDepartment = () => {
    const onSelection = (value) => {
      if (value) {
        setInputValue(value?.id);

        const getUserDepartments = async () => {
          const response = await axios.get('/internal_api/department/get_user_departments', {
            params: {
              project_id: projectId,
              user_id: value?.id,
            },
          });

          let auxDataSet = response.data;
          let tempArray = [];
          for (let i = 0; i < auxDataSet.length; i++) {
            tempArray.push(auxDataSet[i].department_id);
          }
          tempArray.push(departmentId);
          setUserDepartments(tempArray);
        };

        getUserDepartments();
      }
    };

    return (
      <Autocomplete
        className="autocomplete-component"
        id="searchable-dropdown"
        options={listOfUsers}
        getOptionLabel={(option) => option.email}
        onChange={(event, value) => {
          onSelection(value);
        }}
        ListboxProps={{
          popper: {
            sx: {
              zIndex: 99,
            },
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            className="text-field"
            label={languageMap.typeInUserEmail}
            variant="standard"
            open={true}
          />
        )}
      />
    );
  };

  return (
    <div className="InvitePopup">
      <div
        className="invite-popup-container"
        style={userOrDepartment === 'addUserToDepartment' ? { height: '200px' } : {}}
      >
        <div className="invite-popup-header title-text">{titleText}</div>
        <div className="invite-popup-email-input">
          {userOrDepartment === 'addUserToDepartment' && addUserToDepartment()}
          {userOrDepartment !== 'addUserToDepartment' && (
            <input
              className="input-componenet"
              onChange={handleInputFieldChange}
              onKeyDown={handeEnterPress}
              autoFocus
            />
          )}
        </div>
        <div className="invite-popup-buttons">
          <Button className="add-style blue-button-style" onClick={onConfirmClick}>
            {addButtonText}
          </Button>
          <Button className="cancel-style red-button-style" onClick={onCancelClick}>
            {cancelButtonText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withNotifications(InviteUserOrDepartment);
