import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { connect } from 'react-redux';
import { setLoading } from '../../store/global/actions';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { setMainMenu, updateProjectInfo } from 'store/crm/actions';
import {
  selectAllLanguages,
  selectMainMenu,
  selectProjectLanguage,
  selectSecondaryLanguages,
} from 'store/crm/selectors';
import languageMap from '../../../src/Language/Language';
import CameraAlt from '@mui/icons-material/CameraAlt';
import { Button, InputAdornment, TextField } from '@mui/material';
import useComponentDidMount from '../../utils/useComponentDidMount';
import '../CRM/Profile/UserProfile.scss';
import ProfilePhoto from '../CRM/Profile/ProfilePhoto';
import IconButton from '@mui/material/IconButton';
import { getToken } from '../../store/auth/selectors';
import Loader from '../../components/common/Loader';
import LanguagesEnum from './LanguagesEnum';
import DeleteConfirmationModal from '../../components/ConfirmationModal/DeleteConfirmationModal';
import { MAIN_COLOR } from '../../mainTheme';

const settingsContainer = {
  maxWidth: 800,
  borderBottom: '1px solid #C0C0C0',
  margin: '25px auto',
  boxShadow: '0px 0px 5px #aaaaaa',
  backgroundColor: 'white',
  borderRadius: 2,
};

const bigNameContainer = {
  marginBottom: 15,
  background: MAIN_COLOR,
  height: 400,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const containerStyle = {
  flexGrow: 1,
  overflow: 'auto',
  // display: 'flex',
  // flexDirection: 'column',
  alignItems: 'center',
};

const labelName = {
  display: 'flex',
  alignItems: 'center',
  marginTop: 30,
  color: '#FFF',
};

const ProjectSettings = (props) => {
  const [image, setImage] = useState(null);
  const [projectDetailsWereLoaded, setProjectDetailsWereLoaded] = useState(false);
  const [projectKey, setProjectKey] = useState('');
  const projectId = props.projectId;
  const [projectName, setProjectName] = useState('');
  const [description, setDescription] = useState('');
  const [assistantName, setAssistantName] = useState('');
  const [assistantUrl, setAssistantUrl] = useState('');
  const [showProjectKey, setShowProjectKey] = useState(false);
  const [secondaryLanguages, setSecondaryLanguages] = useState(props.secondaryLanguages);
  const [hasMultiLanguage, setHasMultiLanguage] = useState(false);
  const languages = props.languages;
  const filteredLanguages = languages.filter((lang) => lang.value !== props.projectLanguage);

  useComponentDidMount(() => {
    getDetails();
  });

  useEffect(() => {
    setSecondaryLanguages(props.secondaryLanguages);
  }, [props.secondaryLanguages]);

  const getDetails = () => {
    Axios.get('/internal_api/project/' + projectId).then((response) => {
      const { name, description, projectKey, assistant_name, assistant_image_url, features } =
        response.data;
      setProjectName(name);
      setDescription(description);
      setProjectKey(projectKey);
      setProjectDetailsWereLoaded(true);
      setAssistantName(assistant_name);
      setAssistantUrl(assistant_image_url);
      setHasMultiLanguage(features?.has_multilanguage);
    });
  };

  const updateProjectInfo = async () => {
    const { setLoading, updateProjectInfo } = props;
    setLoading(true);
    await updateProjectInfo({
      project_id: projectId,
      project_name: projectName,
      project_description: description,
      project_language: props.projectLanguage,
      assistant_image_url: assistantUrl,
      assistant_name: assistantName,
      secondary_languages: secondaryLanguages,
    });
    if (secondaryLanguages?.length !== props.secondaryLanguages?.length) {
      await Axios.post(`/internal_api/project/${projectId}/translate`);
    }
    setLoading(false);
  };

  const onDelete = () => {
    Axios.post('/internal_api/project/delete', {
      projectId,
    }).then(() => {
      window.location.replace('/projects');
    });
  };

  const onMacros = () => {
    if (props.mainMenu !== 'macros') {
      props.setMenu('macros');
    }
  };

  const onChangeImage = (e) => {
    const target = e.target;
    let files;
    e.preventDefault();
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
      if (target.value) {
        target.value = '';
      }
    };
    reader.readAsDataURL(files[0]);
  };

  const onChangeInfo = (onChange) => (event) => {
    onChange(event.target.value);
  };

  let generalSettings = (
    <div
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}
    >
      <Loader />
    </div>
  );

  if (projectDetailsWereLoaded) {
    generalSettings = (
      <div style={settingsContainer}>
        <div style={bigNameContainer}>
          <div className="ProfileCircle">
            {!!assistantUrl && <img className="ProfileImage" src={assistantUrl} alt="Profile" />}
            <CameraAlt
              className="ProfileCamera"
              style={{ display: !assistantUrl ? 'block' : 'none' }}
            />
            <label htmlFor="ProfilePhotoInput" className="ProfileImageLabel" />
          </div>

          <div className="title-text" style={labelName}>
            <CameraAlt style={{ width: 30, height: 30, marginRight: 10 }} />
            {languageMap.projectDetailsPhoto}
          </div>
        </div>

        <input
          id="ProfilePhotoInput"
          type="file"
          onChange={onChangeImage}
          accept=".gif, .jpg, .jpeg, .png"
        />
        {image && (
          <ProfilePhoto
            imageSrc={image}
            onClose={() => {
              setImage(null);
            }}
            token={props.token}
            onChangeImage={setAssistantUrl}
          />
        )}
        <div className="ProfileInputContainer">
          <div style={{ display: 'flex' }}>
            <TextField
              className="ProfileInput"
              label={languageMap.projectDetailsName}
              value={projectName}
              onChange={onChangeInfo(setProjectName)}
            />
            <TextField
              className="ProfileInput"
              label={languageMap.projectDetailsDescription}
              value={description}
              style={{ marginLeft: 30 }}
              onChange={onChangeInfo(setDescription)}
            />
          </div>
          <div style={{ display: 'flex', marginTop: 20 }}>
            <TextField
              label={languageMap.projectDetailsAssistantName}
              value={assistantName}
              style={{ width: 'calc(50% - 15px)' }}
              onChange={onChangeInfo(setAssistantName)}
            />

            <TextField
              className="ProfileInput"
              label={languageMap.projectDetailsProjectKey}
              value={showProjectKey ? projectKey : '************'}
              style={{ marginLeft: 30 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowProjectKey(!showProjectKey)} size="large">
                      {showProjectKey ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              readOnly
            />
          </div>

          <div style={{ display: 'flex', marginTop: 20 }}>
            <TextField
              className="regular-text ProfileLanguageSelect"
              label={languageMap.projectDetailsLanguage}
              value={
                languages.find((languageInfo) => languageInfo.value === props.projectLanguage).label
              }
            />

            <Button
              className="ProfileSaveButton"
              style={{ marginTop: 15, marginLeft: 25 }}
              variant="contained"
              color="primary"
              onClick={onMacros}
            >
              {languageMap.projectDetailsMacros}
            </Button>
          </div>

          {hasMultiLanguage && (
            <LanguagesEnum
              list={filteredLanguages}
              values={secondaryLanguages}
              onChange={setSecondaryLanguages}
            />
          )}

          <div className="ProfileLogout">
            <Button
              className="ProfileSaveButton"
              variant="contained"
              color="primary"
              onClick={updateProjectInfo}
            >
              {languageMap.profilePhotoSave}
            </Button>
            <DeleteConfirmationModal
              onDelete={onDelete}
              trigger={
                <Button variant="contained" color="secondary">
                  {languageMap.projectDetailsDelete}
                </Button>
              }
            />
          </div>
        </div>
      </div>
    );
  }
  return <div style={containerStyle}>{generalSettings}</div>;
};

const mapStateToProps = (state) => ({
  mainMenu: selectMainMenu(state),
  token: getToken(state),
  languages: selectAllLanguages(state),
  secondaryLanguages: selectSecondaryLanguages(state),
  projectLanguage: selectProjectLanguage(state),
});

const mapDispatchToProps = {
  setMenu: setMainMenu,
  setLoading,
  updateProjectInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSettings);
