import React, { useState } from 'react';
import { Input } from 'semantic-ui-react';
import FormGroup from 'components/common/FormGroup';
import FormErrors from './components/FormErrors';

import './HTMLBlock.scss';
import languageMap from 'Language/Language';

const HTMLBlock = ({ block, onBlockChange }) => {
  const [error, setError] = useState('');

  const dimensions = {
    height: {
      lowerLimit: 30,
      upperLimit: 300,
    },
    width: {
      lowerLimit: 50,
      upperLimit: 284,
    },
  };

  const validate = (fieldToChange, name) => {
    if (fieldToChange[name]) {
      const { lowerLimit, upperLimit } = dimensions[name];

      if (fieldToChange[name] < lowerLimit || fieldToChange[name] > upperLimit) {
        fieldToChange[name] = ``;
        setError(`${name} is not betweeen ${lowerLimit}px & ${upperLimit}px`);
      }
    }
  };

  const handleChange = (fieldToChange) => {
    setError('');
    validate(fieldToChange, 'height');
    validate(fieldToChange, 'width');
    onBlockChange({ ...block, ...fieldToChange });
  };

  const { url, html, width, height } = block;

  return (
    <div className="HTMLBlock">
      <FormGroup labelText="URL">
        <Input
          defaultValue={url}
          className="HTMLBlock_URL"
          onBlur={(e) => handleChange({ url: e.target.value })}
          disabled={!!html}
        />
      </FormGroup>
      <FormGroup labelText="HTML">
        <Input
          defaultValue={html}
          className="HTMLBlock_HTML"
          onBlur={(e) => handleChange({ html: e.target.value })}
          disabled={!!url}
        />
      </FormGroup>
      <div className="HTMLBlock_Dimensions">
        <FormGroup labelText={languageMap.width}>
          <Input
            placeholder={
              width || `${dimensions.width.lowerLimit}px - ${dimensions.width.upperLimit}px`
            }
            defaultValue={width}
            className="HTMLBlock_Width"
            onBlur={(e) => handleChange({ width: e.target.value })}
            required
            type="number"
            min={dimensions.width.lowerLimit}
            max={dimensions.width.upperLimit}
          />
        </FormGroup>
        <FormGroup labelText={languageMap.height}>
          <Input
            placeholder={
              height || `${dimensions.height.lowerLimit}px - ${dimensions.height.upperLimit}px`
            }
            defaultValue={height}
            className="HTMLBlock_Height"
            onBlur={(e) => handleChange({ height: e.target.value })}
            required
            type="number"
            min={dimensions.height.lowerLimit}
            max={dimensions.height.upperLimit}
          />
        </FormGroup>
        {error && <FormErrors errors={error} />}
      </div>
    </div>
  );
};

export default HTMLBlock;
