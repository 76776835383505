import React from 'react';

import { WhiteButton } from 'components/common/Button/Buttons';
import { useNavigate, useLocation } from 'react-router-dom';

import languageMap from 'Language/Language';
import './ConflictsWarning.scss';

const ConfilctsWarning = ({ setReminderDisplay, conflictsCounter }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onClick = (value) => {
    if (value === 'ignore') {
      setReminderDisplay(false);
    } else if (value === 'resolve') {
      navigate(location.pathname + '/qnaConflicts');
    }
  };
  return (
    <div className="DuplicateWarnings">
      <div className="warning-content">
        <span>{`${languageMap.confilctsHeader} ${conflictsCounter} ${languageMap.conflicts}. `}</span>
        <span>{languageMap.confilctsWarning}</span>
      </div>
      <div className="buttons-wrapper">
        <WhiteButton
          className="ignore-button"
          onClick={() => onClick('ignore')}
          text={languageMap.remindMeLater}
        />
        <WhiteButton
          className="resolve-button"
          onClick={() => onClick('resolve')}
          text={languageMap.resolve}
        />
      </div>
    </div>
  );
};

export default ConfilctsWarning;
