import React, { useRef } from 'react';
import {
  actionButtonStyle,
  imageCardActionAreaStyles,
  imageCardIframeStyle,
  imageCardStyles,
  imageContainerStyles,
} from './ImageCarouselCardStyles';
import useComponentDidMount from '../../../../../../../../../../../utils/useComponentDidMount';
import { MAIN_COLOR } from '../../../../../../../../../../../mainTheme';
import languageMap from 'Language/Language';

export default function ImageCarouselCard(props) {
  const { url, image, text, width, fallbackImage, actionButton } = props;
  const imageRef = useRef();
  const onClick = () => {
    window.open(url, '_blank');
  };
  useComponentDidMount(() => {
    const img = document.createElement('img');
    img.src = image;
    img.onerror = () => {
      if (imageRef?.current && fallbackImage) {
        imageRef.current.style.backgroundImage = `url(${fallbackImage})`;
      }
    };
  });
  const handleLoad = (event) => {
    const contentDocument = event.target?.contentDocument;
    if (!contentDocument) {
      return;
    }
    const { head } = contentDocument;
    contentDocument.addEventListener('click', onClick);
    head.insertAdjacentHTML(
      'beforeend',
      '<style type="text/css">html, body {cursor: pointer}</style>'
    );
  };
  return (
    <div style={imageCardStyles}>
      <div style={imageCardActionAreaStyles}>
        <div onClick={onClick}>
          <div ref={imageRef} style={imageContainerStyles(width, image)} />
          {!!text && (
            <iframe
              title="ImageCardParagraph"
              style={imageCardIframeStyle}
              srcDoc={text}
              onLoad={handleLoad}
            />
          )}
        </div>
        {actionButton && (
          <button style={actionButtonStyle(MAIN_COLOR)} onClick={onClick}>
            {languageMap.buy}
          </button>
        )}
      </div>
    </div>
  );
}
