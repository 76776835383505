import React, { useEffect } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import store from './store';
import { setIsMobile } from './store/crm/actions';

import { connect } from 'react-redux';
import { selectCurrentUser } from './store/crm/selectors';

import CurrentUserProvider from './containers/CurrentUser';
import ProjectList from './pages/ProjectList';
import UserProfile from 'pages/CRM/Profile/UserProfile';
import Login from './pages/Auth';
import CRM from './pages/CRM';
import { AnalyticsProvider } from './pages/Analytics/AnalyticsProvider';
import Flow from './pages/Flow';
import Register from './pages/Auth/Register';
import Confirm from './pages/Auth/Confirm';
import ConfirmInvitation from './pages/Auth/ConfirmInvitation';
import ForgotPassword from './pages/Auth/ForgotPassword';
import ResetPassword from './pages/Auth/ResetPassword';
import RequireAuth from './containers/CurrentUser/RequireAuth';
import RenderProjects from 'pages/ProjectList/components/RenderProjects';
import QnA from 'pages/QnA';
import QnAConflicts from 'pages/QnA/components/Conflicts/QnAConflicts';
import RetrainWrapper from './pages/QnA/RetrainWrapper';

const Wisevoice = ({ currentUser }) => {
  useEffect(() => {
    updateIsMobile();

    window.addEventListener('resize', updateIsMobile);
    return () => window.removeEventListener('resize', updateIsMobile);
  }, []);

  const updateIsMobile = () => {
    if (window.innerWidth > 600) {
      store.dispatch(setIsMobile(false));
    } else if (window.innerWidth <= 600) {
      store.dispatch(setIsMobile(true));
    }
  };
  return (
    <Router>
      <CurrentUserProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/confirm_email" element={<Confirm />} />
          <Route path="/confirm_invitation" element={<ConfirmInvitation />} />
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path="/reset_password" element={<ResetPassword />} />

          <Route
            path="/"
            element={
              <RequireAuth>
                <ProjectList />
              </RequireAuth>
            }
          >
            <Route path="projects" element={<RenderProjects currentUser={currentUser} />} />

            <Route path="profile" element={<UserProfile currentUser={currentUser} />} />
          </Route>
          <Route
            path="/project/:id/flow/:appId/:nodeId"
            element={
              <RequireAuth>
                <Flow />
              </RequireAuth>
            }
          />
          <Route
            path="/project/:id/qna/:appId/"
            element={
              <RequireAuth>
                <RetrainWrapper component={QnA} />
              </RequireAuth>
            }
          />

          <Route
            path="/project/:id/qna/:appId/qnaConflicts"
            element={
              <RequireAuth>
                <RetrainWrapper component={QnAConflicts} />
              </RequireAuth>
            }
          />

          <Route
            path="/project/:id/analytics/:tab/:selectedRange/:selectedTimeInterval/:timezone"
            // http://localhost:9000/project/298/analytics/Overview/1679223270217-1681898070218/1d
            element={
              <RequireAuth>
                <AnalyticsProvider />
              </RequireAuth>
            }
          />
          <Route
            path="/project/:id/crm/:menu/:conversation"
            element={
              <RequireAuth>
                <CRM />
              </RequireAuth>
            }
          />
          <Route
            path="/project/:id/crm/:menu"
            element={
              <RequireAuth>
                <CRM />
              </RequireAuth>
            }
          />

          <Route index element={<RequireAuth>{<Navigate to="/projects" replace />}</RequireAuth>} />
          <Route
            path="*"
            element={<RequireAuth>{<Navigate to="/projects" replace />}</RequireAuth>}
          />
        </Routes>
      </CurrentUserProvider>
    </Router>
  );
};

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
});

export default connect(mapStateToProps)(Wisevoice);
