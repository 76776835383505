import React from 'react';
import { connect } from 'react-redux';

import MenuTabsComponent from 'components/MenuTabsComponent';

import { selectReportsTab } from 'store/crm/selectors';

import './SubmenuReportsContainer.scss';
import { setReportsTab } from '../../store/crm/actions';

const SubmenuReportsContainer = ({ reportsTab, setReports, collapsed, ...props }) => {
  const properties = {
    headerTitle: props.headerTitle,
    sections: props.sections,
  };

  return (
    <div className="SubmenuReportsContainer">
      <MenuTabsComponent
        structure={properties}
        tab={reportsTab}
        setTab={setReports}
        currentMenu={'reports'}
        collapsed={collapsed}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  reportsTab: selectReportsTab(state),
});

const mapDispatchToProps = {
  setReports: setReportsTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmenuReportsContainer);
