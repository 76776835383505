export const containedConversationsData = (state) => state.analytics.containedConversationsData;
export const containedConversationsLabels = (state) => state.analytics.containedConversationsLabels;
export const containedConversationsTitle = (state) => state.analytics.containedConversationsTitle;

export const getConversationsGraph = (state) => state.analytics.conversationsGraph;
export const getUniqueUsers = (state) => state.analytics.uniqueUsers;
export const getRepliesSent = (state) => state.analytics.repliesSent;
export const getBusinessHourConversation = (state) => state.analytics.businessHourConversation;
export const getConversationsByBreakdownChannel = (state) =>
  state.analytics.conversationsByBreakdownChannel;
export const getConversationsByBreakdownLanguage = (state) =>
  state.analytics.conversationsByBreakdownLanguage;
export const getCustomerSatisfactionScore = (state) => state.analytics.customerSatisfactionScore;
export const getInboundConversations = (state) => state.analytics.inboundConversations;
export const getContainmentGraph = (state) => state.analytics.containmentGraph;
export const getReactionsGraph = (state) => state.analytics.reactionsGraph;
export const getHeatMapData = (state) => state.analytics.heatMapData;
export const getDialogType = (state) => state.analytics.dialogType;
export const getIntents = (state) => state.analytics.intents;
export const getExcludedIntents = (state) => state.analytics.excludedIntents;
export const getTopAnswers = (state) => state.analytics.topAnswers;
export const getTopAnswersByHandoff = (state) => state.analytics.topAnswersByHandoff;
export const getWiseAgentRating = (state) => state.analytics.wiseAgentRating;
export const getGraphContainmentRateByBreakdownChannelId = (state) =>
  state.analytics.graphContainmentRateByBreakdownChannelId;
export const getGraphContainmentRateByBreakdownLanguage = (state) =>
  state.analytics.graphContainmentRateByBreakdownLanguage;
export const getGraphWiseAgentRatingByBreakdownChannelId = (state) =>
  state.analytics.graphWiseAgentRatingByBreakdownChannelId;
export const getGraphWiseAgentRatingByBreakdownLanguage = (state) =>
  state.analytics.graphWiseAgentRatingByBreakdownLanguage;
export const getDetectionRate = (state) => state.analytics.detectionRate;

export const getAverageResponseTime = (state) => state.analytics.averageResponseTime;
export const getAgentPerformance = (state) => state.analytics.agentPerformance;
export const getConversationsByBreakdown = (state) => state.analytics.conversationsByBreakdown;
export const getHeatMapTeamPerformance = (state) => state.analytics.heatMapTeamPerformance;

export const getConversationRating = (state) => state.analytics.conversationRatings;
export const getTableFeedback = (state) => state.analytics.tableFeedback;
export const getRemarks = (state) => state.analytics.remarks;
export const getFeedbackScore = (state) => state.analytics.feedbackScore;
