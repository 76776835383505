import React from 'react';
import './DocumentMessageStyles.scss';

export default function DocumentMessage(props) {
  const { fileUrl } = props;

  const download = (url, fileName) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div
      className={'DocumentOutgoingMessage'}
      onClick={() => download(fileUrl, fileUrl.slice(fileUrl.lastIndexOf('/') + 1))}
    >
      <img
        className={'document-outgoing-file-message-img'}
        src="https://img.icons8.com/material-outlined/24/000000/document--v1.png"
        alt="Document File Message"
      />
      <span className="DocumentFileMessage_Span">Download file</span>
    </div>
  );
}
