import * as Sentry from '@sentry/react';
import axios from 'axios';

export const uploadFile = async (
  file,
  projectId,
  token,
  fileName = null,
  scope = null,
  language = null
) => {
  try {
    let data = new FormData();

    if (!fileName) {
      fileName = file.name;
    }

    data.append('file', file, fileName);
    if (language) {
      data.append('language', language);
    }
    if (scope) {
      data.append('scope', scope);
    }

    return await axios.post('/internal_api/upload_file/' + projectId, data, {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    Sentry.captureException(`FAILURE - UPLOAD FILE - ${error}`);
  }
};
