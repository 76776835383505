import React, { Component } from 'react';
import MessageRenderer from '../MessageRenderer';

import './BlocksIncomingMessage.scss';

export default class BlocksIncomingMessage extends Component {
  render() {
    const { message, mouseHoverProps, children, ...props } = this.props;
    const { content: messageContent, meta } = message;
    const { message_block_type } = meta;

    return messageContent ? (
      <div className={`BlocksIncomingMessage ${message_block_type}`} {...mouseHoverProps}>
        {children}
        <MessageRenderer message={message} {...props} isFromUser={true} />
      </div>
    ) : null;
  }
}
