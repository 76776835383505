import React, { useState } from 'react';
import './ConflictsWrapper.scss';

import './ConflictsWrapper.scss';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';

import { deleteEntry, ignoreConflict } from 'store/qna/actions';
import languageMap from 'Language/Language';
import DeleteConfirmationModal from 'components/ConfirmationModal/DeleteConfirmationModal';

const ConflictsWrapper = ({
  children,
  deleteEntry,
  ignoreConflict,
  appId_1,
  projectId,
  message_1_id,
  intentId_1,
  intent_title_1,
  appId_2,
  message_2_id,
  intentId_2,
  intent_title_2,
  setTrigger,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleIgnoreConflict = () => {
    setIsLoading(true);

    ignoreConflict({
      projectId,
      intentId_1,
      intentId_2,
    }).then(() => {
      setTrigger((prev) => !prev);
      setIsLoading(false);
    });
  };

  const deleteSelectedEntry = (message_id, intent_id, appId) => {
    setIsLoading(true);
    deleteEntry({
      message_id: message_id,
      intent_id: intent_id,
      app_id: appId,
    }).then(() => {
      setTrigger((prev) => !prev);
      setIsLoading(false);
    });
  };

  const muiButtonStyle = {
    fontSize: '18px',
    boxShadow: 'none',
    color: 'white',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      color: 'rgb(174, 73, 91)',
    },
  };

  const RenderDeleteButton1 = (props) => {
    return (
      <>
        <Button
          className="text-button "
          sx={muiButtonStyle}
          onClick={props.onClick}
          disabled={isLoading}
        >
          {`${languageMap.delete} "${intent_title_1}"`}
        </Button>
        <div style={{ color: 'white' }}>|</div>
      </>
    );
  };

  const RenderDeleteButton2 = (props) => {
    return (
      <>
        <Button
          className="text-button "
          sx={muiButtonStyle}
          onClick={props.onClick}
          disabled={isLoading}
        >
          {`${languageMap.delete} "${intent_title_2}"`}
        </Button>
        <div style={{ color: 'white' }}>|</div>
      </>
    );
  };

  return (
    <div className="ConflictsWrapper">
      <div className="text-buttons">
        <DeleteConfirmationModal
          trigger={<RenderDeleteButton1 />}
          onDelete={() => deleteSelectedEntry(message_1_id, intentId_1, appId_1)}
          text={languageMap.deleteEntryWarning}
          buttonTextConfirm={languageMap.confirm}
          buttonTextCancel={languageMap.cancel}
          deleteEntryWarning={languageMap.deleteEntryWarning}
        />
        <DeleteConfirmationModal
          trigger={<RenderDeleteButton2 />}
          onDelete={() => deleteSelectedEntry(message_2_id, intentId_2, appId_2)}
          text={languageMap.deleteEntryWarning}
          buttonTextConfirm={languageMap.confirm}
          buttonTextCancel={languageMap.cancel}
          deleteEntryWarning={languageMap.deleteEntryWarning}
        />

        <Button
          className="text-button "
          sx={muiButtonStyle}
          onClick={handleIgnoreConflict}
          disabled={isLoading}
        >
          {languageMap.ignore}
        </Button>
      </div>
      <div className="chilren-wrapper">{children}</div>
    </div>
  );
};

const mapDispatchToProps = {
  deleteEntry,
  ignoreConflict,
};

export default connect(null, mapDispatchToProps)(ConflictsWrapper);
