import React, { Component } from 'react';

import Dialog from 'pages/Flow/components/Dialog';
import NameInput from 'pages/Flow/components/NameInput';
import ConnectionsWrapper from 'pages/Flow/containers/ConnectionsWrapper';
import DeleteButton from '../components/DeleteButton';

import { ThemeContext } from '../../../../../../utils/Theme';

export default class FlowConnectorDialog extends Component {
  static contextType = ThemeContext;

  deleteNode = () => {
    this.props.onClose();
    this.props.deleteNode(this.props.nodeId);
  };

  render() {
    const contentviewStyle = {
      padding: 30,
    };

    return (
      <Dialog
        onClose={this.props.onClose}
        headerView={
          <>
            <NameInput
              appId={this.props.appId}
              id={this.props.objectId}
              type="flow_connector"
              nodeId={this.props.nodeId}
              onNameChange={this.props.onNameChange}
              value={this.props.objectName}
            />
            <DeleteButton
              title="Delete Connector"
              type="child-connector"
              onClick={this.deleteNode}
            />
          </>
        }
        contentView={
          <div style={contentviewStyle}>
            <ConnectionsWrapper nodeId={this.props.nodeId} />
          </div>
        }
      />
    );
  }
}
