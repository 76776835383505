import React, { useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Checkbox } from 'semantic-ui-react';

import { selectCurrentUser } from 'store/crm/selectors';
import { setSubMenu } from 'store/crm/actions';

import './UserManagement.scss';
import './ComponentsCommonStyle.scss';
import languageMap from 'Language/Language';
import allPermissions, { PERMISSION_MANAGE_GENAI, PERMISSION_PREFIX } from '../AllPermissions';
import useComponentDidMount from '../../../utils/useComponentDidMount';

const UserManagement = (props) => {
  const { setSubMenu, managedUser, currentUser } = props;

  const [listOfAllPermissions, setListOfAllPermissions] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);
  const [listOfAllDepartments, setListOfAllDepartments] = useState([]);
  const [userDepartments, setUserDepartments] = useState([]);

  let { id: projectId } = useParams();
  projectId = Number(projectId);

  useComponentDidMount(() => {
    const getUserPermissions = async () => {
      const response = await axios.get('/internal_api/permission/get_user_permissions', {
        params: {
          project_id: projectId,
          user_id: managedUser.id,
        },
      });

      let data = response.data;
      let userPermissions = {};
      data.forEach((key) => {
        userPermissions[key] = true;
      });
      setUserPermissions(userPermissions);
    };
    const getUserDepartments = async () => {
      const response = await axios.get('/internal_api/department/get_user_departments', {
        params: {
          project_id: projectId,
          user_id: managedUser.id,
        },
      });

      let data = response.data;
      let userDepartments = {};
      for (let i = 0; i < data.length; i++) {
        userDepartments[data[i].department_id] = data[i].department_name;
      }
      setUserDepartments(userDepartments);
    };

    const getPermissionLabels = () => {
      let labels = {};
      for (let id of allPermissions) {
        if (id !== PERMISSION_MANAGE_GENAI) {
          labels[id] = languageMap[PERMISSION_PREFIX + id];
        } else if (currentUser?.features[projectId]?.has_genai) {
          labels[id] = languageMap[PERMISSION_PREFIX + id];
        }
      }

      setListOfAllPermissions(labels);
    };

    const getDepartments = async () => {
      const response = await axios.get('/internal_api/department/get_departments', {
        params: { project_id: projectId },
      });

      let dataDepartments = response.data.departments;
      let allDepartments = {};
      for (let i = 0; i < dataDepartments.length; i++) {
        allDepartments[dataDepartments[i].department_id] = dataDepartments[i].department_name;
      }
      setListOfAllDepartments(allDepartments);
    };

    getUserDepartments();
    getUserPermissions();
    getPermissionLabels();
    getDepartments();
  });

  const setUsersSubMenu = () => {
    setSubMenu('users');
  };

  const checkIfIsInPermissions = (key) => {
    return !!userPermissions[key];
  };

  const checkIfIsInDepartments = (key) => {
    return !!userDepartments[key];
  };

  const handlePermissionChange = (key) => {
    if (checkIfIsInPermissions(key)) {
      let tempObject = { ...userPermissions };
      delete tempObject[key];
      setUserPermissions(tempObject);
    } else {
      let tempObject = { ...userPermissions };
      tempObject[key] = listOfAllPermissions[key];
      setUserPermissions(tempObject);
    }
  };

  const handleDepartmentChange = (key) => {
    if (checkIfIsInDepartments(key)) {
      let tempObject = { ...userDepartments };
      delete tempObject[key];
      setUserDepartments(tempObject);
    } else {
      let tempObject = { ...userDepartments };
      tempObject[key] = listOfAllDepartments[key];
      setUserDepartments(tempObject);
    }
  };

  const updateAll = () => {
    const updateListOfPermissions = async () => {
      try {
        const listOfPermissionIds = [];
        for (let key in userPermissions) {
          listOfPermissionIds.push(Number(key));
        }
        await axios.post('/internal_api/permission/update_user_permissions', {
          project_id: projectId,
          user_id: managedUser.id,
          permission_ids: listOfPermissionIds,
        });
      } catch (e) {
        console.log(e);
      }
    };

    const updateListOfDepartments = async () => {
      try {
        const listOfDepartmentsIds = [];
        for (let key in userDepartments) {
          listOfDepartmentsIds.push(Number(key));
        }
        await axios.post('/internal_api/department/update_user_departments', {
          project_id: projectId,
          user_id: managedUser.id,
          department_ids: listOfDepartmentsIds,
        });
        alert('Updated successfully');
      } catch (e) {
        console.log(e);
      }
    };

    updateListOfPermissions();
    updateListOfDepartments();
  };

  return (
    <div className="UserManagement">
      <div className="manage-users-title-wrapper">
        <Button className="back-button blue-button-style" onClick={setUsersSubMenu}>
          <ArrowBackIcon> </ArrowBackIcon>
        </Button>
        <div className="header dm-sans-500 title-text">
          {`${managedUser?.first_name} ${managedUser?.name}` || `${managedUser?.email}`}
        </div>
      </div>
      <div className="content-wrapper">
        <div className="content-container">
          <div className="content-header dm-sans-700">{languageMap.permissions}</div>
          <div className="content-list">
            <div
              style={{
                flexGrow: 1,
                padding: 20,
                paddingBottom: 30,
                margin: 'auto',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
              }}
            >
              {Object.keys(listOfAllPermissions).length &&
                Object.keys(listOfAllPermissions).map((key) => (
                  <Checkbox
                    key={key}
                    style={{ fontSize: 16, marginTop: 20 }}
                    label={listOfAllPermissions[key]}
                    checked={checkIfIsInPermissions(key)}
                    onClick={() => handlePermissionChange(key)}
                  />
                ))}
            </div>
          </div>
        </div>
        <div className="content-container">
          <div className="content-header dm-sans-700 ">{languageMap.departments}</div>
          <div className="contentcontent-list">
            <div
              style={{
                flexGrow: 1,
                padding: 20,
                paddingBottom: 30,
                margin: 'auto',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
              }}
            >
              {Object.keys(listOfAllDepartments).length &&
                Object.keys(listOfAllDepartments).map((key) => (
                  <Checkbox
                    key={key}
                    style={{ fontSize: 16, marginTop: 20 }}
                    label={listOfAllDepartments[key]}
                    checked={checkIfIsInDepartments(key)}
                    onClick={() => handleDepartmentChange(key)}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="button-update-container">
        <Button className="update-button blue-button-style" onClick={updateAll}>
          {languageMap.update}
        </Button>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
});
const mapDispatchToProps = {
  setSubMenu: setSubMenu,
};
export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
