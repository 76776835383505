import * as React from 'react';
import { useRef } from 'react';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import './DatePicker.scss';
import useComponentDidMount from '../../../utils/useComponentDidMount';
import languageMap from 'Language/Language';

const DatePicker = window.daterangepicker;
const moment = window.moment;

export default function BasicDateRangePicker({ start, end, onChange }) {
  const datePickerRef = useRef();

  useComponentDidMount(
    () => {
      // eslint-disable-next-line no-new
      new DatePicker(
        datePickerRef.current,
        {
          startDate: start,
          endDate: end,

          ranges: {
            [languageMap.last7Days]: [moment().subtract(6, 'days'), moment()],
            [languageMap.last30Days]: [moment().subtract(29, 'days'), moment()],
            [languageMap.thisMonth]: [moment().startOf('month'), moment().endOf('month')],
            [languageMap.last12Months]: [moment().subtract(1, 'year').startOf('year'), moment()],
          },
          translations: {
            customRange: languageMap.customRange,
            cancel: languageMap.cancelDate,
            apply: languageMap.apply,
          },
        },
        onChange
      );
    },
    () => {
      const datePickerDropdown = document.querySelector('.daterangepicker');
      datePickerDropdown?.remove();
    }
  );

  return (
    <div id="reportrange" ref={datePickerRef} className="DatePicker">
      &nbsp;
      <span className="text">
        {start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY')}
      </span>
      &nbsp; &nbsp;
      <ArrowDropDown />
    </div>
  );
}
