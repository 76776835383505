import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import ConversationCard from '../ConversationCard';

import './ConversationLoader.scss';

const ConversationLoader = ({ loading }) => {
  return (
    <div className="ConversationLoader">
      {loading && (
        <ConversationCard>
          <div className="conversation-loader-circle">
            <CircularProgress />
          </div>
        </ConversationCard>
      )}
    </div>
  );
};

export default ConversationLoader;
