import React from 'react';

const AttachFile = (props) => (
  <svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.7125 0.50002L5.5925 4.38002L9.4725 0.50002C9.8625 0.11002 10.4925 0.11002 10.8825 0.50002C11.2725 0.89002 11.2725 1.52002 10.8825 1.91002L6.2925 6.50002C5.9025 6.89002 5.2725 6.89002 4.8825 6.50002L0.2925 1.91002C-0.0975 1.52002 -0.0975 0.89002 0.2925 0.50002C0.6825 0.12002 1.3225 0.11002 1.7125 0.50002Z"
      fill="#292D32"
    />
  </svg>
);

export default AttachFile;
