import React from 'react';

const SmallNewLogo = (props) => (
  <svg
    width="43"
    height="43"
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.4026 42.8053C33.223 42.8053 42.8053 33.223 42.8053 21.4026C42.8053 9.58229 33.223 0 21.4026 0C9.58228 0 0 9.58229 0 21.4026C0 33.223 9.58228 42.8053 21.4026 42.8053Z"
      fill="url(#paint0_linear_636_1396)"
    />
    <mask
      id="mask0_636_1396"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="43"
      height="43"
    >
      <path
        d="M21.4026 42.8053C33.223 42.8053 42.8053 33.223 42.8053 21.4026C42.8053 9.58229 33.223 0 21.4026 0C9.58228 0 0 9.58229 0 21.4026C0 33.223 9.58228 42.8053 21.4026 42.8053Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_636_1396)">
      <path
        d="M71.5093 13.74C78.3224 1.93946 74.6447 -12.9388 63.295 -19.4916C51.9453 -26.0444 37.2214 -21.7902 30.4084 -9.98964C23.5953 1.81091 27.273 16.6892 38.6227 23.242C49.9725 29.7947 64.6963 25.5406 71.5093 13.74Z"
        fill="url(#paint1_linear_636_1396)"
      />
    </g>
    <mask
      id="mask1_636_1396"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="43"
      height="43"
    >
      <path
        d="M21.4026 42.8053C33.223 42.8053 42.8053 33.223 42.8053 21.4026C42.8053 9.58229 33.223 0 21.4026 0C9.58228 0 0 9.58229 0 21.4026C0 33.223 9.58228 42.8053 21.4026 42.8053Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_636_1396)">
      <path
        d="M16.3565 54.3787C22.9418 42.9726 18.714 28.2031 6.91347 21.39C-4.88708 14.577 -19.7917 18.3004 -26.377 29.7064C-32.9623 41.1125 -28.7345 55.882 -16.934 62.6951C-5.13346 69.5081 9.77121 65.7847 16.3565 54.3787Z"
        fill="url(#paint2_linear_636_1396)"
      />
      <path
        d="M7.90041 17.1493L15.1524 29.5738C15.8181 30.7108 17.4559 30.7049 18.1157 29.5679L20.0362 26.2394C20.3426 25.7091 20.3426 25.0611 20.0362 24.5309L14.8343 15.4349C14.528 14.8988 13.9624 14.5748 13.3497 14.5748H9.37909C8.05358 14.5748 7.2347 16.0123 7.90041 17.1493Z"
        fill="white"
      />
      <path
        d="M17.6914 17.1493L24.9435 29.5738C25.6092 30.7108 27.2469 30.7049 27.9067 29.5679L29.8272 26.2394C30.1336 25.7092 30.1336 25.0612 29.8272 24.531L24.6253 15.435C24.319 14.8989 23.7534 14.5749 23.1408 14.5749H19.1701C17.8446 14.5749 17.0257 16.0123 17.6914 17.1493Z"
        fill="white"
      />
      <path
        d="M27.547 17.1317C28.2716 18.41 28.9962 19.6884 29.7208 20.9727C30.3747 22.1274 32.0361 22.1333 32.6959 20.9845C33.4323 19.7061 34.1687 18.4277 34.9051 17.1434C35.5649 16.0006 34.7401 14.5749 33.4205 14.5749H29.0374C27.7237 14.5749 26.8989 15.9888 27.547 17.1317Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_636_1396"
        x1="-1.26071"
        y1="41.5033"
        x2="46.4166"
        y2="-0.777633"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.05" stopColor="#00CCFF" />
        <stop offset="1" stopColor="#0600F6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_636_1396"
        x1="61.8615"
        y1="33.8948"
        x2="26.1828"
        y2="-25.6283"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.05" stopColor="#00CCFF" />
        <stop offset="0.81" stopColor="#051CF7" />
        <stop offset="1" stopColor="#0600F6" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_636_1396"
        x1="-26.4571"
        y1="29.8451"
        x2="16.2765"
        y2="54.5173"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.05" stopColor="#00CCFF" />
        <stop offset="1" stopColor="#0600F6" />
      </linearGradient>
    </defs>
  </svg>
);

export default SmallNewLogo;
