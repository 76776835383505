import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box } from '@mui/material';
import Collection from '../../../Collections/Collection';
import languageMap from 'Language/Language';
import {
  COLLECTION_ENTITIES,
  COLLECTION_ENTITY_TYPES,
  COLLECTION_INTENTS,
  COLLECTION_MESSAGES,
  COLLECTION_SCRIPTS,
  COLLECTION_SERVICES,
} from '../../../../utils/constants';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const collections = [
  { type: COLLECTION_INTENTS, label: languageMap.intents },
  { type: COLLECTION_ENTITIES, label: languageMap.entities },
  { type: COLLECTION_ENTITY_TYPES, label: languageMap.entityTypes },
  { type: COLLECTION_MESSAGES, label: languageMap.messages },
  { type: COLLECTION_SERVICES, label: languageMap.services },
  { type: COLLECTION_SCRIPTS, label: languageMap.scripts },
];
const CollectionsEntry = ({ id }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div style={{ marginRight: 15, marginLeft: 15, height: '100%' }}>
      <div style={{ maxWidth: 1024, margin: 'auto', height: '100%' }}>
        <Box style={{ borderBottom: 1, borderColor: '#334bfa', marginTop: 20 }}>
          <Tabs indicatorColor="primary" value={value} onChange={handleChange}>
            {collections.map((collection, index) => (
              <Tab key={collection.label} label={collection.label} {...a11yProps(index)} />
            ))}
          </Tabs>
        </Box>
        {collections.map((collection, index) => {
          if (value === index) {
            return (
              <div
                key={collection.type}
                style={{
                  height: 'calc(100% - 93px)',
                  position: 'relative',
                }}
              >
                <Collection projectId={id} collection={collection.type} />
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
};

export default CollectionsEntry;
