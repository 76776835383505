import React from 'react';

const Cancel = (props) => (
  <svg
    style={{ marginLeft: '2px', flex: '0 0 auto', cursor: 'pointer' }}
    width="14px"
    height="14px"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="close-cookie" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M7,0 C7.55228475,-1.01453063e-16 8,0.44771525 8,1 L8,6 L13,6 C13.5522847,6 14,6.44771525 14,7 C14,7.55228475 13.5522847,8 13,8 L8,8 L8,13 C8,13.5522847 7.55228475,14 7,14 C6.44771525,14 6,13.5522847 6,13 L6,8 L1,8 C0.44771525,8 0,7.55228475 0,7 C0,6.44771525 0.44771525,6 1,6 L6,6 L6,1 C6,0.44771525 6.44771525,1.01453063e-16 7,0 Z"
        id="Combined-Shape"
        fill="#000000"
        transform="translate(7.000000, 7.000000) rotate(45.000000) translate(-7.000000, -7.000000) "
      />
    </g>
  </svg>
);

export default Cancel;
