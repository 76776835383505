import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import autobind from 'auto-bind';

import VerticalOrderedListElement from '../VerticalOrderedListElement';
import { AddButton } from '../../../../../components/common/Button';
import AddLinkDialog from 'pages/QnA/components/AddLinkDialog';

import _ from 'lodash';
import './VerticalOrderedList.scss';
import { uuid4 } from '@sentry/utils';
import { connect } from 'react-redux';
import { deleteLink, updateLinkText, reorderLinks } from 'store/qna/actions';

class VerticalOrderedList extends Component {
  constructor(props) {
    super(props);
    autobind(this);
    this.state = {
      elements: this.addUUIDsToElements(),
      showQnaDialog: false,
    };
  }

  handleChange = (elements) => {
    if (this.props.onChange) {
      this.props.onChange(elements);
    }
  };

  componentDidUpdate(prevProps) {
    const { isQna, elements = [] } = this.props;
    if (isQna && elements.length !== prevProps.elements?.length) {
      this.setState({ elements: this.addUUIDsToElements() });
    }
    if (prevProps.selectedLanguage !== this.props.selectedLanguage) {
      this.setState({ elements: this.addUUIDsToElements() });
    }
  }

  addUUIDsToElements = () => {
    const { isQna, elements = [], selectedLanguage } = this.props;
    if (isQna) {
      return elements.map((element) => ({
        destination_node_id: element.destination_node_id,
        link_text_by_language: element.link_text_by_language,
        value: element.link_text_by_language ? element.link_text_by_language[selectedLanguage] : '',
        id: uuid4(),
      }));
    }

    return elements.map((element) => ({
      value: element,
      id: uuid4(),
    }));
  };

  removeUUIDsFromElements = (elements) => {
    if (this.props.isQna) {
      return elements.map((element) => {
        element = _.clone(element);
        delete element.id;
        return element;
      });
    }

    return elements.map((item) => item.value);
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd(result) {
    const { elements } = this.state;
    if (!result.destination) {
      return;
    }

    const reorderedOptions = this.reorder(elements, result.source.index, result.destination.index);

    this.setState({ elements: reorderedOptions });
    const noIdElements = this.removeUUIDsFromElements(reorderedOptions);
    this.handleChange(noIdElements);

    if (this.props.isQna) {
      this.props.reorderLinks({
        source_node_id: this.props.sourceNodeId,
        elements: JSON.stringify(reorderedOptions),
      });
    }
  }

  updateQnaLinkText = _.debounce((index, value) => {
    const { sourceNodeId, selectedLanguage, updateLinkText, isProjectLanguage } = this.props;
    const params = {
      source_node_id: sourceNodeId,
      destination_node_id: this.state.elements[index].destination_node_id,
      link_text_by_language: {
        ...this.state.elements[index].link_text_by_language,
        [selectedLanguage]: value,
      },
      doTranslate: isProjectLanguage,
    };

    updateLinkText(params);
  }, 300);

  onValueChanged = (index, value) => {
    if (this.props.isQna) {
      this.updateQnaLinkText(index, value);
    }

    let newElements = [...this.state.elements];
    newElements[index].value = value;
    this.setState({ elements: newElements });
    const noIdElements = this.removeUUIDsFromElements(newElements);
    this.handleChange(noIdElements);
  };

  addElement = () => {
    if (this.props.isQna) {
      this.setState({ showQnaDialog: true });
      return;
    }

    let newElements = [...this.state.elements];
    newElements.splice(0, 0, { value: '', id: uuid4() });
    this.setState({ elements: newElements });
    const noIdElements = this.removeUUIDsFromElements(newElements);
    this.handleChange(noIdElements);
  };

  addQnaElement = (linkText, destinationNodeId) => {
    let newElement = {
      value: linkText,
      destination_node_id: destinationNodeId,
      id: uuid4(),
    };

    let newElements = [...this.state.elements];
    newElements.splice(0, 0, newElement);
    this.setState({ elements: newElements });
  };

  onRemoved = (index) => () => {
    if (this.props.isQna) {
      const params = {
        destination_node_id: this.state.elements[index].destination_node_id,
        source_node_id: this.props.sourceNodeId,
      };

      this.props.deleteLink(params);
    }

    let newElements = [...this.state.elements];
    newElements.splice(index, 1);
    this.setState({ elements: newElements });
    const noIdElements = this.removeUUIDsFromElements(newElements);

    this.handleChange(noIdElements);
  };

  renderListElements() {
    const { isQna, isProjectLanguage, style, title } = this.props;
    return (
      <div className="VerticalOrderedList" style={style}>
        <div className="title regular-text"> {title} </div>

        <div className="container-style">
          <AddButton
            style={{
              alignSelf: 'center',
              marginRight: '8px',
              minWidth: 26,
              padding: 2,
            }}
            disabled={isQna && !isProjectLanguage}
            onClick={this.addElement}
            icon="add"
          />

          <div className="element-container-style">
            {this.state.elements.map((element, index) => (
              <Draggable key={element.id} draggableId={`id-${element.id}`} index={index}>
                {(provided, snapshot) => {
                  return (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <VerticalOrderedListElement
                        key={element.id}
                        index={index}
                        value={element.value}
                        onValueChanged={this.onValueChanged}
                        onRemoved={isQna && !isProjectLanguage ? null : this.onRemoved(index)}
                        options={this.props.options}
                      />
                    </div>
                  );
                }}
              </Draggable>
            ))}
          </div>
        </div>
      </div>
    );
  }

  hideQnaDialog = () => {
    this.setState({ showQnaDialog: false });
  };

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => {
            return (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {this.state.showQnaDialog && (
                  <AddLinkDialog
                    hide={this.hideQnaDialog}
                    sourceNodeId={this.props.sourceNodeId}
                    existingLinks={this.state.elements}
                    addQnaElement={this.addQnaElement}
                  />
                )}
                {this.renderListElements()}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default connect(null, { deleteLink, updateLinkText, reorderLinks })(VerticalOrderedList);
