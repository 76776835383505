import React, { Component } from 'react';

import RemovableInputElement from '../../../../../components/common/RemovableInputElement';

import './VerticalOrderedListElement.scss';

export default class VerticalOrderedListElement extends Component {
  onValueChanged = (index, value) => {
    this.props.onValueChanged(index, value);
  };

  render() {
    const elementStyle = {
      width: '149px',
      height: '32px',
      borderRadius: '10px',
      color: 'white',
      paddingLeft: '5px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flex: '0 0 auto',
    };

    return (
      <div className="VerticalOrderedListElement">
        <RemovableInputElement
          value={this.props.value}
          style={elementStyle}
          options={this.props.options}
          onValueChanged={(value) => this.onValueChanged(this.props.index, value)}
          onRemoved={this.props.onRemoved}
        />
      </div>
    );
  }
}
