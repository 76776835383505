import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import Table from 'components/Table';
import { Button } from '@mui/material';

import InviteUserOrDepartment from './InviteUserOrDepartment';
import PasswordRecovery from './PasswordRecovery';

import { selectCurrentUser } from 'store/crm/selectors';
import { setMainMenu, setSubMenu } from 'store/crm/actions';
import { setUserName } from './ComponentsCommonFunctions';

import './Users.scss';
import './ComponentsCommonStyle.scss';
import languageMap from 'Language/Language';

const Users = (props) => {
  const { setSubMenu, setManagedUser, currentUser } = props;
  const [inviteUsersPopup, setInviteUsersPopup] = useState(false);
  const [passwordResetPopup, setPasswordResetPopup] = useState(false);
  const [passwordResetUser, setPasswordResetUser] = useState({});
  const [listOfUsers, setListOfUsers] = useState({});

  let { id: projectId } = useParams();
  projectId = Number(projectId);

  const isUserWisevoiceAdmin = currentUser?.admin_access === 1;
  const isUserProjectAdmin = currentUser?.permissions?.[projectId][0] === 1;
  const isUserAdmin = isUserWisevoiceAdmin || isUserProjectAdmin;

  useEffect(() => {
    getUsers();
  }, [listOfUsers?.data?.length, inviteUsersPopup]);

  const getUsers = async () => {
    const response = await axios.get('/internal_api/user/get_project_users', {
      params: {
        project_id: projectId,
      },
    });

    let auxDataSet = response.data;
    let listOfUsersObject = {};
    listOfUsersObject.columns = [languageMap.user, languageMap.status, languageMap.lastLogin];
    isUserAdmin &&
      listOfUsersObject.columns.push(
        languageMap.manage,
        languageMap.delete,
        languageMap.resetOtherUserPassword
      );

    isUserWisevoiceAdmin && listOfUsersObject.columns.push(languageMap.loginAsUser);

    listOfUsersObject.data = [];
    listOfUsersObject.dataResponse = auxDataSet;
    listOfUsersObject.ids = [];
    let temporaryObject = {};

    for (let i = 0; i < auxDataSet?.length; i++) {
      temporaryObject.User = setUserName(auxDataSet, i);
      listOfUsersObject.data.push([
        temporaryObject.User,
        (temporaryObject.Status = auxDataSet[i].status),
        (temporaryObject['Last Login'] = auxDataSet[i].last_login),
        (temporaryObject.Actions = isUserAdmin && manageButton(i)),
        (temporaryObject.Delete = isUserAdmin && deleteButton(i)),
        (temporaryObject.ResetOtherUserPassword = isUserAdmin && resetPasswordButton(i)),
        (temporaryObject.LoginAsUser = isUserWisevoiceAdmin && loginAsUserButton(i)),
      ]);
      listOfUsersObject.ids.push(auxDataSet[i]?.id);
    }
    setListOfUsers(listOfUsersObject);
  };

  const inviteUsersButtonClick = () => {
    setInviteUsersPopup(true);
  };

  const updateUsersList = () => {
    getUsers();
  };

  const manageButton = (index) => {
    const changeSubmenu = () => {
      setManagedUser(listOfUsers?.dataResponse?.[index]);
      setSubMenu('userManagement');
    };
    return (
      <div className="button-as-link" onClick={changeSubmenu}>
        {languageMap.manage}
      </div>
    );
  };

  const deleteButton = (index) => {
    const deleteUserFromProject = async () => {
      const listOfPermissionIds = [];
      await axios
        .post('/internal_api/permission/update_user_permissions', {
          project_id: projectId,
          user_id: listOfUsers?.ids?.[index],
          permission_ids: listOfPermissionIds,
        })
        .then(() => {
          getUsers();
          alert('User removed from the project');
        })
        .catch((e) => {
          console.log(e);
        });
    };
    return (
      <div className="button-as-link" onClick={deleteUserFromProject}>
        {languageMap.delete}
      </div>
    );
  };

  const loginAsUserButton = (index) => {
    const loginAsUser = async () => {
      await axios
        .post('/internal_api/user/login_as_user', {
          user_id: listOfUsers?.ids?.[index],
        })
        .then(() => {
          window.location.reload();
        })
        .catch((e) => {
          console.log(e);
        });
    };
    return (
      <div className="button-as-link" onClick={loginAsUser}>
        {languageMap.loginAsUser}
      </div>
    );
  };

  const resetPasswordButton = (index) => {
    const resetOtherUserPassword = () => {
      setPasswordResetPopup(true);
      setPasswordResetUser(listOfUsers?.dataResponse?.[index]);
    };
    return (
      <div className="button-as-link" onClick={resetOtherUserPassword}>
        {languageMap.resetOtherUserPassword}
      </div>
    );
  };

  return (
    <div className="Users">
      <div className="header dm-sans-500 title-text">{languageMap.users}</div>
      {passwordResetPopup && (
        <PasswordRecovery setPasswordResetPopup={setPasswordResetPopup} user={passwordResetUser} />
      )}
      {inviteUsersPopup && (
        <InviteUserOrDepartment
          titleText={languageMap.typeInUserName}
          addButtonText={languageMap.addUser}
          cancelButtonText={languageMap.cancel}
          userOrDepartment="user"
          setPopup={setInviteUsersPopup}
          update={updateUsersList}
        />
      )}
      <Button className="invite-user-button blue-button-style" onClick={inviteUsersButtonClick}>
        <div className="invite-user-button-text">{languageMap.inviteUser}</div>
      </Button>
      <div className="list">
        <Table tableData={listOfUsers} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
});
const mapDispatchToProps = {
  setMenu: setMainMenu,
  setSubMenu: setSubMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
