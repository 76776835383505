import React, { useContext, useState } from 'react';
import { Link, useLocation, Outlet } from 'react-router-dom';

import languageMap from 'Language/Language';
import './ProjectList.scss';
import ProfileIcon from 'assets/menuIcons/Profile';
import ProjectsIcon from 'assets/menuIcons/Projects';
import LogoutIcon from 'assets/menuIcons/Logout';
import { Logo } from 'assets/logo/Logo';
import { Drawer } from '@mui/material';
import { MAIN_COLOR } from '../../mainTheme';
import { CurrentUser } from '../../containers/CurrentUser';

const ProjectList = () => {
  const location = useLocation();
  const [mobileShowDrawer, setMobileShowDrawer] = useState(false);
  const { logout } = useContext(CurrentUser) || {};
  const unselectedColor = '#A3AED0';
  const selectedColor = MAIN_COLOR;
  let profileLinkColor = unselectedColor;
  let projectsLinkColor = selectedColor;

  if (location.pathname === '/profile') {
    profileLinkColor = selectedColor;
    projectsLinkColor = unselectedColor;
  }

  const toggleDrawer = () => {
    setMobileShowDrawer(!mobileShowDrawer);
  };

  const hideDrawer = () => {
    setMobileShowDrawer(false);
  };

  const onLogout = (event) => {
    event.preventDefault();
    logout?.();
  };

  const renderMenu = (fromDrawer) => {
    let navStyle = {};
    if (fromDrawer) {
      navStyle = { display: 'flex' };
    }

    // Logo will not render correctly because we can't have the same SVG twice in the page
    // this is because there are #id conflicts inside it
    if (!fromDrawer && mobileShowDrawer) {
      return;
    }

    return (
      <div className="nav" style={navStyle}>
        <Link to={{ pathname: '/projects' }}>
          <div id="LogoContainer" onClick={hideDrawer}>
            <Logo id="logo" />
          </div>
        </Link>

        <Link to={{ pathname: '/profile' }} className="link" onClick={hideDrawer}>
          <ProfileIcon fill={profileLinkColor} className="icon" />
          <span className="text dm-sans-500" style={{ color: profileLinkColor }}>
            {languageMap.profile}
          </span>
        </Link>

        <Link to={{ pathname: '/projects' }} className="link" onClick={hideDrawer}>
          <ProjectsIcon fill={projectsLinkColor} className="icon" />
          <span className="text dm-sans-500" style={{ color: projectsLinkColor }}>
            {languageMap.projects}
          </span>
        </Link>

        <Link onClick={onLogout} className="link">
          <LogoutIcon className="icon" />
          <span className="text dm-sans-500">{languageMap.profileLogout}</span>
        </Link>
      </div>
    );
  };

  return (
    <div className="ProjectsList">
      {renderMenu(false)}
      {mobileShowDrawer && (
        <Drawer
          classes={{ paper: 'ProjectsList' }}
          open={mobileShowDrawer}
          onClose={toggleDrawer}
          anchor="left"
        >
          {renderMenu(true)}
        </Drawer>
      )}
      <div id="content">
        <Outlet />
      </div>
    </div>
  );
};

export default ProjectList;
