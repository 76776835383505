import React from 'react';
import { styled } from '@mui/system';
import Avatar from '@mui/material/Avatar';

const AvatarWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  '& > *': {
    margin: theme.spacing(1),
  },
}));

const MyAvatar = (props) => {
  return (
    <AvatarWrapper>
      <Avatar>{props.children}</Avatar>
    </AvatarWrapper>
  );
};

export default MyAvatar;
