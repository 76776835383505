import React, { Component } from 'react';

import 'ace-builds';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/webpack-resolver';
import { WhiteButton } from '../../../../components/common/Button/Buttons';
import languageMap from '../../../../Language/Language';

const buttonStyle = {
  width: '59px',
  height: '26px',
  marginLeft: '24px',
};

const buttonsContainerStyle = {
  display: 'flex',
  flexDirection: 'horizontal',
  marginTop: '20px',
  justifyContent: 'center',
};

let containerStyle = {
  position: 'relative',
  padding: 16,
  background: 'white',
  borderRadius: '5px',
  width: '80%',
  height: '80%',
};

const editorStyle = { alignSelf: 'center', width: '100%', height: 'calc(100% - 46px)' };

export default class CodeEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: this.props.value,
    };
  }

  onChange = (newValue) => {
    this.setState({
      code: newValue,
    });
  };

  onSave = () => {
    this.props.onSave(this.state.code);
  };

  handleCancel = () => {
    this.props.onCancel();
  };

  render() {
    const { showClose, customContainerStyle } = this.props;
    let container = { ...containerStyle, ...customContainerStyle };

    return (
      <div style={container}>
        <AceEditor
          onLoad={(editor) => {
            this.codeEditor = editor;
            this.codeEditor.focus();
          }}
          mode="javascript"
          theme="tomorrow"
          onChange={this.onChange}
          style={editorStyle}
          name="ace_editor"
          editorProps={{ $blockScrolling: true }}
          value={this.state.code}
        />
        <div style={buttonsContainerStyle}>
          {showClose && (
            <WhiteButton text={languageMap.close} style={buttonStyle} onClick={this.handleCancel} />
          )}
          <WhiteButton text={languageMap.save} style={buttonStyle} onClick={this.onSave} />
        </div>
      </div>
    );
  }
}
