import { createAction } from '@reduxjs/toolkit';

export const setContainedConversationsData = createAction(
  'analytics/SET_CONTAINED_CONVERSATIONS_DATA'
);
export const setContainedConversationsLabels = createAction(
  'analytics/SET_CONTAINED_CONVERSATIONS_LABELS'
);
export const setContainedConversationsTitle = createAction(
  'analytics/SET_CONTAINED_CONVERSATIONS_TITLE'
);

export const setConversationsGraph = createAction('analytics/SET_CONVERSATIONS_GRAPH');
export const setUniqueUsers = createAction('analytics/SET_UNIQUE_USERS');
export const setRepliesSent = createAction('analytics/SET_REPLIES_SENT');
export const setBusinessHourConversation = createAction('analytics/SET_BUSINESS_HOUR_CONVERSATION');
export const setConversationsByBreakdownChannel = createAction(
  'analytics/SET_CONVERSATIONS_BY_BREAKDOWN_CHANNEL'
);
export const setConversationsByBreakdownLanguage = createAction(
  'analytics/SET_CONVERSATIONS_BY_BREAKDOWN_LANGUAGE'
);
export const setCustomerSatisfactionScore = createAction(
  'analytics/SET_CUSTOMER_SATISFACTION_SCORE'
);
export const setInboundConversations = createAction('analytics/SET_INBOUND_CONVERSATIONS');
export const setHeatMapData = createAction('analytics/SET_HEAT_MAP_DATA');
export const setDialogType = createAction('analytics/SET_DIALOG_TYPE');
export const setIntents = createAction('analytics/SET_INTENTS');
export const setEntities = createAction('analytics/SET_ENTITIES');
export const setExcludedIntents = createAction('analytics/SET_EXCLUDED_INTENTS');
export const setContainmentGraph = createAction('analytics/SET_CONTAINMENT_GRAPH');
export const setReactionsGraph = createAction('analytics/SET_REACTIONS_GRAPH');
export const setTopAnswers = createAction('analytics/SET_TOP_ANSWERS');
export const setTopAnswersByHandoff = createAction('analytics/SET_TOP_ANSWERS_BY_HANDOFF');
export const setWiseAgentRating = createAction('analytics/SET_WISE_AGENT_RATING');
export const setGraphContainmentRateByBreakdownChannelId = createAction(
  'analytics/SET_GRAPH_CONTAINMENT_RATE_BY_BREAKDOWN_CHANNEL_ID'
);
export const setGraphContainmentRateByBreakdownLanguage = createAction(
  'analytics/SET_GRAPH_CONTAINMENT_RATE_BY_BREAKDOWN_LANGUAGE'
);
export const setGraphWiseAgentRatingByBreakdownChannelId = createAction(
  'analytics/SET_GRAPH_WISE_AGENT_RATING_BY_BREAKDOWN_CHANNEL_ID'
);
export const setGraphWiseAgentRatingByBreakdownLanguage = createAction(
  'analytics/SET_GRAPH_WISE_AGENT_RATING_BY_BREAKDOWN_LANGUAGE'
);
export const setDetectionRate = createAction('analytics/SET_DETECTION_RATE');

export const setAverageResponseTime = createAction('analytics/SET_AVERAGE_RESPONSE_TIME');
export const setConversationsByBreakdown = createAction('analytics/SET_CONVERSATIONS_BY_BREAKDOWN');
export const setHeatMapTeamPerformance = createAction('analytics/SET_HEAT_MAP_TEAM_PERFORMANCE');
export const setAgentPerformance = createAction('analytics/SET_AGENT_PERFORMANCE');

export const setConversationRating = createAction('analytics/SET_CONVERSATION_RATING');
export const setTableFeedback = createAction('analytics/SET_TABLE_FEEDBACK');
export const setRemarks = createAction('analytics/SET_REMARKS');
export const setFeedbackScore = createAction('analytics/SET_FEEDBACK_SCORE');
