import { useEffect } from 'react';
import Axios from 'axios';
import get from 'lodash/get';
import useNotifications from 'utils/hooks/useNotifications';

// let baseUrl = `/api/`;
let baseUrl = '/';
const environment = process.env.NODE_ENV;
if (environment === 'production') {
  // baseUrl = `https://${process.env.REACT_APP_DOMAIN_NAME}/api/`;
  baseUrl = `https://${process.env.REACT_APP_DOMAIN_NAME}/`;
  Axios.defaults.withCredentials = true;
}
Axios.defaults.baseURL = baseUrl;
window.Axios = Axios;

const Setup = ({ children }) => {
  const { showError } = useNotifications();
  useEffect(() => {
    Axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const is401 = get(error, 'response.status') === 401;
        const isInternalApiCall = get(error, 'response.config.url', '').startsWith('/internal_api');
        const isUnauthorized = get(error, 'response.data.error', '').startsWith('Unauthenticated');
        if (is401 && isInternalApiCall && isUnauthorized) {
          window.location.href = '/';
        } else if (get(error, 'response.data')) {
          if (error.response.data?.message) {
            showError(error.response.data.message || 'An error occurred.');
          } else {
            if (typeof error.response.data === 'string') {
              showError(error.response.data || 'An error occurred.');
            } else {
              showError('An error occurred. Please try again later.');
            }
          }
        } else {
          showError('An error occurred. Please try again later.');
        }
        return Promise.reject(error);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return children;
};

export default Setup;
