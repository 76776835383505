import React from 'react';

const WvIcon = (props) => {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
      <g id="Checkbox-Off" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-Copy-14" stroke="#333333" strokeWidth="2">
          <g id="checkbox-checked">
            <rect id="Rectangle-3-Copy" x="1" y="1" width="18" height="18" rx="6"></rect>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default WvIcon;
