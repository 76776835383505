import React, { useEffect, useState } from 'react';
import ChatAvatar from '../ChatAvatar/ChatAvatar';
import './ConversationUser.scss';
import Axios from 'axios';
import { IS_USER_ONLINE_ENDPOINT } from 'utils/constants';
import languageMap from 'Language/Language';
import { Tooltip } from '@mui/material';

let intervalId;
const ConversationUser = ({ userDisplayName, userId, projectId, channel, isMobile }) => {
  const [userStatusOnline, setUserStatusOnline] = useState(null);

  useEffect(() => {
    if (channel === 'Web') {
      const updateUserStatus = async (userId, projectId) => {
        Axios.get(IS_USER_ONLINE_ENDPOINT, {
          params: {
            projectId: projectId,
            user_id: userId,
          },
        })
          .then(({ data }) => setUserStatusOnline(data.is_online))
          .catch(console.log);
      };

      updateUserStatus(userId, projectId);
      intervalId = setInterval(async () => await updateUserStatus(userId, projectId), 10000); // update status every 10 seconds

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [userId]);
  const isWeb = channel === 'Web';
  const userStatus =
    userStatusOnline == null ? ' ' : userStatusOnline ? languageMap.active : languageMap.inactive;
  return (
    <Tooltip title={userDisplayName ? userDisplayName : 'Unknown user'} placement="right-end">
      <div className="ConversationUser">
        <ChatAvatar msgSender={userDisplayName} large={true} />
        {isWeb && (
          <div
            className="user-status-dot"
            style={{ visibility: userStatusOnline ? 'visible' : 'hidden' }}
          />
        )}
        {!isMobile && (
          <div className="name-and-text-status">
            <div className="user-name" style={{ marginLeft: isWeb ? 0 : 8 }}>
              {userDisplayName ? userDisplayName : 'Unknown user'}
            </div>
            {isWeb && <span className="user-status-text">{userStatus}</span>}
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export default ConversationUser;
