import React from 'react';
import CanvasContainer from 'containers/CanvasContainer';
import Canvas from 'pages/Flow/components/Canvas';
import './Flow.scss';
import { useParams } from 'react-router-dom';

const Flow = () => {
  const { id: projectId, appId, nodeId } = useParams();

  return (
    <CanvasContainer projectId={projectId} appId={appId}>
      <Canvas focusedNodeId={nodeId} />
    </CanvasContainer>
  );
};

export default Flow;
