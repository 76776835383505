import { RetrainStatusProvider } from '../Flow/components/Retrain';
import React from 'react';
import useComponentDidMount from '../../utils/useComponentDidMount';
import { getProjectLanguageData } from '../../store/crm/actions';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

const RetrainWrapper = ({ component: Component, ...props }) => {
  const { id: projectId } = useParams();
  useComponentDidMount(() => {
    props.getProjectLanguageData(projectId);
  });
  return (
    <RetrainStatusProvider>
      <Component projectId={projectId} />
    </RetrainStatusProvider>
  );
};

const mapDispatchToProps = {
  getProjectLanguageData,
};

export default connect(null, mapDispatchToProps)(RetrainWrapper);
