import React from 'react';
import Help from '@mui/icons-material/Help';
import './HelperTooltip.scss';
import Tooltip from '@mui/material/Tooltip';

const HelperTooltip = ({ style, title }) => {
  return (
    <Tooltip title={title}>
      <Help style={style} className="HelperTooltip" />
    </Tooltip>
  );
};

export default HelperTooltip;
