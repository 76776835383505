import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import Close from '@mui/icons-material/Close';
import { Checkbox } from 'semantic-ui-react';
import Button from '@mui/material/Button';
import { AppBar, Dialog, DialogActions, IconButton, Toolbar } from '@mui/material';
import LargeStatsWidget from '../Widgets/LargeStatsWidget';
import languageMap from 'Language/Language';
import { v4 as uuidv4 } from 'uuid';

import Graph from '../../../../../../../components/Graphs/Graph/Graph';

import { twin } from '../../../../../../../utils/twin';

import {
  getDialogType,
  getIntents,
  getExcludedIntents,
  getContainmentGraph,
  getReactionsGraph,
  getTopAnswers,
  getTopAnswersByHandoff,
  getWiseAgentRating,
  getGraphContainmentRateByBreakdownChannelId,
  getGraphContainmentRateByBreakdownLanguage,
  getGraphWiseAgentRatingByBreakdownChannelId,
  getGraphWiseAgentRatingByBreakdownLanguage,
  getDetectionRate,
} from '../../../../../../../store/analytics/selectors';

import {
  setDialogType,
  setIntents,
  setExcludedIntents,
  setContainmentGraph,
  setReactionsGraph,
  setTopAnswers,
  setTopAnswersByHandoff,
  setWiseAgentRating,
  setGraphContainmentRateByBreakdownChannelId,
  setGraphContainmentRateByBreakdownLanguage,
  setGraphWiseAgentRatingByBreakdownChannelId,
  setGraphWiseAgentRatingByBreakdownLanguage,
  setDetectionRate,
} from '../../../../../../../store/analytics/actions';

const WiseAgent = ({
  projectId,
  startTimestamp,
  endTimestamp,
  selectedTimeInterval,
  currentTimezone,
  setDialogType,
  setIntents,
  setExcludedIntents,
  setContainmentGraph,
  setReactionsGraph,
  setTopAnswers,
  setTopAnswersByHandoff,
  setWiseAgentRating,
  setGraphContainmentRateByBreakdownChannelId,
  setGraphContainmentRateByBreakdownLanguage,
  setGraphWiseAgentRatingByBreakdownChannelId,
  setGraphWiseAgentRatingByBreakdownLanguage,
  setDetectionRate,
  dialogType,
  allIntents,
  excludedIntents,
  containmentGraph,
  reactionsGraph,
  topAnswers,
  topAnswersByHandoff,
  wiseAgentRating,
  graphContainmentRateByBreakdownChannelId,
  graphContainmentRateByBreakdownLanguage,
  graphWiseAgentRatingByBreakdownChannelId,
  graphWiseAgentRatingByBreakdownLanguage,
  detectionRate,
  forPrint,
}) => {
  let listOfLargeWidgets = [topAnswers, topAnswersByHandoff, wiseAgentRating];
  const updateIntent = async (id, value) => {
    await axios.post('/internal_api/intent_set_option', {
      id,
      option: 'is_excluded',
      value,
    });
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  let narrowScreen = windowWidth <= 1000;

  const closeDialog = () => {
    setDialogType(null);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const listIntents = async () => {
      const response = await axios.get('/internal_api/list_intents', {
        params: {
          project_id: projectId,
        },
      });
      const intents = response.data;
      const excluded = {};
      intents.forEach((intent) => {
        if (intent.is_excluded) {
          excluded[intent.id] = true;
        }
      });
      setExcludedIntents(excluded);
      setIntents(response.data);
    };

    listIntents();
  }, [projectId]);

  useEffect(() => {
    if (!allIntents.length) {
      return;
    }

    const populateGraphContainmentRate = async () => {
      const response = await axios.get('/internal_api/analytics/get_containment_rate', {
        params: {
          projectId: projectId,
          timezone: currentTimezone,
          startTimestamp,
          endTimestamp,
        },
      });

      let auxDataSet = response.data;
      let containmentGraphObject = {};
      let dataSet = [];
      let legend = auxDataSet.chart_legend;
      let info = languageMap.infoReportsConversationsContained;
      let title = auxDataSet.chart_name;
      let containmentRate = `${auxDataSet.containment_rate}%`;
      let labels = auxDataSet.prettified_days;
      let conversationsList = auxDataSet.conversations;
      let comparisonValue = auxDataSet.comparison_value;
      let comparisonText = auxDataSet.comparison_text;

      let randomeColor;

      const generateColor = (i) => {
        if (i === 0) {
          randomeColor = '#1a90ff';
        } else if (i === 1) {
          randomeColor = '#bfc1c6';
        } else {
          // eslint-disable-next-line no-bitwise
          randomeColor = '#' + (((1 << 24) * Math.random()) | 0).toString(16).padStart(6, '0');
        }
        return randomeColor;
      };

      for (let i = 0; i < auxDataSet.data.length; i++) {
        let temporaryAuxDataArray = [...Object.values(auxDataSet.data[i])];
        let temporaryDataArray = [];
        for (let j = 0; j < temporaryAuxDataArray[0].length; j++) {
          temporaryDataArray = [
            ...temporaryDataArray,
            ...Object.values(temporaryAuxDataArray[0][j]),
          ];
        }
        generateColor(i);
        const entry = {
          label: legend[i],
          data: temporaryDataArray,
          borderColor: randomeColor,
          fill: false,
          backgroundColor: randomeColor,
        };
        dataSet.push(entry);
      }
      containmentGraphObject.labels = labels;
      containmentGraphObject.data = dataSet;
      containmentGraphObject.title = title;
      containmentGraphObject.conversationsList = conversationsList;
      containmentGraphObject.comparisonValue = comparisonValue;
      containmentGraphObject.comparisonText = comparisonText;
      containmentGraphObject.info = info;
      containmentGraphObject.legend = legend;
      containmentGraphObject.containmentRate = containmentRate;
      containmentGraphObject.unit = '%';
      setContainmentGraph(containmentGraphObject);
    };

    const populateGraphReactions = async () => {
      const response = await axios.get('/internal_api/analytics/get_reactions', {
        params: {
          projectId: projectId,
          timezone: currentTimezone,
          startTimestamp,
          endTimestamp,
        },
      });
      let auxDataSet = response.data;

      let reactionsGraphObject = {};
      reactionsGraphObject.labels = [...Object.keys(auxDataSet.data)];
      reactionsGraphObject.title = auxDataSet.chart_name;

      let tempDataArray = [];
      let tempComparisonValueArray = [];
      for (let key in auxDataSet.data) {
        tempDataArray.push(auxDataSet?.data[key]?.percentage);
        tempComparisonValueArray.push(auxDataSet?.data[key]?.comparison_value);
      }

      reactionsGraphObject.data = [
        { data: tempDataArray, backgroundColor: ['#4318FF', '#6AD2FF'] },
      ];
      reactionsGraphObject.comparisonValueArray = tempComparisonValueArray;
      reactionsGraphObject.comparisonText = auxDataSet?.reactions_total_text;
      reactionsGraphObject.info = auxDataSet?.info;
      reactionsGraphObject.unit = '%';

      setReactionsGraph(reactionsGraphObject);
    };

    const populateTopAnswers = async () => {
      let excludedIntentsArray = Object.keys(excludedIntents).map(function (str) {
        return parseInt(str);
      });
      const response = await axios.post('/internal_api/analytics/get_top_answers', {
        projectId: projectId,
        timezone: currentTimezone,
        startTimestamp,
        endTimestamp,
        excludedIntents: excludedIntentsArray,
        escalated: 0,
      });
      let TopAnswersObject = {};
      let auxDataSet = response.data;
      TopAnswersObject.list = [];
      TopAnswersObject.title = auxDataSet.chart_name;
      TopAnswersObject.info = languageMap.infoReportsTopAnswers;
      TopAnswersObject.displayOptions = 'intents';

      let temoporaryArrayForExtraction = [];
      for (const key in auxDataSet.data[0]) {
        let temporaryObjectForExtraction = {};
        temporaryObjectForExtraction.lineItem = key;
        temporaryObjectForExtraction.value = auxDataSet.data[0][key];
        temoporaryArrayForExtraction.push(temporaryObjectForExtraction);
      }
      TopAnswersObject.list = [...temoporaryArrayForExtraction];

      setTopAnswers(TopAnswersObject);
    };

    const populateTopAnswersResultingInHandoff = async () => {
      let excludedIntentsArray = Object.keys(excludedIntents).map(function (str) {
        return parseInt(str);
      });

      const response = await axios.post('/internal_api/analytics/get_top_answers', {
        projectId: projectId,
        timezone: currentTimezone,
        startTimestamp,
        endTimestamp,
        excludedIntents: excludedIntentsArray,
        escalated: 1,
      });

      let TopAnswersByHandOffObject = {};
      let auxDataSet = response.data;
      TopAnswersByHandOffObject.list = [];
      TopAnswersByHandOffObject.title = auxDataSet.chart_name;
      TopAnswersByHandOffObject.info = languageMap.infoReportsTopAnswersResultingInHandoff;
      TopAnswersByHandOffObject.displayOptions = 'intents';

      let temoporaryArrayForExtraction = [];
      for (const key in auxDataSet.data[0]) {
        let temporaryObjectForExtraction = {};
        temporaryObjectForExtraction.lineItem = key;
        temporaryObjectForExtraction.value = auxDataSet.data[0][key];
        temoporaryArrayForExtraction.push(temporaryObjectForExtraction);
      }
      TopAnswersByHandOffObject.list = [...temoporaryArrayForExtraction];

      setTopAnswersByHandoff(TopAnswersByHandOffObject);
    };

    const populateWiseAgentRating = async () => {
      const response = await axios.get('/internal_api/analytics/get_wise_agent_customer_rating', {
        params: {
          projectId: projectId,
          timezone: currentTimezone,
          startTimestamp,
          endTimestamp,
        },
      });

      let wiseAgentRatingObject = {};
      let auxDataSet = response.data;

      wiseAgentRatingObject.title = auxDataSet.chart_name;
      wiseAgentRatingObject.info = languageMap.infoReportsWiseAgentCustomerRating;
      wiseAgentRatingObject.comparisonText = auxDataSet.data.comparison_text;
      wiseAgentRatingObject.surveyText = auxDataSet.data.surveys_total_text;
      wiseAgentRatingObject.content = auxDataSet.data.satisfaction_score || 0.01;
      wiseAgentRatingObject.percentage = auxDataSet.data.comparison_value;

      setWiseAgentRating(wiseAgentRatingObject);
    };

    const populateGraphContainmentRateByBreakdownChannelId = async () => {
      const response = await axios.get(
        '/internal_api/analytics/get_containment_rate_by_breakdown',
        {
          params: {
            projectId: projectId,
            timezone: currentTimezone,
            startTimestamp,
            endTimestamp,
            breakdown: 'channel_id',
          },
        }
      );
      let conversationsByBreakdownChannelIDObject = {};
      let auxDataSet = response.data;
      let dataSet = [];
      let labels = Object.keys(auxDataSet.breakdown_color);
      let info = languageMap.infoReportsContainmentRateByChannel;
      let conversationsList = auxDataSet.conversations;
      let comparisonValue = auxDataSet.comparison_value;
      let comparisonText = auxDataSet.comparison_text;
      conversationsByBreakdownChannelIDObject.labels = labels;
      for (let i = 0; i < auxDataSet?.data.length; i++) {
        let temporaryData = [];
        let temporaryColor = [];
        const temporaryDataset = auxDataSet.data[i];
        for (let j = 0; j < labels?.length; j++) {
          temporaryData = [...temporaryData, temporaryDataset[labels[j]]];
          temporaryColor = [...temporaryColor, auxDataSet.breakdown_color[labels[j]]];
        }
        const entry = {
          data: temporaryData,
          fill: false,
          backgroundColor: temporaryColor,
          maxBarThickness: 14,
          borderRadius: 14,
        };
        dataSet.push(entry);

        conversationsByBreakdownChannelIDObject.data = dataSet;
        conversationsByBreakdownChannelIDObject.title = auxDataSet.chart_name;
        conversationsByBreakdownChannelIDObject.conversationsList = conversationsList;
        conversationsByBreakdownChannelIDObject.comparisonValue = comparisonValue;
        conversationsByBreakdownChannelIDObject.comparisonText = comparisonText;
        conversationsByBreakdownChannelIDObject.info = info;
        conversationsByBreakdownChannelIDObject.unit = '%';

        setGraphContainmentRateByBreakdownChannelId(conversationsByBreakdownChannelIDObject);
      }
    };

    const populateGraphContainmentRateByBreakdownLanguage = async () => {
      const response = await axios.get(
        '/internal_api/analytics/get_containment_rate_by_breakdown',
        {
          params: {
            projectId: projectId,
            timezone: currentTimezone,
            startTimestamp,
            endTimestamp,
            breakdown: 'language',
          },
        }
      );
      let conversationsByBreakdownLanguageObject = {};
      let auxDataSet = response.data;
      let dataSet = [];
      let labels = Object.keys(auxDataSet.breakdown_color);
      let info = languageMap.infoReportsContainmentRateByLanguage;
      let conversationsList = auxDataSet.conversations;
      let comparisonValue = auxDataSet.comparison_value;
      let comparisonText = auxDataSet.comparison_text;
      conversationsByBreakdownLanguageObject.labels = labels;
      for (let i = 0; i < auxDataSet?.data.length; i++) {
        let temporaryData = [];
        let temporaryColor = [];
        const temporaryDataset = auxDataSet.data[i];
        for (let j = 0; j < labels?.length; j++) {
          temporaryData = [...temporaryData, temporaryDataset[labels[j]]];
          temporaryColor = [...temporaryColor, auxDataSet.breakdown_color[labels[j]]];
        }
        const entry = {
          data: temporaryData,
          fill: false,
          backgroundColor: temporaryColor,
          maxBarThickness: 14,
          borderRadius: 14,
        };
        dataSet.push(entry);

        conversationsByBreakdownLanguageObject.data = dataSet;
        conversationsByBreakdownLanguageObject.title = auxDataSet.chart_name;
        conversationsByBreakdownLanguageObject.conversationsList = conversationsList;
        conversationsByBreakdownLanguageObject.comparisonValue = comparisonValue;
        conversationsByBreakdownLanguageObject.comparisonText = comparisonText;
        conversationsByBreakdownLanguageObject.info = info;
        conversationsByBreakdownLanguageObject.unit = '%';

        setGraphContainmentRateByBreakdownLanguage(conversationsByBreakdownLanguageObject);
      }
    };

    const populateGraphWiseAgentRatingByBreakdownChannelId = async () => {
      const response = await axios.get(
        '/internal_api/analytics/get_wise_agent_customer_rating_by_breakdown',
        {
          params: {
            projectId: projectId,
            timezone: currentTimezone,
            startTimestamp,
            endTimestamp,
            breakdown: 'channel_id',
          },
        }
      );
      let wiseAgentRatingByBreakdownChannelIdObject = {};
      let auxDataSet = response.data;
      let dataSet = [];
      let labels = Object.keys(auxDataSet.breakdown_color);
      let info = languageMap.infoReportsWiseAgentCustomerRatingByChannelId;
      let conversationsList = auxDataSet.conversations;
      let comparisonValue = auxDataSet.comparison_value;
      let comparisonText = auxDataSet.comparison_text;
      wiseAgentRatingByBreakdownChannelIdObject.labels = labels;
      for (let i = 0; i < auxDataSet?.data.length; i++) {
        let temporaryData = [];
        let temporaryColor = [];
        const temporaryDataset = auxDataSet.data[i];
        for (let j = 0; j < labels?.length; j++) {
          temporaryData = [...temporaryData, temporaryDataset[labels[j]]];
          temporaryColor = [...temporaryColor, auxDataSet.breakdown_color[labels[j]]];
        }
        const entry = {
          data: temporaryData,
          fill: false,
          backgroundColor: temporaryColor,
          maxBarThickness: 14,
          borderRadius: 14,
        };
        dataSet.push(entry);

        wiseAgentRatingByBreakdownChannelIdObject.data = dataSet;
        wiseAgentRatingByBreakdownChannelIdObject.title = auxDataSet.chart_name;
        wiseAgentRatingByBreakdownChannelIdObject.conversationsList = conversationsList;
        wiseAgentRatingByBreakdownChannelIdObject.comparisonValue = comparisonValue;
        wiseAgentRatingByBreakdownChannelIdObject.comparisonText = comparisonText;
        wiseAgentRatingByBreakdownChannelIdObject.info = info;

        setGraphWiseAgentRatingByBreakdownChannelId(wiseAgentRatingByBreakdownChannelIdObject);
      }
    };

    const populateGraphWiseAgentRatingByBreakdownLanguage = async () => {
      const response = await axios.get(
        '/internal_api/analytics/get_wise_agent_customer_rating_by_breakdown',
        {
          params: {
            projectId: projectId,
            timezone: currentTimezone,
            startTimestamp,
            endTimestamp,
            breakdown: 'language',
          },
        }
      );
      let wiseAgentRatingByBreakdownLanguageObject = {};
      let auxDataSet = response.data;
      let dataSet = [];
      let labels = Object.keys(auxDataSet.breakdown_color);
      let info = languageMap.infoReportsWiseAgentCustomerRatingByLanguage;
      let conversationsList = auxDataSet.conversations;
      let comparisonValue = auxDataSet.comparison_value;
      let comparisonText = auxDataSet.comparison_text;
      wiseAgentRatingByBreakdownLanguageObject.labels = labels;
      for (let i = 0; i < auxDataSet?.data.length; i++) {
        let temporaryData = [];
        let temporaryColor = [];
        const temporaryDataset = auxDataSet.data[i];
        for (let j = 0; j < labels?.length; j++) {
          temporaryData = [...temporaryData, temporaryDataset[labels[j]]];
          temporaryColor = [...temporaryColor, auxDataSet.breakdown_color[labels[j]]];
        }
        const entry = {
          data: temporaryData,
          fill: false,
          backgroundColor: temporaryColor,
          maxBarThickness: 14,
          borderRadius: 14,
        };
        dataSet.push(entry);

        wiseAgentRatingByBreakdownLanguageObject.data = dataSet;
        wiseAgentRatingByBreakdownLanguageObject.title = auxDataSet.chart_name;
        wiseAgentRatingByBreakdownLanguageObject.conversationsList = conversationsList;
        wiseAgentRatingByBreakdownLanguageObject.comparisonValue = comparisonValue;
        wiseAgentRatingByBreakdownLanguageObject.comparisonText = comparisonText;
        wiseAgentRatingByBreakdownLanguageObject.info = info;

        setGraphWiseAgentRatingByBreakdownLanguage(wiseAgentRatingByBreakdownLanguageObject);
      }
    };
    //

    const populateDetectionRate = async () => {
      const response = await axios.get('/internal_api/analytics/get_detection_rate', {
        params: {
          projectId: projectId,
          timezone: currentTimezone,
          startTimestamp,
          endTimestamp,
          metric: 'started_conversations',
        },
      });

      let auxDataSet = response.data;
      let detectionRateObject = {};
      let dataSet = [];
      let legend = auxDataSet.chart_legend;
      let info = languageMap.infoReportsDetectionRate;
      let title = auxDataSet.chart_name;
      let containmentRate = `${auxDataSet.detection_rate}%`;
      let labels = auxDataSet.prettified_days;
      let conversationsList = auxDataSet.conversations;
      let comparisonValue = auxDataSet.comparison_value;
      let comparisonText = auxDataSet.comparison_text;

      let randomeColor;

      const generateColor = (i) => {
        if (i === 0) {
          randomeColor = '#1a90ff';
        } else if (i === 1) {
          randomeColor = '#bfc1c6';
        } else {
          // eslint-disable-next-line no-bitwise
          randomeColor = '#' + (((1 << 24) * Math.random()) | 0).toString(16).padStart(6, '0');
        }
        return randomeColor;
      };

      for (let i = 0; i < auxDataSet.data.length; i++) {
        let temporaryAuxDataArray = [...Object.values(auxDataSet.data[i])];
        let temporaryDataArray = [];
        for (let j = 0; j < temporaryAuxDataArray[0].length; j++) {
          temporaryDataArray = [
            ...temporaryDataArray,
            ...Object.values(temporaryAuxDataArray[0][j]),
          ];
        }
        generateColor(i);
        const entry = {
          label: legend[i],
          data: temporaryDataArray,
          borderColor: randomeColor,
          fill: false,
          backgroundColor: randomeColor,
        };
        dataSet.push(entry);
      }
      detectionRateObject.labels = labels;
      detectionRateObject.data = dataSet;
      detectionRateObject.title = title;
      detectionRateObject.conversationsList = conversationsList;
      detectionRateObject.comparisonValue = comparisonValue;
      detectionRateObject.comparisonText = comparisonText;
      detectionRateObject.info = info;
      detectionRateObject.legend = legend;
      detectionRateObject.containmentRate = containmentRate;
      detectionRateObject.yScaleMax = 100;
      detectionRateObject.yScaleMin = 0;
      detectionRateObject.unit = '%';
      setDetectionRate(detectionRateObject);
    };

    populateGraphWiseAgentRatingByBreakdownLanguage();
    populateGraphWiseAgentRatingByBreakdownChannelId();
    populateGraphContainmentRateByBreakdownLanguage();
    populateGraphContainmentRateByBreakdownChannelId();
    populateDetectionRate();
    populateTopAnswersResultingInHandoff();
    populateWiseAgentRating();
    populateTopAnswers();
    populateGraphReactions();
    populateGraphContainmentRate();
  }, [projectId, selectedTimeInterval, startTimestamp, endTimestamp, allIntents, excludedIntents]);

  const duplicates = {};
  for (let i = 0; i < allIntents.length; i++) {
    for (let j = 0; j < allIntents.length; j++) {
      if (i !== j) {
        if (!(allIntents[i].id in duplicates)) {
          if (allIntents[i].intent_name === allIntents[j].intent_name) {
            duplicates[allIntents[i].id] = true;
            duplicates[allIntents[j].id] = true;
          }
        }
      }
    }
  }

  const finalIntents = allIntents.map((intent) => {
    if (intent.id in duplicates) {
      return { ...intent, intent_name: intent.intent_name + ` (${intent.app_name})` };
    } else {
      return intent;
    }
  });

  function renderDialog() {
    const list = finalIntents;
    const update = updateIntent;
    const excluded = excludedIntents;
    const setExcluded = setExcludedIntents;

    return (
      <Dialog open={!!dialogType} fullWidth onClose={closeDialog}>
        <AppBar color="default" position="static">
          <Toolbar variant="dense" style={{ paddingRight: 3 }}>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              {dialogType === 'intents' ? 'Exclude intents' : ''}
            </Typography>
            <IconButton size="small" onClick={() => setDialogType(null)} disableRipple>
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div
          style={{
            flexGrow: 1,
            padding: 20,
            paddingBottom: 30,
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
          }}
        >
          {list.map((info) => (
            <Checkbox
              key={info.id}
              style={{ fontSize: 18, marginTop: 20 }}
              label={dialogType === 'intents' ? info.intent_name : info.name}
              checked={info.id in excluded}
              onChange={() => {
                const id = info.id;
                if (id in excluded) {
                  const newExcluded = { ...excluded };
                  delete newExcluded[id];
                  setExcluded(newExcluded);
                  update(id, false);
                } else {
                  setExcluded({ ...excluded, [id]: true });
                  update(id, true);
                }
              }}
            />
          ))}
        </div>
        <DialogActions>
          <Button color="primary" onClick={closeDialog}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const displayLargeWidget = () => {
    return listOfLargeWidgets
      .filter((widget) => widget && widget.title)
      .map(
        ({
          title,
          content,
          list,
          info,
          percentage,
          surveyText,
          comparisonText,
          displayOptions,
        }) => (
          <LargeStatsWidget
            key={uuidv4()}
            title={title}
            content={content}
            list={list}
            info={info}
            percentage={percentage}
            surveyText={surveyText}
            comparisonText={comparisonText}
            displayOptions={displayOptions}
          />
        )
      );
  };

  return (
    <div>
      {!narrowScreen && !forPrint && (
        <div
          className="quad-bar-graph-cntainer"
          style={{
            display: 'grid',
            gridTemplateColumns: '67% 33%',
            gridColumnGap: '20px',
            margin: '0 20px 0 0',
          }}
        >
          <div className="graph-container">
            <div className="row-container">
              <div className="graphs-row">
                <Graph
                  type="line"
                  {...containmentGraph}
                  value={containmentGraph?.containmentRate}
                />
              </div>
            </div>
          </div>
          <div className="graph-container">
            <div className="row-container">
              <div className="graphs-row">
                <Graph type="pie" {...reactionsGraph} value={reactionsGraph?.reactionsRate} />
              </div>
            </div>
          </div>
        </div>
      )}
      {(narrowScreen || forPrint) && (
        <>
          <div className="graph-container">
            <div className="row-container">
              <div className="graphs-row">
                <Graph
                  type="line"
                  {...containmentGraph}
                  value={containmentGraph?.containmentRate}
                />
              </div>
            </div>
          </div>
          <div className="graph-container">
            <div className="row-container">
              <div className="graphs-row">
                <Graph type="pie" {...reactionsGraph} value={reactionsGraph?.reactionsRate} />
              </div>
            </div>
          </div>
        </>
      )}
      <div
        className="statistics-widgets"
        style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}
      >
        {listOfLargeWidgets && displayLargeWidget()}
      </div>
      <div
        className="quad-bar-graph-cntainer"
        style={{
          display: 'grid',
          gridTemplateColumns: '50% 50%',
          gridColumnGap: '20px',
          margin: '0 20px 0 0',
        }}
      >
        <div className="graph-container">
          <div className="row-container" style={{ background: 'white' }}>
            <div className="graphs-row">
              <Graph
                type="bar"
                data={twin(graphContainmentRateByBreakdownChannelId?.data)}
                labels={graphContainmentRateByBreakdownChannelId?.labels}
                title={graphContainmentRateByBreakdownChannelId?.title}
                value={graphContainmentRateByBreakdownChannelId?.conversationsList}
                comparisonValue={graphContainmentRateByBreakdownChannelId?.comparisonValue}
                comparisonText={graphContainmentRateByBreakdownChannelId?.comparisonText}
                info={graphContainmentRateByBreakdownChannelId?.info}
                unit={graphContainmentRateByBreakdownChannelId?.unit}
              />
            </div>
          </div>
        </div>
        <div className="graph-container">
          <div className="row-container" style={{ background: 'white' }}>
            <div className="graphs-row">
              <Graph
                type="bar"
                data={twin(graphContainmentRateByBreakdownLanguage?.data)}
                labels={graphContainmentRateByBreakdownLanguage?.labels}
                title={graphContainmentRateByBreakdownLanguage?.title}
                value={graphContainmentRateByBreakdownLanguage?.conversationsList}
                comparisonValue={graphContainmentRateByBreakdownLanguage?.comparisonValue}
                comparisonText={graphContainmentRateByBreakdownLanguage?.comparisonText}
                info={graphContainmentRateByBreakdownLanguage?.info}
                unit={graphContainmentRateByBreakdownLanguage?.unit}
              />
            </div>
          </div>
        </div>
        <div className="graph-container">
          <div className="row-container" style={{ background: 'white' }}>
            <div className="graphs-row">
              <Graph
                type="bar"
                data={twin(graphWiseAgentRatingByBreakdownChannelId?.data)}
                labels={graphWiseAgentRatingByBreakdownChannelId?.labels}
                title={graphWiseAgentRatingByBreakdownChannelId?.title}
                value={graphWiseAgentRatingByBreakdownChannelId?.conversationsList}
                comparisonValue={graphWiseAgentRatingByBreakdownChannelId?.comparisonValue}
                comparisonText={graphWiseAgentRatingByBreakdownChannelId?.comparisonText}
                info={graphWiseAgentRatingByBreakdownChannelId?.info}
              />
            </div>
          </div>
        </div>
        <div className="graph-container">
          <div className="row-container" style={{ background: 'white' }}>
            <div className="graphs-row">
              <Graph
                type="bar"
                data={twin(graphWiseAgentRatingByBreakdownLanguage?.data)}
                labels={graphWiseAgentRatingByBreakdownLanguage?.labels}
                title={graphWiseAgentRatingByBreakdownLanguage?.title}
                value={graphWiseAgentRatingByBreakdownLanguage?.conversationsList}
                comparisonValue={graphWiseAgentRatingByBreakdownLanguage?.comparisonValue}
                comparisonText={graphWiseAgentRatingByBreakdownLanguage?.comparisonText}
                info={graphWiseAgentRatingByBreakdownLanguage?.info}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="graph-container"
        style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}
      >
        <div className="row-container">
          <div className="graphs-row">
            <Graph
              type="line"
              data={twin(detectionRate?.data)}
              labels={detectionRate?.labels}
              title={detectionRate?.title}
              legend={detectionRate?.legend}
              info={detectionRate?.info}
              comparisonText={detectionRate?.comparisonText}
              comparisonValue={detectionRate?.comparisonValue}
              value={detectionRate?.containmentRate}
              yScaleMax={detectionRate?.yScaleMax}
              yScaleMin={detectionRate?.yScaleMin}
              unit={detectionRate?.unit}
            />
          </div>
        </div>
      </div>
      {renderDialog()}
    </div>
  );
};

const mapStateToProps = (state) => ({
  dialogType: getDialogType(state),
  allIntents: getIntents(state),
  excludedIntents: getExcludedIntents(state),
  containmentGraph: getContainmentGraph(state),
  reactionsGraph: getReactionsGraph(state),
  topAnswers: getTopAnswers(state),
  topAnswersByHandoff: getTopAnswersByHandoff(state),
  wiseAgentRating: getWiseAgentRating(state),
  detectionRate: getDetectionRate(state),
  graphContainmentRateByBreakdownChannelId: getGraphContainmentRateByBreakdownChannelId(state),
  graphContainmentRateByBreakdownLanguage: getGraphContainmentRateByBreakdownLanguage(state),
  graphWiseAgentRatingByBreakdownChannelId: getGraphWiseAgentRatingByBreakdownChannelId(state),
  graphWiseAgentRatingByBreakdownLanguage: getGraphWiseAgentRatingByBreakdownLanguage(state),
});
const mapDispatchToProps = {
  setDialogType: setDialogType,
  setIntents: setIntents,
  setExcludedIntents: setExcludedIntents,
  setContainmentGraph: setContainmentGraph,
  setReactionsGraph: setReactionsGraph,
  setTopAnswers: setTopAnswers,
  setTopAnswersByHandoff: setTopAnswersByHandoff,
  setWiseAgentRating: setWiseAgentRating,
  setDetectionRate: setDetectionRate,
  setGraphContainmentRateByBreakdownChannelId: setGraphContainmentRateByBreakdownChannelId,
  setGraphContainmentRateByBreakdownLanguage: setGraphContainmentRateByBreakdownLanguage,
  setGraphWiseAgentRatingByBreakdownChannelId: setGraphWiseAgentRatingByBreakdownChannelId,
  setGraphWiseAgentRatingByBreakdownLanguage: setGraphWiseAgentRatingByBreakdownLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(WiseAgent);
