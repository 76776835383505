import axios from 'axios';

export default async (properties, messageId, projectId, translate) => {
  let URL = `/internal_api/message?project_id=${projectId}&translate=${translate}`;
  let response;
  try {
    response = await axios.put(URL, {
      messageId,
      properties,
      // selectedLanguage,
    });
  } catch (e) {
    console.log(e);
    return null;
  }

  return response;
};
