import React, { useState } from 'react';

import { v4 as uuidv4 } from 'uuid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import StarRoundedIcon from '@mui/icons-material/StarRounded';

import Checkbox from '@mui/material/Checkbox';

import './Table.scss';

const Table = ({ tableData, remarks, setRemarksValue, setFeedbackScoreValue, setRowNumber }) => {
  const { columns, data, title } = tableData;

  const [rating, setRating] = useState('');

  const getKeys = () => {
    if (data?.length > 0) {
      return Object.keys(data?.[0]);
    }
    return [];
  };

  const RenderRow = (props) => {
    return props.keys.map((key, index) => {
      return <td key={props.data[key] + key + index}>{props.data[key]}</td>;
    });
  };

  const getRowsData = () => {
    let items = data;
    let keys = getKeys();
    if (items) {
      return items.map((row, index) => {
        return (
          <tr key={index} className="table-row">
            <RenderRow key={index} data={row} keys={keys} />
          </tr>
        );
      });
    }
  };
  const handleRatingChange = (event) => {
    setRating(event.target.value);
    setFeedbackScoreValue(event.target.value);
  };

  const getRemarks = () => {
    const selectionMenuItems = [1, 2, 3, 4, 5];
    return (
      <FormControl variant="outlined" className="drop-down-style" sx={{ m: 1 }}>
        <Select className="select" displayEmpty value={rating} onChange={handleRatingChange}>
          <MenuItem value={''}>
            <em>All</em>
          </MenuItem>

          {selectionMenuItems.map((item) => {
            return (
              <MenuItem value={item} key={uuidv4()} className="selection-menu-item">
                <StarRoundedIcon
                  style={{ fill: 'rgba(255, 222, 6, 0.978)', marginRight: '15px' }}
                />
                {item}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  const handleCheckboxChange = (event) => {
    setRemarksValue(event.target.checked);
  };

  return (
    <div className="Table">
      <div className="table-title-container">
        <div className="title-text">{title}</div>
        {remarks && (
          <div className="title-right-container">
            <Checkbox onChange={handleCheckboxChange} /> Remarks only {getRemarks()}
          </div>
        )}
      </div>
      <table className="table-generic">
        <thead className="table-head">
          <tr className="title-container"></tr>

          <tr>{columns && columns.map((columnName) => <th key={uuidv4()}>{columnName}</th>)}</tr>
        </thead>

        <tbody>{getRowsData()}</tbody>
      </table>
    </div>
  );
};

export default Table;
