import React from 'react';

import './TableAvatar.scss';
import getAssetPath from '../../../../../../../utils/getAssetPath';

const TableAvatar = ({ user, link }) => {
  const firstLetterOfSender = (user) => {
    if (user) {
      const spacesRemoved = user.replace(/\s/g, '');
      const firstLetter = spacesRemoved.charAt(0);

      return firstLetter.toUpperCase();
    }
  };

  const TableAvatarSelector = () => {
    if (link.length > 5) {
      return (
        <div>
          <img src={link} className={'sender-img'} />
        </div>
      );
    } else if (user !== 'Unknown user' && user) {
      return <div className={'sender-first-letter title-text'}>{firstLetterOfSender(user)}</div>;
    } else {
      return (
        <div>
          <img src={getAssetPath('Ellipse_5.png')} className={'no-sender-img'} />
        </div>
      );
    }
  };

  return (
    <div className={'TableAvatar'}>
      <div className={'avatar-wrapper'}>{TableAvatarSelector()}</div>{' '}
      <div className={'user-name'}>{user}</div>
    </div>
  );
};

export default TableAvatar;
