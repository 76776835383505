import en from './English.json';
import ro from './Romanian.json';
let selectedLanguage = window.localStorage.getItem('currentUserLanguage');
if (!selectedLanguage) {
  selectedLanguage = 'ro';
}
const languageToFile = {
  ro,
  en,
};

const languageMap = languageToFile[selectedLanguage];
export default languageMap;
