import {
  selectAllLanguages,
  selectProjectLanguage,
  selectSecondaryLanguages,
} from '../store/crm/selectors';

export default (state) => {
  const languageOptions = [];
  const allLanguages = selectAllLanguages(state);
  const secondaryLanguages = selectSecondaryLanguages(state);
  const selectedLanguage = selectProjectLanguage(state);
  allLanguages.forEach((lang) => {
    if (secondaryLanguages.includes(lang.value) || lang.value === selectedLanguage) {
      languageOptions.push({
        text: lang.label,
        value: lang.value,
      });
    }
  });
  return {
    languageOptions,
    selectedLanguage,
  };
};
