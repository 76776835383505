import React from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import '../../CRM/containers/CRMDashboard/components/AnalyticsContainer/AnalyticsContainer.scss';
import CollectionRow from './CollectionRow';

const table = {
  marginTop: 10,
  width: '100%',
  paddingBottom: 10,
};
const tableCellHeader = {
  paddingLeft: 6,
};
export const CollectionTable = ({ data, headers, keys, onEdit, onDelete, projectId, appId }) => {
  return (
    <Table style={table}>
      <TableHead>
        <TableRow>
          {headers.map((header) => (
            <TableCell key={header} style={tableCellHeader}>
              {header}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((info) => (
          <CollectionRow
            key={info.id}
            info={info}
            keys={keys}
            projectId={projectId}
            appId={appId}
            onEdit={(appId) => {
              onEdit(appId, info);
            }}
            onDelete={onDelete}
          />
        ))}
      </TableBody>
    </Table>
  );
};
