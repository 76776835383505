import React from 'react';

import './FormErrors.scss';

const FormErrors = ({ errors }) => {
  if (errors && errors.length) {
    return <div className="FormErrors">{<p>{errors}</p>}</div>;
  }
  return null;
};

export default FormErrors;
