import React, { Component } from 'react';
import {produce} from 'immer';
import InputText from '../../../../components/common/input/InputText';
import DropDown from 'components/common/DropDown';
import _ from 'lodash';
import HeaderEntryView from './HeaderEntryView';
import Checkbox from 'components/common/LabeledCheckbox/Checkbox';

export default class RestServiceConfiguration extends Component {
  constructor(props) {
    super(props);

    const data = this.props.data !== undefined ? JSON.parse(this.props.data) : undefined;

    this.state = {
      headers: [{ key: '', value: '' }],
      rest_request_type: 'GET',
      url: '',
      body: '',
      isAsync: false,
    };

    if (data !== undefined && data !== null) {
      this.state = {
        headers: data.headers !== undefined ? data.headers : [{ key: '', value: '' }],
        url: data.url !== undefined ? data.url : '',
        rest_request_type: data.rest_request_type !== undefined ? data.rest_request_type : '',
        body: data.body !== undefined ? data.body : '',
        isAsync: data.isAsync !== undefined && data.isAsync !== '0' ? data.isAsync : false,
      };
    }

    this.unitOptions = [
      {
        label: 'GET',
        value: 'GET',
      },
      {
        label: 'POST',
        value: 'POST',
      },
    ];

    this.debouncedStateSync = _.debounce(this.handleStateChanged, 500);
  }

  handleHeaderEntryChange = (index, key = '', value = '') => {
    console.log('change', index, key, value);

    this.setState(
      produce((draft) => {
        draft.headers[index] = { key: key, value: value };

        if (!this.emptyHeaderExists(draft.headers)) {
          draft.headers = [...draft.headers, { key: '', value: '' }];
        }
      }),
      () => {
        this.debouncedStateSync();
      }
    );
  };

  handleValueChange = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      () => {
        this.debouncedStateSync();
      }
    );
  };

  emptyHeaderExists = (headers) => {
    let emptyHeaderExists = false;

    headers.forEach((header) => {
      if (header.key.length === 0 && header.value.length === 0) {
        emptyHeaderExists = true;
      }
    });

    return emptyHeaderExists;
  };

  handleBodyValueChange = (event) => {
    this.setState(
      {
        body: event.target.value,
      },
      () => {
        this.debouncedStateSync();
      }
    );
  };

  handleValueChangeCheckbox = (event) => {
    this.setState(
      {
        isAsync: event.target.checked,
      },
      () => {
        this.debouncedStateSync();
      }
    );
  };

  handleRemoveHeaderEntry = (index) => {
    this.setState(
      produce((draft) => {
        draft.headers.splice(index, 1);
        if (!this.emptyHeaderExists(draft.headers)) {
          draft.headers = [...draft.headers, { key: '', value: '' }];
        }
      }),
      () => {
        this.debouncedStateSync();
      }
    );
  };

  handleStateChanged = () => {
    this.props.onChange(this.props.integrationTypeId, JSON.stringify(this.state));
  };

  handleDeltaUnitChange = (value) => {
    this.setState({ rest_request_type: value }, () => {
      this.debouncedStateSync();
    });
  };

  render() {
    const containerStyle = {
      margin: '20px',
      overflowY: 'scroll',
    };

    const headersContainerStyle = {
      display: 'grid',
      justifyItems: 'center',
      margin: '5px',
    };

    const titleStyle = {
      fontSize: '18px',
      fontWeight: 'bold',
      color: 'black',
    };

    const bodyStyle = {
      width: '655px',
      margin: '5px',
    };

    const headerEntryViews = this.state.headers.map((header, index) => {
      return (
        <HeaderEntryView
          key={index}
          headerKey={header.key}
          headerValue={header.value}
          remove={this.handleRemoveHeaderEntry.bind(this, index)}
          onChange={(key, value) => this.handleHeaderEntryChange(index, key, value)}
        />
      );
    });

    return (
      <div style={containerStyle}>
        <InputText
          title="URL"
          value={this.state.url}
          onValueChanged={this.handleValueChange}
          name="url"
        />

        <DropDown
          value={this.state.rest_request_type}
          defaultText="GET"
          options={this.unitOptions}
          onSelect={this.handleDeltaUnitChange}
          containerStyle={{
            width: 300,
            marginLeft: 0,
          }}
        />

        <p style={titleStyle}>{'Headers'}</p>
        <div style={headersContainerStyle}>{headerEntryViews}</div>
        <p style={titleStyle}>{'Body'}</p>
        <textarea
          style={bodyStyle}
          value={this.state.body}
          onChange={this.handleBodyValueChange}
        ></textarea>
        <p style={titleStyle}>{'Is Asyncronous'}</p>
        <Checkbox checked={this.state.isAsync} onChange={this.handleValueChangeCheckbox} />
      </div>
    );
  }
}
