import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Delete from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import CloudDownload from '@mui/icons-material/CloudDownload';

import './Document.scss';
import DeleteConfirmationModal from 'components/ConfirmationModal/DeleteConfirmationModal';
import languageMap from 'Language/Language';

const tableCellStyle = {
  padding: 6,
};

const DocumentRow = ({ id, name, language, size, created_at, onDelete, onDownload }) => {
  const onDeleteClick = () => {
    onDelete(id);
  };

  const onDownloadClick = () => {
    onDownload(id, name);
  };

  if (created_at === undefined) {
    created_at = 'Uploading';
  }

  return (
    <TableRow className="Documents_tableRow" key={id}>
      <TableCell padding="none" style={tableCellStyle}>
        <div>{name}</div>
      </TableCell>
      <TableCell padding="none" style={tableCellStyle}>
        <div>{language}</div>
      </TableCell>
      <TableCell padding="none" style={tableCellStyle}>
        <div>{size}</div>
      </TableCell>
      <TableCell padding="none" style={{ ...tableCellStyle, width: 250 }}>
        <div>{created_at}</div>
      </TableCell>
      <TableCell padding="none" style={tableCellStyle} key="buttons">
        <IconButton title={languageMap.download} onClick={onDownloadClick} size="large">
          <CloudDownload />
        </IconButton>
        <DeleteConfirmationModal
          onDelete={onDeleteClick}
          trigger={
            <IconButton color="secondary" title={languageMap.delete} size="large">
              <Delete />
            </IconButton>
          }
        />
      </TableCell>
    </TableRow>
  );
};

export default DocumentRow;
