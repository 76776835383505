import { createReducer } from '@reduxjs/toolkit';

import {
  setContainedConversationsData,
  setContainedConversationsLabels,
  setContainedConversationsTitle,
  setConversationsGraph,
  setUniqueUsers,
  setRepliesSent,
  setBusinessHourConversation,
  setConversationsByBreakdownChannel,
  setConversationsByBreakdownLanguage,
  setCustomerSatisfactionScore,
  setInboundConversations,
  setContainmentGraph,
  setReactionsGraph,
  setHeatMapData,
  setConversationRating,
  setTableFeedback,
  setRemarks,
  setFeedbackScore,
  setDialogType,
  setIntents,
  setExcludedIntents,
  setTopAnswers,
  setTopAnswersByHandoff,
  setWiseAgentRating,
  setGraphContainmentRateByBreakdownChannelId,
  setGraphContainmentRateByBreakdownLanguage,
  setGraphWiseAgentRatingByBreakdownChannelId,
  setGraphWiseAgentRatingByBreakdownLanguage,
  setDetectionRate,
  setConversationsByBreakdown,
  setAverageResponseTime,
  setHeatMapTeamPerformance,
  setAgentPerformance,
} from './actions';

const initialState = {
  containedConversationsData: null,
  containedConversationsLabels: null,
  containedConversationsTitle: null,
  conversationsGraph: {},
  uniqueUsers: {},
  repliesSent: {},
  businessHourConversation: {},
  conversationsByBreakdownChannel: {},
  conversationsByBreakdownLanguage: {},
  customerSatisfactionScore: {},
  inboundConversations: {},
  containmentGraph: {},
  reactionsGraph: {},
  heatMapData: {},
  conversationRatings: {},
  tableFeedback: {},
  remarks: false,
  feedbackScore: '',
  dialogType: null,
  intents: [],
  excludedIntents: {},
  topAnswers: {},
  topAnswersByHandoff: {},
  wiseAgentRating: {},
  graphContainmentRateByBreakdownChannelId: {},
  graphContainmentRateByBreakdownLanguage: {},
  graphWiseAgentRatingByBreakdownChannelId: {},
  graphWiseAgentRatingByBreakdownLanguage: {},
  detectionRate: {},
  conversationsByBreakdown: {},
  averageResponseTime: {},
  heatMapTeamPerformance: {},
  agentPerformance: {},
};

export default createReducer(initialState, (builder) => {
  // .addDefaultCase((state, action) => { });
  builder
    .addCase(setContainedConversationsData, (state, action) => {
      state.containedConversationsData = action.payload;
    })
    .addCase(setContainedConversationsLabels, (state, action) => {
      state.containedConversationsLabels = action.payload;
    })
    .addCase(setContainedConversationsTitle, (state, action) => {
      state.containedConversationsTitle = action.payload;
    })
    .addCase(setConversationsGraph, (state, action) => {
      state.conversationsGraph = action.payload;
    })
    .addCase(setUniqueUsers, (state, action) => {
      state.uniqueUsers = action.payload;
    })
    .addCase(setRepliesSent, (state, action) => {
      state.repliesSent = action.payload;
    })
    .addCase(setBusinessHourConversation, (state, action) => {
      state.businessHourConversation = action.payload;
    })
    .addCase(setConversationsByBreakdownChannel, (state, action) => {
      state.conversationsByBreakdownChannel = action.payload;
    })
    .addCase(setConversationsByBreakdownLanguage, (state, action) => {
      state.conversationsByBreakdownLanguage = action.payload;
    })
    .addCase(setCustomerSatisfactionScore, (state, action) => {
      state.customerSatisfactionScore = action.payload;
    })
    .addCase(setInboundConversations, (state, action) => {
      state.inboundConversations = action.payload;
    })
    .addCase(setContainmentGraph, (state, action) => {
      state.containmentGraph = action.payload;
    })
    .addCase(setReactionsGraph, (state, action) => {
      state.reactionsGraph = action.payload;
    })
    .addCase(setHeatMapData, (state, action) => {
      state.heatMapData = action.payload;
    })
    .addCase(setDialogType, (state, action) => {
      state.dialogType = action.payload;
    })
    .addCase(setIntents, (state, action) => {
      state.intents = action.payload;
    })

    .addCase(setExcludedIntents, (state, action) => {
      state.excludedIntents = action.payload;
    })

    .addCase(setTopAnswers, (state, action) => {
      state.topAnswers = action.payload;
    })
    .addCase(setTopAnswersByHandoff, (state, action) => {
      state.topAnswersByHandoff = action.payload;
    })
    .addCase(setWiseAgentRating, (state, action) => {
      state.wiseAgentRating = action.payload;
    })
    .addCase(setGraphContainmentRateByBreakdownChannelId, (state, action) => {
      state.graphContainmentRateByBreakdownChannelId = action.payload;
    })
    .addCase(setGraphContainmentRateByBreakdownLanguage, (state, action) => {
      state.graphContainmentRateByBreakdownLanguage = action.payload;
    })
    .addCase(setGraphWiseAgentRatingByBreakdownChannelId, (state, action) => {
      state.graphWiseAgentRatingByBreakdownChannelId = action.payload;
    })
    .addCase(setGraphWiseAgentRatingByBreakdownLanguage, (state, action) => {
      state.graphWiseAgentRatingByBreakdownLanguage = action.payload;
    })
    .addCase(setDetectionRate, (state, action) => {
      state.detectionRate = action.payload;
    })
    .addCase(setConversationsByBreakdown, (state, action) => {
      state.conversationsByBreakdown = action.payload;
    })
    .addCase(setAverageResponseTime, (state, action) => {
      state.averageResponseTime = action.payload;
    })
    .addCase(setHeatMapTeamPerformance, (state, action) => {
      state.heatMapTeamPerformance = action.payload;
    })
    .addCase(setAgentPerformance, (state, action) => {
      state.agentPerformance = action.payload;
    })

    .addCase(setConversationRating, (state, action) => {
      state.conversationRatings = action.payload;
    })
    .addCase(setTableFeedback, (state, action) => {
      state.tableFeedback = action.payload;
    })
    .addCase(setRemarks, (state, action) => {
      state.remarks = action.payload;
    })
    .addCase(setFeedbackScore, (state, action) => {
      state.feedbackScore = action.payload;
    })
    .addDefaultCase((state, action) => {});
});
