import React, { Component } from 'react';

import MessageOptions from 'pages/Flow/components/Message/MessageOptions';
import InputText from 'components/common/input/InputText';
import './CallcenterErrorHandlingMessages.scss';
import languageMap from 'Language/Language';
export default class CallcenterErrorHandlingMessages extends Component {
  onMessageOptionsChange = (value) => {
    this.props.onMessageOptionsChange(value);
  };

  onInputValueChange = (name, value) => {
    this.props.onParameterChange(name, parseFloat(value));
  };

  render() {
    const {
      title,
      description,
      timeoutResponseOptions,
      minListenTime,
      noResponseTimeout,
      timeExceeded,
      selectedLanguage,
    } = this.props;

    return (
      <div className="CallcenterErrorHandlingMessages">
        <MessageOptions
          title={title}
          description={description}
          options={timeoutResponseOptions}
          onChange={this.onMessageOptionsChange}
          selectedLanguage={selectedLanguage}
        />
        <div className="CallcenterErrorHandlingMessages_ParameterContainer">
          <InputText
            type="number"
            step="any"
            min="-100"
            title={languageMap.minimumListenTime}
            name="min_listen_time"
            value={minListenTime}
            onValueChanged={this.onInputValueChange}
          />
        </div>
        <div className="CallcenterErrorHandlingMessages_ParameterContainer">
          <InputText
            type="number"
            step="any"
            min="-100"
            title={languageMap.noResponseTimeout}
            name="no_response_timeout"
            value={noResponseTimeout}
            onValueChanged={this.onInputValueChange}
          />
        </div>
        <div className="CallcenterErrorHandlingMessages_ParameterContainer">
          <InputText
            type="number"
            step="any"
            min="-100"
            title={languageMap.timeExceeded}
            name="time_exceeded"
            value={timeExceeded}
            onValueChanged={this.onInputValueChange}
          />
        </div>
      </div>
    );
  }
}
