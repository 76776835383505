import Axios from 'axios';
let WEBCHAT_SCRIPT_ID = 'wisevoice_chat_parameters';
let addWebchatTimeout = null;
let addingWebchat = null;
let projectIdToClientKey = {};
export async function addWebchat(projectId) {
  addingWebchat = true;
  if (!projectIdToClientKey[projectId]) {
    const response = await Axios.get('/internal_api/webchat/clientKey', {
      params: { projectId },
    }).catch((error) => {
      console.error('Response WEBCHAT error', error);
    });
    if (!response?.data?.clientKey) {
      return;
    }
    projectIdToClientKey[projectId] = response.data.clientKey;
  }
  addToDOM(projectIdToClientKey[projectId], projectId);
}

export function addToDOM(clientKey, projectId) {
  if (clientKey === undefined) {
    return;
  }
  if (document.getElementById(WEBCHAT_SCRIPT_ID)) {
    document.getElementById(WEBCHAT_SCRIPT_ID).remove();
  }
  clearTimeout(addWebchatTimeout);
  if (addingWebchat) {
    addWebchatTimeout = setTimeout(() => {
      const script = document.createElement('script');
      script.id = WEBCHAT_SCRIPT_ID;
      script.src = process.env.REACT_APP_WEBCHAT_URL;
      script.async = true;
      script.setAttribute('clientKey', clientKey);
      script.setAttribute('projectId', projectId);
      if (process.env.NODE_ENV === 'development') {
        script.setAttribute('isLocalCookie', true);
      }
      document.body.appendChild(script);
    }, 2000);
  }
}

export function removeWebchat() {
  clearTimeout(addWebchatTimeout);
  addingWebchat = false;
  const script = document.getElementById(WEBCHAT_SCRIPT_ID);
  const webchat = document.getElementById('wisevoice_chat');
  window.clearTabObserverInterval?.();
  if (script) {
    script.remove();
    webchat?.remove();
  }
}
