import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import { FlowListElement } from 'pages/ProjectDetails/components/FlowListElement';
import { AddButton } from 'components/common/Button/Buttons';
import CreateNewFlow from 'pages/ProjectDetails/components/CreateNewFlow';
import languageMap from 'Language/Language';

import './Flows.scss';
import Axios from 'axios';
import {produce} from 'immer';
import FlowSettingsDialog from '../FlowSettingsDialog';

class Flows extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showCreateFlowMenu: false,
      flows: [],
    };
  }

  componentDidMount() {
    this.getFlows();
  }

  editFlow = (flowId, flowName) => {
    this.selectedFlowId = flowId;
    this.selectedFlowName = flowName;
    this.handleShowFlowSettingsDialog();
  };

  getFlows = () => {
    this.handleCloseFlowSettingsDialog();
    Axios.get('/internal_api/project/' + this.props.projectId).then((response) => {
      this.setState(
        produce((draft) => {
          draft.flows = response.data.apps.map((app) => {
            return {
              name: app.app_name,
              id: app.app_id,
            };
          });

          draft.integrations = response.data.integrations;
          return draft;
        })
      );
    });
  };

  handleNewFlow = (flow) => {
    this.setState(
      produce((draft) => {
        draft.flows.splice(0, 0, flow);
      })
    );
  };

  handleShowFlowSettingsDialog = () => {
    this.setState({ showFlowSettingsDialog: true });
  };

  handleCloseFlowSettingsDialog = () => {
    this.setState({ showFlowSettingsDialog: false });
  };

  handleMenuClose = () => {
    this.setState({
      showCreateFlowMenu: false,
    });
  };

  handleOnNewFlowButtonClick = () => {
    this.setState({
      showCreateFlowMenu: true,
    });
  };

  render() {
    const { showCreateFlowMenu, showFlowSettingsDialog, flows } = this.state;
    const { projectId, style } = this.props;
    let createFlowMenu = null;
    let flowSettingsDialog;

    if (showFlowSettingsDialog) {
      flowSettingsDialog = (
        <FlowSettingsDialog
          onHide={this.handleCloseFlowSettingsDialog}
          projectId={projectId}
          flowId={this.selectedFlowId}
          flowName={this.selectedFlowName}
          onFlowChanged={this.getFlows}
        />
      );
    }

    if (showCreateFlowMenu) {
      createFlowMenu = (
        <CreateNewFlow
          projectId={projectId}
          close={this.handleMenuClose}
          onNewFlow={this.handleNewFlow}
        />
      );
    }

    return (
      <div className="Flows" style={style}>
        {
          <>
            {flowSettingsDialog}

            <div className="Flows_Header">
              <AddButton
                icon="add"
                text={languageMap.createNewFlow}
                onClick={this.handleOnNewFlowButtonClick}
              />
            </div>
            <div className="Flows_ListContainer">
              {_.clone(flows)
                .sort((a, b) => b.id - a.id)
                .map((flow) => {
                  return (
                    <FlowListElement
                      key={flow.id}
                      flowId={flow.id}
                      flowTitle={flow.name}
                      projectId={projectId}
                      onFlowChanged={this.getFlows}
                      editFlow={this.editFlow}
                      pathName={'/project/' + projectId + '/flow/' + flow.id + '/-1'}
                    />
                  );
                })}
            </div>
          </>
        }
        {createFlowMenu}
      </div>
    );
  }
}

export default connect(null)(Flows);
