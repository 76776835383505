import React from 'react';

const Collapse = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M18.1 7.2214C16.29 7.2214 15.55 5.9414 16.45 4.3714C16.97 3.4614 16.66 2.3014 15.75 1.7814L14.02 0.791401C13.23 0.321401 12.21 0.601401 11.74 1.3914L11.63 1.5814C10.73 3.1514 9.25 3.1514 8.34 1.5814L8.23 1.3914C7.78 0.601401 6.76 0.321401 5.97 0.791401L4.24 1.7814C3.33 2.3014 3.02 3.4714 3.54 4.3814C4.45 5.9414 3.71 7.2214 1.9 7.2214C0.86 7.2214 0 8.0714 0 9.1214V10.8814C0 11.9214 0.85 12.7814 1.9 12.7814C3.71 12.7814 4.45 14.0614 3.54 15.6314C3.02 16.5414 3.33 17.7014 4.24 18.2214L5.97 19.2114C6.76 19.6814 7.78 19.4014 8.25 18.6114L8.36 18.4214C9.26 16.8514 10.74 16.8514 11.65 18.4214L11.76 18.6114C12.23 19.4014 13.25 19.6814 14.04 19.2114L15.77 18.2214C16.68 17.7014 16.99 16.5314 16.47 15.6314C15.56 14.0614 16.3 12.7814 18.11 12.7814C19.15 12.7814 20.01 11.9314 20.01 10.8814V9.1214C20 8.0814 19.15 7.2214 18.1 7.2214ZM10 13.2514C8.21 13.2514 6.75 11.7914 6.75 10.0014C6.75 8.2114 8.21 6.7514 10 6.7514C11.79 6.7514 13.25 8.2114 13.25 10.0014C13.25 11.7914 11.79 13.2514 10 13.2514Z" />
  </svg>
);

export default Collapse;
