import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Logo } from 'assets/logo/Logo';
import LoginPanel from 'assets/svg/LoginPanel';

import './Auth.scss';
import Axios from 'axios';
import AllRights from '../../components/AllRights/AllRights';

const ResetPassword = () => {
  const search = useLocation().search;
  const urlParams = new URLSearchParams(search);
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  // const [loading, setLoading] = useState(false);
  // const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const token = urlParams.get('token');

  const isValidPassword = (password) => {
    return password.length >= 8;
  };

  const handleResetPassword = () => {
    if (!isValidPassword(password)) {
      alert('Invalid password');
      return;
    }

    // setLoading(true);

    Axios.post('/internal_api/reset_password', {
      password: password,
      token: token,
    })
      .then((response) => {
        alert(response.data);
        navigate('/projects', { replace: true });
      })
      .catch((error) => {
        console.log(error);
        // setErrors(error.response?.data?.errors || {});
      });
  };

  return (
    <div className="Auth">
      <div className="Container">
        <Logo className="Logo" />
        <span className="Title dm-sans-700">Reset password</span>
        <div className="FormRow">
          <div className="RegisterFormLabel dm-sans-500">
            <span className="FormLabel dm-sans-500">New password</span>
            {!isValidPassword(password) && (
              <span className="FormLabel dm-sans-500" style={{ color: '#0530F9' }}>
                * (required)
              </span>
            )}
          </div>
          <input
            name="password"
            autoComplete="current-password"
            className="Input RegisterInput dm-sans-400"
            placeholder="Your password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="FormRow">
          <div className="RegisterFormLabel dm-sans-500">
            <span className="FormLabel dm-sans-500">Confirm password</span>
            {password !== confirmedPassword && (
              <span className="FormLabel dm-sans-500" style={{ color: '#0530F9' }}>
                * (invalid)
              </span>
            )}
          </div>
          <input
            name="confirm_password"
            autoComplete="current-password"
            className="Input RegisterInput dm-sans-400"
            placeholder="Your password"
            type="password"
            value={confirmedPassword}
            onChange={(e) => setConfirmedPassword(e.target.value)}
          />
        </div>
        <button className="Submit dm-sans-700" onClick={handleResetPassword}>
          Reset password
        </button>
        <AllRights />
      </div>

      <LoginPanel className="ShowOnlyOnDesktop" />
    </div>
  );
};

export default ResetPassword;
