import React, { Component } from 'react';
import debounce from 'lodash/debounce';

import VerticalOrderedList from 'pages/Flow/components/Message/VerticalOrderedList';
import InputText from 'components/common/input/InputText';
import withNotifications from 'components/withNotifications';

import './EmailServiceConfiguration.scss';

class EmailServiceConfiguration extends Component {
  constructor(props) {
    super(props);

    const data = this.props.data !== undefined ? JSON.parse(this.props.data) : {};

    if (data !== null) {
      this.state = {
        recipients: data.recipients || [],
        subject: data.subject || '',
        body: data.body || '',
        sender_email_info: data.sender_email_info || {},
      };
    } else {
      this.state = {
        recipients: [],
        subject: '',
        body: '',
        sender_email_info: {},
      };
    }
    console.log('email info:', this.state);

    this.debouncedStateSync = debounce(this.handleStateChanged, 500);
  }

  handleStateChanged = () => {
    this.props.onChange(this.props.integrationTypeId, JSON.stringify(this.state));
  };

  onElementChanged = (element) => (value) => {
    this.setState(
      {
        [element]: value,
      },
      () => {
        this.debouncedStateSync();
      }
    );
  };

  onEmailInfoChange = (key, value) => {
    const emailInfo = { ...this.state.sender_email_info };
    emailInfo[key] = value;
    this.setState(
      {
        sender_email_info: emailInfo,
      },
      () => {
        this.debouncedStateSync();
      }
    );
  };

  onValueChanged = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      () => {
        this.debouncedStateSync();
      }
    );
  };

  onBodyContentChanged = (event) => {
    const { name, value } = event.target;
    this.onValueChanged(name, value);
  };

  renderBodyInput = () => {
    const { body } = this.state;

    return (
      <div className="EmailServiceConfiguration_Body">
        <span className="EmailServiceConfiguration_Body_Title">Email Body</span>
        <textarea
          className="EmailServiceConfiguration_Body_Input"
          name="body"
          value={body}
          onChange={this.onBodyContentChanged}
          placeholder="Body..."
        />
      </div>
    );
  };

  render() {
    const { recipients, subject, sender_email_info } = this.state;

    return (
      <div className="EmailServiceConfiguration">
        <div className="EmailServiceConfiguration_EmailInfo">
          <InputText
            type="text"
            title="Sender email"
            name="email"
            value={sender_email_info.email}
            onValueChanged={this.onEmailInfoChange}
            shouldBeColumn={true}
            placeholder="Sender email"
          />
        </div>
        <div className="EmailServiceConfiguration_EmailInfo">
          <InputText
            type="text"
            title="Sender email username"
            name="email_username"
            value={sender_email_info.email_username}
            onValueChanged={this.onEmailInfoChange}
            shouldBeColumn={true}
            placeholder="Sender email username"
          />
        </div>
        <div className="EmailServiceConfiguration_EmailInfo">
          <InputText
            type="password"
            title="Password"
            name="password"
            value={sender_email_info.password}
            onValueChanged={this.onEmailInfoChange}
            shouldBeColumn={true}
            placeholder="Password"
          />
        </div>
        <div className="EmailServiceConfiguration_EmailInfo">
          <InputText
            type="text"
            title="Outbound email server"
            name="outbound_email_server"
            value={sender_email_info.outbound_email_server}
            onValueChanged={this.onEmailInfoChange}
            shouldBeColumn={true}
            placeholder="Outbound email server"
          />
        </div>
        <div className="EmailServiceConfiguration_EmailInfo">
          <InputText
            type="text"
            title="Outbound port"
            name="outbound_port"
            value={sender_email_info.outbound_port}
            onValueChanged={this.onEmailInfoChange}
            shouldBeColumn={true}
            placeholder="Outbound port"
          />
        </div>
        <div className="EmailServiceConfiguration_EmailInfo">
          <InputText
            type="text"
            title="Outbound encryption"
            name="outbound_encryption"
            value={sender_email_info.outbound_encryption}
            onValueChanged={this.onEmailInfoChange}
            shouldBeColumn={true}
            placeholder="Outbound encryption"
          />
        </div>
        <div className="EmailServiceConfiguration_Recipients">
          <VerticalOrderedList
            title="Email Recipients"
            elements={recipients}
            onChange={this.onElementChanged('recipients')}
          />
        </div>
        <div className="EmailServiceConfiguration_Subject">
          <InputText
            type="text"
            title="Email Subject"
            name="subject"
            value={subject}
            onValueChanged={this.onValueChanged}
            shouldBeColumn={true}
            placeholder="Subject"
          />
        </div>
        {this.renderBodyInput()}
      </div>
    );
  }
}

export default withNotifications(EmailServiceConfiguration);
