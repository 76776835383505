import React, { useState, useRef, Fragment } from 'react';
import debounce from 'lodash/debounce';

import CarouselSlide from './CarouselSlide';
import Button from './components/Button';
import './Carousel.css';

const CarouselComponent = ({ carouselElements }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const scrollContainerRef = useRef(null);

  const slidesNumber = carouselElements.length;

  const viewport = window.visualViewport;
  const screenWidth = viewport?.width || window.innerWidth;
  const isMobile = screenWidth <= 500;
  const slideOffset = isMobile ? 210 : 260;
  const slideWidth = isMobile ? 190 : 240;

  const scrollBack = () => {
    const nextSlide = Math.max(currentSlide - 1, 0);
    setCurrentSlide(nextSlide);
    scrollContainerRef.current.scrollLeft = nextSlide * slideOffset;
  };

  const scrollForward = () => {
    const nextSlide = Math.min(currentSlide + 1, slidesNumber - 1);
    setCurrentSlide(nextSlide);
    scrollContainerRef.current.scrollLeft = nextSlide * slideOffset;
  };

  const scrollFixedPosition = () => {
    if (!isMobile) {
      return;
    }
    const newScrollPos = scrollContainerRef.current.scrollLeft;
    if (newScrollPos >= (slidesNumber - 2) * slideOffset + 20) {
      setCurrentSlide(slidesNumber - 1);
      scrollContainerRef.current.scrollLeft = (slidesNumber - 1) * slideOffset;
      return;
    }
    const currentSlide = Math.round(newScrollPos / slideOffset);
    const posDelta = Math.abs(currentSlide * slideOffset - newScrollPos);
    const currentSlidePos = currentSlide * slideOffset;

    if (currentSlide === slidesNumber - 1) {
      setCurrentSlide(currentSlide);
      scrollContainerRef.current.scrollLeft = (slidesNumber - 1) * slideOffset;
    } else if (posDelta < 100) {
      scrollContainerRef.current.scrollLeft = currentSlidePos;
      setCurrentSlide(currentSlide);
    } else if (newScrollPos < currentSlidePos && currentSlide !== 0) {
      setCurrentSlide(currentSlide - 1);
      scrollContainerRef.current.scrollLeft = (currentSlide - 1) * slideOffset;
    } else if (newScrollPos > currentSlidePos && currentSlide !== slidesNumber - 1) {
      setCurrentSlide(currentSlide + 1);
      scrollContainerRef.current.scrollLeft = (currentSlide + 1) * slideOffset;
    }
  };
  const debouncedScroll = debounce(scrollFixedPosition, 150);

  const renderScrollBackButton = () => {
    const isFirstSlide = currentSlide === 0;

    return !isFirstSlide && !isMobile && <Button type="left" onClick={scrollBack} />;
  };

  const renderScrollForwardButton = () => {
    const isLastSlide = currentSlide === slidesNumber - 1;

    return !isLastSlide && !isMobile && <Button type="right" onClick={scrollForward} />;
  };

  const renderCarouselSlides = () => {
    return carouselElements.map((slide, index) => {
      return (
        <Fragment key={index}>
          <CarouselSlide key={index} index={index} slide={slide} width={slideWidth} />
        </Fragment>
      );
    });
  };

  return (
    <div className="CarouselComponent">
      <div className="CarouselComponent_ScrollBackContainer">{renderScrollBackButton()}</div>
      <div
        className="CarouselComponent_ScrollContainer"
        ref={scrollContainerRef}
        onScroll={debouncedScroll}
      >
        <div className="CarouselComponent_SlidesContainer">{renderCarouselSlides()}</div>
      </div>
      <div className="CarouselComponent_ScrollForwardContainer">{renderScrollForwardButton()}</div>
    </div>
  );
};

export default CarouselComponent;
