import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Axios from 'axios';
import { Dropdown } from 'semantic-ui-react';

import { NODES_ENDPOINT } from 'utils/constants/index';

const NodeFilter = ({ selectedNode, onChange }) => {
  const { id: projectId } = useParams();
  const [nodes, setNodes] = useState([]);
  /**
   * Fetch nodes
   */
  useEffect(() => {
    Axios.get(NODES_ENDPOINT, { params: { projectId } })
      .then(({ data }) => {
        setNodes(data);
      })
      .catch(console.log);
  }, [projectId]);

  const nodeOptions = useMemo(() => {
    const options = nodes.map(({ node_id, state_name }, index) => {
      return {
        key: `${node_id}+${index}`,
        text: state_name,
        value: node_id,
      };
    });

    return [{ key: 0, text: 'All states', value: 'all' }, ...options];
  }, [nodes]);

  return (
    <Dropdown
      placeholder="Select state"
      selection
      search
      options={nodeOptions}
      onChange={(e, { value }) => {
        onChange(value === '' ? null : value);
      }}
      value={selectedNode}
      loading={false}
    />
  );
};

export default NodeFilter;
