import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Logo } from 'assets/logo/Logo';
import Loader from 'components/common/Loader';
import LoginPanel from 'assets/svg/LoginPanel';

import './Auth.scss';
import axios from 'axios';
import AllRights from '../../components/AllRights/AllRights';

const Confirm = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const search = useLocation().search;
  const urlParams = new URLSearchParams(search);

  const email = urlParams.get('email');
  const code = urlParams.get('code');

  useEffect(() => {
    if (!email || !code) {
      setLoading(false);
      let error = 'Invalid url, missing ';
      if (!email) {
        error += 'email';
        if (!code) {
          error += ' and confirmation code';
        }
      } else {
        error += 'confirmation code';
      }

      setError(error);
      setLoading(false);
      return;
    }

    axios
      .get('/internal_api/confirm_email', { params: { email, code } })
      .then((response) => {
        if (response.data != 'ok') {
          setError(response.data);
        }

        console.log('confirm response:', response.data);
      })
      .catch((error) => {
        console.log('Error when confirming email', error);
        setError('Failed to confirm email');
      })
      .finally(setLoading(false));
  }, []);

  return (
    <div className="Auth">
      <div className="Container">
        <Link to="/login">
          <Logo className="Logo" />
        </Link>
        {error && <span className="Title dm-sans-700">Confirm Email</span>}

        {loading && <Loader style={{ width: '100%', height: '100%' }} />}

        {error && (
          <span className="dm-sans-500" style={{ color: 'red', fontSize: 20 }}>
            {error}
          </span>
        )}

        {!error && !loading && (
          <span className="dm-sans-500" style={{ fontSize: 20 }}>
            Confirmation succesful
          </span>
        )}

        <Link to="/login" style={{ fontSize: 20, marginTop: 10 }}>
          <span className="dm-sans-500">Login</span>
        </Link>

        <AllRights />
      </div>

      {document.title != 'Tectu' && <LoginPanel className="ShowOnlyOnDesktop" />}
    </div>
  );
};

export default Confirm;
