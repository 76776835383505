import React, { memo } from 'react';
import AttachButtonIcon from 'assets/svg/AttachFile';

import './FileInput.scss';

const FileInput = ({ onSelect, disabled }) => {
  const handleFileSelect = (event) => {
    if (event.target.files[0]) {
      onSelect(event.target.files[0]);
      event.target.value = '';
    }
  };

  return (
    <div className="FileInput">
      <label htmlFor="inbox-file-attach" className="button">
        <AttachButtonIcon className="attach-button-icon" />
      </label>
      <input
        id="inbox-file-attach"
        name="inbox-file"
        className="input"
        type="file"
        onChange={handleFileSelect}
        accept=".txt, .pdf, .gif, .jpg, .jpeg, .png, .xls, .xlsx, .docx"
        disabled={disabled}
      />
    </div>
  );
};

export default memo(FileInput);
