import React from 'react';

const AttachFile = (props) => (
  <svg
    width="20"
    height="23"
    viewBox="0 0 20 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.3578 18.3369C11.1472 20.0526 9.14327 21.0225 7.10048 20.8818C5.05788 20.7409 3.28699 19.5104 2.45491 17.6539C1.62293 15.7973 1.85601 13.5969 3.0667 11.8811L8.71392 3.87795C9.52094 2.73425 10.857 2.08756 12.2187 2.18156C13.5804 2.27546 14.761 3.09577 15.3157 4.33346C15.8705 5.57116 15.7151 7.03816 14.9081 8.18185L10.3901 14.5846C9.98663 15.1565 9.31862 15.4798 8.63766 15.4328C7.95682 15.3858 7.36653 14.9756 7.08922 14.3568C6.81179 13.738 6.8895 13.0045 7.293 12.4326L11.8109 6.02985L10.2625 4.95394L5.74457 11.3567C4.93755 12.5004 4.7821 13.9674 5.33675 15.205C5.89152 16.4427 7.07209 17.263 8.43388 17.357C9.79554 17.4509 11.1316 16.8042 11.9386 15.6605L16.4565 9.25776C17.667 7.54222 17.9002 5.34171 17.068 3.48529C16.2361 1.62867 14.4652 0.39819 12.4225 0.257384C10.3799 0.116441 8.37581 1.08651 7.16535 2.80195L1.51813 10.8051C0.319906 12.5033 -0.176603 14.5853 0.137669 16.5934C0.452003 18.6011 1.55149 20.3704 3.19423 21.5118C4.83697 22.6533 6.88857 23.0734 8.89722 22.6798C10.9062 22.2862 12.708 21.1111 13.9063 19.4128L19.5535 11.4097L18.0049 10.3337L12.3578 18.3369Z"
      fill="#A3AED0"
    />
  </svg>
);

export default AttachFile;
