import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { MAIN_COLOR } from '../../../mainTheme';

export default function Loader(props) {
  const containerStyle = {
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    ...props.style,
  };

  const color = props.color || MAIN_COLOR;
  const size = props.size || 40;

  return (
    <div style={containerStyle} className={props.className}>
      <CircularProgress style={{ color }} size={size} />
    </div>
  );
}
