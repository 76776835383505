import React, { Component } from 'react';
import { produce } from 'immer';
import Modal from '../../../pages/Flow/components/Modal';
import ContextMenu from '../../ContextMenu';
import AutoInput from '../../AutoInput/AutoInput';
import languageMap from '../../../Language/Language';
import { selectBotTab } from '../../../store/crm/selectors';
import { connect } from 'react-redux';

class UtteranceText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_entity_menu: false,
      entity_menu_left: 0,
      entity_menu_top: 0,
      inputValue: this.props.value,
      initialValue: this.props.value,
    };
  }

  handleSelect(event) {
    let strLength = event.target.value.length;
    let calculatedSelectionLenght = event.target.selectionEnd - event.target.selectionStart;
    let top = event.nativeEvent.pageY;
    let left = event.nativeEvent.pageX;
    let selection_start = event.target.selectionStart;
    let selection_end = event.target.selectionEnd;
    const value = event.target.value;
    const clean = value.substring(selection_start, selection_end).trim();
    if (!clean) {
      return;
    }
    for (let i = event.target.selectionStart; i <= event.target.selectionEnd; i++) {
      if (value[i] !== ' ') {
        selection_start = i;
        break;
      }
    }
    for (let i = event.target.selectionEnd; i >= event.target.selectionStart; i--) {
      if (value[i - 1] !== ' ') {
        selection_end = i;
        break;
      }
    }
    let entity_menu_options = this.props.entities.map((row) => {
      return {
        label: row.entity_name,
        returnValue: [row.entity_id, row.entity_name],
      };
    });

    if (strLength === calculatedSelectionLenght && this.props.botTab === 'QnA') {
      return;
    }
    // eslint-disable-next-line eqeqeq
    if (selection_start !== selection_end && top && left) {
      this.setState(
        produce((draft) => {
          draft.show_entity_menu = true;
          draft.entity_menu_top = top;
          draft.entity_menu_left = left;
          draft.selection_start = selection_start;
          draft.selection_end = selection_end;
          draft.entity_menu_options = entity_menu_options;
        })
      );
    }
  }

  handleEntitySelect([entity_id, entity_name]) {
    this.setState({ show_entity_menu: false });
    this.props.onNewEntity(
      entity_id,
      entity_name,
      this.state.selection_start,
      this.state.selection_end
    );
  }

  handleInputChange = (event) => {
    const value = event.target.value;
    this.setState({ inputValue: value });
  };

  handleSave = () => {
    if (this.props.onSave && this.state.inputValue !== this.state.initialValue) {
      this.props.onSave(this.state.inputValue);
      this.setState({ initialValue: this.state.inputValue });
    }
  };

  onKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.target.blur();
      this.setState({ show_entity_menu: false });
    }
  };

  render() {
    let entity_menu = null;
    const { isShort } = this.props;

    const inputStyle = {
      border: 'none',
      resize: 'none',
      fontSize: 15,
      color: '#333',
      padding: '5px 10px',
      backgroundColor: this.props.hasConflict ? 'rgb(246, 195, 204)' : 'transparent',
    };

    if (this.state.show_entity_menu) {
      entity_menu = (
        <Modal
          onHide={() => {
            this.setState({ show_entity_menu: false });
          }}
        >
          <ContextMenu
            options={this.state.entity_menu_options}
            left={this.state.entity_menu_left}
            top={this.state.entity_menu_top}
            onSelect={this.handleEntitySelect.bind(this)}
          />
        </Modal>
      );
    }

    return (
      <>
        <AutoInput
          style={inputStyle}
          type="text"
          value={this.state.inputValue}
          maxWidth={600}
          placeholder={isShort ? '...' : languageMap.typePhrase}
          onSelect={this.handleSelect.bind(this)}
          onChange={this.handleInputChange}
          onSave={this.handleSave}
          onKeyPress={this.onKeyPress}
        />
        {entity_menu}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  botTab: selectBotTab(state),
});
export default connect(mapStateToProps, null)(UtteranceText);
