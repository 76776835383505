const ImageFileMessage = {
  maxHeight: 200,
  cursor: 'pointer',
};

const ImageFileMessage_Image = {
  maxHeight: 200,
};

export const styles = {
  ImageFileMessage: ImageFileMessage,
  ImageFileMessage_Image: ImageFileMessage_Image,
};
