import { useContext, useMemo } from 'react';
import { NodeUpdater } from 'pages/Flow/utils/canvas-contexts';
import without from 'lodash/without';
import isEmpty from 'lodash/isEmpty';

const objectClasses = [
  'entity',
  'intent',
  'message',
  'script',
  'service',
  'endOfDialog',
  'flow_connector',
  'parent_connector',
  'returnToParentFlow',
];

const useAllowedConnections = (node) => {
  const { nodes } = useContext(NodeUpdater);

  const allowedConnectionClasses = useMemo(() => {
    if (!node) {
      return [];
    }
    let allowedClasses = objectClasses;
    const { object_class } = node;

    if (object_class === 'entity' || object_class === 'intent') {
      allowedClasses = without(allowedClasses, 'intent');
    } else {
      const nextIds = Object.keys(node.next);
      const filteredIds = [];

      nextIds.forEach((id) => {
        const currentNode = nodes[id];

        if (currentNode && currentNode.object_class !== 'flow_connector') filteredIds.push(id);
      });

      if (!isEmpty(filteredIds)) {
        const nextNode = nodes[filteredIds[0]];

        if (nextNode.object_class === 'intent') {
          allowedClasses = ['intent', 'flow_connector', 'parent_connector'];
        } else {
          allowedClasses = without(allowedClasses, 'intent');
        }
      }
    }

    return allowedClasses;
  }, [node, nodes]);

  return allowedConnectionClasses;
};

export default useAllowedConnections;
