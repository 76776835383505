import React, { Component } from 'react';
import Axios from 'axios';
import {produce} from 'immer';

import Title from 'pages/ProjectDetails/components/Title';
import Integration from 'components/common/Integration/Integration';
import UIPathConfigurationDialog from 'pages/ProjectDetails/components/Integrations/components/UIPathConfigurationDialog';
import WebchatConfigurationDialog from 'pages/ProjectDetails/components/Integrations/components/WebchatConfigurationDialog';
import SipConfigurationDialog from 'pages/ProjectDetails/components/Integrations/components/SipConfigurationDialog';
import FacebookConfigurationDialog from 'pages/ProjectDetails/components/Integrations/components/FacebookConfigurationDialog';
import MSTeamsConfigurationDialog from 'pages/ProjectDetails/components/Integrations/components/MSTeamsConfigurationDialog';
import './Integrations.scss';
import languageMap from 'Language/Language';

const IntegrationTypes = {
  Webchat: 2,
  UIPath: 3,
  SIP: 4,
  Facebook: 5,
  MSTeams: 7,
};

const integrationsStyle = {
  width: '50%',
  maxWidth: '400px',
  height: '100%',
  marginLeft: '10%',
  marginTop: 50,
};

class Integrations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showIntegrationConfigurationForTypeId: undefined,
      integrations: {},
    };

    Axios.get('/internal_api/project/integrations', {
      params: { projectId: this.props.projectId },
    }).then((response) => {
      this.setState(
        produce((draft) => {
          draft.integrations = response.data;
        })
      );
    });
  }

  needsExtraConfiguration = (integrationTypeId) => {
    switch (parseInt(integrationTypeId)) {
      case IntegrationTypes.UIPath:
      case IntegrationTypes.SIP:
      case IntegrationTypes.Webchat:
      case IntegrationTypes.Facebook:
      case IntegrationTypes.MSTeams:
        return true;
      default:
        return false;
    }
  };

  onIntegrationStatusChange = (integrationTypeId, isActive) => {
    this.setState(
      produce((draft) => {
        draft.integrations[integrationTypeId].isActive = isActive;
      })
    );

    Axios.post('/internal_api/project/integration/update', {
      projectId: this.props.projectId,
      typeId: integrationTypeId,
      column: 'is_active',
      value: isActive,
    }).then((response) => {
      if (isActive && this.needsExtraConfiguration(integrationTypeId)) {
        this.setState(
          produce((state) => {
            state.showIntegrationConfigurationForTypeId = integrationTypeId;
          })
        );
      }
    });
  };

  handleCloseConfig = () => {
    this.setState(
      produce((draft) => {
        draft.showIntegrationConfigurationForTypeId = undefined;
      })
    );
  };

  getHandleIntegrationClick = (integrationTypeId) => () => {
    if (this.needsExtraConfiguration(integrationTypeId)) {
      this.setState(
        produce((draft) => {
          draft.showIntegrationConfigurationForTypeId = integrationTypeId;
        })
      );
    }
  };

  render() {
    const { showIntegrationConfigurationForTypeId } = this.state;
    const { projectId } = this.props;

    const integrations = Object.entries(this.state.integrations).map(
      ([integrationTypeId, integration]) =>
        integrationTypeId < 9 && ( // Will not render Email and Rest api client integrations in the list.
          <Integration
            key={integrationTypeId}
            title={integration.integrationName}
            integrationTypeId={integrationTypeId}
            active={integration.isActive}
            configurable={this.needsExtraConfiguration(integrationTypeId)}
            onChange={this.onIntegrationStatusChange}
            onClick={this.getHandleIntegrationClick(integrationTypeId)}
          />
        )
    );

    let configurationScreen = null;

    switch (parseInt(showIntegrationConfigurationForTypeId)) {
      case IntegrationTypes.UIPath:
        configurationScreen = (
          <UIPathConfigurationDialog
            projectId={projectId}
            integrationTypeId={showIntegrationConfigurationForTypeId}
            close={this.handleCloseConfig}
          />
        );
        break;
      case IntegrationTypes.Webchat:
        configurationScreen = (
          <WebchatConfigurationDialog
            integrationTypeId={showIntegrationConfigurationForTypeId}
            projectId={projectId}
            close={this.handleCloseConfig}
          />
        );
        break;
      case IntegrationTypes.SIP:
        configurationScreen = (
          <SipConfigurationDialog projectId={projectId} close={this.handleCloseConfig} />
        );
        break;
      case IntegrationTypes.Facebook:
        configurationScreen = (
          <FacebookConfigurationDialog projectId={projectId} close={this.handleCloseConfig} />
        );
        break;
      case IntegrationTypes.MSTeams:
        configurationScreen = (
          <MSTeamsConfigurationDialog projectId={projectId} close={this.handleCloseConfig} />
        );
        break;
      default:
        break;
    }

    return (
      <div className="Integrations" style={integrationsStyle}>
        <Title title={languageMap.integrations} />
        <div className="Integrations_ScrollableContainer">
          <p className="Integrations_ScrollableContainer_Paragraph">
            {languageMap.integrationsHeaderText}
          </p>
          {integrations}
          {configurationScreen}
        </div>
      </div>
    );
  }
}

export default Integrations;
