import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';

import { CollectionTable } from '../components/CollectionTable';
import { setLoading } from '../../../store/global/actions';
import Loader from '../../../components/common/Loader';
import languageMap from 'Language/Language';

const mappings = {
  headers: [
    languageMap.intent,
    languageMap.flow,
    languageMap.utterances,
    languageMap.lastUpdate,
    '',
  ],
  keys: ['intent_name', 'app_name', 'utterances', 'updated'],
};
const CollectionIntents = ({
  dispatch,
  projectId,
  setObjectSettings,
  setAppId,
  setAppData,
  search,
  selectedFlows,
  listCounter,
  onDelete,
}) => {
  const [data, setData] = useState([]);
  const [loadingList, setLoadingList] = useState(true);

  const onEditIntent = async (appId, info) => {
    dispatch(setLoading(true));
    const response = await Axios.get(`/internal_api/project/${projectId}/flow/${appId}/data`);
    setAppData(response.data);
    setAppId(appId);
    setObjectSettings({
      objectClass: 'intent',
      objectId: info.id,
      nodeId: info.node_id,
      objectName: info.intent_name,
    });
    dispatch(setLoading(false));
  };

  const listIntents = useCallback(
    async (shouldLoadList) => {
      shouldLoadList && setLoadingList(true);
      const response = await Axios.get('/internal_api/list_intents', {
        params: {
          project_id: projectId,
          search,
          selectedFlows: selectedFlows.length ? selectedFlows : undefined,
        },
      });
      const intents = response.data;
      setData(intents);
      shouldLoadList && setLoadingList(false);
    },
    [projectId, search, selectedFlows]
  );

  useEffect(() => {
    listIntents(true);
  }, [listCounter, listIntents]);

  if (loadingList) {
    return <Loader />;
  }
  return (
    <CollectionTable
      data={data}
      objectClass={'intent'}
      projectId={projectId}
      headers={mappings.headers}
      keys={mappings.keys}
      onEdit={onEditIntent}
      onDelete={onDelete}
      setObjectSettings={setObjectSettings}
    />
  );
};

export default connect(null)(CollectionIntents);
