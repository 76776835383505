import React, { Component } from 'react';

import styles from 'components/common/TabBar.module.css';

function Tab(props) {
  const { activeTab, label, onClick } = props;

  let className = styles.tabListItem;

  if (activeTab === label) className += ' ' + styles.tabListActive;

  return (
    <li className={className} onClick={onClick}>
      {label}
    </li>
  );
}

export default class Tabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: this.props.children[0].props.label,
    };
  }

  handleOnClick = (label) => () => {
    this.setState({ activeTab: label });
  };

  render() {
    const {
      props: { children },
      state: { activeTab },
    } = this;

    const childStyle = {
      width: '100%',
      height: '100%',
      overflowY: 'auto',
    };

    return (
      <div className={styles.tabs}>
        <ol className={styles.tabList}>
          {children.map((child) => {
            const { label } = child.props;

            return (
              <Tab
                activeTab={activeTab}
                key={label}
                label={label}
                onClick={this.handleOnClick(label)}
              />
            );
          })}
        </ol>
        {children.map((child, index) => (
          <div
            key={'#child_' + index}
            className={styles.tabContent}
            style={{
              ...childStyle,
              display: child.props.label === activeTab ? 'block' : 'none',
            }}
          >
            {child.props.children}
          </div>
        ))}
      </div>
    );
  }
}
