import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { addAgentParticipant, addDepartmentParticipant, getChatHistory } from 'store/crm/actions';
import { selectCurrentConversation } from 'store/crm/selectors';

import { styled } from '@mui/system';

import languageMap from 'Language/Language';

const FormControlStyled = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 120,
}));

const DialogForm = ({
  title,
  isOpen,
  onClose,
  agents,
  departments,
  currentConversation: { user_id, conversation_id },
  ...props
}) => {
  const initialState = { participantType: '', agent: '', department: '' };
  const [state, setState] = useState(initialState);
  const { id: projectId } = useParams();

  const getOptions = (items) => {
    return items.map(({ value, label }) => (
      <option key={label} value={value}>
        {label}
      </option>
    ));
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleCancelButtton = () => {
    setState(initialState);
    onClose();
  };

  const handleAddParticipant = async () => {
    const { participantType, agent, department } = state;
    const params = {
      project_id: projectId,
      conversation_id,
    };

    if (participantType === 'agent') {
      await props.addAgentParticipant({
        participant_id: agent,
        ...params,
      });
    } else if (participantType === 'department') {
      await props.addDepartmentParticipant({
        participant_id: department,
        ...params,
      });
    }
    onClose();
    setState(initialState);
    await props.setChatHistory({ user_id, project_id: projectId, conversation_id });
  };

  return (
    <div>
      <Dialog open={isOpen} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{languageMap.toAddParticipants}</DialogContentText>
          <FormControlStyled>
            <InputLabel htmlFor="age-native-simple">{languageMap.participantType}</InputLabel>
            <Select
              native
              value={state.participantType}
              onChange={handleChange}
              inputProps={{
                name: 'participantType',
                id: 'participant-type',
              }}
            >
              <option aria-label="None" value="None" />
              <option value="agent">{languageMap.agent}</option>
              <option value="department">{languageMap.department}</option>
            </Select>
          </FormControlStyled>
          {state.participantType === 'agent' && (
            <FormControlStyled>
              <InputLabel htmlFor="age-native-simple">{languageMap.agents}</InputLabel>
              <Select
                native
                value={state.agent}
                onChange={handleChange}
                inputProps={{
                  name: 'agent',
                  id: 'add-agent',
                }}
              >
                <option aria-label="None" value="None" />
                {getOptions(agents)}
              </Select>
            </FormControlStyled>
          )}
          {state.participantType === 'department' && (
            <FormControlStyled>
              <InputLabel htmlFor="age-native-simple">{languageMap.departments}</InputLabel>
              <Select
                native
                value={state.department}
                onChange={handleChange}
                inputProps={{
                  name: 'department',
                  id: 'add-department',
                }}
              >
                <option aria-label="None" value="None" />
                {getOptions(departments)}
              </Select>
            </FormControlStyled>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelButtton} color="secondary">
            {languageMap.cancel?.toUpperCase()}
          </Button>
          <Button onClick={handleAddParticipant} color="primary">
            {languageMap.add}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentConversation: selectCurrentConversation(state),
});

const mapDispatchToProps = {
  addAgentParticipant,
  addDepartmentParticipant,
  setChatHistory: getChatHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(DialogForm);
