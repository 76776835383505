import React, { createContext } from 'react';
import { connect } from 'react-redux';

import ConversationInboxListContainer from 'containers/ConversationInboxListContainer';
import ConversationsStreamContainer from 'pages/CRM/containers/CRMDashboard/components/ConversationsStreamContainer';
import ConversationDetails from 'pages/CRM/components/ConversationDetails';

import {
  selectCurrentConversation,
  selectUserPermissionForConversation,
  selectIsMobile,
} from 'store/crm/selectors';

import './ConversationsInboxContainer.scss';
import clsx from 'clsx';
import { useState } from 'react';

export const displayConversationContext = createContext(false);

const ConversationsInboxContainer = (props) => {
  const {
    currentConversation: { conversation_id },
    handleNewInternalConversation,
    conversationPermission,
    isMobile,
    conversationDetails,
    handleConversationDetailsButtonClick,
  } = props;

  const [displayConversationDetails, setDisplayConversationDetails] = useState(false);

  const isConversationInboxContainer = conversation_id !== null;
  const noAccessText =
    "We're sorry. It seems you don’t have permission to acces this conversation.";

  const renderConversation = () => {
    return (
      <ConversationsStreamContainer
        conversationDetails={conversationDetails}
        handleConversationDetailsButtonClick={handleConversationDetailsButtonClick}
      />
    );
  };

  const renderConversationContent = () => {
    if (conversationPermission || !conversation_id) {
      return renderConversation();
    } else {
      return <div className="no-access-text">{noAccessText}</div>;
    }
  };

  return (
    <div
      className={clsx(
        'ConversationsInboxContainer',
        isMobile && 'ConversationsInboxContainerisMobile'
      )}
      style={conversationDetails ? {} : { width: '100%' }}
    >
      <displayConversationContext.Provider
        value={{ displayConversationDetails, setDisplayConversationDetails }}
      >
        <ConversationInboxListContainer
          handleNewInternalConversation={handleNewInternalConversation}
        />
        {isConversationInboxContainer && (
          <div className={clsx('ConversationInbox', isMobile && 'ConversationInbox-mobile')}>
            {renderConversationContent()}
          </div>
        )}
        {displayConversationDetails && <ConversationDetails />}
      </displayConversationContext.Provider>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentConversation: selectCurrentConversation(state),
  conversationPermission: selectUserPermissionForConversation(state),
  isMobile: selectIsMobile(state),
});

export default connect(mapStateToProps, null)(ConversationsInboxContainer);
