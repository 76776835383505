import dispatchMouseEvent from '../../utils/dispatchMouseEvent';

let recentConfig;

/**
 *
 * @param config A bag of props. Example:
 *      {
 *          onBrowse: <function>,
 *          accepts: ['.mp3', 'audio/mpeg', 'audio/mp3'],
 *          multiple: false,
 *          params: *
 *      }
 */
const browseFiles = (config) => {
  recentConfig = config;

  const { accepts, multiple } = config;
  const inputElement = document.createElement('input');
  inputElement.type = 'file';
  inputElement.multiple = multiple;
  if (accepts) {
    const onlyExtensions = accepts.filter((o) => o.charAt(0) === '.').sort();
    inputElement.accept = onlyExtensions.join(',');
  }
  inputElement.addEventListener('change', onChange);
  inputElement.addEventListener('cancel', () => console.log(1));
  dispatchMouseEvent(inputElement, 'click');
};

/**
 *
 */
const onChange = (event) => {
  const { onBrowse, accepts, params } = recentConfig;
  recentConfig = null;
  let output = event.currentTarget.files;
  if (accepts) {
    output = [].slice.call(event.currentTarget.files).filter(({ name }) => {
      for (const extension of accepts) {
        const cleanExtension = extension.substr(1); // remove any leading dots
        if (name.match(new RegExp(cleanExtension, 'i'))) {
          return true;
        }
      }
      return false;
    });
  }
  if (output.length) {
    onBrowse(output, params);
  }
};

export default browseFiles;
