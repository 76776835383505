import React, { useState } from 'react';
import axios from 'axios';

import { Tooltip } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';

import withNotifications from 'components/withNotifications';

import './PasswordRecovery.scss';
import './ComponentsCommonStyle.scss';
import languageMap from 'Language/Language';

const PasswordRecovery = (props) => {
  const { setPasswordResetPopup, user } = props;

  const [automaticalyGeneratePopup, setAutomaticalyGeneratePopup] = useState(false);
  const [passwordValue, setPasswordValue] = useState('');
  const [selectedOption, setSelectedOption] = useState('automatic');
  const [showPassword, setShowPassword] = useState(false);

  let userName = () => {
    if (user?.first_name !== null && user?.name !== null) {
      return `${user?.first_name} ${user?.name}`;
    } else if (user?.email) {
      return user?.email;
    } else {
      return 'Unknown User';
    }
  };

  const handleInputFieldChange = (e) => {
    setPasswordValue(e.target.value);
  };

  const validatePassword = () => {
    if (passwordValue.length < 8) {
      alert('Please provide a password with at least 8 characters.');
      return false;
    }
    return true;
  };

  const generateRandomPassword = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let password = '';
    for (let i = 0; i < 12; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      password += characters.charAt(randomIndex);
    }
    return password;
  };

  const onCancelClick = () => {
    setPasswordResetPopup(false);
    setPasswordResetPopup(false);
    setPasswordValue('');
  };

  const onResetClick = () => {
    if (selectedOption === 'automatic') {
      const password = generateRandomPassword();
      setPasswordValue(password);
      axios
        .post('/internal_api/user/reset_other_user_password', {
          password: password,
          user_id: user?.id,
        })
        .then((response) => {
          setAutomaticalyGeneratePopup(true);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      if (validatePassword()) {
        axios
          .post('/internal_api/user/reset_other_user_password', {
            password: passwordValue,
            user_id: user?.id,
          })
          .then((response) => {
            setAutomaticalyGeneratePopup(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };
  const copyPasswordClick = () => {
    navigator.clipboard
      .writeText(passwordValue)
      .then(() => {
        console.log('Password copied to clipboard');
      })
      .catch((err) => {
        console.error('Could not copy password: ', err);
      });
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handeEnterPress = (e) => {
    if (e.key === 'Enter') {
      if (automaticalyGeneratePopup) {
        onCancelClick();
      } else {
        onResetClick();
      }
    } else if (e.key === 'Escape') {
      onCancelClick();
    }
  };

  const handleShowPasswordChange = (event) => {
    setShowPassword(event.target.checked);
  };

  const passwordRecoveryContent = () => {
    if (automaticalyGeneratePopup) {
      return (
        <div className="password-recovery-content">
          <div className="copy-automatic-password" style={{ width: '100%' }}>
            <TextField
              type={showPassword ? 'text' : 'password'}
              value={passwordValue}
              label="Your New Passowrd"
              variant="outlined"
              disabled
              style={{ width: '100%' }}
            />
            <Tooltip
              placement="top"
              classes={{ popper: 'tooltip_popper' }}
              title={languageMap.copyPassword}
              style={{ zIndex: '9001 !important' }}
            >
              <FileCopyIcon
                onClick={copyPasswordClick}
                style={{ margin: '5px', cursor: 'pointer' }}
              />
            </Tooltip>
          </div>
          <FormControlLabel
            control={
              <Checkbox
                checked={showPassword}
                onChange={handleShowPasswordChange}
                color="secondary"
                size="small"
              />
            }
            label="Show Password"
          />
        </div>
      );
    } else {
      return (
        <div className="password-recovery-content">
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="match type"
              name="match_selection"
              value={selectedOption}
              onChange={handleOptionChange}
            >
              <FormControlLabel
                value="automatic"
                control={<Radio color="default" />}
                label={languageMap.automaticallyGeneratePassword}
              />
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ marginLeft: '30px', marginTop: '-10px' }}
              >
                {languageMap.viewAndCopyPassword}
              </Typography>
              <FormControlLabel
                value="password_input"
                control={<Radio color="default" />}
                label={languageMap.createPassword}
              />
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ marginLeft: '30px', marginTop: '-10px' }}
              >
                {languageMap.typeInNewPassword}
              </Typography>
            </RadioGroup>
            {selectedOption === 'password_input' && (
              <>
                <TextField
                  type={showPassword ? 'text' : 'password'}
                  label="Enter Password"
                  variant="outlined"
                  style={{ marginTop: '20px' }}
                  onChange={handleInputFieldChange}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showPassword}
                      onChange={handleShowPasswordChange}
                      color="secondary"
                      size="small"
                    />
                  }
                  label="Show Password"
                />
              </>
            )}
          </FormControl>
        </div>
      );
    }
  };

  return (
    <div className="PasswordRecovery">
      <div className="password-recovery-popup-container" onKeyUp={handeEnterPress}>
        <div className="password-recovery-popup-header dm-sans-700">
          {!automaticalyGeneratePopup && (
            <p style={{ padding: '0', margin: '0' }}>{languageMap.resetPasswordFor}</p>
          )}
          {automaticalyGeneratePopup && (
            <p style={{ padding: '0', margin: '0' }}> {languageMap.resetPasswordSuccesful}</p>
          )}
          <p>{userName()}</p>
        </div>
        <div className="password-recovery-new-password">{passwordRecoveryContent()}</div>
        <div className="password-recovery-popup-buttons">
          <Button
            className={`add-style ${
              !automaticalyGeneratePopup ? 'blue-button-style' : 'red-button-style'
            }`}
            onClick={onCancelClick}
          >
            {!automaticalyGeneratePopup ? languageMap.cancel : languageMap.done}
          </Button>
          {!automaticalyGeneratePopup && (
            <Button className="cancel-style red-button-style" onClick={onResetClick}>
              {languageMap.reset}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default withNotifications(PasswordRecovery);
