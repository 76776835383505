import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import languageMap from 'Language/Language';

import { editConversationSubject, refreshConversationCardSubject } from 'store/crm/actions';
import {
  selectCurrentConversation,
  selectConversationAllParticipants,
  selectCurrentUser,
} from 'store/crm/selectors';

import { isCurrentUserAdmin } from 'utils/utils';

import './ConversationSubject.scss';

const ConversationSubject = ({
  currentConversation,
  currentConversation: { conversation_id, subject: conversationSubject },
  participants,
  currentUser,
  ...props
}) => {
  const { id: project_id } = useParams();

  const [subject, setSubject] = useState('');
  const [isEdit, setIsEdit] = useState(false);

  const defaultSubject = languageMap.insertSubject;

  useEffect(() => {
    setSubject(conversationSubject);
  }, [conversation_id]);

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleEditClick = () => {
    setIsEdit(true);
  };

  const handleSaveClick = async () => {
    setIsEdit(false);

    if (subject !== conversationSubject) {
      const params = { project_id, conversation_id, subject };
      await props.editConversationSubject(params);
      props.refreshConversationCardSubject(params);
    }
  };

  const isSubjectFieldDisabled = () => {
    let isSubjectFieldDisabled = true;

    if (isCurrentUserAdmin(participants.all_participants, currentUser)) {
      if (isEdit) {
        isSubjectFieldDisabled = false;
      }
    }

    return isSubjectFieldDisabled;
  };

  const getConversationSubject = () => {
    let displaySubject;
    if (isEdit) {
      displaySubject = subject;
    } else {
      displaySubject = conversationSubject || defaultSubject;
    }
    return displaySubject;
  };

  return (
    <div className="ConversationSubject">
      <form className="form" noValidate autoComplete="off" id="conversationSubjectForm">
        <TextField
          id={`internal-conversation-subject_${conversation_id}`}
          className="subject-input"
          variant="filled"
          value={getConversationSubject() || ''}
          onChange={handleSubjectChange}
          maxLength="50"
          disabled={isSubjectFieldDisabled()}
          InputProps={
            isSubjectFieldDisabled() ? { disableUnderline: true } : { disableUnderline: false }
          }
        />
      </form>
      {isCurrentUserAdmin(participants.all_participants, currentUser) && !isEdit && (
        <button className="edit-button" value="Edit" onClick={handleEditClick}>
          {languageMap.edit}
        </button>
      )}
      {isEdit && (
        <button className="save-button" value="Save" onClick={handleSaveClick}>
          {languageMap.save}
        </button>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentConversation: selectCurrentConversation(state),
  participants: selectConversationAllParticipants(state),
  currentUser: selectCurrentUser(state),
});

const mapDispatchToProps = {
  editConversationSubject,
  refreshConversationCardSubject,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConversationSubject);
