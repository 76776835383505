import React from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import languageMap from 'Language/Language';

import { setConversationNotes } from 'store/crm/actions';
import { selectConversationNotes, selectCurrentConversation } from 'store/crm/selectors';

import './NotesCard.scss';

const NotesCard = ({ notes, setNotes, currentConversation: { conversation_id } }) => {
  const handleSaveNewNote = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      let notesArray = [...notes];
      notesArray.unshift(event.target.value);
      setNotes({ conversation_id, notes: [...notesArray] });
      event.target.value = '';
      const element = document.getElementById('Notes_textarea');
      element.blur();
    }
  };

  const handleModifyNote = (event, index) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      let notesArray = [...notes];

      if (event.target.value.length === 0) {
        notesArray.splice(index, 1);
      } else {
        notesArray[index] = event.target.value;
      }
      setNotes({ conversation_id, notes: notesArray });
      const element = document.getElementById(`Note_${index}`);
      element.blur();
    }
  };

  const renderNotes = () => {
    return notes?.map((note, index) => {
      return (
        <textarea
          key={uuidv4()}
          name="note"
          id={`Note_${index}`}
          cols="10"
          rows="1"
          placeholder={languageMap.addNote}
          onKeyPress={(event) => {
            handleModifyNote(event, index);
          }}
          defaultValue={note}
        />
      );
    });
  };

  return (
    <div className="NotesCardContent">
      <textarea
        name="note"
        id="Notes_textarea"
        cols="10"
        rows="5"
        placeholder={languageMap.addNote}
        onKeyPress={(event) => {
          handleSaveNewNote(event);
        }}
      />
      {renderNotes()}
    </div>
  );
};

const mapStateToProps = (state) => ({
  notes: selectConversationNotes(state),
  currentConversation: selectCurrentConversation(state),
});

const mapDispatchToProps = {
  setNotes: setConversationNotes,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotesCard);
