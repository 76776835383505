import React from 'react';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import HelperTooltip from 'components/HelperTooltip/HelperTooltip';
import { IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

import { connect } from 'react-redux';

import 'react-circular-progressbar/dist/styles.css';

import './LargeStatsWidget.scss';

import { setDialogType } from '../../../../../../../store/analytics/actions';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 0,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: '#1a90ff',
  },
}));

const LargeStatsWidget = ({
  title,
  content,
  list,
  info,
  percentage,
  surveyText,
  comparisonText,
  displayOptions,
  setDialogType,
}) => {
  const elementsOfTheList = () => {
    return list?.map(({ lineItem, value }) => (
      <div className="list regular-text" key={`${lineItem}${value}`}>
        <div className="progress-bar-title">{lineItem}</div>
        <div className="progress-bar-container">
          <div className="progress-bar-value">{`${value}%`}</div>
          <BorderLinearProgress variant="determinate" value={Number(value)} />
        </div>
      </div>
    ));
  };

  const displayChangeValue = () => {
    if (percentage > 0) {
      return (
        <div className="comparison-text-wrapper small-text">
          <ArrowDownwardIcon className="arrow-icon-up" style={{ fill: 'green' }} />
          <div style={{ color: 'green', padding: '0 5px 0 0px' }}>{`${percentage} `}</div>{' '}
          <div className="small-text" style={{ color: 'black' }}>
            {comparisonText}
          </div>
        </div>
      );
    } else if (percentage <= 0) {
      percentage *= -1;
      return (
        <div className="comparison-text-wrapper small-text">
          <ArrowDownwardIcon className="arrow-icon" style={{ fill: 'red' }} />
          <div style={{ color: 'red', padding: '0 5px 0 0px' }}>{`${percentage} `}</div>{' '}
          <div className="small-text" style={{ color: 'black' }}>
            {comparisonText}
          </div>
        </div>
      );
    }
  };

  const contentWidget = () => {
    return (
      <div className="content-widget">
        <div className="content-container">
          <CircularProgressbarWithChildren
            value={content * 20}
            circleRatio={0.6}
            strokeWidth={10}
            styles={{
              text: { fill: '#1a90ff', textAnchor: 'bottom' },
              trail: {
                stroke: '#d6d6d6',
                strokeLinecap: 'butt',
                transform: 'rotate(-106deg)',
                transformOrigin: 'center center',
              },
              path: {
                stroke: '#1a90ff',
                strokeLinecap: 'butt',
                transform: 'rotate(-106deg)',
                transformOrigin: 'center center',
              },
            }}
          >
            <div className="title-text" style={{ fontSize: '34px', color: '#1a90ff' }}>
              {content > 0.01 ? content : ''}
            </div>
          </CircularProgressbarWithChildren>
          <div className="under-circular-progress-bar-text">
            <div className="small-text" style={{ color: 'black' }}>
              {title}
            </div>
            <div className="survey-text small-text" style={{ color: 'gray' }}>
              {surveyText}
            </div>
            {displayChangeValue()}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="LargeStatsWidget">
      <div className="title regular-text">
        <div className="title-text">
          {!content && title}
          {info && <HelperTooltip title={info} placement="top" arrow style={{ height: '20px' }} />}
        </div>
        {displayOptions && (
          <IconButton
            className="intents-filter-button setting-button"
            onClick={() => setDialogType(displayOptions)}
            size="large"
          >
            <SettingsIcon className="setting-icon" />
          </IconButton>
        )}
      </div>
      {list && elementsOfTheList()}
      {content && contentWidget()}
    </div>
  );
};

const mapDispatchToProps = {
  setDialogType: setDialogType,
};

export default connect(null, mapDispatchToProps)(LargeStatsWidget);
