import React, { useState, useCallback, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 } from 'uuid';
import omit from 'lodash/omit';
import { AddButton } from 'components/common/Button/Buttons';
import { Icon } from 'semantic-ui-react';

import languageMap from 'Language/Language';
import {
  SIMPLE_TEXT_BLOCK,
  RANDOM_BLOCK,
  LINK_TEXT_BLOCK,
  IMAGE_BLOCK,
  HTML_BLOCK,
  CAROUSEL_BLOCK,
  uuidKey,
  createLinkTextBlock,
  createNewRandomBlock,
  createNewSimpleBlock,
  createImageBlock,
  createHTMLBlock,
  createCarouselBlock,
  GRAPH_BLOCK,
  createGraphBlock,
} from './blocks-config';
import RandomMessageBlock from './RandomMessageBlock';
import SimpleTextBlock from './SimpleTextBlock';
import LinkTextBlock from './LinkTextBlock';
import ImageBlock from './ImageBlock';
import HTMLBlock from './HTMLBlock';
import CarouselBlock from './CarouselBlock';
import './MessageBlocks.scss';
import OptionCheckbox from './components/OptionCheckbox';
import HelperTooltip from '../../../../../components/HelperTooltip/HelperTooltip';
import { FlexRow } from '../../../../../components/Utterances/Misc';
import GraphBlock from './GraphBlock/GraphBlock';

const BLOCK_TYPES = [
  {
    type: SIMPLE_TEXT_BLOCK,
    text: languageMap.text,
  },
  {
    type: RANDOM_BLOCK,
    text: languageMap.random,
  },
  {
    type: LINK_TEXT_BLOCK,
    text: languageMap.link,
  },
  {
    type: IMAGE_BLOCK,
    text: languageMap.image,
  },
  {
    type: HTML_BLOCK,
    text: languageMap.html,
  },
  {
    type: CAROUSEL_BLOCK,
    text: languageMap.carousel,
  },
  {
    type: GRAPH_BLOCK,
    text: languageMap.graphBlock,
  },
];

const injectUuidIntoBlocks = (blocks) => blocks.map((b) => ({ ...b, [uuidKey]: v4() }));

const removeUuidFromBlocks = (blocks) => blocks.map((b) => omit(b, uuidKey));

const MessageBlocks = ({
  options,
  onChange,
  voiceInteraction,
  automaticTranslation,
  onChangeAutomaticTranslation,
  showAnimatePlaceholder,
  animatePlaceholder,
  animateMessage,
  onChangeAnimatePlaceholder,
  onChangeAnimateMessage,
  selectedLanguage,
  hideTitle,
  projectId,
  loadingChange,
  blockTypes,
}) => {
  const [blocks, setBlocks] = useState(injectUuidIntoBlocks(options || []));
  const [collapsedStateMap, setCollapsedStateMap] = useState(
    blocks.reduce((acc, current, currentIndex) => {
      acc[current[uuidKey]] = currentIndex !== 0;
      return acc;
    }, {})
  );
  useEffect(() => {
    const newBlocks = injectUuidIntoBlocks(options || []);
    const collapseMap = newBlocks.reduce((acc, current, currentIndex) => {
      acc[current[uuidKey]] = currentIndex !== 0;
      return acc;
    }, {});
    // console.log('Change language', newBlocks);
    setCollapsedStateMap(collapseMap);
    setBlocks(newBlocks);
  }, [selectedLanguage]);

  const toggleCollapse = useCallback(
    (blockUuid) => {
      const newState = {
        ...collapsedStateMap,
        [blockUuid]: !collapsedStateMap[blockUuid],
      };

      setCollapsedStateMap(newState);
    },
    [collapsedStateMap]
  );

  const setBlockAndEmitChange = (newBlocks) => {
    onChange(removeUuidFromBlocks(newBlocks), true);
    setBlocks(newBlocks);
  };

  const changeBlock = (block, blockIndex) => {
    const newBlocks = blocks.map((item, index) => {
      return index === blockIndex ? block : item;
    });

    setBlockAndEmitChange(newBlocks);
  };

  const removeBlockAtIndex = (blockIndex) => {
    const newBlocks = blocks.filter((block, index) => {
      return index !== blockIndex;
    });

    setBlockAndEmitChange(newBlocks);
  };

  const renderAddButtons = (blockTypes) => {
    return blockTypes.map((item, index) => {
      return (
        <AddButton
          style={{ marginTop: 6 }}
          onClick={() => addBlock(item.type)}
          text={item.text}
          icon="add"
          key={index}
        />
      );
    });
  };

  const renderBlocks = () => {
    return blocks.map((item, index) => {
      const blockUuid = item[uuidKey];
      return (
        <BlockFactory
          loadingChange={loadingChange}
          projectId={projectId}
          collapsed={collapsedStateMap[blockUuid]}
          toggleCollapse={() => toggleCollapse(blockUuid)}
          block={item}
          key={blockUuid}
          index={index}
          voiceInteraction={voiceInteraction}
          onBlockChange={(editedBlock) => changeBlock(editedBlock, index)}
          onBlockRemove={() => removeBlockAtIndex(index)}
        />
      );
    });
  };

  const addBlock = (blockType) => {
    let newBlock;
    switch (blockType) {
      case RANDOM_BLOCK:
        newBlock = createNewRandomBlock();
        break;
      case SIMPLE_TEXT_BLOCK:
        newBlock = createNewSimpleBlock();
        break;
      case LINK_TEXT_BLOCK:
        newBlock = createLinkTextBlock();
        break;
      case IMAGE_BLOCK:
        newBlock = createImageBlock();
        break;
      case HTML_BLOCK:
        newBlock = createHTMLBlock();
        break;
      case CAROUSEL_BLOCK:
        newBlock = createCarouselBlock();
        break;
      case GRAPH_BLOCK:
        newBlock = createGraphBlock();
        break;
      default:
        break;
    }
    if (!newBlock) {
      return;
    }
    setBlockAndEmitChange([...blocks, newBlock]);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedBlocks = reorder(blocks, result.source.index, result.destination.index);

    setBlockAndEmitChange(reorderedBlocks);
  };

  return (
    <div className="MessageBlocks">
      {!hideTitle && (
        <div className="TitleContainer regular-text">
          <div className="">{languageMap.messageBlocksLabel}</div>
          <HelperTooltip
            style={{ marginLeft: 10, height: 25, width: 25 }}
            title={languageMap.helperTextMessage}
          />
        </div>
      )}
      <div className="MessageBlocks_AddButtons">{renderAddButtons(blockTypes || BLOCK_TYPES)}</div>

      {automaticTranslation !== undefined && (
        <FlexRow>
          <OptionCheckbox
            style={{ marginRight: 0 }}
            checked={automaticTranslation}
            onChange={() => onChangeAutomaticTranslation(!automaticTranslation)}
            title={languageMap.automaticTranslation}
          />
          <HelperTooltip
            style={{ marginLeft: 10, height: 25, width: 25 }}
            title={languageMap.helperTextAutomaticTranslation}
          />
        </FlexRow>
      )}
      {showAnimatePlaceholder && (
        <FlexRow>
          <OptionCheckbox
            style={{ marginRight: 0 }}
            checked={animatePlaceholder}
            onChange={() => onChangeAnimatePlaceholder(!animatePlaceholder)}
            title={languageMap.animatePlaceholder}
          />
          <HelperTooltip
            style={{ marginLeft: 10, height: 25, width: 25 }}
            title={languageMap.helperTextAnimatePlaceholder}
          />
        </FlexRow>
      )}
      <FlexRow>
        <OptionCheckbox
          style={{ marginRight: 0 }}
          checked={animateMessage}
          onChange={() => onChangeAnimateMessage(!animateMessage)}
          title={languageMap.animateMessage}
        />
        <HelperTooltip
          style={{ marginLeft: 10, height: 25, width: 25 }}
          title={languageMap.helperTextAnimateMessage}
        />
      </FlexRow>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => {
            return (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="MessageBlocks_BlocksContainer"
              >
                {renderBlocks()}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

const BlockFactory = ({
  block,
  collapsed,
  toggleCollapse,
  onBlockChange,
  onBlockRemove,
  index,
  voiceInteraction,
  projectId,
  loadingChange,
}) => {
  // const [collapsed, setCollapsed] = useState(index !== 0);
  const renderBlock = () => {
    const commonProps = { block, onBlockChange, voiceInteraction, projectId, loadingChange };
    switch (block.message_block_type) {
      case RANDOM_BLOCK:
        return <RandomMessageBlock {...commonProps} />;
      case SIMPLE_TEXT_BLOCK:
        return <SimpleTextBlock {...commonProps} />;
      case LINK_TEXT_BLOCK:
        return <LinkTextBlock {...commonProps} />;
      case IMAGE_BLOCK:
        return <ImageBlock {...commonProps} />;
      case HTML_BLOCK:
        return <HTMLBlock {...commonProps} />;
      case CAROUSEL_BLOCK:
        return <CarouselBlock {...commonProps} />;
      case GRAPH_BLOCK:
        return <GraphBlock {...commonProps} />;
      default:
        return null;
    }
  };

  const renderBlockLabel = (type) => {
    switch (block.message_block_type) {
      case RANDOM_BLOCK:
        return languageMap.randomText;
      case SIMPLE_TEXT_BLOCK:
        return languageMap.text;
      case LINK_TEXT_BLOCK:
        return languageMap.link;
      case IMAGE_BLOCK:
        return languageMap.image;
      case HTML_BLOCK:
        return languageMap.html;
      case CAROUSEL_BLOCK:
        return languageMap.carousel;
      default:
        return type;
    }
  };

  const isCarouselBlock = block.message_block_type === CAROUSEL_BLOCK;

  // TODO: https://github.com/atlassian/react-beautiful-dnd/issues/2563
  return (
    <Draggable
      key={block[uuidKey]}
      draggableId={`id-${index}`}
      index={index}
      isDragDisabled={isCarouselBlock && !collapsed}
    >
      {(provided, snapshot) => {
        return (
          <div
            className="BlockFactory"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className="BlockFactory_Header">
              <h5 className="BlockFactory_Header_Title">
                <Icon className="BlockFactory_Header_MoveIcon" name="arrows alternate vertical" />
                <span onClick={toggleCollapse}>{renderBlockLabel(block.message_block_type)}</span>
              </h5>
              <span>
                <Icon
                  className="BlockFactory_Header_CollapseIcon"
                  name={collapsed ? 'angle down' : 'angle up'}
                  onClick={toggleCollapse}
                />
                <Icon
                  className="BlockFactory_Header_DeleteIcon"
                  name="delete"
                  onClick={onBlockRemove}
                />
              </span>
            </div>
            {!collapsed && <div className="BlockFactory_Content">{renderBlock()}</div>}
          </div>
        );
      }}
    </Draggable>
  );
};

export default MessageBlocks;
