import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';

import { CollectionTable } from '../components/CollectionTable';
import { setLoading } from '../../../store/global/actions';
import Loader from '../../../components/common/Loader';
import languageMap from '../../../Language/Language';

const mappings = {
  headers: [
    languageMap.entity,
    languageMap.flow,
    languageMap.entityType,
    languageMap.lastUpdate,
    '',
  ],
  keys: ['name', 'app_name', 'entity_type_name', 'updated'],
};
const CollectionEntities = ({
  dispatch,
  projectId,
  setObjectSettings,
  setAppId,
  setAppData,
  search,
  selectedFlows,
  listCounter,
  onDelete,
}) => {
  const [data, setData] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);

  const onEditEntity = async (appId, info) => {
    dispatch(setLoading(true));
    const response = await Axios.get(`/internal_api/project/${projectId}/flow/${appId}/data`);
    setAppData(response.data);
    setAppId(appId);
    setObjectSettings({
      objectClass: 'entity',
      objectId: info.id,
      nodeId: info.node_id,
      objectName: info.name,
    });
    dispatch(setLoading(false));
  };

  const listEntities = useCallback(async () => {
    const response = await Axios.get('/internal_api/list_entities', {
      params: {
        project_id: projectId,
        search: search ? search : undefined,
        selectedFlows: selectedFlows.length ? selectedFlows : undefined,
      },
    });
    const entities = response.data;
    setData(entities);
    if (firstLoad) {
      setFirstLoad(false);
    }
  }, [search, projectId, selectedFlows, firstLoad]);

  useEffect(() => {
    listEntities();
  }, [listCounter, listEntities]);

  if (firstLoad) {
    return <Loader />;
  }
  return (
    <CollectionTable
      data={data}
      objectClass={'intent'}
      projectId={projectId}
      headers={mappings.headers}
      keys={mappings.keys}
      onEdit={onEditEntity}
      onDelete={onDelete}
      setObjectSettings={setObjectSettings}
    />
  );
};

export default connect(null)(CollectionEntities);
