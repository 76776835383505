import React, { useState, useCallback, useContext, useRef } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Popper } from '@mui/material';
import { Icon } from 'semantic-ui-react';
import startsWith from 'lodash/startsWith';
import get from 'lodash/get';
import trim from 'lodash/trim';
import find from 'lodash/find';
import endsWith from 'lodash/endsWith';
import isEmpty from 'lodash/isEmpty';
import { EntitiesContext } from 'pages/Annotations';

import './AnnotationUtterance.scss';

const AnnotationUtterance = ({ appId, utterance, setUtterance }) => {
  const [showEntityName, setShowEntityName] = useState(false);
  const [selection, setSelection] = useState('');
  const annotationRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const getEntityRegex = /({.+?})/g;

  const entities = useContext(EntitiesContext).filter((entity) => entity.app_id === appId);

  const parseToReact = (utterance, renderEntity, renderSimpleChunk) => {
    const chunks = utterance.split(getEntityRegex);

    return chunks
      .filter((chunk) => !isEmpty(chunk))
      .map((string, index) => {
        if (startsWith(string, '{') && endsWith(string, '}')) {
          const entry = string.replace(/\{|\}/g, '');

          const [id, value] = entry.split(':');

          return renderEntity(id, value);
        }
        if (renderSimpleChunk && typeof renderSimpleChunk === 'function') {
          return renderSimpleChunk(string);
        }
        return string;
      });
  };

  const renderEntity = useCallback(
    (id, value) => {
      return (
        <div
          key={id}
          className="AnnotationUtterance_Entity"
          onMouseEnter={() => setShowEntityName(true)}
          onMouseLeave={() => setShowEntityName(false)}
        >
          {value}
          {showEntityName && (
            <div className="AnnotationUtterance_Entity_Name">
              {get(
                find(entities, (e) => e.entity_id.toString() === id),
                'entity_name'
              )}
            </div>
          )}
          <Icon
            name="delete"
            className="AnnotationUtterance_Entity_CloseIcon"
            onClick={() => {
              const newUtterance = utterance.replace(`{${id}:${value}}`, value);
              setUtterance(newUtterance);
            }}
          />
        </div>
      );
    },
    [entities, setUtterance, showEntityName, utterance]
  );

  const renderSimpleChunk = (text) => {
    const cleanText = text.replace(/&nbsp;/gi, ' ');
    return (
      <div
        ref={annotationRef}
        key={cleanText}
        onMouseUp={({ target }) => {
          // timeout needed to ensure our logic happens after browser handles selection
          setTimeout(() => {
            const currentSelection = window.getSelection();
            const selectedText = currentSelection.toString();

            if (trim(selectedText)) {
              setSelection(selectedText);
              setAnchorEl(annotationRef.current);
            }

            // This doesn't work in firefox and not sure it's even necessary
            // const hasSelection = Boolean(
            //   get(currentSelection, 'baseNode.parentElement') === target && trim(selectedText)
            // );
            // if (hasSelection) {
            //   setSelection(selectedText);
            // }
          });
        }}
      >
        {cleanText}
      </div>
    );
  };

  const createEntityValue = useCallback(
    (entityId) => {
      const newUtterance = utterance.replace(selection, `{${entityId}:${selection}}`);

      setUtterance(newUtterance);
      setSelection('');
      setAnchorEl(null);
    },
    [selection, setUtterance, utterance]
  );

  return (
    <div className="AnnotationUtterance">
      <ClickAwayListener
        onClickAway={() => {
          setSelection('');
          setAnchorEl(null);
        }}
      >
        {entities.length ? (
          <Popper
            anchorEl={anchorEl}
            open={!isEmpty(selection)}
            className="AnnotationUtterance_EntitySelector"
          >
            {entities.map(({ entity_id, entity_name }, index) => {
              return (
                <div
                  key={entity_id + index}
                  className="AnnotationUtterance_EntitySelector_Item"
                  onClick={() => createEntityValue([entity_id])}
                >
                  {entity_name}
                </div>
              );
            })}
          </Popper>
        ) : (
          <></>
        )}
      </ClickAwayListener>
      {parseToReact(utterance, renderEntity, renderSimpleChunk)}
    </div>
  );
};

export default AnnotationUtterance;
