import React from 'react';
import { connect } from 'react-redux';
import { Popup } from 'semantic-ui-react';

import { setMainMenu, setSearchStatus, setSubMenu } from 'store/crm/actions';
import { selectMainMenu, selectSearch } from 'store/crm/selectors';

import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import QnA from 'assets/menuIcons/QnA';
import Flows from 'assets/menuIcons/Flows';
import Collections from 'assets/menuIcons/Collections';
import Annotations from 'assets/menuIcons/Annotations';
import Overview from 'assets/menuIcons/Overview';
import WiseAgent from 'assets/menuIcons/WiseAgent';
import TeamPerformance from 'assets/menuIcons/TeamPerformance';
import Feedback from 'assets/menuIcons/Feedback';
import Changelog from 'assets/menuIcons/Changelog';
import Integrations from 'assets/menuIcons/Integrations';
import QnASelected from 'assets/menuIcons/QnASelected';
import FlowsSelected from 'assets/menuIcons/FlowsSelected';
import CollectionsSelected from 'assets/menuIcons/CollectionsSelected';
import AnnotationsSelected from 'assets/menuIcons/AnnotationsSelected';
import OverviewSelected from 'assets/menuIcons/OverviewSelected';
import WiseAgentSelected from 'assets/menuIcons/WiseAgentSelected';
import TeamPerformanceSelected from 'assets/menuIcons/TeamPerformanceSelected';
import FeedbackSelected from 'assets/menuIcons/FeedbackSelected';
import ChangelogSelected from 'assets/menuIcons/ChangelogSelected';
import IntegrationsSelected from 'assets/menuIcons/IntegrationsSelected';

import './MenuTabsComponent.scss';

const MenuTabsComponent = (props) => {
  const {
    structure,
    setTab,
    tab,
    mainMenu,
    setMenu,
    setSubMenu,
    currentMenu,
    search: { isSearching },
    setSearchStatusAction,
    collapsed,
  } = props;
  const { sections, headerTitle } = structure;

  const { id: projectId } = useParams();
  const navigate = useNavigate();

  const handleClick = async (tab) => {
    setSubMenu('');
    if (isSearching) {
      setSearchStatusAction(false);
    }
    if (mainMenu !== currentMenu) {
      setMenu(currentMenu);
    }
    navigate('/project/' + projectId + '/crm/' + tab);
    setTab(tab);
  };

  const matchIcon = (value, tab) => {
    const isSelected = tab === value && mainMenu === currentMenu;
    switch (value) {
      case 'Flows':
        return isSelected ? <FlowsSelected /> : <Flows />;
      case 'QnA':
        return isSelected ? <QnASelected /> : <QnA />;
      case 'Collections':
        return isSelected ? <CollectionsSelected /> : <Collections />;
      case 'Learning':
        return isSelected ? <AnnotationsSelected /> : <Annotations />;
      case 'Change logs':
        return isSelected ? <ChangelogSelected /> : <Changelog />;
      case 'Integrations':
        return isSelected ? <IntegrationsSelected /> : <Integrations />;
      case 'Overview':
        return isSelected ? <OverviewSelected /> : <Overview />;
      case 'Wise Agent':
        return isSelected ? <WiseAgentSelected /> : <WiseAgent />;
      case 'Team Performance':
        return isSelected ? <TeamPerformanceSelected /> : <TeamPerformance />;
      case 'Feedback':
        return isSelected ? <FeedbackSelected /> : <Feedback />;
      case 'Generative AI':
        return isSelected ? <CollectionsSelected /> : <Collections />;

      default:
        return <div>SVG</div>;
    }
  };

  const isSelected = (tab, section) => {
    return mainMenu === currentMenu && tab === section.value;
  };

  const getClasses = (tab, section) => {
    if (mainMenu === currentMenu && tab === section.value) {
      return 'submenu-section selected';
    } else {
      return 'submenu-section';
    }
  };

  const renderSection = (section, index, tab) => {
    return (
      <Popup
        key={`${index}_section`}
        style={{ color: '#56595f' }}
        trigger={
          <div className={getClasses(tab, section)} onClick={() => handleClick(section.value)}>
            <div className="icon" style={{ marginLeft: tab == 'settings' ? 5 : 0 }}>
              {matchIcon(section.value, tab)}
            </div>
            {!collapsed && <div className="dm-sans-500 title">{section.title}</div>}
            {!collapsed && isSelected(tab, section) && <div className="selected-bar" />}
          </div>
        }
        content={section.title}
        disabled={!collapsed}
        size="mini"
        position="right center"
      />
    );
  };

  return (
    <div className="MenuReportsComponent">
      {!collapsed && (
        <div className="submenu-header">
          <div className="title">{headerTitle}</div>
        </div>
      )}
      {sections?.map((section, index) => renderSection(section, index, tab))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  mainMenu: selectMainMenu(state),
  search: selectSearch(state),
});

const mapDispatchToProps = {
  setMenu: setMainMenu,
  setSearchStatusAction: setSearchStatus,
  setSubMenu: setSubMenu,
};
export default connect(mapStateToProps, mapDispatchToProps)(MenuTabsComponent);
