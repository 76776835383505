import React, { Component } from 'react';
import Flows from './components/Flows';
import { addWebchat, removeWebchat } from '../Flow/Services/WebchatHandler/WebchatHandler';
import { CurrentUser } from 'containers/CurrentUser';
import CollectionsEntry from './components/Collections';
import Integrations from './components/Integrations';
import { selectBotTab } from '../../store/crm/selectors';
import { connect } from 'react-redux';
import Annotations from '../Annotations';
import History from '../History/History';

import './ProjectDetails.scss';
import { RetrainStatusProvider } from '../Flow/components/Retrain';
import QnAList from '../QnA/QnAList';
import GenerativeAI from './components/GenerativeAI';

class ProjectDetails extends Component {
  componentDidMount() {
    addWebchat(this.props.projectId);
  }

  componentWillUnmount() {
    removeWebchat();
  }

  render() {
    const { botTab, projectId } = this.props;

    return (
      <div className="projectDetails-container-style">
        <div className="content-style">
          {botTab === 'Flows' && <Flows projectId={projectId} />}

          {botTab === 'Collections' && (
            <RetrainStatusProvider>
              <CollectionsEntry id={projectId} />
            </RetrainStatusProvider>
          )}
          {botTab === 'QnA' && <QnAList projectId={projectId} />}
          {botTab === 'Learning' && (
            <RetrainStatusProvider>
              <Annotations projectId={projectId} />
            </RetrainStatusProvider>
          )}
          {botTab === 'Change logs' && <History projectId={projectId} />}
          {botTab === 'Integrations' && <Integrations projectId={projectId} />}
          {botTab === 'Generative AI' && <GenerativeAI projectId={projectId} />}
        </div>
      </div>
    );
  }
}

ProjectDetails.contextType = CurrentUser;

const mapStateToProps = (state) => ({
  botTab: selectBotTab(state),
});
export default connect(mapStateToProps)(ProjectDetails);
