import React, { useCallback, useContext, useMemo } from 'react';
import Axios from 'axios';
import get from 'lodash/get';
import set from 'lodash/set';
import clone from 'lodash/cloneDeep';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import debounce from 'lodash/debounce';

import Connections from 'pages/Flow/containers/ConnectionsWrapper/Connection';
import { NodeUpdater, FlowMetaContext, NodeName } from 'pages/Flow/utils/canvas-contexts';
import withNotifications from 'components/withNotifications';
import { getSortedConnections } from 'pages/Flow/components/Canvas/utils/nodeCharacteristics';
import ConditionText from 'pages/Flow/components/Canvas/components/NodesRenderer/Node/components/ConditionText';
import useAllowedConnections from 'pages/Flow/components/Canvas/utils/useAllowedConnections';

const ConnectionsWrapper = ({ nodeId, showError }) => {
  const { nodes, updateOrCreateNode, updateDefaultConnection } = useContext(NodeUpdater);
  const appData = useContext(FlowMetaContext);
  const nodeNames = useContext(NodeName);
  const selectedNode = nodes[nodeId];
  // const objectFromNode = useObjectFromNode(selectedNode);
  const allowedClasses = useAllowedConnections(selectedNode);

  const connectionFilter = useCallback(
    ([id, node]) => {
      // Filter next possible connections based on intent / non-intent rules
      const restrictedNodeIds = Object.keys(get(selectedNode, 'next', {}));
      if (restrictedNodeIds.indexOf(id) !== -1) return false;

      if (nodeId === id) {
        return false;
      }

      if (allowedClasses.indexOf(node.object_class) === -1) return false;

      return true;
    },
    [allowedClasses, selectedNode]
  );

  const sortedConnections = useMemo(() => {
    const connections = getSortedConnections(selectedNode);

    return connections.map((connection, index) => {
      return {
        ...connection,
        sourceNodeId: nodeId,
        destinationNodeId: connection.nodeId,
        label: (
          <span style={{ textTransform: 'capitalize' }}>
            <ConditionText
              connection={connection}
              isFirst={index === 0}
              hasOneCondition={connections.length === 1}
              showConditionalOnly
            />
          </span>
        ),
      };
    });
  }, [nodeId, selectedNode]);

  const connectionsIndexMap = useMemo(() => {
    return sortedConnections.reduce((acc, current, currentIndex) => {
      return { ...acc, [current.destinationNodeId]: currentIndex };
    }, {});
  }, [sortedConnections]);

  const persistConnectionChange = useCallback((connection_id, order_position, condition_json) => {
    Axios.post('/internal_api/update_node_connection', {
      connection_id,
      order_position,
      condition_json,
    });
  }, []);

  const debouncedPersistConnectionChange = useMemo(() => {
    return debounce(persistConnectionChange, 200);
  }, [persistConnectionChange]);

  const actionValues = ['move_up', 'move_down', 'delete_connection', 'create_connection'];
  const fieldValues = ['variable', 'operator', 'value'];

  const handleMove = (sourceNodeId, destNodeId, field) => {
    const connectionIndex = connectionsIndexMap[destNodeId];
    const sourceNode = clone(nodes[sourceNodeId]);

    const connectionGoingUp =
      field === 'move_up'
        ? sortedConnections[connectionIndex]
        : sortedConnections[connectionIndex + 1];
    const connectionGoingDown =
      field === 'move_up'
        ? sortedConnections[connectionIndex - 1]
        : sortedConnections[connectionIndex];

    const { nodeId: nodeIdGoingUp, order_position: nodePositionGoingUp } = connectionGoingUp;
    const { nodeId: nodeIdGoingDown, order_position: nodePositionGoingDown } = connectionGoingDown;

    set(sourceNode, `next.${nodeIdGoingUp}.order_position`, nodePositionGoingDown);
    set(sourceNode, `next.${nodeIdGoingDown}.order_position`, nodePositionGoingUp);

    updateOrCreateNode({
      [sourceNodeId]: sourceNode,
    });
    persistConnectionChange(
      get(sourceNode, `next.${nodeIdGoingUp}.connection_id`),
      get(sourceNode, `next.${nodeIdGoingUp}.order_position`)
    );
    persistConnectionChange(
      get(sourceNode, `next.${nodeIdGoingDown}.connection_id`),
      get(sourceNode, `next.${nodeIdGoingDown}.order_position`)
    );
  };

  const handleCreate = (sourceNodeId, destinationNodeId) => {
    Axios.post('/internal_api/create_node_connection', {
      app_id: appData.appId,
      source_node_id: sourceNodeId,
      destination_node_id: destinationNodeId,
    })
      .then((response) => {
        const sourceNode = clone(nodes[sourceNodeId]);
        const destNode = clone(nodes[destinationNodeId]);

        let connectionData = {
          variable: '',
          operator: '',
          value: '',
          connection_id: response.data.connection_id,
          order_position: response.data.order_position,
        };
        set(sourceNode, `next.${destinationNodeId}`, connectionData);

        destNode.prev.push(sourceNodeId);

        updateOrCreateNode({
          [sourceNodeId]: sourceNode,
          [destinationNodeId]: destNode,
        });
      })
      .catch((e) => {
        showError('Something went wrong, please try again later');
      });
  };

  const handleDelete = (destNodeId, sourceNodeId) => {
    const destinationNode = clone(nodes[destNodeId]);
    const sourceNode = clone(nodes[sourceNodeId]);

    const connection_id = get(sourceNode, `next.${destNodeId}.connection_id`);
    const removedConnectionData = get(sourceNode, `next.${destNodeId}`);

    updateDefaultConnection(sourceNode, removedConnectionData);

    Axios.delete('/internal_api/delete_node_connection', {
      data: {
        app_id: appData.appId,
        connection_id,
      },
    });
    delete sourceNode.next[destNodeId];
    destinationNode.prev = filter(destinationNode.prev, (id) => id !== sourceNodeId);

    updateOrCreateNode({
      [destNodeId]: destinationNode,
      [sourceNodeId]: sourceNode,
    });
  };

  const handleConnectionAction = (sourceNodeId, destNodeId, field) => {
    switch (field) {
      case 'move_up':
      case 'move_down':
        handleMove(sourceNodeId, destNodeId, field);
        break;
      case 'delete_connection':
        handleDelete(destNodeId, sourceNodeId);
        break;
      case 'create_connection':
        handleCreate(sourceNodeId, destNodeId);
        break;
      default:
        break;
    }
  };

  /**
   * @param {Number|String} sourceNodeId
   * @param {Number|String} destNodeId
   * @param {('variable'|'operator'|'value')} field
   * @param {String} fieldValue
   * Will update the node with the updated connection in the local state
   * Persists the connection change
   */
  const handleFieldChange = (sourceNodeId, destNodeId, field, fieldValue) => {
    const sourceNode = clone(nodes[sourceNodeId]);
    if (sourceNode == null) {
      return;
    }

    set(sourceNode, `next.${destNodeId}.${field}`, fieldValue);

    updateOrCreateNode({
      [sourceNodeId]: sourceNode,
    });
    const { connection_id, order_position, variable, operator, value } =
      sourceNode.next[destNodeId];
    debouncedPersistConnectionChange(connection_id, order_position, {
      variable,
      operator,
      value,
    });
  };

  const handleConnectionChange = (sourceNodeId, destNodeId, field, value) => {
    if (includes(actionValues, field)) {
      handleConnectionAction(sourceNodeId, destNodeId, field);
    }
    if (includes(fieldValues, field)) {
      handleFieldChange(sourceNodeId, destNodeId, field, value);
    }
  };

  const handleChangeAllowInterruptions = (value) => {
    const node = clone(nodes[nodeId]);
    node.allow_interruptions = value;
    updateOrCreateNode({
      [nodeId]: node,
    });
    Axios.post('/internal_api/update_node_allow_interruptions', {
      app_id: appData.appId,
      node_id: nodeId,
      value,
    });
  };

  return (
    <Connections
      sourceNodeId={nodeId}
      nodes={nodes}
      entities={appData.entities}
      connections={sortedConnections}
      connectionFilter={connectionFilter}
      onChange={handleConnectionChange}
      onChangeAllowInterruptions={handleChangeAllowInterruptions}
      nodeNames={nodeNames}
    />
  );
};

export default withNotifications(ConnectionsWrapper);
