import React from 'react';
import { Popup } from 'semantic-ui-react';

import { getTimestamp } from 'utils/utils';

import './LogMessage.scss';

const LogMessage = ({ children, timestamp, messageType }) => {
  const messageTypeStyle = messageType !== 'audit' ? 'audit' : 'log small-text';

  return (
    <div className="LogMessage">
      <Popup
        style={{
          borderRadius: 10,
          opacity: 0.7,
        }}
        trigger={<div className={messageTypeStyle}>{children}</div>}
        content={getTimestamp(timestamp)}
        size="mini"
        inverted
        position="top center"
      />
    </div>
  );
};

export default LogMessage;
