import React from 'react';

const Collapse = (props) => (
  <svg
    width="39"
    height="41"
    viewBox="0 0 39 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 10C0 4.47715 4.47715 0 10 0H29C34.5228 0 39 4.47715 39 10V31C39 36.5228 34.5228 41 29 41H10C4.47715 41 0 36.5228 0 31V10Z"
      fill="white"
    />
    <path
      d="M0.5 10C0.5 4.7533 4.7533 0.5 10 0.5H29C34.2467 0.5 38.5 4.7533 38.5 10V31C38.5 36.2467 34.2467 40.5 29 40.5H10C4.75329 40.5 0.5 36.2467 0.5 31V10Z"
      stroke="#A3AED0"
      strokeOpacity="0.4"
    />
    <path
      d="M22.6446 16.1454L17.9309 21.0063L22.6446 25.8671C23.1185 26.3557 23.1185 27.145 22.6446 27.6336C22.1708 28.1221 21.4055 28.1221 20.9317 27.6336L15.3554 21.8832C14.8815 21.3946 14.8815 20.6054 15.3554 20.1168L20.9317 14.3664C21.4055 13.8779 22.1708 13.8779 22.6446 14.3664C23.1063 14.855 23.1185 15.6568 22.6446 16.1454Z"
      fill="#A3AED0"
    />
  </svg>
);

export default Collapse;
