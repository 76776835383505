import { v4 } from 'uuid';

export const RANDOM_BLOCK = 'RANDOM_MESSAGE_BLOCK';
export const SIMPLE_TEXT_BLOCK = 'SIMPLE_TEXT_BLOCK';
export const LINK_TEXT_BLOCK = 'LINK_TEXT_BLOCK';
export const IMAGE_BLOCK = 'IMAGE_BLOCK';
export const HTML_BLOCK = 'HTML_BLOCK';
export const CAROUSEL_BLOCK = 'CAROUSEL_BLOCK';
export const GRAPH_BLOCK = 'GRAPH_BLOCK';
export const uuidKey = 'uuid';

export const createNewRandomBlock = () => {
  return {
    [uuidKey]: v4(),
    message_block_type: RANDOM_BLOCK,
    random_options: [''],
    tts_on: [true],
    alternative_text_on: [false],
    alternative_text: [''],
  };
};

export const createNewSimpleBlock = () => {
  return {
    [uuidKey]: v4(),
    message_block_type: SIMPLE_TEXT_BLOCK,
    text: '',
    tts_on: true,
    alternative_text_on: false,
    alternative_text: '',
  };
};

export const createLinkTextBlock = () => {
  return {
    [uuidKey]: v4(),
    message_block_type: LINK_TEXT_BLOCK,
    url: '',
    linkText: '',
    description: '',
  };
};

export const createImageBlock = () => {
  return {
    [uuidKey]: v4(),
    message_block_type: IMAGE_BLOCK,
    titleLabel: '',
    url: '',
    text: '',
    image: '',
    tts_on: false,
    alternative_text_on: false,
    alternative_text: '',
  };
};

export const createHTMLBlock = () => {
  return {
    [uuidKey]: v4(),
    message_block_type: HTML_BLOCK,
    url: '',
    html: '',
    width: '',
    height: '',
  };
};

export const createCarouselBlock = () => {
  return {
    [uuidKey]: v4(),
    message_block_type: CAROUSEL_BLOCK,
    tts_on: false,
    alternative_text_on: false,
    alternative_text: '',
    carousel_elements: [],
    dataSource: '',
  };
};

export const createGraphBlock = () => {
  return {
    [uuidKey]: v4(),
    message_block_type: GRAPH_BLOCK,
    graph_datasource: '',
  };
};
