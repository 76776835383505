export const PERMISSION_HAS_MEMBERSHIP = 0;
export const PERMISSION_MANAGE_TEAMMATES = 1;
export const PERMISSION_MANAGE_BOT = 2;
export const PERMISSION_CRM_AGENT = 3;
export const PERMISSION_ACCESS_CONVERSATIONS = 4;
export const PERMISSION_MANAGE_MACROS = 5;
export const PERMISSION_REASSIGN_CONVERSATIONS = 6;
export const PERMISSION_ACCESS_REPORTS = 7;
export const PERMISSION_MANAGE_INTEGRATIONS = 8;
export const PERMISSION_MANAGE_GENAI = 9;

export const PERMISSION_PREFIX = 'permission_';

const allPermissions = [
  PERMISSION_MANAGE_TEAMMATES,
  PERMISSION_MANAGE_BOT,
  PERMISSION_CRM_AGENT,
  PERMISSION_ACCESS_CONVERSATIONS,
  PERMISSION_MANAGE_MACROS,
  PERMISSION_REASSIGN_CONVERSATIONS,
  PERMISSION_ACCESS_REPORTS,
  PERMISSION_MANAGE_INTEGRATIONS,
  PERMISSION_MANAGE_GENAI,
];
export default allPermissions;
