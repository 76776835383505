import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';

import { CollectionTable } from '../components/CollectionTable';
import { setLoading } from '../../../store/global/actions';
import Loader from '../../../components/common/Loader';
import languageMap from '../../../Language/Language';

const mappings = {
  headers: [languageMap.service, languageMap.flow, languageMap.lastUpdate, ''],
  keys: ['name', 'app_name', 'updated'],
};
const CollectionScripts = ({
  dispatch,
  projectId,
  setObjectSettings,
  appId,
  setAppId,
  setAppData,
  search,
  selectedFlows,
  listCounter,
  onDelete,
}) => {
  const [data, setData] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);

  const onEditService = async (appId, info) => {
    dispatch(setLoading(true));
    const response = await Axios.get(`/internal_api/project/${projectId}/flow/${appId}/data`);
    setAppData(response.data);
    setAppId(appId);
    setObjectSettings({
      objectClass: 'service',
      objectId: info.id,
      nodeId: info.node_id,
      objectName: info.name,
    });
    dispatch(setLoading(false));
  };

  const listServices = useCallback(async () => {
    const response = await Axios.get('/internal_api/services', {
      params: {
        project_id: projectId,
        selectedFlows: selectedFlows.length ? selectedFlows : undefined,
        search: search ? search : undefined,
      },
    });
    setData(response.data);
    if (firstLoad) {
      setFirstLoad(false);
    }
  }, [search, projectId, selectedFlows, firstLoad]);

  useEffect(() => {
    listServices();
  }, [listServices, listCounter]);

  if (firstLoad) {
    return <Loader />;
  }
  return (
    <CollectionTable
      data={data}
      appId={appId}
      objectClass={'service'}
      projectId={projectId}
      headers={mappings.headers}
      keys={mappings.keys}
      onEdit={onEditService}
      onDelete={onDelete}
      setObjectSettings={setObjectSettings}
    />
  );
};

export default connect(null)(CollectionScripts);
