import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';
import { IconButton } from '@mui/material';
import { Link } from 'react-router-dom';

import './CollectionRow.css';
import DeleteConfirmationModal from '../../../components/ConfirmationModal/DeleteConfirmationModal';
// =====================================================================================================================
//  D E C L A R A T I O N S
// =====================================================================================================================

const tableCell = {
  padding: 6,
};

const disabled = {
  pointerEvents: 'none',
};

// =====================================================================================================================
//  C O M P O N E N T
// =====================================================================================================================
const CollectionRow = ({ info, keys, onEdit, onDelete, projectId, appId }) => {
  const onDeleteClick = () => {
    onDelete(info.id);
  };

  const onEditClick = () => {
    onEdit(info.app_id || appId);
  };
  const isDisabled = !info.node_id || info.controller_type === 'qna';

  return (
    <TableRow className="tableRow" onDoubleClick={onEditClick}>
      {keys.map((key) => (
        <TableCell padding="none" style={tableCell} key={key}>
          <div>{info[key]}</div>
        </TableCell>
      ))}
      <TableCell padding="none" style={tableCell} key="buttons">
        <Link
          to={{ pathname: `/project/${projectId}/flow/${info.app_id}/${info.node_id || -1}` }}
          style={isDisabled ? disabled : null}
        >
          <IconButton title={'Link'} disabled={isDisabled} size="large">
            <LinkIcon />
          </IconButton>
        </Link>
        <IconButton
          onClick={onEditClick}
          color="primary"
          title={'Edit'}
          disabled={!info.app_id && !appId}
          size="large"
        >
          <Edit />
        </IconButton>
        <DeleteConfirmationModal
          onDelete={onDeleteClick}
          trigger={
            <IconButton color="secondary" title={'Delete'} disabled={!!info.node_id} size="large">
              <Delete />
            </IconButton>
          }
        />
      </TableCell>
    </TableRow>
  );
};
export default CollectionRow;
