import { React } from 'react';
import { connect } from 'react-redux';

import MenuComponent from 'components/MenuComponent';
import languageMap from 'Language/Language';
import Collapse from 'assets/svg/Collapse';
import Expand from 'assets/svg/Expand';
import { Popup } from 'semantic-ui-react';

import { selectMainMenu, selectIsMobile } from 'store/crm/selectors';

import './MenuContainer.scss';

const MenuContainer = ({ isMobile, mainMenu, collapsed, toggleCollapse, ...props }) => {
  const inboxSubMenus = [
    { title: languageMap.you, value: 'you', type: 'External' },
    { title: languageMap.unassigned, value: 'unassigned', type: 'External' },
    { title: languageMap.all, value: 'external_all', type: 'External' },
    { title: languageMap.allInternal, value: 'internal_all', type: 'Internal' },
  ];

  const properties = {
    headerTitle: props.headerTitle,
    inboxSubMenus,
  };

  let collapseStyle = { left: collapsed ? 21 : 220 };
  if (collapsed) {
    collapseStyle = {
      left: 0,
      bottom: 0,
      backgroundColor: 'white',
      width: 87,
      height: 70,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderTop: '1px solid #f4f7fe',
    };
  }

  let collapseButton = collapsed ? (
    <Expand onClick={toggleCollapse} />
  ) : (
    <Collapse onClick={toggleCollapse} />
  );

  return (
    <div className="MenuContainer" style={isMobile ? { width: '100%', height: '100%' } : {}}>
      <MenuComponent
        structure={properties}
        projectId={props.projectId}
        permissions={props.permissions}
        collapsed={collapsed}
        props={props}
      />

      {!isMobile && (
        <div className="collapse-container" style={collapseStyle}>
          <Popup
            style={{ color: '#56595f' }}
            trigger={collapseButton}
            content={languageMap.expand}
            disabled={!collapsed}
            size="mini"
            position="right center"
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  mainMenu: selectMainMenu(state),
  isMobile: selectIsMobile(state),
});

export default connect(mapStateToProps, null)(MenuContainer);
