import React from 'react';
import './DocumentMessage.scss';

const download = (url, fileName) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const DocumentMessage = ({ fileUrl }) => {
  return (
    <div
      className="IncomingMessage DocumentFileMessage"
      onClick={() => download(fileUrl, fileUrl.slice(fileUrl.lastIndexOf('/') + 1))}
    >
      <img
        className="DocumentFileMessage_Img"
        src="https://img.icons8.com/material-outlined/24/000000/document--v1.png"
        alt="Message File"
      />
      <span className="DocumentFileMessage_Span">Download file</span>
    </div>
  );
};

export default DocumentMessage;
