export const selectMainMenu = (state) => {
  return state.crm.mainMenu;
};

export const selectSubMenu = (state) => {
  return state.crm.subMenu;
};

export const selectInboxFolder = (state) => {
  return state.crm.inboxFolder;
};

export const selectConversationsStatusFilter = (state) => {
  return state.crm.conversationsStatusFilter;
};

export const selectConversations = (state) => {
  return state.crm.conversations?.conversations;
};

export const selectCurrentConversation = (state) => {
  return state.crm?.currentConversation || {};
};
export const selectMediaAndFiles = (state) => {
  return state.crm?.currentConversation?.mediaAndFiles;
};
export const selectAgents = (state) => {
  return state.crm?.agents || [];
};

export const selectDepartments = (state) => {
  return state.crm?.departments.departments || [];
};

export const selectDepartmentsIDs = (state) => {
  return state.crm?.departments.my_departments || [];
};

export const selectCurrentUser = (state) => {
  return state.crm?.currentUser;
};

export const selectReportCategory = (state) => {
  return state.crm?.reportCategory;
};

export const selectReportsTab = (state) => {
  return state.crm?.reportsTab;
};

export const selectBotTab = (state) => {
  return state.crm?.botTab;
};

export const selectConversationHistory = (state) => {
  return state.crm.conversationHistory?.history;
};

export const selectInboxCustomData = (state) => {
  return state.crm.conversationHistory?.inbox_custom_data;
};

export const selectConversationAllParticipants = (state) => {
  return state.crm.conversationHistory?.participants;
};

export const selectConversationCurrentParticipants = (state) => {
  return state.crm.conversationHistory?.participants?.current_participants;
};

export const selectHistoryConversations = (state) => {
  return state.crm.conversationHistory?.conversations;
};

export const selectFirstSeen = (state) => {
  return state.crm.conversationHistory?.first_seen;
};

export const selectConversationNotes = (state) => {
  return state.crm.conversationHistory?.conversation_data?.conversation_notes;
};

export const selectUserPermissionForConversation = (state) => {
  return state.crm.conversationHistory.allowed;
};

export const selectFolderTotals = (state) => {
  return state.crm.conversations?.folder_conversations;
};

export const selectUnreadConversations = (state) => {
  return state.crm.conversations?.unread_conversations;
};

export const selectSelectedMacro = (state) => {
  return state.crm.selectedMacro;
};

export const selectMacros = (state) => {
  return state.crm.macros;
};

export const selectFilters = (state) => {
  return state.crm.filters;
};

export const selectAllLanguages = (state) => {
  return state.crm.allLanguages;
};

export const selectSecondaryLanguages = (state) => {
  return state.crm.secondaryLanguages;
};

export const selectProjectLanguage = (state) => {
  return state.crm.projectLanguage;
};

export const selectSearch = (state) => {
  return state.crm.search;
};
export const selectSearchFilters = (state) => {
  return state.crm.search.searchFilters;
};
export const selectStartFromConversationIndex = (state) => {
  return state.crm.search?.startFromConversationIndex;
};
export const selectIsMobile = (state) => {
  return state.crm?.isMobile;
};
