import React from 'react';
import Checkbox from './Checkbox';

const LabeledCheckbox = React.forwardRef((props, ref) => {
  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
  };

  const titleStyle = {
    color: '#333',
  };

  return (
    <div style={{ ...containerStyle, ...props.style }}>
      <Checkbox ref={ref} {...props} />
      <div style={titleStyle} ref={ref} {...props}>
        {props.label}
      </div>
    </div>
  );
});

export default LabeledCheckbox;
