import React, { useState } from 'react';
import { Logo } from 'assets/logo/Logo';
import LoginPanel from 'assets/svg/LoginPanel';

import './Auth.scss';
import Axios from 'axios';
import AllRights from '../../components/AllRights/AllRights';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  // const [loading, setLoading] = useState(false);
  // const [errors, setErrors] = useState({});

  const isValidEmail = (email) => {
    const emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, 'gm');
    return emailRegex.test(email);
  };

  const handleSendEmail = () => {
    if (!isValidEmail(email)) {
      alert('Invalid email');
      return;
    }

    // setLoading(true);

    Axios.get('/internal_api/send_forgot_password_email', {
      params: {
        email: email,
      },
    })
      .then(() => alert('Email sent!'))
      .catch((error) => {
        console.log(error);
        // setErrors(error.response?.data?.errors || {});
      });
    // .finally(() => setLoading(false));
  };

  return (
    <div className="Auth">
      <div className="Container">
        <Logo className="Logo" />
        <span className="Title dm-sans-700">Forgot password</span>
        <div className="FormRow">
          <div className="RegisterFormLabel dm-sans-500">
            <span className="FormLabel dm-sans-500">Email</span>
            {!isValidEmail(email) && (
              <span className="FormLabel dm-sans-500" style={{ color: '#0530F9' }}>
                * (invalid)
              </span>
            )}
          </div>
          <input
            name="email"
            autoComplete="email"
            className="Input RegisterInput dm-sans-400"
            placeholder="Your email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <button className="Submit dm-sans-700" onClick={handleSendEmail}>
          Send email
        </button>
        <AllRights />
      </div>

      <LoginPanel className="ShowOnlyOnDesktop" />
    </div>
  );
};

export default ForgotPassword;
