import React from 'react';
import { Link } from 'react-router-dom';
import { Logo } from 'assets/logo/Logo';
import LoginPanel from 'assets/svg/LoginPanel';

import './Auth.scss';
import RegisterForm from './RegisterForm';
import AllRights from '../../components/AllRights/AllRights';

const Register = () => {
  return (
    <div className="Auth">
      <div className="Container">
        <Logo className="Logo" />
        <span className="Title dm-sans-700">Register</span>
        <RegisterForm />
        <div className="CreateAccountContainer">
          <span className="NotRegistered dm-sans-400">Already have an account?</span>
          <Link to="/login">
            <span className="SpanButton dm-sans-500">Sign in</span>
          </Link>
        </div>
        <AllRights />
      </div>

      <LoginPanel className="ShowOnlyOnDesktop" />
    </div>
  );
};

export default Register;
