import React from 'react';
import Button from '@mui/material/Button';
import { AppBar, Dialog, DialogActions, IconButton, Toolbar } from '@mui/material';
import Typography from '@mui/material/Typography';
import Close from '@mui/icons-material/Close';
import './DialogComponent.scss';
const DialogComponent = ({ title, onClose, isOpen, onProceed, children }) => {
  return (
    <Dialog
      open={isOpen}
      fullWidth
      onClose={onClose}
      classes={{ paperFullWidth: 'PaperWidthFull', paperWidthSm: 'PaperWidthSmall' }}
    >
      <AppBar color="default" position="static">
        <Toolbar variant="dense" style={{ paddingRight: 3 }}>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <IconButton size="small" onClick={onClose} disableRipple>
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div
        style={{
          margin: 'auto',
          overflowY: 'auto',
          position: 'relative',
        }}
      >
        {children}
      </div>
      <DialogActions>
        <Button color="primary" onClick={onProceed}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogComponent;
