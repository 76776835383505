import React, { Component } from 'react';

import './MessageResponseOption.scss';
import { Icon } from 'semantic-ui-react';

const inputStyle = {
  flex: 1,
  border: 'none',
  height: '100%',
  width: '100%',
  resize: 'none',
  marginTop: 8,
  // outline: 'none',
  // marginTop: 8,
  overflow: 'auto',
  padding: 8,
  borderRadius: 8,
};

const boxStyle = {
  marginTop: 8,
  padding: 8,
  borderRadius: 8,
  border: '1px solid lightgray',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
};

const deleteStyle = {
  marginLeft: 'auto',
  cursor: 'pointer',
  justifySelf: 'flex-end',
};

export default class MessageResponseOption extends Component {
  handleValueChange = (event) => {
    this.props.onTextChange(event.target.name, event.target.value);
  };

  onRemoved = (index) => {
    this.props.onRemoved(index);
  };

  render() {
    const value = this.props.value;
    const textValue = value.text !== undefined ? value.text : '';

    return (
      <div style={boxStyle}>
        <Icon
          className="MessageResponseOption_DeleteIcon"
          style={deleteStyle}
          name="delete"
          onClick={() => {
            this.onRemoved(this.props.index);
          }}
        />
        <textarea
          name={this.props.index}
          style={inputStyle}
          onBlur={this.handleValueChange}
          defaultValue={textValue}
        />
      </div>
    );
  }
}
