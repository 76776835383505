import React from 'react';
import { Icon } from 'semantic-ui-react';

import OptionCheckbox from 'pages/Flow/components/Message/MessageBlocks/components/OptionCheckbox';

import './RandomMessageBlock.scss';
import languageMap from 'Language/Language';
import { AddButton } from '../../../../../../components/common/Button';

const RandomMessageBlock = ({ block, onBlockChange, voiceInteraction }) => {
  const { random_options = [], tts_on, alternative_text_on = [], alternative_text } = block;

  const renderRandomMessageForm = () => {
    return (
      <div className="RandomMessageBlock_Form">
        <>
          {random_options.map((text, index) => (
            <div key={index} className="RandomMessageBlock_Form_Container">
              <div
                key={`random_message_block_form_${index}`}
                className="RandomMessageBlock_Form_Chip"
              >
                <textarea
                  key={`random_options_${index}`}
                  name={`random_options.${index}`}
                  className="RandomMessageBlock_Form_Chip_Input"
                  autoComplete="off"
                  defaultValue={text}
                  onBlur={(event) => {
                    const newRandomOptions = [...random_options];
                    newRandomOptions[index] = event.target.value;
                    onBlockChange({
                      ...block,
                      random_options: newRandomOptions,
                    });
                  }}
                />
                <Icon
                  key={`random_block_delete_icon_${index}`}
                  className="RandomMessageBlock_Form_Chip_Icon"
                  name="delete"
                  onClick={() => {
                    const newRandomOptions = [...random_options];
                    const newTtsOn = [...tts_on];
                    const newAlternativeTextOn = [...alternative_text_on];
                    const newAlternativeText = [...alternative_text];
                    newRandomOptions.splice(index, 1);
                    newTtsOn.splice(index, 1);
                    newAlternativeTextOn.splice(index, 1);
                    newAlternativeText.splice(index, 1);
                    onBlockChange({
                      ...block,
                      random_options: newRandomOptions,
                      tts_on: newTtsOn,
                      alternative_text_on: newAlternativeTextOn,
                      alternative_text: newAlternativeText,
                    });
                  }}
                />
              </div>
              {renderAlternativeTextArea(index)}
              {renderOptionalSettings(index)}
            </div>
          ))}
          <div>
            <AddButton
              text={languageMap.addNew}
              // className="RandomMessageBlock_Form_Chip RandomMessageBlock_Form_Chip_Add"
              onClick={() => {
                const newRandomOptions = [...random_options, ''];
                const newTtsOn = [...tts_on, true];
                const newAlternativeTextOn = [...alternative_text_on, false];
                const newAlternativeText = [...alternative_text, ''];
                onBlockChange({
                  ...block,
                  random_options: newRandomOptions,
                  tts_on: newTtsOn,
                  alternative_text_on: newAlternativeTextOn,
                  alternative_text: newAlternativeText,
                });
              }}
            />
          </div>
        </>
      </div>
    );
  };

  const renderTTSOption = (index) => {
    return (
      voiceInteraction && (
        <OptionCheckbox
          key={`optional_settings_tts_${index}`}
          checked={tts_on[index] || false}
          onChange={() => {
            const newTtsOn = [...tts_on];
            const newAlternativeTextOn = [...alternative_text_on];
            newTtsOn[index] = !newTtsOn[index];
            if (!tts_on[index]) {
              newAlternativeTextOn[index] = false;
            }
            onBlockChange({
              ...block,
              tts_on: newTtsOn,
              alternative_text_on: newAlternativeTextOn,
            });
          }}
          title={languageMap.ttsOnLabel}
        />
      )
    );
  };

  const renderAltTextOption = (index) => {
    return (
      <OptionCheckbox
        key={`optional_settings_alt_text_${index}`}
        checked={alternative_text_on[index] || false}
        onChange={() => {
          const newAlternativeTextOn = [...alternative_text_on];
          newAlternativeTextOn[index] = !newAlternativeTextOn[index];
          onBlockChange({
            ...block,
            alternative_text_on: newAlternativeTextOn,
          });
        }}
        title={languageMap.alternativeText}
      />
    );
  };

  const renderOptionalSettings = (index) => {
    return (
      <div key={`optional_settings_${index}`} className={`RandomMessageBlock_OptionalSettings`}>
        {renderTTSOption(index)}
        {renderAltTextOption(index)}
      </div>
    );
  };

  const renderAlternativeTextArea = (index) => {
    return (
      alternative_text_on[index] && (
        <div style={{ flexGrow: 1, flexShrink: 1 }}>
          <textarea
            key={`alternative_text_${index}`}
            className="RandomMessageBlock_TextArea"
            defaultValue={alternative_text[index]}
            onBlur={(e) => {
              const newAlternativeText = [...alternative_text];
              newAlternativeText[index] = e.target.value;
              onBlockChange({
                ...block,
                alternative_text: newAlternativeText,
              });
            }}
          />
        </div>
      )
    );
  };

  return <div className="RandomMessageBlock">{renderRandomMessageForm()}</div>;
};

export default RandomMessageBlock;
