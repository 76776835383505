import React from 'react';
import { connect } from 'react-redux';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import './ScrollToBottomButton.scss';
import { selectIsMobile } from 'store/crm/selectors';

const ScrollToBottomButton = ({ showScrollDownButton, onButtonClick, isMobile }) => {
  return (
    <div
      id="scroll-to-bottom-button"
      className={showScrollDownButton ? 'ScrollToBottomButton' : 'ScrollToBottomButton hidden'}
      style={isMobile ? { bottom: '18%' } : { bottom: '13%' }}
      onClick={onButtonClick}
    >
      <ArrowDownwardIcon className="arrow-down" />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMobile: selectIsMobile(state),
});

export default connect(mapStateToProps, null)(ScrollToBottomButton);
