import React, { Component } from 'react';

export default class HeaderEntryView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDelete: false,
    };
  }

  handleKeyChange = (event) => {
    this.props.onChange(event.target.value, this.props.headerValue);
  };

  handleValueChange = (event) => {
    this.props.onChange(this.props.headerKey, event.target.value);
  };

  handleOnMouseOver = () => {
    this.setState({
      showDelete: true,
    });
  };

  handleOnMouseOut = () => {
    this.setState({
      showDelete: false,
    });
  };

  handleRemove = () => {
    this.props.remove();
  };

  render() {
    const inputStyle = {
      width: '100%',
    };

    const containerStyle = {
      display: 'flex',
      width: '100%',
    };

    const deleteStyle = {
      width: '35px',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    };

    return (
      <div
        style={containerStyle}
        onMouseOver={this.handleOnMouseOver}
        onMouseOut={this.handleOnMouseOut}
      >
        <input
          style={inputStyle}
          placeholder="Key"
          value={this.props.headerKey}
          onChange={this.handleKeyChange}
        ></input>
        <input
          style={inputStyle}
          placeholder="Value"
          value={this.props.headerValue}
          onChange={this.handleValueChange}
        ></input>
        {this.state.showDelete ? (
          <div style={deleteStyle} onClick={this.handleRemove}>
            X
          </div>
        ) : (
          <div style={deleteStyle}></div>
        )}
      </div>
    );
  }
}
