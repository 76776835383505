import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { Icon, Checkbox, Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';

import Modal from 'pages/Flow/components/Modal';
import { AddButton } from 'components/common/Button/Buttons';
import languageMap from 'Language/Language';

import './AddLinkDialog.scss';

import { getSelectedFlowId, getProjectId } from 'store/qna/selectors';
import { createLink } from 'store/qna/actions';
import { selectProjectLanguage } from '../../../../store/crm/selectors';
import { FlexRow, Input, Label } from '../../../../components/Utterances/Misc';

function AddLinkDialog(props) {
  const [linkText, setLinkText] = useState('');
  const [selectedMessageNodeId, setSelectedMessageNodeId] = useState(-1);
  const [messages, setMessages] = useState([]);
  const [createNewMessage, setCreateNewMessage] = useState(false);

  useEffect(() => {
    console.log(props);
    axios
      .get('/internal_api/qna/get_project_messages', {
        params: {
          project_id: props.projectId,
          language_code: props.selectedLanguage,
        },
      })
      .then((response) => {
        let messages = [];
        let first = 0;

        response.data
          .filter((msg) => {
            if (props.sourceNodeId === msg.node_id) return false;

            for (let link of props.existingLinks) {
              if (link.destination_node_id === msg.node_id) {
                return false;
              }
            }

            return true;
          })
          .forEach((msg) => {
            if (!first) {
              first = msg.node_id;
            }

            messages.push({
              key: msg.node_id,
              value: msg.node_id,
              text: msg.message_name + ` (${msg.app_name})`,
            });
          });

        setMessages(messages);
        if (first !== 0) {
          setSelectedMessageNodeId(first);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeMessage = useCallback((e, { value }) => {
    setSelectedMessageNodeId(value);
  }, []);

  const createLink = () => {
    if (linkText.length === 0) return;

    let params = {
      flow_id: props.flowId,
      source_node_id: props.sourceNodeId,
      link_text_by_language: { [props.selectedLanguage]: linkText },
      addElement: (node_id) => {
        props.addQnaElement(linkText, node_id);
      },
    };

    if (!createNewMessage) {
      params.destination_node_id = selectedMessageNodeId;
    }

    props.createLink(params);
    props.hide();
  };

  return (
    <Modal onHide={props.hide}>
      <div className="AddLinkDialogModalContainer">
        <div className="AddLinkDialogCover" onClick={props.hide} />
        <div className="AddLinkDialogOuterContainer">
          <Icon
            name="delete"
            onClick={props.hide}
            style={{ cursor: 'pointer', position: 'fixed', right: 0, fontSize: 20 }}
          />

          <div className="AddLinkDialogTitle">
            <span>{languageMap.addLink}</span>
          </div>

          <div className="AddLinkDialogInnerContainer">
            <FlexRow>
              <Label text={languageMap.linkName} width={130} />
              <Input value={linkText} onChange={setLinkText} />
            </FlexRow>

            {!createNewMessage && (
              <FlexRow>
                <Label text={languageMap.message} width={130} />
                <Dropdown
                  style={{ fontSize: 18 }}
                  fluid
                  deburr
                  search
                  selection
                  options={messages}
                  value={selectedMessageNodeId}
                  onChange={handleChangeMessage}
                />
              </FlexRow>
            )}

            <FlexRow>
              <Checkbox
                style={{ fontSize: 18 }}
                label={languageMap.createNewMessage}
                checked={createNewMessage}
                onChange={() => setCreateNewMessage(!createNewMessage)}
              />
            </FlexRow>

            {linkText.length === 0 && (
              <FlexRow>
                <span style={{ color: 'red' }}>{languageMap.linkTextCanNotBeEmpty}</span>
              </FlexRow>
            )}

            <AddButton icon="add" text={languageMap.createLink} onClick={createLink} />
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  selectedLanguage: selectProjectLanguage(state),
  flowId: getSelectedFlowId(state),
  projectId: getProjectId(state),
});

const mapDispatchToProps = { createLink };

export default connect(mapStateToProps, mapDispatchToProps)(AddLinkDialog);
