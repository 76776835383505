import React, { useState, createContext, useCallback, useContext } from 'react';
import get from 'lodash/get';

import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import { StyledEngineProvider } from '@mui/system';

import { useParams } from 'react-router-dom';

import Modal from 'pages/Flow/components/Modal';
import IntentDialog from 'pages/Flow/components/Canvas/components/NodeSettingsProvider/components/IntentDialog';
import MessageDialog from 'pages/Flow/components/Canvas/components/NodeSettingsProvider/components/MessageDialog';
import EntityDialog from 'pages/Flow/components/Canvas/components/NodeSettingsProvider/components/EntityDialog';
import EventDialog from 'pages/Flow/components/Canvas/components/NodeSettingsProvider/components/EventDialog';
import ServiceDialog from 'pages/Flow/components/Canvas/components/NodeSettingsProvider/components/ServiceDialog';
import ScriptDialog from 'pages/Flow/components/Script/ScriptDialog';
import FlowConnectorDialog from 'pages/Flow/components/Canvas/components/NodeSettingsProvider/components/FlowConnectorDialog';
import ParentConnectorDialog from 'pages/Flow/components/Canvas/components/NodeSettingsProvider/components/ParentConnectorDialog';
import { NodeUpdater, FlowMetaContext } from 'pages/Flow/utils/canvas-contexts';
import useObjectFromNode from 'pages/Flow/components/Canvas/utils/useObjectFromNode';
import { ThemeContext, objectThemes as themes } from 'pages/Flow/utils/Theme';
import { connect } from 'react-redux';
import extractLanguageInfo from '../../../../../../utils/extractLanguageInfo';
import mainTheme from '../../../../../../mainTheme';

export const SettingsDrawerContext = createContext({
  openSettingsDrawer: () => {},
  closeSettingsDrawer: () => {},
});

const NodeSettingsProvider = ({ children, languageOptions, selectedLanguage }) => {
  const [settings, setSettings] = useState(null);

  const { id: projectId, appId } = useParams();
  const appData = useContext(FlowMetaContext);
  const { nodes, deleteNode } = useContext(NodeUpdater);

  const selectedNodeId = get(settings, 'nodeId');
  const selectedNode = nodes[selectedNodeId];

  const objectFromNode = useObjectFromNode(selectedNode);

  const openSettingsDrawer = useCallback((objectClass, objectId, nodeId) => {
    setSettings({ objectClass, objectId, nodeId });
  }, []);

  const closeSettingsDrawer = useCallback(() => {
    setSettings(null);
  }, []);

  const getOnNameChange = (objectNameKey) => {
    const { objectClass } = settings;
    const { updateObject } = appData;

    return (id, newName) => {
      updateObject(objectClass, {
        ...objectFromNode,
        [objectNameKey]: newName,
      });
    };
  };

  const renderSettingsDrawer = () => {
    const { nodeId, objectId, objectClass } = settings;

    switch (objectClass) {
      case 'intent':
        return (
          <IntentDialog
            appId={appId}
            nodeId={nodeId}
            objectId={objectId}
            entities={appData.entities}
            onNameChange={getOnNameChange('intent_name')}
            objectName={objectFromNode.intent_name}
            deleteNode={deleteNode}
            onClose={closeSettingsDrawer}
            languageOptions={languageOptions}
            selectedLanguage={selectedLanguage}
          />
        );
      case 'message':
        return (
          <MessageDialog
            appId={appId}
            projectId={projectId}
            messageId={objectId}
            nodeId={nodeId}
            objectModel={objectFromNode}
            onNameChange={getOnNameChange('message_name')}
            objectName={objectFromNode.message_name}
            deleteNode={deleteNode}
            onClose={closeSettingsDrawer}
            languageOptions={languageOptions}
            selectedLanguage={selectedLanguage}
          />
        );
      case 'entity':
        return (
          <EntityDialog
            appId={appId}
            nodeId={nodeId}
            entities={appData.entities}
            projectId={projectId}
            entityTypes={appData.entity_types}
            entityId={objectId}
            onNameChange={getOnNameChange('entity_name')}
            objectName={objectFromNode.entity_name}
            deleteNode={deleteNode}
            onClose={closeSettingsDrawer}
            languageOptions={languageOptions}
            selectedLanguage={selectedLanguage}
          />
        );
      case 'event':
        return (
          <EventDialog
            projectId={projectId}
            appId={appId}
            nodeId={nodeId}
            eventTypes={appData.event_types}
            eventId={objectId}
            settings={objectFromNode}
            onNameChange={getOnNameChange('event_name')}
            objectName={objectFromNode.event_name}
            deleteNode={deleteNode}
            onClose={closeSettingsDrawer}
            updateEventInCanvasContext={appData.updateObject}
          />
        );
      case 'service':
        return (
          <ServiceDialog
            appId={appId}
            serviceId={objectId}
            nodeId={nodeId}
            onClose={closeSettingsDrawer}
            onNameChange={getOnNameChange('service_name')}
            objectName={objectFromNode.service_name}
            deleteNode={deleteNode}
          />
        );
      case 'script':
        return (
          <ScriptDialog
            nodeId={nodeId}
            script={objectFromNode}
            appId={appId}
            projectId={projectId}
            onClose={closeSettingsDrawer}
            onNameChange={getOnNameChange('script_name')}
            objectName={objectFromNode.script_name}
            deleteNode={deleteNode}
            updateObject={appData.updateObject}
          />
        );
      case 'flow_connector':
        return (
          <FlowConnectorDialog
            projectId={projectId}
            appId={appId}
            objectId={objectId}
            nodeId={nodeId}
            onClose={closeSettingsDrawer}
            onNameChange={getOnNameChange('flow_connector_name')}
            objectName={objectFromNode.flow_connector_name}
            deleteNode={deleteNode}
          />
        );
      case 'parent_connector':
        return (
          <ParentConnectorDialog
            projectId={projectId}
            appId={appId}
            objectId={objectId}
            nodeId={nodeId}
            onClose={closeSettingsDrawer}
            onNameChange={getOnNameChange('parent_connector_name')}
            objectName={objectFromNode.parent_connector_name}
            deleteNode={deleteNode}
            languageOptions={languageOptions}
            selectedLanguage={selectedLanguage}
          />
        );
      default:
        break;
    }
  };
  return (
    <SettingsDrawerContext.Provider value={{ openSettingsDrawer, closeSettingsDrawer }}>
      {settings && (
        <Modal onHide={closeSettingsDrawer}>
          <ThemeContext.Provider value={themes[settings.objectClass]}>
            <StyledEngineProvider injectFirst>
              <MuiThemeProvider
                theme={createTheme({
                  ...mainTheme,
                  palette: {
                    ...mainTheme.palette,
                    primary: {
                      main: themes[settings.objectClass].main,
                      contrastText: '#FFF',
                    },
                  },
                })}
              >
                {renderSettingsDrawer()}
              </MuiThemeProvider>
            </StyledEngineProvider>
          </ThemeContext.Provider>
        </Modal>
      )}
      {children}
    </SettingsDrawerContext.Provider>
  );
};

export default connect(extractLanguageInfo)(NodeSettingsProvider);
