import React from 'react';
import { connect } from 'react-redux';
import { selectConversationAllParticipants } from 'store/crm/selectors';

import './ChatAvatar.scss';
import clsx from 'clsx';
import getAssetPath from '../../../../../../../../utils/getAssetPath';

const ChatAvatar = (props) => {
  const { msgSender } = props;

  const firstLetterOfSender = (msgSender) => {
    if (msgSender) {
      const firstLetter = msgSender?.charAt(0);
      return firstLetter.toUpperCase();
    }
  };

  const chatAvatarSelector = () => {
    if (msgSender !== 'unknown user' && msgSender) {
      return (
        <div
          className={clsx(
            'sender-first-letter title-text',
            props.large && 'sender-first-letter-large'
          )}
        >
          {firstLetterOfSender(msgSender)}
        </div>
      );
    } else {
      return (
        <img
          src={getAssetPath('Ellipse_5.png')}
          className={clsx('NoSenderImg', props.large && 'NoSenderImgLarge')}
        />
      );
    }
  };

  return (
    <div className={'ChatAvatar' + (props.large ? ' ChatAvatarLarge' : '')}>
      {chatAvatarSelector()}
    </div>
  );
};

const mapStateToProps = (state) => ({
  conversationParticipants: selectConversationAllParticipants(state),
});

export default connect(mapStateToProps, null)(ChatAvatar);
