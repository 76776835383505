import { useEffect, useState } from 'react';
import { checkSession } from 'utils/utils';

const useCurrentUser = (setUserAction) => {
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    setLoading(true);
    checkSession()
      .then((response) => {
        setCurrentUser(response);
        setUserAction && setUserAction(response);
      })
      .catch((err) => console.log('Check session err', err))
      .finally(() => setLoading(false));
  }, [checkSession]);

  return [currentUser, loading, setCurrentUser];
};

export default useCurrentUser;
