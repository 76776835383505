import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { connect } from 'react-redux';

import { Input } from '../../components/Utterances/Misc';
import Back from '../../assets/svg/Back';
import Link from '../../components/common/NavBar/components/Link';
import ConflictsWarning from './components/ConfilctsWarning';
import LanguageOptions from './components/LanguageOptions/LanguageOptions';
import { WhiteButton } from 'components/common/Button/Buttons';
import Entry from './components/Entry';
import { useComputeLanguageOptions } from './components/LanguageOptions/ComputeLanguageOptions';

import { downloadFileFromUrl } from 'utils/utils';
import languageMap from 'Language/Language';
import './QnA.scss';

import {
  clearState,
  createEntry,
  createFlow,
  deleteEntry,
  fetchEntries,
  fetchInitialState,
  updateEntry,
  conflictsCount,
} from 'store/qna/actions';
import { getProjectLanguageData } from 'store/crm/actions';
import {
  getEntries,
  getEntriesTotal,
  getHasInitialState,
  getConflictsCount,
} from 'store/qna/selectors';
import Pagination from '../../components/Pagination/Pagination';
import { addWebchat, removeWebchat } from '../Flow/Services/WebchatHandler/WebchatHandler';
import Retrain from '../Flow/components/Retrain';
import HelperTooltip from '../../components/HelperTooltip/HelperTooltip';

import {
  selectAllLanguages,
  selectProjectLanguage,
  selectSecondaryLanguages,
} from '../../store/crm/selectors';

const PAGE_COUNT = 6;

function QnA({
  clearState,
  conflictsCount,
  conflictsCountAction,
  createEntry,
  createFlow,
  deleteEntry,
  entries,
  entriesTotal,
  fetchEntries,
  fetchInitialState,
  getProjectLanguageData,
  hasInitialState,
  languages,
  secondaryLanguages,
  selectedLanguage,
  ...props
}) {
  const { id: projectId, appId: flowId } = useParams();

  const [filter, setFilter] = useState('');
  const [selectedTopLanguage, setSelectedTopLanguage] = useState(selectedLanguage);
  const [entryToLanguage, setEntryToLanguage] = useState({});
  const [skip, setSkip] = useState(0);
  const [reminderDisplay, setReminderDisplay] = useState(true);
  const [checkReminder, setCheckReminder] = useState(false);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setSelectedTopLanguage(selectedLanguage);
  }, [selectedLanguage]);

  useEffect(() => {
    if (!selectedLanguage) {
      getProjectLanguageData(projectId);
    }
    fetchInitialState({ project_id: projectId, flow_id: flowId });
    addWebchat(projectId);
    conflictsCountAction({ project_id: projectId });

    return function cleanup() {
      clearState();
      removeWebchat();
    };
  }, []);

  const handleCreateEntry = useCallback(() => {
    if (!flowId) {
      createFlow({ project_id: projectId, language: selectedLanguage });
    } else {
      createEntry({ flow_id: flowId });
    }
  }, [props, projectId]);

  useEffect(() => {
    if (flowId) {
      applyFilter(filter);
    }
  }, [filter, skip]);

  useEffect(() => {
    if (!conflictsCount || !reminderDisplay) {
      setCheckReminder(false);
    } else {
      setCheckReminder(true);
    }
  }, [conflictsCount, reminderDisplay]);

  const applyFilter = useCallback(
    _.debounce((filter) => {
      if (hasInitialState) {
        return;
      }
      fetchEntries({
        flow_id: flowId,
        filter,
        skip,
        limit: PAGE_COUNT,
      });
    }, 500),
    [hasInitialState, flowId, skip]
  );

  function handleSetFilter(filter) {
    setSkip(0);
    setFilter(filter);
  }

  const updateEntry = (intent_id, utterances_by_language, automaticTranslation) => {
    props.updateEntry({ intent_id, utterances_by_language, automaticTranslation });
  };

  const languageOptions = useComputeLanguageOptions({
    languages: languages,
    secondaryLanguages: secondaryLanguages,
    selectedLanguage: selectedLanguage,
  });

  function handleExport() {
    const url = '/internal_api/qna/export';
    const contentType = 'application/json';
    const params = {
      app_id: flowId,
    };
    const fileName = `qna_${projectId}_${selectedLanguage}.xlsx`;
    downloadFileFromUrl(url, contentType, fileName, params);
  }
  return (
    <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      <div id="QnaTopBar">
        <Link
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
          shouldHardRedirect={false}
          to={{ pathname: '/project/' + projectId + '/crm/QnA' }}
        >
          <Back className="back-button" />
        </Link>
        <div id="SearchContainer">
          <Input
            placeholder={languageMap.search}
            value={filter}
            onChange={handleSetFilter}
            style={{
              width: 250,
              padding: '7px 10px',
              background: '#fff',
              border: '1px solid rgba(34,36,38,.15)',
            }}
          />

          <WhiteButton
            style={{ marginRight: 15, marginLeft: 15 }}
            text={languageMap.createNewPair}
            onClick={handleCreateEntry}
          />
          <WhiteButton
            style={{ marginRight: 15 }}
            text={languageMap.export}
            onClick={handleExport}
          />
        </div>

        <HelperTooltip
          style={{ marginRight: 10, height: 30, width: 30 }}
          title={languageMap.helperTextQna}
        />

        <LanguageOptions
          setEntryToLanguage={setEntryToLanguage}
          languageOptions={languageOptions}
          setSelectedTopLanguage={setSelectedTopLanguage}
        />

        <Retrain style={{ marginRight: 12, marginLeft: 10 }} />
      </div>
      {checkReminder && (
        <ConflictsWarning
          setReminderDisplay={setReminderDisplay}
          conflictsCounter={conflictsCount}
        />
      )}
      <div
        style={
          checkReminder
            ? { height: `calc(${height}px - 300px)`, overflowY: 'auto' }
            : { height: `calc(${height}px - 150px)`, overflowY: 'auto' }
        }
      >
        <div id="QnaEntriesContainer">
          <div id="QnaEntriesHeader">
            <span>{languageMap.questions}</span>
            <span>{languageMap.answers}</span>
          </div>

          {entries &&
            entries.map((entry) => (
              <Entry
                key={entry.message_id}
                appId={flowId}
                deleteEntry={deleteEntry}
                languageOptions={languageOptions}
                selectedLanguage={entryToLanguage[entry.message_id] || selectedTopLanguage}
                entryToLanguage={entryToLanguage}
                setEntryToLanguage={setEntryToLanguage}
                isProjectLanguage={Boolean(
                  (entryToLanguage[entry.message_id] || selectedTopLanguage) === selectedTopLanguage
                )}
                updateEntry={updateEntry}
                {...entry}
              />
            ))}
        </div>
      </div>

      <Pagination total={entriesTotal} skip={skip} setPage={setSkip} pageCount={PAGE_COUNT} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  languages: selectAllLanguages(state),
  secondaryLanguages: selectSecondaryLanguages(state),
  selectedLanguage: selectProjectLanguage(state),
  entries: getEntries(state),
  entriesTotal: getEntriesTotal(state),
  hasInitialState: getHasInitialState(state),
  conflictsCount: getConflictsCount(state),
});

const mapDispatchToProps = {
  getProjectLanguageData,
  fetchInitialState,
  createEntry,
  updateEntry,
  deleteEntry,
  fetchEntries,
  createFlow,
  clearState,
  conflictsCountAction: conflictsCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(QnA);
