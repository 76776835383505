import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import * as Sentry from '@sentry/react';

import {
  AGENTS,
  DEPARTMENTS,
  CONVERSATIONS,
  CHAT_HISTORY,
  GET_MEDIA_AND_FILES,
  UPDATE_CONVERSATION_SUBJECT,
  UPDATE_CONVERSATION_STATUS,
  ASSIGN_AGENT,
  ASSIGN_DEPARTMENT,
  CONVERSATION_NOTES,
  SET_CONVERSATION_READ,
  MACROS_GET,
  MACROS_CREATE,
  MACROS_DELETE,
  MACROS_EDIT,
  USERS_SUGGESTIONS,
  FILTERED_CONVERSATIONS,
  CREATE_NEW_CONVERSATION,
  ADD_AGENT_PARTICIPANT,
  REMOVE_AGENT_PARTICIPANT,
  ADD_DEPARTMENT_PARTICIPANT,
  REMOVE_DEPARTMENT_PARTICIPANT,
  SET_ADMIN,
  UNSET_ADMIN,
  CONVERSATION_META,
} from 'utils/constants';

export const getAgents = createAsyncThunk('crm/GET_AGENTS', async (projectId) => {
  const response = await axios.get(AGENTS, { params: { project_id: projectId } });
  return response.data;
});

// check if the second parameter passed to the action is as expected
export const getDepartments = createAsyncThunk('crm/GET_DEPARTMENTS', async (projectId) => {
  const response = await axios.get(DEPARTMENTS, { params: { project_id: projectId } });
  return response.data;
});

// params: folder, conversation_status, start_from_conversation_id
export const getConversations = createAsyncThunk('crm/GET_CONVERSATIONS', async (params) => {
  const response = await axios.get(CONVERSATIONS, {
    params,
  });
  return response.data;
});

export const refreshConversationChannel = createAsyncThunk(
  'crm/GET_CONVERSATION_META',
  async (params) => {
    const response = await axios.get(CONVERSATION_META, {
      params,
    });
    console.log('response.data', response.data);
    return response.data;
  }
);

export const clearConversations = createAction('crm/CLEAR_CONVERSATIONS');

export const getChatHistory = createAsyncThunk('crm/GET_CHAT_HISTORY', async (params) => {
  const { user_id, project_id, conversation_id } = params;

  let allowed = false;
  try {
    let response = await axios.get(CHAT_HISTORY, {
      params: { user_id, project_id, conversation_id, page: 0, page_size: 1000 },
    });
    allowed = true;

    return { ...response.data, allowed };
  } catch (error) {
    console.log('Chat History - ', error);
    return {};
  }
});

export const getAllLanguages = createAsyncThunk('crm/GET_ALL_LANGUAGES', async () => {
  const languageOptions = [];
  const response = await axios.get('/internal_api/project/languages');
  response.data.forEach((language) => {
    languageOptions.push({
      label: language.language_name,
      value: language.language_code,
    });
  });
  return languageOptions;
});

export const getProjectLanguageData = createAsyncThunk(
  'crm/GET_PROJECT_LANGUAGE_DATA',
  async (params) => {
    const response = await axios.get(`/internal_api/project/${params}/language`);
    if (!response.data.projectLanguage && !response.data.project_language) {
      Sentry.captureException('FAILURE - NO LANGUAGE - ' + JSON.stringify(response.data));
    }
    return response.data;
  }
);

export const updateProjectInfo = createAsyncThunk('project/UPDATE_PROJECT_INFO', async (params) => {
  await axios.post(`/internal_api/project/update`, params);
  return {
    projectLanguage: params.project_language,
    secondaryLanguages: params.secondary_languages,
  };
});

export const getCurrentUser = createAction('crm/GET_CURRENT_USER');

export const setMainMenu = createAction('crm/SET_LEFT_BAR_MENU');
export const setSubMenu = createAction('crm/SET_ADMINISTRATION_SUBMENU');
export const setInboxFolder = createAction('crm/SET_INBOX_FOLDER');
export const setConversationsStatusFilter = createAction('crm/SET_CONVERSATIONS_STATUS_FILTER');
export const setCurrentConversation = createAction('crm/SET_CURRENT_CONVERSATION');
export const resetCurrentConversation = createAction('crm/RESET_CURRENT_CONVERSATION');

export const editConversationSubject = createAsyncThunk(
  'crm/EDIT_CONVERSATION_SUBJECT',
  async (params) => {
    const { subject } = params;

    await axios.patch(UPDATE_CONVERSATION_SUBJECT, params);
    return subject;
  }
);

export const refreshConversationCardSubject = createAction('crm/REFRESH_CONVERSATION_CARD_SUBJECT');

export const getMediaAndFiles = createAsyncThunk('crm/GET_MEDIA_AND_FILES', async (params) => {
  const { project_id, conversation_id } = params;
  let response = await axios.get(GET_MEDIA_AND_FILES, {
    params: { project_id, conversation_id },
  });

  return response.data;
});

export const createNewInternalConversation = createAsyncThunk(
  'crm/CREATE_NEW_INTERNAL_CONVERSATION',
  async (params) => {
    const { projectId } = params;

    const response = await axios.post(CREATE_NEW_CONVERSATION, {
      project_id: projectId,
    });

    return response.data;
  }
);

export const setConversationStatus = createAsyncThunk(
  'crm/SET_CURRENT_CONVERSATION_STATUS',
  async (params) => {
    const { newConversationStatus, user_id, conversation_id } = params;
    await axios.post(UPDATE_CONVERSATION_STATUS, {
      user_id,
      conversation_status: newConversationStatus,
      conversation_id,
    });

    return newConversationStatus;
  }
);

export const setAssignAgent = async (params) => {
  try {
    await axios.post(ASSIGN_AGENT, params);
  } catch (error) {
    console.log('SET ASSIGN AGENT FAILED - ', error);
  }
};

export const setAssignDepartment = async (params) => {
  try {
    await axios.post(ASSIGN_DEPARTMENT, params);
  } catch (error) {
    console.log('SET ASSIGN DEPARTMENT FAILED - ', error);
  }
};

export const setReportsTab = createAction('crm/SET_REPORTS_TAB');

export const setBotTab = createAction('crm/SET_BOT_TAB');

export const setConversationNotes = createAsyncThunk(
  'crm/SET_CONVERSATION_NOTES',
  async (params) => {
    await axios.post(CONVERSATION_NOTES, params);

    return { ...params };
  }
);

export const updateLastMessage = createAction('crm/UPDATE_LAST_MESSAGE');
export const updateMessageTimestamp = createAction('crm/UPDATE_MESSAGE_TIMESTAMP');
export const setNewConversation = createAction('crm/SET_NEW_CONVERSATION');
export const removeConversation = createAction('crm/REMOVE_CONVERSATION');
export const setToConversationHistory = createAction('crm/SET_TO_CONVERSATION_HISTORY');

export const incrementYou = createAction('crm/INCREMENT_YOU_TOTALS');
export const decrementYou = createAction('crm/DECREMENT_YOU_TOTALS');
export const addToYouUnread = createAction('crm/ADD_TO_YOU_UNREAD');
export const removeFromYouUnread = createAction('crm/REMOVE_FROM_YOU_UNREAD');

export const incrementUnassigned = createAction('crm/INCREMENT_UNASSIGNED_FOLDER');
export const decrementUnassigned = createAction('crm/DECREMENT_UNASSIGNED_FOLDER');
export const addToUnassignedUnread = createAction('crm/ADD_TO_UNASSIGNED_UNREAD');
export const removeFromUnassignedUnread = createAction('crm/REMOVE_FROM_UNASSIGNED_UNREAD');

export const incrementAll = createAction('crm/INCREMENT_ALL_FOLDER');
export const decrementAll = createAction('crm/DECREMENT_ALL_FOLDER');
export const addToExternalAllUnread = createAction('crm/ADD_TO_EXTERNAL_ALL_UNREAD');
export const removeFromExternalAllUnread = createAction('crm/REMOVE_FROM_EXTERNAL_ALL_UNREAD');

export const incrementInternalAll = createAction('crm/INCREMENT_INTERNAL_ALL_FOLDER');
export const decrementInternalAll = createAction('crm/DECREMENT_INTERNAL_ALL_FOLDER');
export const addToInternalAllUnread = createAction('crm/ADD_TO_INTERNAL_ALL_UNREAD');
export const removeFromInternalAllUnread = createAction('crm/REMOVE_FROM_INTERNAL_ALL_UNREAD');

export const updateConversationInList = createAction('crm/UPDATE_CONVERSATION_IN_LIST');

export const setConversationReadStatus = createAsyncThunk(
  'crm/SET_CONVERSATION_READ_STATUS',
  async (params) => {
    await axios.post(SET_CONVERSATION_READ, params);

    return params;
  }
);

export const setSelectedMacro = createAction('crm/SET_SELECTED_MACRO');

export const setMacros = createAsyncThunk('crm/SET_MACROS', async (projectId) => {
  const response = await axios.get(MACROS_GET, { params: { project_id: projectId } });
  return response.data;
});

export const createMacro = createAsyncThunk('crm/CREATE_MACRO', async (params) => {
  const response = await axios.post(MACROS_CREATE, params);
  return response.data;
});

export const deleteMacro = createAsyncThunk('crm/DELETE_MACRO', async (macroId) => {
  const response = await axios.delete(MACROS_DELETE, {
    data: {
      macro_id: macroId,
    },
  });
  return response.data;
});

export const editMacro = createAsyncThunk('crm/EDIT_MACRO', async (params) => {
  const { project_id, macro_id, macro_title, macro_content, agent_id } = params;
  const response = await axios.patch(MACROS_EDIT, {
    project_id,
    macro_id,
    macro_title,
    macro_content,
    agent_id,
  });
  return response.data;
});

export const setSearchStatus = createAction('crm/SET_SEARCH_STATUS');
export const resetSearch = createAction('crm/RESET_SEARCH');
export const setSearchFilters = createAction('crm/SET_SEARCH_FILTERS');
export const setSearchResultsWarning = createAction('crm/SET_SEARCH_RESULTS_WARNING');
export const setStartFromConversationIndex = createAction('crm/SET_START_FROM_CONVERSATION_ID');

export const setFilters = createAction('crm/SET_FILTERS');

export const setFilteredData = createAsyncThunk('crm/SET_FILTERED_DATA', async (params) => {
  const response = await axios.post(FILTERED_CONVERSATIONS, params);

  return response.data;
});

export const setFilteredDataAdd = createAsyncThunk('crm/SET_FILTERED_DATA_ADD', async (params) => {
  const response = await axios.post(FILTERED_CONVERSATIONS, params);

  return response.data;
});

export const resetFilteredData = createAction('crm/RESET_FILTERS');

export const getUsersSuggestions = createAsyncThunk(
  'crm/GET_USERS_SUGGESTIONS',
  async ({ projectId, searchText }) => {
    const response = await axios.get(USERS_SUGGESTIONS, {
      params: {
        project_id: projectId,
        search_string: searchText,
      },
    });
    return response.data;
  }
);

export const addAgentParticipant = createAsyncThunk('crm/ADD_AGENT_PARTICIPANT', async (params) => {
  const response = await axios.post(ADD_AGENT_PARTICIPANT, params);
  return response.data;
});
export const removeAgentFromGroup = createAsyncThunk(
  'crm/REMOVE_AGENT_FROM_GROUP',
  async (params) => {
    const response = await axios.delete(REMOVE_AGENT_PARTICIPANT, { data: params });
    return response.data;
  }
);

export const addDepartmentParticipant = createAsyncThunk(
  'crm/ADD_DEPARTMENT_PARTICIPANT',
  async (params) => {
    const response = await axios.post(ADD_DEPARTMENT_PARTICIPANT, params);
    return response.data;
  }
);
export const removeDepartmentFromGroup = createAsyncThunk(
  'crm/REMOVE_DEPARTMENT_FROM_GROUP',
  async (params) => {
    const response = await axios.delete(REMOVE_DEPARTMENT_PARTICIPANT, { data: params });
    return response.data;
  }
);

export const setAdmin = createAsyncThunk('crm/SET_ADMIN', async (params) => {
  await axios.patch(SET_ADMIN, params);
  return { is_admin: 1 };
});
export const unsetAdmin = createAsyncThunk('crm/UNSET_ADMIN', async (params) => {
  await axios.patch(UNSET_ADMIN, params);
  return { is_admin: 0 };
});

export const setIsMobile = createAction('crm/SET_IS_MOBILE');
