import Axios from 'axios';
import * as Sentry from '@sentry/react';

const generateTokenUrl = () => {
  return '/internal_api/generate_token';
};

export const generateAuthToken = async () => {
  const tokenUrl = generateTokenUrl();

  try {
    const response = await Axios.get(tokenUrl);
    return response.data;
  } catch (error) {
    console.log(error);
    Sentry.captureException('FAIL GENERATE TOKEN CRM - ' + error);
  }
};
