import React from 'react';

const AttachFile = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.54167 17.125C3.83333 17.125 0 13.2917 0 8.58335C0 3.87502 3.83333 0.041687 8.54167 0.041687C13.25 0.041687 17.0833 3.87502 17.0833 8.58335C17.0833 13.2917 13.25 17.125 8.54167 17.125ZM8.54167 1.29169C4.51667 1.29169 1.25 4.56669 1.25 8.58335C1.25 12.6 4.51667 15.875 8.54167 15.875C12.5667 15.875 15.8333 12.6 15.8333 8.58335C15.8333 4.56669 12.5667 1.29169 8.54167 1.29169Z"
      fill="#A3AED0"
    />
    <path
      d="M17.2917 17.9584C17.1333 17.9584 16.975 17.9 16.85 17.775L15.1833 16.1084C14.9417 15.8667 14.9417 15.4667 15.1833 15.225C15.425 14.9834 15.825 14.9834 16.0667 15.225L17.7333 16.8917C17.975 17.1334 17.975 17.5334 17.7333 17.775C17.6083 17.9 17.45 17.9584 17.2917 17.9584Z"
      fill="#A3AED0"
    />
  </svg>
);

export default AttachFile;
