import React, { useState, useRef } from 'react';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardContent';
import DOMPurify from 'dompurify';
import './HTMLCard.scss';
const HTMLCard = ({ html, url, width, height, isFromUser }) => {
  const [iframeWidth, setIframeWidth] = useState(Number(width));
  const [iframeHeight, setIframeHeight] = useState(Number(height));
  const iframeRef = useRef(null);
  let securityProps = {};
  if (isFromUser) {
    securityProps['sandbox'] = 'allow-popups allow-popups-to-escape-sandbox allow-same-origin';
    securityProps['csp'] = "script-src 'none'";
  }

  const handleIframeLoad = () => {
    const iframe = iframeRef.current;

    if (iframe) {
      const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
      const newWidth = iframeDocument.body.scrollWidth;
      const newHeight = iframeDocument.body.scrollHeight + 50;
      setIframeWidth(Math.min(newWidth, 568));
      setIframeHeight(newHeight);
    }
  };
  const add_security_tags = (doc) => {
    // disables javascript
    const meta = doc.createElement('meta');
    meta.setAttribute('http-equiv', 'Content-Security-Policy');
    meta.setAttribute('content', "script-src 'none'");
    doc.head.appendChild(meta);

    const styleSheet = doc.createElement('style');
    // styleSheet.innerText =
    //   'body { width: 800px; overflow: scroll; overflow-wrap: break-word; padding: 0px; } pre { white-space: pre-wrap; }';
    doc.head.appendChild(styleSheet);

    // makes all links open a new tab / window
    const base = doc.createElement('base');
    base.setAttribute('target', '_blank');
    doc.head.appendChild(base);
  };

  const sanitize_html = (html) => {
    // DOMPurify protects against XSS attacks
    let clean_html = DOMPurify.sanitize(html);
    const parser = new DOMParser();
    const doc = parser.parseFromString(clean_html, 'text/html');

    add_security_tags(doc);

    const serializer = new XMLSerializer();
    return serializer.serializeToString(doc);
  };
  const cleanHtml = isFromUser ? sanitize_html(html) : html;

  return (
    <Card className="HTMLCard">
      <CardActionArea className="HTMLCardAction">
        <iframe
          title="html-iframe"
          className="HTMLCardIframe"
          ref={iframeRef}
          onLoad={isFromUser ? handleIframeLoad : null}
          srcDoc={cleanHtml || url}
          sandbox="allow-scripts allow-presentation"
          style={{ width: iframeWidth, height: iframeHeight }}
          {...securityProps}
        />
      </CardActionArea>
    </Card>
  );
};

export default HTMLCard;
