import React from 'react';
import { connect } from 'react-redux';

import MenuTabsComponent from 'components/MenuTabsComponent';

import { selectBotTab } from 'store/crm/selectors';

import './SubmenuProjectContainer.scss';
import { setBotTab } from '../../store/crm/actions';

const SubmenuProjectContainer = ({ botTab, setBotTab, collapsed, ...props }) => {
  const properties = {
    headerTitle: props.headerTitle,
    sections: props.sections,
  };

  return (
    <div className="SubmenuProjectContainer">
      <MenuTabsComponent
        structure={properties}
        tab={botTab}
        setTab={setBotTab}
        currentMenu={'bot'}
        collapsed={collapsed}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  botTab: selectBotTab(state),
});

const mapDispatchToProps = {
  setBotTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmenuProjectContainer);
