import React, { Component } from 'react';
import Axios from 'axios';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import maxBy from 'lodash/maxBy';
import clone from 'lodash/cloneDeep';
import size from 'lodash/size';
import entries from 'lodash/entries';
import map from 'lodash/map';
import each from 'lodash/each';
import { connect } from 'react-redux';

import { getKeyForObjectClass } from 'pages/Flow/components/Canvas/utils/nodeCharacteristics';
import NodeSettingProvider from 'pages/Flow/components/Canvas/components/NodeSettingsProvider';
import { RetrainStatusProvider } from 'pages/Flow/components/Retrain';
import withNotifications from 'components/withNotifications';
import { NodeUpdater, FlowMetaContext } from 'pages/Flow/utils/canvas-contexts';
import { NodeNamesProvider } from 'pages/Flow/components/Canvas/utils/useNodeName';
import { endOfDialogNode, returnToParentFlowNode } from 'utils/constants';
import { getProjectLanguageData } from '../../store/crm/actions';

class CanvasContainer extends Component {
  state = {
    loading: true,
    appData: null,
  };

  async componentDidMount() {
    await this.props.getProjectLanguageData(this.props.projectId);
    await this.fetchAppData();
  }

  fetchAppData = async () => {
    const { projectId, appId } = this.props;

    await Axios.get(`/internal_api/project/${projectId}/flow/${appId}/data`).then((response) => {
      this.setState({
        appData: response.data,
        nodes: response.data.nodes,
      });
    });
  };

  updateObject = (objectClass, object) => {
    const stateKey = getKeyForObjectClass(objectClass);
    const currentObjects = this.state.appData[stateKey];
    if (currentObjects == null) {
      return;
    }
    const newObjects = currentObjects.map((row) => {
      if (row[`${objectClass}_id`] === object[`${objectClass}_id`]) {
        return object;
      }
      return row;
    });

    this.setState({
      appData: { ...this.state.appData, [stateKey]: newObjects },
    });
  };

  addObjectToState = (objectClass, object) => {
    const stateKey = getKeyForObjectClass(objectClass);
    const currentObjects = this.state.appData[stateKey];
    if (currentObjects == null) {
      return;
    }

    this.setState({
      appData: {
        ...this.state.appData,
        [stateKey]: [...currentObjects, object],
      },
    });
  };

  render() {
    const { projectId, appId, children } = this.props;
    const { appData } = this.state;

    if (appData == null) {
      return <div></div>;
    }
    return (
      <FlowMetaContext.Provider
        value={{
          projectId,
          appId,
          ...appData,
          updateObject: this.updateObject,
          addObjectToState: this.addObjectToState,
        }}
      >
        <RetrainStatusProvider>
          <NodesProviderDecorated appId={appId} initialNodes={this.state.nodes}>
            <NodeSettingProvider>{children}</NodeSettingProvider>
          </NodesProviderDecorated>
        </RetrainStatusProvider>
      </FlowMetaContext.Provider>
    );
  }
}

class NodeProvider extends Component {
  state = { nodes: this.props.initialNodes };

  componentDidUpdate(prevProps) {
    const { initialNodes } = this.props;

    if (!isEqual(initialNodes, prevProps.initialNodes)) {
      this.setState({ nodes: initialNodes });
    }
  }

  manageNodeIndexes = (nodes) => {
    const indexMap = {};
    each(nodes, (value, nodeId) => {
      indexMap[value.object_id] = indexMap[value.object_id]
        ? [...indexMap[value.object_id], nodeId]
        : [nodeId];
    });

    each(indexMap, (nodeIds, objectId) => {
      if (size(nodeIds) > 1) {
        let initialIndex = 1;
        each(nodeIds, (nodeId) => {
          nodes[nodeId].objectIndex = initialIndex;
          initialIndex++;
        });
      } else {
        const nodeId = nodeIds[0];
        if (nodes[nodeId]?.objectIndex) {
          delete nodes[nodeId]?.objectIndex;
        }
      }
    });

    return nodes;
  };

  checkForChildNode(nodes) {
    const isChildNodesArray = map(nodes, (node, key) => node.object_class === 'flow_connector');
    const isChildNode = !!isChildNodesArray.find((item) => item === true);
    return isChildNode;
  }

  adaptNodes = (nodes) => {
    const adaptedNodes = this.manageNodeIndexes(nodes);
    const isChildNode = this.checkForChildNode(nodes);

    if (isChildNode)
      return {
        ...adaptedNodes,
        [-2]: returnToParentFlowNode,
        0: endOfDialogNode,
      };
    return { ...adaptedNodes, 0: endOfDialogNode };
  };

  updateOrCreateNode = (updatedNodes) => {
    const newNodes = { ...this.state.nodes, ...updatedNodes };
    this.setState({ nodes: newNodes });
  };

  updateDefaultConnection = (sourceNode, removedConnectionData) => {
    if (removedConnectionData?.order_position !== 0) {
      return;
    }
    let lastConnection = maxBy(Object.values(sourceNode.next), 'order_position');
    lastConnection.order_position = 0;

    Axios.post('/internal_api/update_node_connection', {
      connection_id: lastConnection.connection_id,
      order_position: 0,
    });
  };

  deleteNode = (nodeId) => {
    if (nodeId === 0) return;
    const { nodes } = this.state;

    let node = nodes[nodeId];

    const newNodes = { ...nodes };

    // delete node from parent connections
    node.prev.forEach((prevId) => {
      let prevNode = nodes[prevId];
      let connectionData = get(prevNode, `next.${nodeId}`);
      this.updateDefaultConnection(prevNode, connectionData);
      delete prevNode.next[nodeId];
      newNodes[prevId] = clone(prevNode);
    });

    // delete node from children connections
    entries(node.next).forEach(([nextNodeId, connection]) => {
      const newPreviousNodes = get(newNodes, `${nextNodeId}.prev`, []).filter(
        // eslint-disable-next-line eqeqeq
        (id) => id != nodeId
      );
      newNodes[nextNodeId] = {
        ...newNodes[nextNodeId],
        prev: newPreviousNodes,
      };
    });
    delete newNodes[nodeId];
    this.setState({ nodes: newNodes });
    Axios.delete('/internal_api/delete_node', {
      data: {
        node_id: nodeId,
      },
    });
  };

  persistNodes = () => {
    Axios.post('/internal_api/update_nodes', {
      app_id: this.props.appId,
      nodes: this.state.nodes,
    });
  };

  render() {
    const { nodes } = this.state;

    return (
      <NodeUpdater.Provider
        value={{
          nodes: this.adaptNodes(nodes),
          updateOrCreateNode: this.updateOrCreateNode,
          persistNodes: this.persistNodes,
          deleteNode: this.deleteNode,
          updateDefaultConnection: this.updateDefaultConnection,
        }}
      >
        <NodeNamesProvider>{this.props.children}</NodeNamesProvider>
      </NodeUpdater.Provider>
    );
  }
}

const NodesProviderDecorated = withNotifications(NodeProvider);
const mapDispatchToProps = {
  getProjectLanguageData,
};
export default connect(null, mapDispatchToProps)(CanvasContainer);
