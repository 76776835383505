import React from 'react';
import { useLocation } from 'react-router-dom';
import { Logo } from 'assets/logo/Logo';
import LoginPanel from 'assets/svg/LoginPanel';

import './Auth.scss';
import ConfirmInvitationForm from './ConfirmInvitationForm';
import AllRights from '../../components/AllRights/AllRights';

const ConfirmInvitation = () => {
  const search = useLocation().search;
  const urlParams = new URLSearchParams(search);

  const confirmation_code = urlParams.get('confirmation_code');
  return (
    <div className="Auth">
      <div className="Container">
        <Logo className="Logo" />
        <span className="Title dm-sans-700">Confirm invitation</span>
        <ConfirmInvitationForm confirmation_code={confirmation_code} />
        <AllRights />
      </div>

      <LoginPanel className="ShowOnlyOnDesktop" />
    </div>
  );
};

export default ConfirmInvitation;
