import React, { Component } from 'react';
import { ThemeContext } from '../../../pages/Flow/utils/Theme';
import { Popper } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import './RemovableInputElement.scss';
const removeButtonStyle = {
  marginRight: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '18px',
  fontWeight: 'bold',
  cursor: 'pointer',
};

const textStyle = {
  marginLeft: '5px',
  display: 'flex',
  width: '70%',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'transparent',
  border: 'none',
  textAlign: 'left',
  resize: 'none',
  outline: 'none',
  fontSize: '14px',
  fontWeight: '500',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  color: '#ffffff',
};

const listItemStyle = {
  color: 'inherit',
  border: '0',
  cursor: 'pointer',
  margin: '0',
  display: 'inline-flex',
  outline: '0',
  padding: '6 8',
  paddingLeft: 20,
  position: 'relative',
  alignItems: 'center',
  userSelect: 'none',
  borderRadius: '0',
  verticalAlign: 'middle',
  justifyContent: 'left',
  textDecoration: 'none',
  width: 'auto',
  overflow: 'hidden',
  fontSize: 14,
  boxSizing: 'border-box',
  minHeight: '48px',
  fontWeight: '400',
  lineHeight: '1.5',
  letterSpacing: '0.00938em',
};

const muiPaper = {
  display: 'flex',
  flexDirection: 'column',
  boxShadow:
    '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  borderRadius: 4,
  background: '#fff',
};

export default class RemovableInputElement extends Component {
  static contextType = ThemeContext;

  state = {
    anchorEl: null,
    value: this.props.value,
  };
  inputRef = React.createRef();
  hasChangedValue = false;

  onValueChanged = (event) => {
    if (this.hasChangedValue) {
      this.props.onValueChanged(event.target.value);
    }
  };

  render() {
    const theme = this.context;
    const { options, onRemoved } = this.props;
    const { anchorEl, value } = this.state;

    return (
      <div style={{ ...this.props.style, backgroundColor: theme.main }}>
        <DragIndicatorIcon />
        <input
          ref={this.inputRef}
          style={textStyle}
          value={value}
          onBlur={this.onValueChanged}
          onChange={(event) => {
            this.hasChangedValue = true;
            this.setState({ value: event.target.value });
          }}
          onFocus={() => {
            this.hasChangedValue = false;
            if (!value && options) {
              this.setState({ anchorEl: this.inputRef.current });
            }
          }}
          onClick={() => {
            if (!value && options) {
              this.setState({ anchorEl: this.inputRef.current });
            }
          }}
        />
        {options && (
          <Popper
            style={{ zIndex: 999999 }}
            open={Boolean(options.length && anchorEl)}
            anchorEl={anchorEl}
            placement="top-start"
          >
            <div style={{ ...muiPaper, width: 300 }}>
              {options.map((option, i) => (
                <div
                  key={i}
                  className="listItem"
                  style={listItemStyle}
                  onPointerDown={(event) => {
                    event.preventDefault();
                    this.hasChangedValue = true;
                    this.setState({ anchorEl: null, value: options[i] });
                  }}
                >
                  <p style={{ margin: 0 }}>{option}</p>
                </div>
              ))}
            </div>
          </Popper>
        )}
        {onRemoved && (
          <div style={removeButtonStyle} onClick={onRemoved}>
            x
          </div>
        )}
      </div>
    );
  }
}
