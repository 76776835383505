import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import get from 'lodash/get';
import { RetrainButton } from 'components/common/Button/Buttons';
import useNotifications from 'utils/hooks/useNotifications';

import Axios from 'axios';
import { useParams } from 'react-router-dom';
import languageMap from 'Language/Language';

const RetrainContext = createContext({});

const Retrain = (props) => {
  const { retraining, handleRetrain, loading } = useContext(RetrainContext);

  const { id, ...otherProps } = props;
  const onClick = useCallback(() => {
    handleRetrain(id);
  }, [handleRetrain, id]);

  return (
    <RetrainButton {...otherProps} retraining={retraining} loading={loading} onClick={onClick} />
  );
};

export const RetrainStatusProvider = ({ children }) => {
  const [retraining, setRetraining] = useState(false);
  const [loading, setLoading] = useState(true);
  const { id: projectId } = useParams();
  const { showError } = useNotifications();

  const checkRetrainStatus = useCallback(() => {
    return Axios.get(`/internal_api/retrain/status/${projectId}`).then((response) => {
      const isRetraining = `${response.data}` !== '1';
      retraining !== isRetraining && setRetraining(isRetraining);

      return response;
    });
  }, [projectId, retraining]);

  const pollRetrainStatus = useCallback(() => {
    return new Promise((resolve) => {
      checkRetrainStatus().then(({ data }) => {
        if (`${data}` === '1') {
          return resolve(true);
        }
        setTimeout(() => {
          resolve(pollRetrainStatus());
        }, 1000);
      });
    });
  }, [checkRetrainStatus]);

  useEffect(() => {
    setLoading(true);
    pollRetrainStatus().then(() => {
      setLoading(false);
    });
  }, [pollRetrainStatus]);

  const handleRetrain = useCallback(
    (id) => {
      setRetraining(true);
      try {
        Axios.post('/internal_api/retrain/project', { projectId, id }).then((response) => {
          const hasRetrainedObject = get(response, 'data.status') === 'ok';
          if (!hasRetrainedObject) {
            return showError(
              `${languageMap.failedToStartRetrainBecause} ${get(response, 'data.message')}`
            );
          }
          pollRetrainStatus().then(() => {
            setRetraining(false);
          });
        });
      } catch (error) {
        showError(`${languageMap.failedToStartRetrainBecause} ${error.message}`);
        setRetraining(false);
      }
    },
    [pollRetrainStatus, projectId, showError]
  );

  return (
    <RetrainContext.Provider value={{ handleRetrain, retraining, loading }}>
      {children}
    </RetrainContext.Provider>
  );
};

export default Retrain;
