import { createReducer } from '@reduxjs/toolkit';

import {
  fetchInitialState,
  createLink,
  deleteLink,
  updateLinkText,
  createEntry,
  deleteEntry,
  ignoreConflict,
  fetchEntries,
  createFlow,
  importQna,
  clearState,
  updateMessage,
  updateEntry,
  conflictsCount,
} from './actions';

const initialState = {
  hasInitialState: true,
  entries: [],
  entriesTotal: 0,
  entities: [],
  conflictsCount: 0,
};

function findEntryById(state, nodeId) {
  for (let i = 0; i < state.entries.length; i++) {
    let entry = state.entries[i];
    if (entry.message_node_id === nodeId) {
      return [i, entry];
    }
  }

  return [-1, null];
}

export default createReducer(initialState, (builder) => {
  builder
    .addCase(fetchInitialState.fulfilled, (state, action) => {
      return {
        ...action.payload,
        entries: action.payload?.entries,
        entriesTotal: action.payload.entriesCount,
        hasInitialState: false,
        conflictsCount: state.conflictsCount,
      };
    })
    .addCase(createLink.fulfilled, (state, action) => {
      const payload = action.payload;

      function insert_new_link_in_entry(type, payload) {
        if (type === 'outbound') {
          const foundEntry = findEntryById(state, payload.source_node_id)[1];
          if (foundEntry?.outbound_links) {
            foundEntry.outbound_links.push({
              destination_node_id: payload.destination_node_id,
              link_text_by_language: payload.link_text_by_language,
            });
          }
        } else if (type === 'inbound') {
          const foundEntry = findEntryById(state, payload.destination_node_id)[1];
          if (foundEntry?.inbound_links) {
            foundEntry.inbound_links.push({
              source_node_id: payload.source_node_id,
              link_text_by_language: payload.link_text_by_language,
            });
          }
        }
      }

      if (payload.link_type === 'new_entry') {
        let [srcIndex] = findEntryById(state, payload.source_node_id);

        const link = payload.entry.inbound_links[0];
        insert_new_link_in_entry('outbound', {
          source_node_id: link?.source_node_id,
          destination_node_id: payload.entry.message_node_id,
          link_text_by_language: link?.link_text_by_language,
        });

        state.entries.splice(srcIndex + 1, 0, payload.entry);
      } else if (payload.link_type === 'internal') {
        insert_new_link_in_entry('outbound', payload);
        insert_new_link_in_entry('inbound', payload);
      } else if (payload.link_type === 'external') {
        insert_new_link_in_entry('outbound', payload);
      }
    })
    .addCase(deleteLink.fulfilled, (state, action) => {
      const { source_node_id, destination_node_id } = action.meta.arg;

      // eslint-disable-next-line no-unused-vars
      let [_i, srcEntry] = findEntryById(state, source_node_id);
      // eslint-disable-next-line no-unused-vars
      let [_j, dstEntry] = findEntryById(state, destination_node_id);

      if (srcEntry) {
        srcEntry.outbound_links = srcEntry.outbound_links.filter(
          (link) => link.destination_node_id !== destination_node_id
        );
      }

      if (dstEntry) {
        dstEntry.inbound_links = dstEntry.inbound_links.filter(
          (link) => link.source_node_id !== source_node_id
        );
      }
    })
    .addCase(updateLinkText.fulfilled, (state, action) => {
      const { source_node_id, destination_node_id, link_text_by_language } = action.payload;

      // eslint-disable-next-line no-unused-vars
      let [_i, srcEntry] = findEntryById(state, source_node_id);
      // eslint-disable-next-line no-unused-vars
      let [_j, dstEntry] = findEntryById(state, destination_node_id);

      if (srcEntry) {
        srcEntry.outbound_links.forEach((element) => {
          if (element.destination_node_id === destination_node_id) {
            element.link_text_by_language = link_text_by_language;
          }
        });
      }

      if (dstEntry) {
        dstEntry.inbound_links.forEach((element) => {
          if (element.source_node_id === source_node_id) {
            element.link_text_by_language = link_text_by_language;
          }
        });
      }
    })
    .addCase(createEntry.fulfilled, (state, action) => {
      state.entries.splice(0, 0, action.payload);
    })
    .addCase(ignoreConflict.fulfilled, (state, action) => {
      state.entries.splice(0, 0, action.payload);
    })
    .addCase(updateEntry, (state, action) => {
      const entry = state.entries.find((entry) => entry.intent_id === action.payload.intent_id);
      if (entry) {
        entry.utterances_by_language = action.payload.utterances_by_language;
        entry.automaticTranslation = action.payload.automaticTranslation;
      }
    })
    .addCase(conflictsCount.fulfilled, (state, action) => {
      state.conflictsCount = action.payload.conflictsCount;
    })
    .addCase(updateMessage, (state, action) => {
      const entryUpdated = state.entries.find(
        (entry) => entry.message_id === action.payload?.message_id
      );
      if (entryUpdated) {
        entryUpdated.message_properties = action.payload.message_properties;
      }
    })
    .addCase(deleteEntry.fulfilled, (state, action) => {
      const messageId = action.payload;
      let entry_index = 0;
      let entry;

      for (; entry_index < state.entries.length; entry_index++) {
        if (state.entries[entry_index].message_id === Number(messageId)) {
          entry = state.entries[entry_index];
          break;
        }
      }

      for (let linkIdx in entry?.inbound_links) {
        let link = entry.inbound_links[linkIdx];
        let [outboundEntry] = findEntryById(state, link.source_node_id);

        if (outboundEntry?.outbound_links) {
          outboundEntry.outbound_links = outboundEntry.outbound_links.filter(
            (outboundLink) => outboundLink.destination_node_id !== entry.message_node_id
          );
        }
      }

      for (let linkIdx in entry?.outbound_links) {
        let link = entry.outbound_links[linkIdx];
        // eslint-disable-next-line no-unused-vars
        let [idx, inboundEntry] = findEntryById(state, link.destination_node_id);

        if (inboundEntry?.inbound_links) {
          inboundEntry.inbound_links = inboundEntry.inbound_links.filter(
            (inboundLink) => inboundLink.source_node_id !== entry.message_node_id
          );
        }
      }

      state.entries.splice(entry_index, 1);
    })
    .addCase(fetchEntries.fulfilled, (state, action) => {
      state.entries = action.payload?.entries || [];
      state.entriesTotal = action.payload?.count || 0;
    })
    .addCase(createFlow.fulfilled, (state, action) => {
      state.flow_id = action.payload.flow_id;
      state.entries = [action.payload.entry];
      state.entities = action.payload.entities;
    })
    .addCase(importQna.fulfilled, (state, action) => {
      if (action.payload?.flow_id) {
        state.flow_id = action.payload.flow_id;
      }
      state.entries = action.payload?.entries.slice(0, 6);
      state.entriesTotal = action.payload?.count;
    })
    .addCase(clearState, () => {
      return initialState;
    })
    .addDefaultCase(() => {});
});
