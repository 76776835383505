import React, { Component } from 'react';
import { Chart, registerables } from 'chart.js';
import ArrowDropUpTwoToneIcon from '@mui/icons-material/ArrowDropUpTwoTone';
import HelperTooltip from 'components/HelperTooltip/HelperTooltip';
import { replaceSeconds } from '../../../utils/utils';

import './Graph.scss';
import languageMap from 'Language/Language';

Chart.register(...registerables);
export default class Graph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myChart: 'undefined',
    };
    this.chartRef = React.createRef();
  }

  componentDidMount() {
    this.buildChart();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data || prevProps.labels !== this.props.labels) {
      this.buildChart();
    }
  }

  createChart = () => {
    const unit = this.props.unit ? this.props.unit : '';
    // Function to find the maximum value in the "data" array
    var dataObject = this.props.data;
    const findMaxValue = (dataObject) => {
      let max = -Infinity;
      for (const item of dataObject) {
        const maxValueInItem = Math.max(...item.data);
        max = Math.max(max, maxValueInItem);
      }
      return max;
    };

    let maxDataValue = findMaxValue(dataObject);

    // Function to find the minimum value in the "data" array
    const findMinValue = (dataObject) => {
      let min = Infinity;
      for (const item of dataObject) {
        const minValueInItem = Math.min(...item.data);
        min = Math.min(min, minValueInItem);
      }
      return min;
    };

    let minDataValue = findMinValue(dataObject);

    if (unit === 'd') {
      minDataValue /= 86400;
      maxDataValue /= 86400;
    } else if (unit === 'h') {
      minDataValue /= 3600;
      maxDataValue /= 3600;
    } else if (unit === 'min') {
      minDataValue /= 60;
      maxDataValue /= 60;
    }

    const yScaleMax = this.props.yScaleMax ? this.props.yScaleMax : maxDataValue;
    const yScaleMin = this.props.yScaleMin ? this.props.yScaleMin : minDataValue;

    const offsetTrue = this.props.labels?.length <= 1;
    const { changeDisplayedTooltipValue = false } = this.props;

    let data = {
      labels: this.props.labels,
      datasets: this.props.data,
    };
    let type = this.props.type;

    switch (this.props.type) {
      case 'bar':
        return {
          type: type,
          data: data,
          options: {
            animations: false,
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (context) {
                    let label = context.dataset.label || '';

                    if (label) {
                      label += ': ';
                    }
                    if (context.parsed.y !== null) {
                      label += context.parsed.y + unit;
                    }
                    return label;
                  },
                },
              },
              legend: { display: false },
              animation: {
                duration: 0,
              },
            },
            maintainAspectRatio: false,
            responsive: true,
            scales: {
              x: { grid: { color: 'white' }, ticks: { color: 'black' } },
              y: {
                ticks: {
                  callback: function (value) {
                    return value + unit;
                  },
                  count: 5,
                  color: 'black',
                },
              },
            },
          },
        };
      case 'line':
        return {
          type: type,
          data: data,
          options: {
            animations: false,
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (context) {
                    let label = context.dataset.label || '';

                    if (label) {
                      label += ': ';
                    }
                    if (changeDisplayedTooltipValue === true) {
                      if (context.parsed.y !== null) {
                        label += replaceSeconds(context.parsed.y);
                      }
                      return label;
                    } else {
                      if (context.parsed.y !== null) {
                        label += context.parsed.y + unit;
                      }
                      return label;
                    }
                  },
                },
              },
              legend: {
                display: true,
                position: 'bottom',
                align: 'end',
                labels: {
                  useLineStyle: true,
                  boxHeight: 0,
                },
              },
            },
            maintainAspectRatio: false,
            responsive: true,
            scales: {
              x: { offset: offsetTrue, grid: { color: 'white' }, ticks: { color: 'black' } },
              y: {
                offset: offsetTrue,
                suggestedMin: yScaleMin,
                suggestedMax: yScaleMax,
                ticks: {
                  callback: function (value) {
                    if (unit === 'd') {
                      value = (value / 86400).toFixed(1);
                    } else if (unit === 'h') {
                      value = (value / 3600).toFixed(1);
                    } else if (unit === 'min') {
                      value = (value / 60).toFixed(1);
                    }
                    return value + unit;
                  },
                  count: 5,
                  color: 'black',
                },
              },
            },
          },
        };
      case 'pie':
        return {
          type: type,
          data: {
            ...data,
            backgroundColor: this.props.backgroundColor,
          },
          options: {
            animations: false,
            plugins: {
              tooltip: {
                callbacks: {
                  label: (tooltipItem) => {
                    return `${tooltipItem?.label}: ${tooltipItem?.formattedValue}${unit}`;
                  },
                },
              },
              legend: {
                display: false,
              },
            },
            maintainAspectRatio: true,
            responsive: true,
            rotation: 180,
          },
        };
      default:
        return null;
    }
  };

  checkIfDataExists = () => {
    for (let i = 0; i < this.props.data?.length; i++) {
      if (this.props.data[i].data?.length && this.props.data[i].data.length !== 0) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  buildChart = () => {
    if (this.checkIfDataExists()) {
      const myChartRef = this.chartRef.current.getContext('2d');

      if (this.state.myChart !== 'undefined') {
        this.state.myChart.destroy();
      }

      this.setState({ myChart: new Chart(myChartRef, this.createChart()) });
    }
  };

  displayChangeValue = (change = this.props.comparisonValue) => {
    if (change > 0) {
      return (
        <div className="value-change small-text value-green">
          <ArrowDropUpTwoToneIcon />
          {change}
        </div>
      );
    } else if (change < 0) {
      change *= -1;
      return (
        <div className="value-change small-text value-red">
          <ArrowDropUpTwoToneIcon className="arrow-icon-down" />
          {change}
        </div>
      );
    }
  };

  displayLegend = () => {
    let labels = this.props.labels;
    return (
      <div className="under-chart-label">
        {labels.map((label, index) => (
          <div key={index} className="content regular-text">
            <div className="content-header regular-text">
              <div
                className="dot-mark"
                style={{ backgroundColor: this.props.data[0]?.backgroundColor[index] }}
              />
              {this.props.labels[index]}
            </div>

            <div className="content-value .dm-sans-700">
              {this.props.data[0].data[index]}
              {this.props.unit}
              {this.props.comparisonValueArray[index] && (
                <>{this.displayChangeValue(this.props.comparisonValueArray[index])} </>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  render() {
    return (
      <div className="GenericMultiDataLine">
        <div
          className="isResizable"
          style={this.props.type === 'pie' ? { minWidth: '100px', height: '100%' } : {}}
        >
          <div className="chart-container">
            <div
              className="title-container"
              style={this.props.type === 'pie' ? { marginBottom: '50px' } : {}}
            >
              <label className="chart-title-label title-text">{this.props.title}</label>
              {this.props.info && (
                <HelperTooltip title={this.props.info} placement="top" arrow></HelperTooltip>
              )}
            </div>

            {!this.checkIfDataExists() && (
              <div className="no-data title-text">{languageMap.noDataAvailable}</div>
            )}
            {this.checkIfDataExists() && (
              <>
                {!this.props.comparisonValueArray && (
                  <div className="underneath-chart-title-label">
                    <div className="content-value title-text">{this.props.value} </div>
                    {this.displayChangeValue()}
                    <div className="under-title-text small-text">{this.props.comparisonText}</div>
                  </div>
                )}
                <canvas
                  id="bar-chart"
                  ref={this.chartRef}
                  style={this.props.type === 'pie' ? { maxHeight: '250px' } : {}}
                />
                {this.props.comparisonValueArray && this.displayLegend()}
                {this.props.comparisonValueArray && (
                  <div className="under-chart-text small-text">{`* ${this.props.comparisonText}`}</div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
