import React from 'react';

import { connect } from 'react-redux';
import DetailsCloseButton from 'assets/svg/DetailsCloseButton.js';

import { selectIsMobile } from 'store/crm/selectors';

import './ConversationSummary.scss';

const ConversationSummary = ({
  subject,
  title,
  handleConversationDetailsButtonClick,
  isMobile,
}) => {
  const IdFeatureFlag = false;

  const handleCloseButtonClick = () => {
    handleConversationDetailsButtonClick();
  };

  return (
    <div className="ConversationSummary">
      <div className="Title title-text">
        {title}
        {!isMobile && (
          <DetailsCloseButton style={{ cursor: 'pointer' }} onClick={handleCloseButtonClick} />
        )}
      </div>
      {IdFeatureFlag && (
        <div className="Id">
          <div className="label">ID</div>
          <div className="content">18985100000</div>
        </div>
      )}
      {subject && (
        <div className="Subject">
          <div className="label">Subject</div>
          <div className={`content ${subject ? '' : 'empty'}`}>{subject}</div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMobile: selectIsMobile(state),
});

export default connect(mapStateToProps, null)(ConversationSummary);
