import { useMemo, useContext } from 'react';
import { FlowMetaContext } from 'pages/Flow/utils/canvas-contexts';
import { nodeObjectClass } from './nodeCharacteristics';
import find from 'lodash/find';
import get from 'lodash/get';

export default (node) => {
  const {
    messages,
    intents,
    entities,
    services,
    events,
    scripts,
    flow_connectors,
    parent_connectors,
  } = useContext(FlowMetaContext);
  const object_class = get(node, 'object_class');
  const object_id = get(node, 'object_id');

  const objectFromNode = useMemo(() => {
    switch (object_class) {
      case nodeObjectClass.MESSAGE:
        return find(messages, ({ message_id }) => message_id === object_id);
      case nodeObjectClass.INTENT:
        return find(intents, ({ intent_id }) => intent_id === object_id);
      case nodeObjectClass.ENTITY:
        return find(entities, ({ entity_id }) => entity_id === object_id);
      case nodeObjectClass.SERVICE:
        return find(services, ({ service_id }) => service_id === object_id);
      case nodeObjectClass.EVENT:
        return find(events, ({ event_id }) => event_id === object_id);
      case nodeObjectClass.SCRIPT:
        return find(scripts, ({ script_id }) => script_id === object_id);
      case nodeObjectClass.FLOW_CONNECTOR:
        return find(flow_connectors, ({ flow_connector_id }) => flow_connector_id === object_id);
      case nodeObjectClass.PARENT_CONNECTOR:
        return find(
          parent_connectors,
          ({ parent_connector_id }) => parent_connector_id === object_id
        );
      default:
        return null;
    }
  }, [
    entities,
    events,
    flow_connectors,
    intents,
    messages,
    object_class,
    object_id,
    scripts,
    services,
    parent_connectors,
  ]);

  return objectFromNode;
};
