import React from 'react';
import { MAIN_COLOR } from '../../../mainTheme';

export const objectThemes = {
  event: {
    main: 'rgb(45, 75, 143)',
    headerInput: 'rgb(40, 65, 119)',
    selected: 'rgba(45, 75, 143, 0.24)',
    border: 'white',
  },
  intent: {
    main: 'rgb(64, 140, 214)',
    headerInput: 'rgb(54, 124, 193)',
    border: 'white',
  },
  message: {
    main: 'rgb(64, 193, 214)',
    headerInput: 'rgb(54, 174, 193)',
    border: 'white',
  },
  entity: {
    main: 'rgb(118, 121, 196)',
    headerInput: 'rgb(99, 102, 170)',
    border: 'white',
  },
  service: {
    main: 'rgb(152, 123, 116)',
    headerInput: 'rgb(130, 104, 98)',
    selected: 'rgba(152, 123, 116, 0.24)',
    border: 'white',
  },
  script: {
    main: 'rgb(109, 109, 109)',
    headerInput: 'rgb(91, 91, 91)',
    border: 'white',
  },
  entity_type: {
    main: '#7679c4',
    headerInput: '#6467ab',
    border: 'white',
  },
  start: {
    main: 'rgb(109, 109, 109)',
    headerInput: 'rgb(91, 91, 91)',
    border: 'white',
  },
  flow_connector: {
    main: 'hsl(45, 88%, 55%, 1)',
    headerInput: 'rgb(207, 130, 22)',
    selected: 'rgba(237, 160, 52, 0.24)',
    border: 'white',
  },
  parent_connector: {
    main: 'hsl(35, 88%, 49%, 1)',
    headerInput: 'hsl(35, 81%, 45%)',
    selected: 'hsla(35, 84%, 57%, 0.24)',
    border: 'white',
  },
};

export const ThemeContext = React.createContext({
  main: MAIN_COLOR,
  border: 'white',
});
