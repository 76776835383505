import React, { createContext, useCallback, useState, useReducer, useEffect } from 'react';
import { Message } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';
import cx from 'classnames';
import './Notifications.scss';

export const NotificationsContext = createContext();

export const NOTIFICATION_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
};

const Notifications = ({ children }) => {
  const [queue, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'SHOW':
        return [...state, action.payload];
      case 'HIDE':
        return state.filter(({ id }) => id !== action.payload);
      default:
        return state;
    }
  }, []);

  const showNotification = useCallback((type = 'success', message, title) => {
    if (!message) {
      return;
    }
    const id = uuid();

    dispatch({ type: 'SHOW', payload: { id, type, message, title } });
    setTimeout(() => {
      dispatch({ type: 'HIDE', payload: id });
    }, 5000);
  }, []);

  const renderNotifications = () => {
    return queue.map((alert) => {
      return (
        <Alert
          key={alert.id}
          {...alert}
          onDismiss={() => dispatch({ type: 'HIDE', payload: alert.id })}
        />
      );
    });
  };

  return (
    <NotificationsContext.Provider value={showNotification}>
      <div
        className="Notifications"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {renderNotifications()}
      </div>
      {children}
    </NotificationsContext.Provider>
  );
};

const Alert = ({ type, id, message, title, onDismiss }) => {
  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    setAnimated(true);
  }, []);

  const alertClass = cx('Notifications_Item', {
    Notifications_Item_Animated: animated,
  });

  return (
    <Message
      className={alertClass}
      key={id}
      negative={type === NOTIFICATION_TYPES.ERROR}
      positive={type === NOTIFICATION_TYPES.SUCCESS}
      onDismiss={onDismiss}
    >
      {title && <Message.Header>{title}</Message.Header>}
      {message}
    </Message>
  );
};

export default Notifications;
