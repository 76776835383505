import React from 'react';
import HelperTooltip from 'components/HelperTooltip/HelperTooltip';
import ArrowDropUpTwoToneIcon from '@mui/icons-material/ArrowDropUpTwoTone';

import './SmallStatsWidget.scss';

const SmallStatsWidget = ({ title, content, info, change, comparisonText }) => {
  const displayChangeValue = () => {
    if (change > 0) {
      return (
        <div className="value-change small-text value-green">
          <ArrowDropUpTwoToneIcon />
          {change}
        </div>
      );
    } else if (change <= 0) {
      change *= -1;
      return (
        <div className="value-change small-text value-red">
          <ArrowDropUpTwoToneIcon className="arrow-icon-down" />
          {change}
        </div>
      );
    }
  };

  return (
    <div className="SmallStatsWidget">
      <div className="title regular-text">
        {title}
        {info && (
          <HelperTooltip
            title={info}
            placement="top"
            arrow
            style={{ height: '20px' }}
          ></HelperTooltip>
        )}
      </div>

      <div className="content-value title-text">
        {content}
        {change && displayChangeValue()}{' '}
        <div className="under-title-text small-text">{comparisonText}</div>
      </div>
    </div>
  );
};

export default SmallStatsWidget;
