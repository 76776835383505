import React, { useState } from 'react';

import InfoCard from 'components/InfoCard';
import ParticipantsWidget from './components/ParticipantsWidget';
import MediaAndFilesWidget from '../MediaAndFilesWidget';
import Collapse from '@mui/material/Collapse';
import ConversationSubject from './components/ConversationSubjectWidget';
import languageMap from 'Language/Language';

const InternalUserProfile = (props) => {
  const [mediaAndFilesOpen, setMediaAndFilesOpen] = useState(true);

  return (
    <>
      <InfoCard cardName="Subject" title={languageMap.subject}>
        <ConversationSubject />
      </InfoCard>
      <InfoCard cardName="Chat users" title={languageMap.chatUsers}>
        <ParticipantsWidget />
      </InfoCard>
      <InfoCard
        cardName="MediaAndFilesWidget"
        title={languageMap.mediaFiles}
        collapsible
        open={mediaAndFilesOpen}
        onSetOpen={setMediaAndFilesOpen}
      >
        <Collapse in={mediaAndFilesOpen} timeout="auto" unmountOnExit>
          <MediaAndFilesWidget />
        </Collapse>
      </InfoCard>
    </>
  );
};

export default InternalUserProfile;
