import React, { Component } from 'react';

import SeparatorV2 from 'components/common/SeparatorV2';

// Component used to separate in sections the children.
export default class Sections extends Component {
  render() {
    const children = this.props.children;

    return children.map((child, index) => {
      if (!child) {
        return null;
      }
      return (
        <div key={index.toString()}>
          {child}
          {
            // Don't display a separator on the last line
            index < children.length - 1 ? <SeparatorV2 /> : undefined
          }
        </div>
      );
    });
  }
}
