import React, { memo } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { nodeObjectClass } from '../../../../../utils/nodeCharacteristics';
import useNodeName from '../../../../../utils/useNodeName';
import withNode from '../../../../../utils/withNode';
import '../../Node.scss';

const ConditionText = withNode(
  (props) => get(props, 'connection.nodeId'),
  memo((props) => {
    const { node, hasOneCondition, connection, isFirst, showConditionalOnly } = props;
    const [nodeName] = useNodeName(node);
    const isLast = get(connection, 'order_position') === 0;
    const isIntent = get(node, 'object_class') === nodeObjectClass.INTENT;
    const isEndCondition = parseInt(connection.nodeId) === 0;

    const prefix = () => {
      if (isIntent) {
        return isFirst ? 'if' : 'else if';
      }
      if (isFirst) {
        return 'if';
      }
      return isLast ? 'else' : 'else if';
    };

    if (node.object_class === nodeObjectClass.PARENT_CONNECTOR) {
      return 'parent connector';
    }

    if (showConditionalOnly) {
      return hasOneCondition ? 'default' : prefix();
    }

    if (isIntent) {
      return `${prefix()} intent = ${nodeName}`;
    }

    if (hasOneCondition) {
      return `default${isEndCondition ? ' END' : ''}`;
    }

    if (isLast) {
      return 'else';
    }

    const { variable, operator, value } = connection;

    const isInvalidCondition = () => {
      const isValueValid = !isEmpty(`${value}`) || operator === 'exists'; // exists operator does not require a value

      return !variable || !operator || !isValueValid;
    };

    if (isInvalidCondition())
      return <span style={{ fontStyle: 'italic' }}>Undefined condition</span>;

    return `${prefix()} ${variable} ${operator} ${operator === 'exists' ? '' : value}${
      isEndCondition ? ' then END' : ''
    }`;
  })
);
ConditionText.displayName = 'ConditionText';
export default ConditionText;
