export const imageCardStyles = {
  cursor: 'pointer',
  width: '100%',
  marginBottom: 10,
};

export const imageContainerStyles = (height, image) => ({
  height,
  backgroundImage: `url(${image})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderTopLeftRadius: '15px',
  borderTopRightRadius: '15px',
});

export const imageCardActionAreaStyles = {
  padding: '0px',
  border: 'none',
  backgroundColor: 'white',
  boxShadow: '0px 0px 2px #aaa',
  borderRadius: '15px',
  maxWidth: '100%',
  textAlign: 'center',
};

export const imageTitleStyles = {
  marginTop: 10,
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: '600',
};

export const imageCardIframeStyle = {
  border: 'none',
  height: 120,
  width: '100%',
  overflowY: 'auto',
};

export const actionButtonStyle = (themeColor) => ({
  backgroundColor: themeColor,
  border: 'solid 4px ' + themeColor,
  borderRadius: '12px',
  fontSize: 16,
  fontWeight: 'bold',
  maxWidth: '135px',
  minWidth: '90px',
  color: '#fff',
  padding: '5px 10px',
  marginBottom: 10,
  cursor: 'pointer',
  outline: 'none',
});
