import React, { Component } from 'react';
import { ThemeContext } from '../../utils/Theme';

import languageMap from 'Language/Language';
import { WhiteButton } from '../../../../components/common/Button/Buttons';
import LoadingOverlay from '../../../../components/common/LoadingOverlay/LoadingOverlay';

const container_style = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  cursor: 'default',
  // fontSize: 17,
};

const cover_style = {
  position: 'fixed',
  top: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'black',
  opacity: 0.65,
};

const dialog_style = {
  width: 800,
  height: '100%',
  backgroundColor: 'white',
  position: 'absolute',
  // float: 'right',
  right: 0,
  display: 'flex',
  flexDirection: 'column',
};

const button_style = {
  margin: 18,
};

export default class Dialog extends Component {
  static contextType = ThemeContext;

  render() {
    const theme = this.context;
    const header_style = {
      backgroundColor: theme.main,
      width: '100%',
      height: 62,
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: 0,
    };

    const { isLoading, onClose } = this.props;
    return (
      <div
        className="Dialog"
        style={container_style}
        onMouseDown={(e) => e.stopPropagation()}
        onWheel={(e) => e.stopPropagation()}
      >
        <div style={cover_style} onClick={onClose} />
        <div style={dialog_style}>
          <div style={header_style}>
            <LoadingOverlay isLoading={isLoading} style={{ color: theme.main }} />
            <WhiteButton
              style={button_style}
              onClick={this.props.onClose}
              text={languageMap.close}
            />
            {this.props.headerView}
          </div>

          {this.props.contentView}
        </div>
      </div>
    );
  }
}
