const getAssetPath = (assetName) => {
  const environment = process.env.NODE_ENV;
  const url = process.env.PUBLIC_URL;

  if (environment === 'production') {
    return `${url}/${assetName}`;
  } else if (environment === 'development') {
    return `${url}/${assetName}`;
  }
};

export default getAssetPath;
