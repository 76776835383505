import React, { Component } from 'react';
import Axios from 'axios';
import {produce} from 'immer';

import Dialog from 'pages/Flow/components/Dialog';
import Modal from 'pages/Flow/components/Modal';
import InputText from 'components/common/input/InputText';
import { AddButton } from 'components/common/Button/Buttons';
import './UIPathConfigurationDialog.scss';

export default class UIPathConfigurationDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      tenancyName: '',
    };

    this.isLoaded = false;
  }

  componentDidMount() {
    Axios.get('/internal_api/project/integration/data', {
      params: {
        project_id: this.props.projectId,
        type_id: 3,
      },
    })
      .then((response) => {
        if (response.data.status === 'ok') {
          let data = JSON.parse(response.data.data);

          this.setState(
            produce((draft) => {
              draft.username = data !== undefined ? data.username : '';
              draft.password = data !== undefined ? data.password : '';
              draft.tenancyName = data !== undefined ? data.tenancyName : '';
            })
          );

          this.isLoaded = true;
        }
      })
      .finally(() => {
        if (!this.isLoaded) {
          alert('Could not load integration data');
          this.props.close();
        }
      });
  }

  handleValueChange = (key, value) => {
    this.setState({ [key]: value });
  };

  save = () => {
    Axios.get('/uipath/checkCredentials', {
      params: {
        projectId: this.props.projectId,
        username: this.state.username,
        password: this.state.password,
        tenancyName: this.state.tenancyName,
      },
    })
      .then((response) => {
        this.props.close();
      })
      .catch((error) => {
        console.log('Error: ', error);
      });
  };

  render() {
    const { username, password, tenancyName } = this.state;
    const { close } = this.props;

    return (
      <Modal onHide={close}>
        <Dialog
          onClose={close}
          headerView={<div className="UIPathConfigurationDialog_Header">UIPath Integration</div>}
          contentView={
            <div className="UIPathConfigurationDialog_Content">
              <InputText
                value={username}
                onValueChanged={this.handleValueChange}
                placeholder="Username"
                name="username"
              />
              <InputText
                type="password"
                value={password}
                onValueChanged={this.handleValueChange}
                name="password"
                placeholder="Password"
              />
              <InputText
                value={tenancyName}
                onValueChanged={this.handleValueChange}
                placeholder="Tenancy Name"
                name="tenancyName"
              />
              <AddButton onClick={this.save} text="Verify and Save" />
            </div>
          }
        />
      </Modal>
    );
  }
}
