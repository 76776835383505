import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import './MediaAndFilesWidget.scss';

import { getMediaAndFiles } from 'store/crm/actions';

import {
  selectConversationHistory,
  selectCurrentConversation,
  selectMediaAndFiles,
} from 'store/crm/selectors';

const MediaAndFilesWidget = (props) => {
  const {
    conversationHistory,
    currentConversation: { conversation_id: conversation_id = 0 },
    mediaAndFiles = [],
  } = props;

  const { id: project_id } = useParams();
  const [isFileAttached, setIsFileAttached] = useState(0);
  const selectedMessageTypes = ['DOCUMENT_MESSAGE', 'AUDIO_MESSAGE', 'IMAGE_MESSAGE'];

  useEffect(() => {
    updateMediaAndFiles();
  }, [conversationHistory]);

  useEffect(() => {
    const callSetMediaAndFiles = async () => {
      const params = { project_id, conversation_id };

      if (project_id && conversation_id) {
        await props.setMediaAndFiles(params);
      }
    };

    callSetMediaAndFiles();
  }, [conversation_id, isFileAttached]);

  const updateMediaAndFiles = () => {
    if (
      selectedMessageTypes.includes(
        conversationHistory?.[0]?.message?.message?.blocks?.[0].message_block_type
      )
    ) {
      setIsFileAttached(isFileAttached + 1);
    } else if (conversationHistory?.[0]?.message?.file !== null) {
      setIsFileAttached(isFileAttached + 1);
    }
  };

  const renderMediaAndFiles = () => {
    if (mediaAndFiles) {
      return mediaAndFiles?.map((item) => {
        const onLinkClick = (event) => {
          event.preventDefault();
          window.open(item.file_url, '_blank');
        };
        return (
          <div key={item.file_url} className="media-and-files">
            <a onClick={onLinkClick}>{item.filename}</a>
          </div>
        );
      });
    }
  };

  return (
    <div className="MediaAndFilesWidget">
      <div className="media-and-files-container">{renderMediaAndFiles()}</div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentConversation: selectCurrentConversation(state),
  mediaAndFiles: selectMediaAndFiles(state),
  conversationHistory: selectConversationHistory(state),
});

const mapDispatchToProps = { setMediaAndFiles: getMediaAndFiles };

export default connect(mapStateToProps, mapDispatchToProps)(MediaAndFilesWidget);
