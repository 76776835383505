import React from 'react';

const Back = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx="18" cy="18" r="18" fill="#5A5A5A" />
      <path
        fill="#FFF"
        d="M18.943 8.63a.892.892 0 0 1 .007 1.268l-7.102 7.101L26.172 17a1 1 0 0 1 0 2H11.768l7.226 7.225a.953.953 0 0 1-.007 1.355.973.973 0 0 1-1.37-.007l-8.031-8.032a2 2 0 0 1 0-2.828l8.076-8.076a.911.911 0 0 1 1.28-.007z"
      />
    </g>
  </svg>
);

export default Back;
