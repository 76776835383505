import React from 'react';
import useAudio from 'utils/hooks/useAudio';
import './AudioMessage.scss';

const AudioMessage = ({ fileUrl }) => {
  const [, toggle] = useAudio(fileUrl);
  return (
    <div className="IncomingMessage AudioFileMessage">
      <button className="AudioFileMessage_Play" onClick={toggle} />
      <span> Recorded Message</span>
    </div>
  );
};

export default AudioMessage;
