import size from 'lodash/size';
import entries from 'lodash/entries';
import { objectThemes } from 'pages/Flow/utils/Theme';
import { orderPositionSortFunction } from 'pages/Flow/components/Canvas/components/LinesRenderer';

export const nodeWidth = 262;
export const headerHeight = 44;
export const conditionHeight = 34;
export const conditionMargin = 6;
export const SVG_MARGIN = 5000;

export const nodeStyle = {
  width: `${nodeWidth}px`,
};

export const computeLineDestinationPosition = (destNode) => {
  return {
    x2: destNode?.x + SVG_MARGIN,
    y2: SVG_MARGIN + destNode?.y + headerHeight / 2,
  };
};

export const computeLineSrcPosition = (srcNode, offset = 0, isLine = true) => {
  const canvasOffset = isLine ? SVG_MARGIN : 0;
  const conditionPositionOffset =
    offset * (conditionMargin + conditionHeight) + (conditionMargin + conditionHeight / 2);
  return {
    x1: srcNode.x + nodeWidth + canvasOffset,
    y1: canvasOffset + srcNode.y + headerHeight + conditionPositionOffset,
  };
};

export const computeLineToNewNodeDestination = (srcNode) => {
  const nextNodesSize = size(srcNode.next);
  // if has no or one next node, new condition would be first, otherwise it will before the last one
  const offset = nextNodesSize > 1 ? nextNodesSize - 1 : 0;
  const srcCoords = computeLineSrcPosition(srcNode, offset, false);
  return { x: srcCoords.x1 + 60, y: srcCoords.y1 - headerHeight / 2 };
};

export const computeLineProps = (srcNode, destNode, offset) => {
  const { x1, y1 } = computeLineSrcPosition(srcNode, offset);
  const { x2, y2 } = computeLineDestinationPosition(destNode);

  const delta = 35;

  let linePoints;
  const xDelta = Math.abs(x2 - x1);

  if (Math.abs(y1 - y2) < 20) {
    linePoints = `M ${x1} ${y1} L ${x2} ${y2}`;
  } else if (xDelta < 2 * delta) {
    linePoints = `M ${x1} ${y1} Q ${x1 + xDelta / 2} ${y1} ${(x1 + x2) / 2} ${(y1 + y2) / 2} Q ${
      x2 - xDelta / 2
    } ${y2} ${x2} ${y2}`;
  } else {
    linePoints = `M ${x1} ${y1} Q ${x1 + delta} ${y1} ${(x1 + x2) / 2} ${(y1 + y2) / 2} Q ${
      x2 - delta
    } ${y2} ${x2} ${y2}`;
  }

  return {
    stroke: objectThemes[srcNode.object_class].main,
    d: linePoints,
  };
};

export const nodeObjectClass = {
  MESSAGE: 'message',
  INTENT: 'intent',
  ENTITY: 'entity',
  SERVICE: 'service',
  EVENT: 'event',
  SCRIPT: 'script',
  FLOW_CONNECTOR: 'flow_connector',
  PARENT_CONNECTOR: 'parent_connector',
  END_OF_DIALOG: 'endOfDialog',
  RETURN_TO_PARENT_FLOW: 'returnToParentFlow',
};

export const getKeyForObjectClass = (objectClass) => {
  switch (objectClass) {
    case 'event':
      return 'events';
    case 'message':
      return 'messages';
    case 'intent':
      return 'intents';
    case 'entity':
      return 'entities';
    case 'service':
      return 'services';
    case 'script':
      return 'scripts';
    case 'node':
      return 'nodes';
    case 'flow_connector':
      return 'flow_connectors';
    case 'parent_connector':
      return 'parent_connectors';
    default:
      console.error('Wrong class! ' + objectClass);
  }
};

export const getSortedConnections = (node = {}) => {
  return entries(node.next || [])
    .sort(orderPositionSortFunction)
    .map(([nodeId, connection]) => {
      return { ...connection, nodeId };
    });
};
