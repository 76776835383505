import { useMemo, useCallback, useState } from 'react';

export function useComputeLanguageOptions({ languages, secondaryLanguages, selectedLanguage }) {
  const languageOptions = useMemo(() => {
    const options = [];
    languages.forEach((lang) => {
      if (secondaryLanguages.includes(lang.value) || lang.value === selectedLanguage) {
        options.push({
          text: lang.label,
          value: lang.value,
        });
      }
    });
    return options;
  }, [languages, secondaryLanguages, selectedLanguage]);

  return languageOptions;
}

export const useHandleEntryLanguageChange = (
  currentLanguage,
  messageId,
  entryToLanguage,
  setEntryToLanguage
) => {
  const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage);

  const setLanguage = useCallback(
    (language) => {
      const newEntry = { ...entryToLanguage, [messageId]: language };
      setEntryToLanguage(newEntry);
      setSelectedLanguage(language);
    },
    [entryToLanguage, setEntryToLanguage]
  );
  return setLanguage;
};
