import React from 'react';
import ReactCarousel, { slidesToShowPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import CarouselEditorSlide from './CarouselEditorSlide';
import './CarouselEditor.scss';

const CarouselEditor = ({
  slides,
  setSlidesAndEmitChange,
  currentSlide,
  setCurrentSlide,
  onSlideChange,
  fallbackImage,
  actionButton,
  projectId,
}) => {
  const slidesNumber = slides.length;

  const changeSlide = (editedSlide, slideIndex) => {
    const newSlides = slides.map((item, index) => {
      return index === slideIndex ? editedSlide : item;
    });

    setSlidesAndEmitChange(newSlides);
  };

  const removeSlideAtIndex = (slideIndex) => {
    const newSlides = slides.filter((slide, index) => {
      return index !== slideIndex;
    });

    setSlidesAndEmitChange(newSlides);
  };

  const renderCarouselSlides = () => {
    return slides.map((item, index) => {
      return (
        <CarouselEditorSlide
          projectId={projectId}
          key={item.uuid}
          index={index}
          onSlideChange={(editedSlide) => changeSlide(editedSlide, index)}
          slide={item}
          setCurrentSlide={setCurrentSlide}
          slidesNumber={slidesNumber}
          fallbackImage={fallbackImage}
          actionButton={actionButton}
          removeSlide={() => removeSlideAtIndex(index)}
        />
      );
    });
  };

  return (
    <div className="CarouselEditorComponent">
      <div className="CarouselEditorComponent_SlidesContainer">
        <ReactCarousel
          value={currentSlide}
          onChange={onSlideChange}
          draggable={false}
          plugins={[
            'infinite',
            'fastSwipe',
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: slidesNumber,
              },
            },
          ]}
        >
          {renderCarouselSlides()}
        </ReactCarousel>
      </div>
    </div>
  );
};

export default CarouselEditor;
