import React, { memo, useMemo, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import get from 'lodash/get';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { Tooltip } from '@mui/material';

import { objectThemes } from 'pages/Flow/utils/Theme';
import AddNewNode from './components/AddNewNode';
import ConditionText from './components/ConditionText';
import useDidUpdate from 'utils/hooks/useDidUpdate';
import useNodeName from 'pages/Flow/components/Canvas/utils/useNodeName';
import {
  headerHeight,
  nodeStyle,
  conditionHeight,
  conditionMargin,
  getSortedConnections,
} from 'pages/Flow/components/Canvas/utils/nodeCharacteristics';

import './Node.scss';
import languageMap from '../../../../../../../Language/Language';

const objectClassToLabel = {
  intent: languageMap.intent,
  entity: languageMap.entity,
  script: languageMap.script,
  event: languageMap.event,
  message: languageMap.message,
  parent_connector: languageMap.parentConnector,
};
const Node = memo(
  ({ node_id, node, scale, objectIndex, updateNode, persistNodes, openSettingsDrawer }) => {
    const { x, y, object_class, object_id } = node;
    const hasBeenDragged = useRef(false);
    const [showAddConnection, setShowAddConnection] = useState(null);

    const [nodeName] = useNodeName(node);

    const nodeMainColor = useMemo(() => {
      return get(objectThemes, `${object_class}.main`);
    }, [object_class]);

    useDidUpdate(() => {
      hasBeenDragged.current = true;
    }, [x, y]);

    const updateNodePosition = (x, y) => {
      if (Math.abs(x - node.x) < 20000) {
        const updatedNode = { ...node, x, y };
        updateNode({ [node_id]: updatedNode });
      }
    };

    const handleDragStart = (e, data) => {
      e.stopPropagation();
      e.preventDefault();
    };

    const handleDrag = (e, { x, y }) => {
      e.stopPropagation();
      e.preventDefault();

      updateNodePosition(x, y);
    };

    const handleDragEnd = (e, { x, y }) => {
      e.stopPropagation();
      e.preventDefault();

      if (hasBeenDragged.current) {
        persistNodes();
      }
      // add a timeout so we can check onClick that this is being dragged
      setTimeout(() => {
        hasBeenDragged.current = false;
      });
    };

    const sortedConnections = useMemo(() => {
      return getSortedConnections(node);
    }, [node]);

    const getBlockTag = (object_class) => {
      return objectClassToLabel[object_class];
    };

    const renderNodeHeader = () => {
      const { object_class, return_to_parent_flow } = node;

      return (
        <div
          className="Node_Header"
          onClick={() => {
            !hasBeenDragged.current && openSettingsDrawer(object_class, object_id, node_id);
          }}
          style={{
            height: `${headerHeight}px`,
            backgroundColor: nodeMainColor,
          }}
        >
          <Tooltip title={nodeName} placement="top-start">
            <p className="Node_Header_Title">{nodeName}</p>
          </Tooltip>
          {objectIndex && <span className="Node_Header_Title_Index">[{objectIndex}]</span>}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              className="Node_Header_Class"
              style={{
                color: nodeMainColor,
              }}
            >
              {getBlockTag(object_class)}
            </span>
            {['flow_connector', 'parent_connector'].includes(object_class) &&
              return_to_parent_flow === false && <ArrowRightAltIcon style={{ fontSize: 17 }} />}
            {['flow_connector', 'parent_connector'].includes(object_class) &&
              return_to_parent_flow === true && <SyncAltIcon style={{ fontSize: 17 }} />}
            <span style={{ fontSize: 17 }} className="material-icons">
              crop_free
            </span>
          </div>
        </div>
      );
    };

    return (
      <Draggable
        handle=".Node"
        scale={scale}
        defaultPosition={{ x, y }}
        onStart={handleDragStart}
        onDrag={handleDrag}
        onStop={handleDragEnd}
      >
        <div className="Node" style={nodeStyle}>
          {renderNodeHeader()}
          <ul className="Node_Connections">
            {sortedConnections?.map((item, index) => {
              return (
                <li
                  key={`${item.order_position}-${index}`}
                  className="Node_Connections_Condition"
                  style={{
                    color: nodeMainColor,
                    height: `${conditionHeight}px`,
                    margin: `${conditionMargin}px`,
                  }}
                >
                  <Tooltip
                    title={
                      <ConditionText
                        connection={item}
                        isFirst={index === 0}
                        hasOneCondition={sortedConnections?.length === 1}
                      />
                    }
                    placement="top-start"
                  >
                    <span className="Node_Connections_Condition_Text">
                      <ConditionText
                        connection={item}
                        isFirst={index === 0}
                        hasOneCondition={sortedConnections?.length === 1}
                      />
                    </span>
                  </Tooltip>
                  <span className="material-icons Node_Connections_Condition_PlusIcon">
                    add_box
                  </span>
                </li>
              );
            })}
            <li
              className="Node_Connections_Condition Node_Connections_Condition_Add"
              onClick={(e) => {
                if (!showAddConnection) {
                  setShowAddConnection({ mouse_x: e.pageX, mouse_y: e.pageY });
                }
              }}
              style={{
                height: `${conditionHeight}px`,
                margin: `${conditionMargin}px`,
              }}
            >
              Add new connection
              <span
                className="material-icons Node_Connections_Condition_PlusIcon"
                style={{
                  color: nodeMainColor,
                }}
              >
                add_box
              </span>
              {showAddConnection && (
                <AddNewNode
                  node={{ node_id, ...node, nodeName }}
                  left={showAddConnection.mouse_x}
                  top={showAddConnection.mouse_y}
                  openSettingsDrawer={openSettingsDrawer}
                  closeDropdown={() => {
                    setShowAddConnection(null);
                  }}
                />
              )}
            </li>
          </ul>
        </div>
      </Draggable>
    );
  }
);

export default Node;
