import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Dropdown, Input, Modal } from 'semantic-ui-react';
import Button from '@mui/material/Button';
import debounce from 'lodash/debounce';
import languageMap from 'Language/Language';
import { uploadFile } from 'utils/uploadFile';
import { getToken } from 'store/auth/selectors';
import { selectAllLanguages } from 'store/crm/selectors';
import { connect } from 'react-redux';
import axios from 'axios';

import { DocumentTable } from './DocumentTable';

const UploadModal = (props) => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [extension, setExtension] = useState('');
  const [language, setLanguage] = useState(null);
  const fileInputRef = useRef(null);

  const handleUpload = () => {
    if (!file) {
      alert('No file selected');
      return;
    }

    if (!language) {
      alert('You need to select a language');
      return;
    }

    props.onUpload && props.onUpload(file, fileName + '.' + extension, language);
    setOpen(false);
  };

  const onChangeLanguage = (e, { value }) => {
    setLanguage(value);
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      const size = file.size / 1024 / 1024;
      if (size > 10) {
        alert('File too large, max 10MB allowed');
      }
      let ext = file.name.split('.').pop();
      let name = file.name.slice(0, (ext.length + 1) * -1);
      setFileName(name);
      setExtension(ext);
      setFile(file);
    }
  };

  const languageOptions = props.languages.map((entry) => {
    const name = entry.label;
    const code = entry.value;

    return {
      key: name,
      text: name,
      value: code,
    };
  });

  return (
    <Modal
      size="mini"
      onClose={() => setOpen(false)}
      onOpen={() => {
        setOpen(true);
        setFile(null);
        setFileName('');
      }}
      open={open}
      trigger={props.trigger}
    >
      <Modal.Header>{languageMap.uploadDocument}</Modal.Header>
      <Modal.Content>
        <Button
          style={{ marginBottom: 10 }}
          variant="outlined"
          color="primary"
          onClick={() => fileInputRef?.current.click()}
        >
          Select File
        </Button>

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span>File name</span>
          <Input
            placeholder="File name"
            style={{ height: '39px', width: '200px', marginBottom: 10 }}
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
          />
        </div>
        <input
          ref={fileInputRef}
          id="file-attach"
          type="file"
          onChange={handleFileSelect}
          accept=".docx,.xls,.xlsx"
          style={{
            display: 'none',
            cursor: 'none',
          }}
        />

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span>Language</span>
          <Dropdown
            style={{ width: 200 }}
            placeholder={languageMap.selectLanguage}
            options={languageOptions}
            fluid
            selection
            search
            onChange={onChangeLanguage}
          />
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Button variant="outlined" color="primary" onClick={handleUpload}>
          {languageMap.uploadFile}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const Documents = ({ projectId, scope, token, languages }) => {
  const [search, setSearch] = useState(undefined);
  const [documents, setDocuments] = useState([]);

  const onChangeSearch = useCallback(
    debounce((value) => {
      setSearch(value);
    }, 300),
    []
  );

  const getDocuments = () => {
    axios
      .get('/internal_api/list_files', {
        params: { project_id: projectId, scope: scope },
      })
      .then((response) => {
        if (Array.isArray(response.data)) {
          const prefix = projectId + '/';
          let documents = response.data.map((row) => {
            if (row.name.startsWith(prefix)) {
              row.name = row.name.slice(prefix.length);
            }
            return row;
          });
          setDocuments(documents);
        }
      });
  };

  useEffect(getDocuments, []);

  const handleUpload = useCallback(
    (file, fileName, language) => {
      for (let i = 0; i < documents.length; i++) {
        if (documents[i].name == fileName) {
          console.log(documents[i]);
          alert('File already exists, delete it first');
          return;
        }
      }

      setDocuments((docs) => {
        let newDocs = [...docs];

        newDocs.unshift({
          name: fileName,
          status: 'Upload in progress',
          last_modified: '',
        });

        return newDocs;
      });
      uploadFile(file, projectId, token, fileName, scope, language)
        .then((response) => {
          // Refresh list of documents
          getDocuments();
        })
        .catch((error) => {
          getDocuments();
        });
    },
    [documents]
  );

  const handleDelete = (id) => {
    axios
      .delete('/internal_api/delete_file', {
        params: {
          project_id: projectId,
          file_id: id,
          scope: scope,
        },
      })
      .then((response) => {
        getDocuments();
      })
      .catch((error) => {
        getDocuments();
      });
  };

  let filtered_documents = documents;
  if (search) {
    filtered_documents = documents.filter((x) => x.name.includes(search));
  }

  const handleDownload = (id, filename) => {
    axios({
      url: '/internal_api/download_file',
      method: 'GET',
      responseType: 'blob',
      params: {
        project_id: projectId,
        scope: scope,
        file_id: id,
      },
    }).then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        maxWidth: 1024,
        height: '100%',
      }}
    >
      <div style={{ display: 'flex', marginTop: 40, paddingBottom: 20 }}>
        <Input
          placeholder={languageMap.search}
          icon="search"
          style={{ height: '39px', width: '300px' }}
          onChange={(e) => onChangeSearch(e.target.value)}
        />
        <div>
          <UploadModal
            languages={languages}
            onUpload={handleUpload}
            trigger={
              <Button style={{ marginLeft: 16 }} variant="outlined" color="primary">
                {languageMap.upload}
              </Button>
            }
          />
        </div>
      </div>
      <DocumentTable
        data={filtered_documents}
        languages={languages}
        projectId={projectId}
        onDelete={handleDelete}
        onDownload={handleDownload}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: getToken(state),
  languages: selectAllLanguages(state),
});

export default connect(mapStateToProps)(Documents);
