import React, { useMemo, useContext } from 'react';
import { FlowMetaContext, NodeName, NodeUpdater } from 'pages/Flow/utils/canvas-contexts';
import { nodeObjectClass } from './nodeCharacteristics';
import find from 'lodash/find';
import entries from 'lodash/entries';
import get from 'lodash/get';

export default (node) => {
  const data = useContext(FlowMetaContext);
  const object_class = get(node, 'object_class');

  const nodeNameData = useMemo(() => {
    return getNodeName(data, node);
  }, [data, node]);

  return [nodeNameData[0], object_class];
};

const getNodeName = (data, node) => {
  const {
    messages,
    intents,
    entities,
    services,
    events,
    scripts,
    flow_connectors,
    parent_connectors,
  } = data;
  const object_class = get(node, 'object_class');
  const object_id = get(node, 'object_id');

  const getName = () => {
    switch (object_class) {
      case nodeObjectClass.MESSAGE:
        return get(
          find(messages, ({ message_id }) => message_id === object_id),
          'message_name'
        );
      case nodeObjectClass.INTENT:
        return get(
          find(intents, ({ intent_id }) => intent_id === object_id),
          'intent_name'
        );
      case nodeObjectClass.ENTITY:
        return get(
          find(entities, ({ entity_id }) => entity_id === object_id),
          'entity_name'
        );
      case nodeObjectClass.SERVICE:
        return get(
          find(services, ({ service_id }) => service_id === object_id),
          'service_name'
        );
      case nodeObjectClass.EVENT:
        return get(
          find(events, ({ event_id }) => event_id === object_id),
          'event_name'
        );
      case nodeObjectClass.SCRIPT:
        return get(
          find(scripts, ({ script_id }) => script_id === object_id),
          'script_name'
        );
      case nodeObjectClass.FLOW_CONNECTOR:
        return get(
          find(flow_connectors, ({ flow_connector_id }) => flow_connector_id === object_id),
          'flow_connector_name'
        );
      case nodeObjectClass.PARENT_CONNECTOR:
        return get(
          find(parent_connectors, ({ parent_connector_id }) => parent_connector_id === object_id),
          'parent_connector_name'
        );
      case nodeObjectClass.END_OF_DIALOG:
        return get(node, 'name');
      case nodeObjectClass.RETURN_TO_PARENT_FLOW:
        return get(node, 'name');
      default:
        return '';
    }
  };
  return [getName(), object_id, object_class];
};

export const NodeNamesProvider = ({ children }) => {
  const data = useContext(FlowMetaContext);
  const { nodes } = useContext(NodeUpdater);

  const nodeNames = useMemo(() => {
    const nodeNamesMap = {};
    entries(nodes).forEach(([nodeId, nodeValue]) => {
      const [nodeName, objectId, objectClass] = getNodeName(data, nodeValue);
      nodeNamesMap[nodeId] = { nodeName, nodeId, objectId, objectClass };
    });

    return nodeNamesMap;
  }, [data, nodes]);

  return <NodeName.Provider value={nodeNames}>{children}</NodeName.Provider>;
};
