import React from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import You from 'assets/menuIcons/You';
import Unassigned from 'assets/menuIcons/Unassigned';
import External from 'assets/menuIcons/External';
import Internal from 'assets/menuIcons/Internal';
import YouSelected from 'assets/menuIcons/YouSelected';
import UnassignedSelected from 'assets/menuIcons/UnassignedSelected';
import ExternalSelected from 'assets/menuIcons/ExternalSelected';
import InternalSelected from 'assets/menuIcons/InternalSelected';

import { Popup } from 'semantic-ui-react';

import { setInboxFolder, setMainMenu, setSubMenu } from 'store/crm/actions';
import {
  selectConversationsStatusFilter,
  selectCurrentConversation,
  selectFolderTotals,
  selectMainMenu,
  selectUnreadConversations,
} from 'store/crm/selectors';

import './MessagesMenu.scss';

const MessagesMenu = ({
  menuItems,
  setInbox,
  inboxFolder,
  folderTotals,
  unreadConversations,
  mainMenu,
  setMenu,
  setSubMenu,
  collapsed,
}) => {
  const navigate = useNavigate();
  const { id: projectId } = useParams();

  const handleMenuOptionClick = async (inbox) => {
    if (mainMenu !== 'inbox') {
      setMenu('inbox');
      setSubMenu('inbox');
    }

    const { submenu, file } = inbox;
    setInbox({ submenu, file });
    navigate('/project/' + projectId + '/crm/inbox');
  };

  const isSelected = (value) => {
    return mainMenu === 'inbox' && inboxFolder.file.value === value;
  };

  const getClasses = (value) => {
    if (isSelected(value)) {
      return 'submenu-file selected';
    }
    return 'submenu-file';
  };

  const getUnreadConversationsCount = (file) => {
    const count = unreadConversations[file.value].length;

    return count < 99 ? count : '99+';
  };

  const matchIcon = (value) => {
    const isSelected = mainMenu === 'inbox' && inboxFolder.file.value === value;
    switch (value) {
      case 'you':
        return isSelected ? <YouSelected /> : <You />;
      case 'unassigned':
        return isSelected ? <UnassignedSelected /> : <Unassigned />;
      case 'external_all':
        return isSelected ? <ExternalSelected /> : <External />;
      case 'internal_all':
        return isSelected ? <InternalSelected /> : <Internal />;
      default:
        return <div>SVG</div>;
    }
  };

  const renderFiles = (file) => {
    const subMenuTitle = file.type;
    if (file.title) {
      return (
        <Popup
          key={`${file.value}_file`}
          style={{ color: '#56595f' }}
          trigger={
            <div
              className={getClasses(file.value)}
              onClick={() => handleMenuOptionClick({ submenu: subMenuTitle, file })}
            >
              <div className="file-container">
                <div className="icon">{matchIcon(file.value)}</div>
                {!collapsed && <span className="dm-sans-500 title"> {file.title} </span>}
              </div>
              {!collapsed && (
                <span className="total-container">
                  {Object.keys(folderTotals).length > 0 ? (
                    <div className="total-collector">
                      <div className="unread">{getUnreadConversationsCount(file)}</div>
                      <div className="total">({folderTotals[file.value]})</div>
                    </div>
                  ) : null}
                </span>
              )}
              {!collapsed && isSelected(file.value) && <div className="selected-bar" />}
            </div>
          }
          content={file.title}
          disabled={!collapsed}
          size="mini"
          position="right center"
        />
      );
    }
  };

  return <div className="MessagesMenu">{menuItems?.map((file) => renderFiles(file))}</div>;
};

const mapStateToProps = (state) => ({
  conversationsStatus: selectConversationsStatusFilter(state),
  currentConversation: selectCurrentConversation(state),
  folderTotals: selectFolderTotals(state),
  unreadConversations: selectUnreadConversations(state),
  mainMenu: selectMainMenu(state),
});

const mapDispatchToProps = {
  setInbox: setInboxFolder,
  setMenu: setMainMenu,
  setSubMenu: setSubMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessagesMenu);
