import React, { Component } from 'react';
import Axios from 'axios';
import { connect } from 'react-redux';

import Dialog from '../../Flow/components/Dialog';
import InputText from 'components/common/input/InputText';
import { AddButton } from 'components/common/Button/Buttons';
import DropDown from 'components/common/DropDown';
import Modal from '../../Flow/components/Modal';
import { getCurrentUser } from '../../../store/crm/actions';
import { checkSession } from '../../../utils/utils';
import languageMap from 'Language/Language';

const inputTextStyle = {
  marginTop: '30px',
  marginLeft: '30px',
  width: '200px',
};

const addButtonStyle = {
  marginLeft: '30px',
};

const containerStyle = {
  display: 'flex',
  alignItems: 'center',
  marginTop: 10,
};

const headerTitleStyle = {
  color: 'white',
  fontWeight: 'bold',
  fontSize: 18,
  marginRight: 22,
};

const labelName = {
  paddingRight: '10px',
  width: '150px',
  color: '#000',
  marginLeft: '30px',
};

class CreateNewProject extends Component {
  constructor(props) {
    super(props);

    this.state = {
      projectName: '',
      selectedLanguage: 'ro',
    };
  }

  handleNameChange = (key, name) => {
    this.setState({ [key]: name });
  };

  handleLanguageChange = (value) => {
    this.setState({ selectedLanguage: value });
  };

  handleCreateEvent = () => {
    Axios.post('/internal_api/projects', {
      projectName: this.state.projectName,
      projectLanguage: this.state.selectedLanguage,
    })
      .then(async (response) => {
        const userData = await checkSession();
        this.props.setUserAction(userData);
        this.props.onProjectCreate(response.data);
        this.props.close();
      })
      .catch((error) => {
        console.error('Error while creating project!', error);
      });
  };

  render() {
    return (
      <Modal onHide={this.props.close}>
        <Dialog
          onClose={this.props.close}
          headerView={<div style={headerTitleStyle}>Create new project</div>}
          contentView={
            <>
              <div>
                <InputText
                  style={inputTextStyle}
                  value={this.state.projectName}
                  onValueChanged={this.handleNameChange}
                  name="projectName"
                  placeholder={languageMap.projectDetailsName}
                />
              </div>

              <div style={containerStyle}>
                <label style={labelName}>{languageMap.defaultLanguage}</label>
                <DropDown
                  value={this.state.selectedLanguage}
                  defaultText={languageMap.defaultDisplayLanguage}
                  options={this.props.languages}
                  onSelect={this.handleLanguageChange}
                  containerStyle={{
                    width: '200px',
                    marginLeft: '30px',
                    marginBottom: '30px',
                    marginTop: '0px',
                  }}
                />
                <AddButton
                  style={addButtonStyle}
                  onClick={this.handleCreateEvent}
                  text={languageMap.create}
                />
              </div>
            </>
          }
        />
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  setUserAction: getCurrentUser,
};
export default connect(null, mapDispatchToProps)(CreateNewProject);
