import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import HelperTooltip from 'components/HelperTooltip/HelperTooltip';
import { Tooltip } from '@mui/material';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import ArrowDropUpTwoToneIcon from '@mui/icons-material/ArrowDropUpTwoTone';

import './ConversationRatings.scss';

const ConversationRatings = ({ conversationRatingsData }) => {
  const [legend, setLegend] = useState([]);
  const [listOfDays, setListOfDays] = useState([]);

  useEffect(() => {
    legendAssembler();
    listOfDaysAssembler();
  }, [conversationRatingsData?.averages]);

  const {
    averages,
    colorBreakdown,
    comparisonText,
    comparisonValue,
    data,
    feedbackAverage,
    prettifiedDays,
    title,
    info,
  } = conversationRatingsData;

  const listOfDaysAssembler = () => {
    let temporaryListOfDays = [];

    for (let i = 0; i < prettifiedDays?.length; i++) {
      temporaryListOfDays.push({
        date: prettifiedDays[i],
        value1: data?.[i]?.[1],
        value2: data?.[i]?.[2],
        value3: data?.[i]?.[3],
        value4: data?.[i]?.[4],
        value5: data?.[i]?.[5],
      });
    }

    setListOfDays(temporaryListOfDays);
  };

  const legendAssembler = () => {
    let temporaryLegend = [];
    for (let i = 1; i < averages?.length + 1; i++) {
      temporaryLegend.push({ mainValue: i, color: colorBreakdown[i], percentage: averages[i - 1] });
    }
    setLegend(temporaryLegend);
  };

  const displayChangeValue = () => {
    let change = comparisonValue;

    if (change > 0) {
      return (
        <div className="value-change small-text value-green">
          <ArrowDropUpTwoToneIcon />
          {change}
        </div>
      );
    } else if (change <= 0) {
      change *= -1;
      return (
        <div className="value-change small-text value-red">
          <ArrowDropUpTwoToneIcon className="arrow-icon-down" />
          {change}
        </div>
      );
    } else {
      return;
    }
  };

  const barTootltip = (index, value) => {
    if (legend.length) {
      return (
        <div className="legend-row" key={uuidv4()}>
          {legend[index]?.mainValue}
          <StarRoundedIcon
            style={{
              fill: 'rgba(255, 222, 6, 0.978)',
              marginLeft: '1px',
              marginRight: '2px',
              height: '20px',
              marginBottom: '-4px',
            }}
          />
          {`: ${value} %`}
        </div>
      );
    }
  };

  return (
    <div>
      <div className="ConversationRatings">
        <div className="title-wrapper">
          <div className="title title-text">
            <div className="title-container">
              {title}
              {info && <HelperTooltip title={info} placement="top" arrow></HelperTooltip>}
            </div>
            <div className="subtitle-text regular-text">
              <div className="title-text" style={{ marginRight: '5px' }}>
                {feedbackAverage}
              </div>
              {displayChangeValue()}
              <div>{comparisonText}</div>
            </div>
          </div>

          <div className="legend">
            {legend.map(({ mainValue, color, percentage }) => (
              <div className="legend-row" key={uuidv4()}>
                <FiberManualRecordIcon style={{ fill: color, width: '20%', marginRight: '2px' }} />
                {mainValue}
                <StarRoundedIcon
                  style={{
                    fill: 'rgba(255, 222, 6, 0.978)',
                    marginLeft: '2px',
                    marginRight: '5px',
                  }}
                />
                {percentage} %
              </div>
            ))}
          </div>
        </div>
        <div className="list">
          {listOfDays.map(({ date, value1, value2, value3, value4, value5 }) => (
            <div className="line-item-container" key={uuidv4()}>
              <div className="url">{date}</div>
              <div className="values-container">
                <Tooltip title={barTootltip(4, value5)} placement="top" arrow>
                  <div
                    className="percentage-bar"
                    style={{ backgroundColor: colorBreakdown[5], width: `${value5}%` }}
                  />
                </Tooltip>
                <Tooltip title={barTootltip(3, value4)} placement="top" arrow>
                  <div
                    className="percentage-bar"
                    style={{ backgroundColor: colorBreakdown[4], width: `${value4}%` }}
                  />
                </Tooltip>
                <Tooltip title={barTootltip(2, value3)} placement="top" arrow>
                  <div
                    className="percentage-bar"
                    style={{ backgroundColor: colorBreakdown[3], width: `${value3}%` }}
                  />
                </Tooltip>
                <Tooltip title={barTootltip(1, value2)} placement="top" arrow>
                  <div
                    className="percentage-bar"
                    style={{ backgroundColor: colorBreakdown[2], width: `${value2}%` }}
                  />
                </Tooltip>
                <Tooltip title={barTootltip(0, value1)} placement="top" arrow>
                  <div
                    className="percentage-bar"
                    style={{ backgroundColor: colorBreakdown[1], width: `${value1}%` }}
                  />
                </Tooltip>
              </div>
            </div>
          ))}
        </div>
        <div className="x-axis">
          <span>20 %</span>
          <span>40 %</span>
          <span>60 %</span>
          <span>80 %</span>
          <span>100 %</span>
        </div>
      </div>
    </div>
  );
};

export default ConversationRatings;
