import Axios from 'axios';
import get from 'lodash/get';

export default async (messages) => {
  let messageList = [];
  for (const msg of messages) {
    try {
      const response = await Axios.get('/internal_api/message', {
        params: { message_id: msg.message_id },
      });
      const extracted = get(response, 'data.message_details');
      messageList.push({
        message_id: extracted.message_id,
        message_properties: extracted.message_properties,
        url_regex: msg.url_regex,
      });
    } catch (err) {
      console.log(err);
    }
  }
  return messageList;
};
