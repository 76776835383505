import React from 'react';

import './InfoCard.scss';

const InfoCard = ({ cardName, title, children }) => {
  const infoCardChildren = React.Children.toArray(children);

  return (
    <div className={`InfoCard ${cardName || ''}`}>
      <div className="header">
        <div className="title regular-text">{title || 'Info'}</div>
        {infoCardChildren[1] || null}
      </div>
      <div className="InfoCard_body">{infoCardChildren[0]}</div>
    </div>
  );
};

export default InfoCard;
