import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Table from '../../../../../../../components/Table';
import Graph from '../../../../../../../components/Graphs/Graph/Graph';
import HeatMapWidget from '../Widgets/HeatMapWidget';

import TableAvatar from '../Widgets/TableAvatar';

import { twin } from 'utils/twin';
import languageMap from 'Language/Language';
import { replaceSeconds } from '../../../../../../../utils/utils';

import {
  getConversationsByBreakdown,
  getAverageResponseTime,
  getHeatMapTeamPerformance,
  getAgentPerformance,
} from '../../../../../../../store/analytics/selectors';
import {
  setConversationsByBreakdown,
  setAverageResponseTime,
  setHeatMapTeamPerformance,
  setAgentPerformance,
} from '../../../../../../../store/analytics/actions';

const TeamPerformance = ({
  projectId,
  startTimestamp,
  endTimestamp,
  selectedTimeInterval,
  forPrint,
  currentTimezone,
  setConversationsByBreakdown,
  setAverageResponseTime,
  setHeatMapTeamPerformance,
  setAgentPerformance,
  conversationsByBreakdown,
  averageResponseTime,
  heatMapTeamPerformance,
  agentPerformance,
}) => {
  useEffect(() => {
    const populateConversationsByBreakdown = async () => {
      const response = await axios.get('/internal_api/analytics/get_conversations_by_breakdown', {
        params: {
          projectId,
          startTimestamp,
          endTimestamp,
          timezone: currentTimezone,
          breakdown: 'department_id_assigned',
          data_representation: 'total',
        },
      });
      let conversationsByBreakdownObject = {};
      let auxDataSet = response.data;
      let dataSet = [];
      let labels = Object.keys(auxDataSet.breakdown_color);
      let info = languageMap.infoReportsConversationsByTeam;
      let conversationsList = auxDataSet.conversations;
      let comparisonValue = auxDataSet.comparison_value;
      let comparisonText = auxDataSet.comparison_text;
      conversationsByBreakdownObject.labels = labels;
      for (let i = 0; i < auxDataSet?.data.length; i++) {
        let temporaryData = [];
        let temporaryColor = [];
        const temporaryDataset = auxDataSet.data[i];
        for (let j = 0; j < labels?.length; j++) {
          temporaryData = [...temporaryData, temporaryDataset[labels[j]]];
          temporaryColor = [...temporaryColor, auxDataSet.breakdown_color[labels[j]]];
        }
        const entry = {
          data: temporaryData,
          fill: false,
          backgroundColor: temporaryColor,
          maxBarThickness: 14,
          borderRadius: 14,
        };
        dataSet.push(entry);

        conversationsByBreakdownObject.data = dataSet;
        conversationsByBreakdownObject.title = auxDataSet.chart_name;
        conversationsByBreakdownObject.conversationsList = conversationsList;
        conversationsByBreakdownObject.comparisonValue = comparisonValue;
        conversationsByBreakdownObject.comparisonText = comparisonText;
        conversationsByBreakdownObject.info = info;

        setConversationsByBreakdown(conversationsByBreakdownObject);
      }
    };

    const populAverageResponseTime = async () => {
      const response = await axios.get(
        '/internal_api/analytics/get_average_first_response_time_by_breakdown',
        {
          params: {
            projectId: projectId,
            timezone: currentTimezone,
            startTimestamp,
            endTimestamp,
            breakdown: 'department_id_assigned',
          },
        }
      );

      let auxDataSet = response.data;
      let averageResponseTimeObject = {};
      let dataSet = [];
      let legend = auxDataSet.chart_legend;
      let info = languageMap.infoReportsAverageFirstResponseTimeByTeam;
      let title = auxDataSet.chart_name;
      let containmentRate = auxDataSet.containment_rate;
      let labels = auxDataSet.prettified_days;
      let conversationsList = auxDataSet.conversations;
      let comparisonValue = auxDataSet.comparison_value;
      let comparisonText = auxDataSet.comparison_text;

      let randomeColor;

      const generateColor = (i) => {
        if (i === 0) {
          randomeColor = '#1a90ff';
        } else if (i === 1) {
          randomeColor = '#bfc1c6';
        } else {
          // eslint-disable-next-line no-bitwise
          randomeColor = '#' + (((1 << 24) * Math.random()) | 0).toString(16).padStart(6, '0');
        }
        return randomeColor;
      };

      let maxValueOfTheTempArray = 0;
      for (let j = 0; j < legend.length; j++) {
        let temporaryDataArray = [];
        let temporaryColor = [auxDataSet.breakdown_color[legend[j]]];
        for (let i = 0; i < auxDataSet.data.length; i++) {
          temporaryDataArray = [...temporaryDataArray, auxDataSet.data[i][legend[j]]];
        }

        if (Math.max(...temporaryDataArray) > maxValueOfTheTempArray) {
          maxValueOfTheTempArray = Math.max(...temporaryDataArray);
        }
        generateColor(j);
        const entry = {
          label: legend[j],
          data: temporaryDataArray,
          borderColor: temporaryColor,
          fill: false,
          backgroundColor: temporaryColor,
        };
        dataSet.push(entry);
      }

      const getTimeUnit = (total, num) => {
        return total / num;
      };

      const loopThroughData = (divisionValue) => {
        for (let i = 0; i < dataSet.length; i++) {
          dataSet[i].data.reduce(getTimeUnit, divisionValue);
        }
      };

      if (maxValueOfTheTempArray >= 86400) {
        loopThroughData(86400);
        averageResponseTimeObject.unit = 'd';
      } else if (maxValueOfTheTempArray >= 3600) {
        loopThroughData(3600);
        averageResponseTimeObject.unit = 'h';
      } else if (maxValueOfTheTempArray >= 60) {
        loopThroughData(60);
        averageResponseTimeObject.unit = 'min';
      } else if (maxValueOfTheTempArray === 0) {
        averageResponseTimeObject.unit = 's';
      } else {
        averageResponseTimeObject.unit = 's';
      }
      averageResponseTimeObject.labels = labels;
      averageResponseTimeObject.info = info;
      averageResponseTimeObject.data = dataSet;
      averageResponseTimeObject.title = title;
      averageResponseTimeObject.conversationsList = conversationsList;
      averageResponseTimeObject.comparisonValue = comparisonValue;
      averageResponseTimeObject.comparisonText = comparisonText;
      averageResponseTimeObject.legend = legend;
      averageResponseTimeObject.containmentRate = containmentRate;
      averageResponseTimeObject.changeDisplayedTooltipValue = true;

      setAverageResponseTime(averageResponseTimeObject);
    };

    const populateHeatMapTeamPerformance = async () => {
      const response = await axios.get('/internal_api/analytics/get_conversations_heatmap', {
        params: {
          projectId: projectId,
          timezone: currentTimezone,
          startTimestamp,
          endTimestamp,
          metric: 'avg_response_time',
        },
      });

      let auxDataSet = response.data;
      let heatMapDataObject = {};
      heatMapDataObject.title = auxDataSet.chart_name;
      heatMapDataObject.oxLabels = auxDataSet.ox_labels;
      heatMapDataObject.oyLabels = auxDataSet.oy_labels;

      heatMapDataObject.data = auxDataSet.data;
      heatMapDataObject.info = languageMap.infoReportsAverageTimeToResponsePerHour;
      heatMapDataObject.displayInDays = true;

      setHeatMapTeamPerformance(heatMapDataObject);
    };
    const populateAgentPerformance = async () => {
      const response = await axios.get('/internal_api/analytics/get_agent_performance', {
        params: {
          projectId: projectId,
          startTimestamp,
          endTimestamp,
          timezone: currentTimezone,
        },
      });
      let tableAgentPerformanceObject = {};
      let auxDataSet = response.data;

      tableAgentPerformanceObject.title = auxDataSet.chart_name;
      tableAgentPerformanceObject.columns = auxDataSet.table_column_header;
      tableAgentPerformanceObject.data = [];
      tableAgentPerformanceObject.avatarLink = [];

      for (const key in auxDataSet.data) {
        const temporaryDataObject = {};
        tableAgentPerformanceObject.avatarLink.push(
          `${auxDataSet.data?.[key]?.['avatar'] ? auxDataSet.data?.[key]?.['avatar'] : ''}`
        );
        temporaryDataObject[auxDataSet?.table_column_header?.[0]] = ` ${
          auxDataSet.data?.[key]?.['name'] ? auxDataSet.data?.[key]?.['name'] : 'Unknown'
        }`;
        temporaryDataObject[auxDataSet?.table_column_header?.[1]] = `${
          auxDataSet.data?.[key]?.['assigned_conversations']
            ? auxDataSet.data?.[key]?.['assigned_conversations']
            : '0'
        }`;
        temporaryDataObject[auxDataSet?.table_column_header?.[2]] = `${
          auxDataSet.data?.[key]?.['replies_sent'] ? auxDataSet.data?.[key]?.['replies_sent'] : '0'
        }`;
        temporaryDataObject[auxDataSet?.table_column_header?.[3]] = `${
          auxDataSet.data?.[key]?.['closed_conversations']
            ? auxDataSet.data?.[key]?.['closed_conversations']
            : '0'
        }`;
        temporaryDataObject[auxDataSet?.table_column_header?.[4]] = `${
          auxDataSet.data?.[key]?.['rating'] ? auxDataSet.data?.[key]?.['rating'] : '0'
        }`;
        temporaryDataObject[auxDataSet?.table_column_header?.[5]] = `${
          auxDataSet.data?.[key]?.['avg_response_time']
            ? replaceSeconds(auxDataSet.data?.[key]?.['avg_response_time'])
            : '0'
        }`;

        tableAgentPerformanceObject.data.push(temporaryDataObject);
      }

      setAgentPerformance(tableAgentPerformanceObject);
    };

    populateAgentPerformance();
    populateHeatMapTeamPerformance();
    populAverageResponseTime();
    populateConversationsByBreakdown();
  }, [projectId, selectedTimeInterval, startTimestamp, endTimestamp]);

  const dataEnhancment = (agentPerformance) => {
    for (let i = 0; i < agentPerformance?.data?.length; i++) {
      agentPerformance.data[i]['Name'] = (
        <TableAvatar
          link={agentPerformance.avatarLink[i]}
          user={agentPerformance.data[i]['Name']}
        />
      );
    }
    return agentPerformance;
  };

  const checkLengthOfTble = () => {
    return agentPerformance?.data?.length > 6 && forPrint;
  };
  return (
    <>
      <div className="graph-container">
        <div className="row-container" style={{ background: 'white' }}>
          <div className="graphs-row">
            <Graph
              data={twin(conversationsByBreakdown?.data)}
              labels={conversationsByBreakdown?.labels}
              title={conversationsByBreakdown?.title}
              value={conversationsByBreakdown?.conversationsList}
              comparisonValue={conversationsByBreakdown?.comparisonValue}
              comparisonText={conversationsByBreakdown?.comparisonText}
              info={conversationsByBreakdown?.info}
              type="bar"
            />
          </div>
        </div>
      </div>
      <div className="graph-container">
        <div className="row-container">
          <div className="graphs-row">
            <Graph
              type="line"
              data={twin(averageResponseTime?.data)}
              labels={averageResponseTime?.labels}
              title={averageResponseTime?.title}
              legend={averageResponseTime?.legend}
              info={averageResponseTime?.info}
              unit={averageResponseTime?.unit}
              changeDisplayedTooltipValue={averageResponseTime?.changeDisplayedTooltipValue}
            />
          </div>
        </div>
      </div>
      <div className="table-container" style={forPrint ? { pageBreakBefore: 'always' } : null}>
        <div className="row-container-table">
          <div className="table-row">
            <HeatMapWidget
              forPrint
              title={heatMapTeamPerformance?.title}
              info={heatMapTeamPerformance?.info}
              data={heatMapTeamPerformance?.data}
              oxLabels={heatMapTeamPerformance?.oxLabels}
              oyLabels={heatMapTeamPerformance?.oyLabels}
              displayInDays={true}
            />
          </div>
        </div>
      </div>
      <div
        className="table-container"
        style={
          checkLengthOfTble()
            ? { pageBreakBefore: 'always', pageBreakAfter: 'always' }
            : { pageBreakAfter: 'always' }
        }
      >
        <div className="row-container-table">
          <div className="table-row">
            <Table
              tableData={dataEnhancment(twin(agentPerformance))}
              remarks={false}
              className="table-element"
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  conversationsByBreakdown: getConversationsByBreakdown(state),
  averageResponseTime: getAverageResponseTime(state),
  heatMapTeamPerformance: getHeatMapTeamPerformance(state),
  agentPerformance: getAgentPerformance(state),
});
const mapDispatchToProps = {
  setConversationsByBreakdown: setConversationsByBreakdown,
  setAverageResponseTime: setAverageResponseTime,
  setHeatMapTeamPerformance: setHeatMapTeamPerformance,
  setAgentPerformance: setAgentPerformance,
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamPerformance);
