import React from 'react';
import { connect } from 'react-redux';

import ConversationTab from './ConversationTab';

import { selectHistoryConversations } from 'store/crm/selectors';

import './LatestConversationsCard.scss';

const LatestConversationsCard = (props) => {
  const { conversations } = props;

  return (
    <>
      {conversations?.length === 0 ? (
        <div className="no-conversations">No conversations yet</div>
      ) : (
        conversations?.map((conversation, index) => {
          return (
            <ConversationTab
              key={conversation.conversation_id}
              conversation={conversation}
              index={index}
            />
          );
        })
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  conversations: selectHistoryConversations(state),
});

export default connect(mapStateToProps, null)(LatestConversationsCard);
