import React from 'react';
import { Popup } from 'semantic-ui-react';
import { getTimestamp } from 'utils/utils';
import './MessageThumbnail.scss';

const MessageThumbnail = ({ children, timestamp, position }) => {
  return (
    <Popup
      style={{
        borderRadius: 10,
        opacity: 0.7,
      }}
      className="message-thumbnail-popup"
      trigger={<div className={`MessageThumbnail ${position}`}>{children}</div>}
      content={getTimestamp(timestamp)}
      inverted
      size="mini"
      position={position || 'right center'}
    />
  );
};

export default MessageThumbnail;
