import React from 'react';
import cx from 'classnames';

import './Card.scss';

const Card = ({ className, ...props }) => {
  return <div className={cx('Card', className)} {...props} />;
};

export default Card;
