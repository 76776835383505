import React, { useContext } from 'react';
import Axios from 'axios';
import without from 'lodash/without';
import ContextMenu from 'components/ContextMenu';
import Modal from 'pages/Flow/components/Modal';
import { NodeUpdater, FlowMetaContext } from 'pages/Flow/utils/canvas-contexts';
import { computeLineToNewNodeDestination } from 'pages/Flow/components/Canvas/utils/nodeCharacteristics';
import createObject from 'pages/Flow/Services/CreateObjectService/CreateObjectService';
import useAllowedConnections from 'pages/Flow/components/Canvas/utils/useAllowedConnections';
import useNotifications from 'utils/hooks/useNotifications';

const AddNewNode = ({ node, closeDropdown, left, top, openSettingsDrawer }) => {
  let menu_data;
  const appData = useContext(FlowMetaContext);
  const { updateOrCreateNode } = useContext(NodeUpdater);
  const { showError } = useNotifications();

  const allowedConnectionClasses = useAllowedConnections(node);

  const mapObjects = (object_type, table_name, name_column, id_column) => {
    const mapCallback = (object) => {
      return {
        label: object[name_column] || 'Undefined Name',
        returnValue: {
          objectClass: object_type,
          id: object[id_column],
        },
      };
    };

    const menuData = appData[table_name].map(mapCallback);

    const createNewOption = {
      label: 'Create new',
      returnValue: {
        type: 'Create new',
        objectClass: object_type,
      },
    };
    return [createNewOption].concat(menuData);
  };

  const allowedClasses = without(allowedConnectionClasses, 'endOfDialog', 'returnToParentFlow');

  menu_data = allowedClasses
    .filter((item) => item !== 'flow_connector')
    .map((objectClass) => {
      if (objectClass === 'message') {
        return {
          label: 'Message',
          children: mapObjects('message', 'messages', 'message_name', 'message_id'),
        };
      } else if (objectClass === 'intent') {
        return {
          label: 'Intent',
          children: mapObjects('intent', 'intents', 'intent_name', 'intent_id'),
        };
      } else if (objectClass === 'entity') {
        return {
          label: 'Entity',
          children: mapObjects('entity', 'entities', 'entity_name', 'entity_id'),
        };
      } else if (objectClass === 'service') {
        return {
          label: 'Service',
          children: mapObjects('service', 'services', 'service_name', 'service_id'),
        };
      } else if (objectClass === 'script') {
        return {
          label: 'Script',
          children: mapObjects('script', 'scripts', 'script_name', 'script_id'),
        };
      } else if (objectClass === 'parent_connector') {
        return {
          label: 'Parent Connector',
          children: mapObjects(
            'parent_connector',
            'parent_connectors',
            'parent_connector_name',
            'parent_connector_id'
          ),
        };
      } else {
        return null;
      }
    });

  const getNewNodeCoordinates = () => {
    const coords = computeLineToNewNodeDestination(node);
    return {
      x: coords.x || 50,
      y: coords.y || 200,
    };
  };

  const createNode = (objectId, objectClass) => {
    const { appId, projectId } = appData;

    return Axios.post('/internal_api/create_node', {
      app_id: appId,
      projectId,
      object_class: objectClass,
      object_id: objectId,
      ...getNewNodeCoordinates(),
      source_node_id: node.node_id,
    }).then((resp) => resp.data);
  };

  /**
   * @param {object} selection
   * Creates a new object of selected class if 'Create new' is selected
   * Creates new node with selected object id or the newly created
   */
  const handleOptionSelect = async (selection) => {
    try {
      const selectionType = selection.type;
      const objectClass = selection.objectClass;

      const { appId, projectId, addObjectToState } = appData;

      const objectIdPromise = () => {
        if (selectionType === 'Create new') {
          return createObject(objectClass, appId, projectId).then((data) => {
            // save new objects in the state
            addObjectToState(objectClass, data.object);
            return data.objectId;
          });
        }
        return Promise.resolve(selection.id);
      };

      const objectId = await objectIdPromise();
      const nodeConnectionData = await createNode(objectId, objectClass);
      const { id: node_id, order_position, connection_id } = nodeConnectionData;
      updateOrCreateNode({
        [node_id]: {
          object_class: objectClass,
          object_id: objectId,
          ...getNewNodeCoordinates(),
          next: {},
          prev: [node.node_id],
        },
        [node.node_id]: {
          ...node,
          next: {
            ...node.next,
            [node_id]: {
              variable: '',
              operator: '',
              value: '',
              connection_id,
              order_position,
            },
          },
        },
      });
      openSettingsDrawer(objectClass, objectId, node_id);
      closeDropdown();
    } catch (error) {
      showError('Could not create node');
    }
  };

  return (
    <Modal
      onHide={() => {
        closeDropdown && closeDropdown();
      }}
    >
      <ContextMenu
        left={left}
        top={top}
        options={menu_data}
        nodeName={node?.nodeName}
        onSelect={handleOptionSelect}
        hasSearch={true}
      />
    </Modal>
  );
};

export default AddNewNode;
