import React, { Component } from 'react';
import Axios from 'axios';
import _ from 'lodash';
import { connect } from 'react-redux';

import Dialog from 'pages/Flow/components/Dialog';
import Modal from 'pages/Flow/components/Modal';
import InputText from 'components/common/input/InputText';
import { AddButton } from 'components/common/Button/Buttons';
import './CreateNewFlow.scss';
import { setLoading } from '../../../../store/global/actions';
import languageMap from 'Language/Language';
import { selectProjectLanguage } from '../../../../store/crm/selectors';
import RegexList from '../../../../components/RegexList/RegexList';

class CreateNewFlow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      flowName: '',
      flowDescription: '',
      qnaRegex: [],
    };
  }

  handleValueChange = (key, value) => {
    this.setState({ [key]: value });
  };

  handleNewFlow = () => {
    if (_.isEmpty(this.state.flowName)) {
      alert(languageMap.mustFillName);
      return;
    }
    const { close, selectedLanguage, isQna, projectId, onNewFlow } = this.props;
    let url = '/internal_api/project/' + projectId + '/flows';
    const data = {
      name: this.state.flowName,
      description: this.state.flowDescription,
      language: selectedLanguage,
    };
    if (isQna) {
      url = '/internal_api/qna/create_flow';
      data.url_regexes = this.state.qnaRegex;
      data.project_id = projectId;
    }
    Axios.post(url, data).then((response) => {
      onNewFlow({
        name: response.data.app_name,
        id: response.data.app_id,
        regex: response.data.url_prefixes,
        language: response.data.language,
      });
      close();
    });
  };

  handleImport = () => {
    if (_.isEmpty(this.state.flowName)) {
      alert(languageMap.mustFillName);
      return;
    }
    this.fileInput.click();
  };

  handleRegexChange = (qnaRegex) => {
    this.setState({ qnaRegex });
  };

  handleFileSelect = (event) => {
    let file = event.target.files[0];
    let data = new FormData();
    const { dispatch, projectId, isQna, close } = this.props;
    data.set('project_id', projectId);
    data.set('app_name', this.state.flowName);
    data.set('language', this.state.selectedLanguage);

    let url = '/internal_api/upload_app';
    if (isQna) {
      url = '/internal_api/qna/import';
      data.set('url_regexes', this.state.qnaRegex);
      data.append('file', file);
    } else {
      data.append('json_file', file);
    }
    dispatch(setLoading(true));

    Axios.post(url, data, {
      'Content-Type': 'multipart/form-data',
    })
      .then((response) => {
        this.props.onNewFlow({
          language: this.state.selectedLanguage,
          name: this.state.flowName,
          id: isQna ? response.data?.flow_id : response.data,
        });
        dispatch(setLoading(false));

        close();
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoading(false));
      });
  };

  render() {
    const { close, isQna } = this.props;
    return (
      <Modal onHide={close}>
        <Dialog
          onClose={close}
          headerView={
            <div className="headerTitle">
              {isQna ? languageMap.createNewQna : languageMap.createNewFlow}
            </div>
          }
          contentView={
            <div className="inputFieldsContainer">
              <InputText
                value={this.state.flowName}
                onValueChanged={this.handleValueChange}
                placeholder={isQna ? languageMap.qnaName : languageMap.flowName}
                name="flowName"
              />
              <InputText
                value={this.state.flowDescription}
                onValueChanged={this.handleValueChange}
                placeholder={isQna ? languageMap.qnaDescription : languageMap.flowDescription}
                name="flowDescription"
              />
              {isQna && (
                <RegexList values={this.state.qnaRegex} onChange={this.handleRegexChange} />
              )}
              <div style={{ display: 'flex', flexDirection: 'row', margin: 0 }}>
                <AddButton
                  onClick={this.handleNewFlow}
                  text={languageMap.create}
                  style={{ marginTop: 20, marginLeft: 30 }}
                />
                <AddButton
                  onClick={this.handleImport}
                  text={languageMap.import}
                  style={{ marginTop: 20, marginLeft: 30 }}
                />
                <input
                  type="file"
                  name="file"
                  ref={(el) => (this.fileInput = el)}
                  style={{ display: 'none' }}
                  onChange={this.handleFileSelect}
                />
              </div>
            </div>
          }
        />
      </Modal>
    );
  }
}
const mapStateToProps = (state) => ({
  selectedLanguage: selectProjectLanguage(state),
});
export default connect(mapStateToProps)(CreateNewFlow);
