import languageMap from 'Language/Language';
import React from 'react';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { ThemeContext } from '../../../pages/Flow/utils/Theme';
import Loader from '../Loader';
import mainTheme from '../../../mainTheme';

const WHITE_THEME = {
  ...mainTheme,
  palette: {
    ...mainTheme.palette,
    primary: {
      main: '#FFF',
      contrastText: '#000',
    },
  },
};
const whiteTheme = createTheme(WHITE_THEME);

export class LinkButton extends React.Component {
  static contextType = ThemeContext;

  render() {
    const theme = this.context;

    let style = {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      padding: '3px 8px 4px 5px',
      margin: 0,
      color: 'white',
      borderRadius: 10,
      backgroundColor: theme.main,
    };

    let iconStyle = {
      marginRight: '8px',
    };

    const indexStyle = { marginLeft: '5px' };

    const { nodeIndex, text } = this.props;

    return (
      <div style={{ ...style, ...this.props.style }} onClick={this.props.onClick}>
        <svg style={iconStyle} width="27px" height="14px" viewBox="0 0 27 14">
          <g id="linked" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
              d="M6.97990157,-1.77635684e-15 L10.9950284,-1.77635684e-15 C11.5473131,-1.77635684e-15 11.9950284,0.44771525 11.9950284,1 C11.9950284,1.51283584 11.6089882,1.93550716 11.1116495,1.99327227 L10.9950284,2 L6.97990157,2 C4.22957788,2 2,4.22957788 2,6.97990157 C2,9.65784832 4.1137757,11.8420963 6.76388377,11.9552022 L6.97990157,11.9598031 L10.9950284,11.9598031 C11.5473131,11.9598031 11.9950284,12.4075184 11.9950284,12.9598031 C11.9950284,13.472639 11.6089882,13.8953103 11.1116495,13.9530754 L10.9950284,13.9598031 L6.97990157,13.9598031 C3.12500838,13.9598031 8.8817842e-16,10.8347948 8.8817842e-16,6.97990157 C8.8817842e-16,3.20531865 2.99615603,0.130525473 6.73994277,0.00404736403 L6.97990157,-1.77635684e-15 L10.9950284,-1.77635684e-15 Z M20.0151268,-1.77635684e-15 C23.87002,-1.77635684e-15 26.9950284,3.12500838 26.9950284,6.97990157 C26.9950284,10.8347948 23.87002,13.9598031 20.0151268,13.9598031 L16,13.9598031 C15.4477153,13.9598031 15,13.5120879 15,12.9598031 C15,12.4075184 15.4477153,11.9598031 16,11.9598031 L20.0151268,11.9598031 C22.7654505,11.9598031 24.9950284,9.73022526 24.9950284,6.97990157 C24.9950284,4.22957788 22.7654505,2 20.0151268,2 L16,2 C15.4477153,2 15,1.55228475 15,1 C15,0.44771525 15.4477153,-1.77635684e-15 16,-1.77635684e-15 L20.0151268,-1.77635684e-15 Z M20,6 C20.5522847,6 21,6.44771525 21,7 C21,7.55228475 20.5522847,8 20,8 L7,8 C6.44771525,8 6,7.55228475 6,7 C6,6.44771525 6.44771525,6 7,6 L20,6 Z"
              id="Combined-Shape"
              fill="#333333"
            ></path>
          </g>
        </svg>

        <span>{text}</span>
        {nodeIndex && <span style={indexStyle}>[{nodeIndex}]</span>}
      </div>
    );
  }
}

export class AddButton extends React.Component {
  render() {
    let iconComponent, textComponent;
    const { color, disabled, onClick, icon, text, title, className } = this.props;

    let iconStyle = {
      width: 14,
      height: 14,
      margin: '0px 6px 0px 0px',
    };
    const style = {
      textTransform: 'none',
      fontWeight: 600,
      ...this.props.style,
    };

    if (text !== undefined) {
      textComponent = <span>{text}</span>;
    } else {
      if (icon === 'add') {
        iconStyle = {
          paddingTop: 4,
          paddingBottom: 4,
          width: 32,
          height: 26,
        };

        if (color !== undefined) {
          iconStyle.borderColor = color;
        }
      }
    }

    if (icon === 'add') {
      iconComponent = <WvButtonAddIcon style={iconStyle} />;
    }

    return (
      <Button
        className={className}
        variant="contained"
        color="primary"
        style={style}
        onClick={onClick}
        title={title}
        disabled={disabled}
      >
        {iconComponent}
        {textComponent}
      </Button>
    );
  }
}

export const WhiteButton = (props) => {
  return (
    <ThemeProvider theme={whiteTheme}>
      <AddButton {...props} />
    </ThemeProvider>
  );
};
export class RetrainButton extends React.PureComponent {
  render() {
    let text = languageMap.retrain;
    const { retraining, loading } = this.props;

    let style = {
      ...this.props.style,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: '90px',
      minHeight: '32px',
    };

    if (retraining) {
      style.backgroundColor = 'yellow';
      style.borderColor = 'yellow';
      text = (
        <>
          {languageMap.training} <Loader color="#666" size={18} />
        </>
      );
      style.pointerEvents = 'none';
    } else if (loading) {
      text = <Loader color="#666" size={18} />;
      style.pointerEvents = 'none';
    }

    return <NavButton style={style} text={text} onClick={this.props.onClick} />;
  }
}

export class NavButton extends React.PureComponent {
  render() {
    let style = {
      padding: '5px 9px',
      borderRadius: 10,
      boxShadow: '0 2px 4px 0 #00060780',
      textShadow: '0 0 1px rgba(255, 255, 255, 0)',
      textAlign: 'center',
      ...this.props.style,
    };

    return <WhiteButton style={style} text={this.props.text} onClick={this.props.onClick} />;
  }
}

const WvButtonAddIcon = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 14 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="adauga" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M7,0 C7.55228475,-1.01453063e-16 8,0.44771525 8,1 L8,6 L13,6 C13.5522847,6 14,6.44771525 14,7 C14,7.55228475 13.5522847,8 13,8 L8,8 L8,13 C8,13.5522847 7.55228475,14 7,14 C6.44771525,14 6,13.5522847 6,13 L6,8 L1,8 C0.44771525,8 0,7.55228475 0,7 C0,6.44771525 0.44771525,6 1,6 L6,6 L6,1 C6,0.44771525 6.44771525,1.01453063e-16 7,0 Z"
          id="Combined-Shape"
          fill="white"
        />
      </g>
    </svg>
  );
};

export const WvDeleteIcon = (props) => (
  <svg
    {...props}
    viewBox="0 0 14 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M9,2.25 L9,1.5 C9,0.675 8.3595,0 7.536,0 L4.464,0 C3.63975,0 3,0.675 3,1.5 L3,2.25 L0.75,2.25 L0.75,3.375 L1.5,3.375 L1.5,10.5 C1.5,11.325 2.175,12 3,12 L9,12 C9.825,12 10.5,11.325 10.5,10.5 L10.5,3.375 L11.25,3.375 L11.25,2.25 L9,2.25 L9,2.25 Z M3.84375,10.125 C3.84375,10.33125 3.675,10.5 3.46875,10.5 L3.09375,10.5 C2.8875,10.5 2.71875,10.33125 2.71875,10.125 L2.71875,4.125 C2.71875,3.91875 2.8875,3.75 3.09375,3.75 L3.46875,3.75 C3.675,3.75 3.84375,3.91875 3.84375,4.125 L3.84375,10.125 L3.84375,10.125 Z M6.5625,10.125 C6.5625,10.33125 6.39375,10.5 6.1875,10.5 L5.8125,10.5 C5.60625,10.5 5.4375,10.33125 5.4375,10.125 L5.4375,4.125 C5.4375,3.91875 5.60625,3.75 5.8125,3.75 L6.1875,3.75 C6.39375,3.75 6.5625,3.91875 6.5625,4.125 L6.5625,10.125 L6.5625,10.125 Z M7.875,2.25 L4.125,2.25 L4.125,1.5 C4.125,1.28625 4.2705,1.125 4.464,1.125 L7.536,1.125 C7.73025,1.125 7.875,1.28625 7.875,1.5 L7.875,2.25 L7.875,2.25 Z M9.28125,10.125 C9.28125,10.33125 9.1125,10.5 8.90625,10.5 L8.53125,10.5 C8.325,10.5 8.15625,10.33125 8.15625,10.125 L8.15625,4.125 C8.15625,3.91875 8.325,3.75 8.53125,3.75 L8.90625,3.75 C9.1125,3.75 9.28125,3.91875 9.28125,4.125 L9.28125,10.125 L9.28125,10.125 Z"
        id="path-1"
      />
    </defs>
    <g id="DELETE" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      {/* <rect fill="#D91313" x="0" y="0" width="14" height="14"></rect> */}
      <g id="settings/solidicon_delete_trashcan_remove" transform="translate(1.000000, 1.000000)">
        <mask id="mask-2" fill="white">
          <use xlinkHref="#path-1" />
        </mask>
        <use id="Shape" fill="#000000" fillRule="nonzero" xlinkHref="#path-1" />
        <g id="🎨/grey-7-#F5F5F5" mask="url(#mask-2)" fill="#F5F5F5" fillRule="nonzero">
          <rect id="Rectangle-13" x="0" y="0" width="12" height="12" />
        </g>
      </g>
    </g>
  </svg>
);
