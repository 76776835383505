import { PERMISSION_MANAGE_MACROS } from '../pages/SettingsMenu/AllPermissions';

const menuAccessRequirement = {
  userManagement: 1,
  bot: 2,
  qna: 2,
  inbox: 3,
  macros: 5,
  reports: 7,
  settings: 1,
  integrations: 8,
  generativeAi: 9,
};

export const hasAccess = (projectId, projectPermissionMap, menu, shouldHandleException = true) => {
  try {
    if (projectId && projectPermissionMap[projectId]) {
      let projectPermissions = [...projectPermissionMap[projectId]];

      if (shouldHandleException && !projectPermissions.includes(PERMISSION_MANAGE_MACROS)) {
        // diff logic for manage macros
        projectPermissions.push(PERMISSION_MANAGE_MACROS);
      }
      return projectPermissions.includes(menuAccessRequirement[menu]);
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
  }
  return false;
};
