const onConversationStatusUpdate = (data, props) => {
  const {
    inboxFolder,
    conversationStatusFilter,
    conversations,
    myDepartmentsIDs,
    currentUser,
    currentConversation,
  } = props;

  const {
    conversation_status,
    previousConversationStatus,
    conversation_meta,
    conversation_meta: { department_id_assigned, assignee, conversation_id },
  } = data;

  const isCurrentFilter = conversation_status === conversationStatusFilter;
  const isAssignedToMe = assignee === currentUser.user_id;
  const isAssignedToThisDepartment = myDepartmentsIDs.includes(department_id_assigned);
  const isUnassigned = assignee === -1;
  const folderYou = inboxFolder.submenu === 'External' && inboxFolder.file.value === 'you';
  const folderUnassigned =
    inboxFolder.submenu === 'External' && inboxFolder.file.value === 'unassigned';
  const folderAll = inboxFolder.submenu === 'External' && inboxFolder.file.value === 'external_all';
  const currentlySelected = currentConversation.conversation_id === conversation_id;

  const folderInteralAll =
    inboxFolder.submenu === 'Internal' && inboxFolder.file.value === 'internal_all'; // needs revision
  const conversationStatusChanged = conversation_status !== previousConversationStatus;

  const conversationIsInList = conversations.some(
    (item) => item.conversation_id === conversation_id
  );

  // UPDATE COUNT
  if (isCurrentFilter) {
    if (isAssignedToMe) {
      props.incrementYou();
      props.incrementAll();

      if (!currentlySelected) {
        props.addToYouUnread({ conversation_id });
        props.addToExternalAllUnread({ conversation_id });
      }
    } else if (isAssignedToThisDepartment && isUnassigned) {
      props.incrementUnassigned();
      props.incrementAll();

      if (!currentlySelected) {
        props.addToUnassignedUnread({ conversation_id });
        props.addToExternalAllUnread({ conversation_id });
      }
    } else if (isAssignedToThisDepartment) {
      props.incrementAll();
      if (!currentlySelected) {
        props.addToExternalAllUnread({ conversation_id });
      }
    }
    if (conversationStatusChanged && folderInteralAll) {
      props.incrementInternalAll();
      if (!currentlySelected) {
        props.addToInternalAllUnread({ conversation_id });
      }
    }
  } else {
    if (isAssignedToMe) {
      props.decrementYou();
      props.decrementAll();

      if (!currentlySelected) {
        props.removeFromYouUnread({ conversation_id });
        props.removeFromExternalAllUnread({ conversation_id });
      }
    } else if (isAssignedToThisDepartment && isUnassigned) {
      props.decrementUnassigned();
      props.decrementAll();

      if (!currentlySelected) {
        props.removeFromUnassignedUnread({ conversation_id });
        props.removeFromExternalAllUnread({ conversation_id });
      }
    } else if (isAssignedToThisDepartment) {
      props.decrementAll();
      if (!currentlySelected) {
        props.removeFromExternalAllUnread({ conversation_id });
      }
    }

    if (conversationStatusChanged && folderInteralAll) {
      props.decrementInternalAll();
      if (!currentlySelected) {
        props.removeFromInternalAllUnread({ conversation_id });
      }
    }
  }

  // UPDATE LIST
  if (isCurrentFilter) {
    if (
      (folderYou && isAssignedToMe) ||
      (folderAll && isAssignedToThisDepartment) ||
      (folderUnassigned && isAssignedToThisDepartment && isUnassigned)
    ) {
      if (currentlySelected) {
        props.setCurrentConversation(conversation_meta);
      }
    }
  } else {
    if (conversationIsInList) {
      props.removeConversation({ conversation_id });
    }
    if (currentlySelected) {
      props.setCurrentConversation();
    }
  }
};

export default onConversationStatusUpdate;
