import React from 'react';
import update from 'immutability-helper';
import _ from 'lodash';
import size from 'lodash/size';
import { produce } from 'immer';
import NavBar from 'components/common/NavBar';
import { addWebchat, removeWebchat } from 'pages/Flow/Services/WebchatHandler/WebchatHandler';
import LinesRenderer from 'pages/Flow/components/Canvas/components/LinesRenderer';
import NodesRenderer from 'pages/Flow/components/Canvas/components/NodesRenderer';
import { NodeUpdater } from 'pages/Flow/utils/canvas-contexts';
import { FlowMetaContext } from 'pages/Flow/utils/canvas-contexts';
import CreateEntryPoint from 'pages/Flow/components/CreateEntryPoint';
import 'pages/Flow/utils/chrome-fix.js';
import { headerHeight, nodeWidth } from './utils/nodeCharacteristics';

export default class Canvas extends React.Component {
  state = {
    showCreateEntrypointMenu: false,
    showAnnotations: false,
    retrainStatus: 'loading',
    scale: 1,
    lines: {},
    canvas_style: {
      transformOrigin: '0 0',
    },
  };

  static contextType = FlowMetaContext;

  canvas_container_style = {
    width: '100%',
    overflow: 'hidden',
    margin: 0,
    padding: 0,
    cursor: 'pointer',
    fontWeight: 'bold',
  };

  drag = { in_progress: false };

  onCanvasMouseDown = (e) => {
    if (e.button !== 0) {
      return;
    }

    this.drag = {
      in_progress: true,
      target: e.target,
      last_mouse_x: e.clientX,
      last_mouse_y: e.clientY,
    };

    this.mouse_x = e.clientX;
    this.mouse_y = e.clientY;

    this.drag.target.style.cursor = 'grab';

    let on_mouse_move = (e) => {
      if (this.drag.in_progress) {
        this.canvas_x_offset += e.clientX - this.drag.last_mouse_x;
        this.canvas_y_offset += e.clientY - this.drag.last_mouse_y;

        this.canvas_x += e.clientX - this.drag.last_mouse_x;
        this.canvas_y += e.clientY - this.drag.last_mouse_y;

        this.updateCanvasTransform();
        this.drag.last_mouse_x = e.clientX;
        this.drag.last_mouse_y = e.clientY;
      }

      e.stopPropagation();
      e.preventDefault();
    };

    window.addEventListener('mousemove', on_mouse_move);

    window.addEventListener(
      'mouseup',
      (e) => {
        this.drag.in_progress = false;
        e.stopPropagation();
        e.preventDefault();
        this.drag.target.style.cursor = 'pointer';
        window.removeEventListener('mousemove', on_mouse_move);
      },
      { once: true }
    );

    e.stopPropagation();
    e.preventDefault();
  };

  updateCanvasTransform() {
    let transform_value = `translate(${this.canvas_x_offset}px, ${this.canvas_y_offset}px) scale(${this.scale})`;
    this.setState((state) =>
      update(state, { canvas_style: { transform: { $set: transform_value } } })
    );
  }

  canvasZoom(type, zoom_origin) {
    let zoom_origin_x, zoom_origin_y;
    if (zoom_origin === 'center') {
      zoom_origin_x = this.container_center_x;
      zoom_origin_y = this.container_center_y;
    } else {
      // Assuming mouse
      zoom_origin_x = this.mouse_x;
      zoom_origin_y = this.mouse_y;
    }

    let delta_x = (zoom_origin_x - this.canvas_x) / this.scale;
    let delta_y = (zoom_origin_y - this.canvas_y) / this.scale;

    if (type === 'in') this.scale = _.clamp(this.scale + 0.035, 0.2, 5);
    else this.scale = _.clamp(this.scale - 0.035, 0.2, 5);

    this.setState(
      produce((draft) => {
        draft.scale = this.scale;
      })
    );

    let scaled_delta_x = delta_x * this.scale;
    this.canvas_x = zoom_origin_x - scaled_delta_x;
    this.canvas_x_offset = this.canvas_x - this.origin_x;

    let scaled_delta_y = delta_y * this.scale;
    this.canvas_y = zoom_origin_y - scaled_delta_y;
    this.canvas_y_offset = this.canvas_y - this.origin_y;

    this.updateCanvasTransform();
  }

  onWheel = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let [x_offset, y_offset] = [0, 0];

    let speed = 5;

    if (e.deltaY > 0) y_offset = speed;
    else if (e.deltaY < 0) y_offset = -speed;

    if (e.deltaX > 0) x_offset = speed;
    else if (e.deltaX < 0) x_offset = -speed;

    this.canvas_x_offset += x_offset;
    this.canvas_y_offset += y_offset;

    this.canvas_x += x_offset;
    this.canvas_y += y_offset;

    this.updateCanvasTransform();
  };

  componentDidMount() {
    let rect = this.canvas.getBoundingClientRect();
    let containerRect = this.container.getBoundingClientRect();
    this.scale = 1;
    // canvas x,y | origin x,y
    this.canvas_x = this.origin_x = rect.x;
    this.canvas_y = this.origin_y = rect.y;

    this.container_center_x = containerRect.x + containerRect.width / 2;
    this.container_center_y = containerRect.y + containerRect.height / 2;

    this.canvas_x_offset = this.initialNodeX
      ? containerRect.width / 2 - this.initialNodeX - nodeWidth / 2
      : 0;
    this.canvas_y_offset = this.initialNodeY
      ? containerRect.height / 2 - this.initialNodeY - headerHeight - 62
      : 0;

    window.onmousemove = (e) => {
      this.mouse_x = e.clientX;
      this.mouse_y = e.clientY;
    };
    this.updateCanvasTransform();

    addWebchat(this.context.projectId);
  }

  componentWillUnmount() {
    removeWebchat();
  }

  onResetLocationClick() {
    this.canvas_x -= this.canvas_x_offset;
    this.canvas_y -= this.canvas_y_offset;
    this.canvas_x_offset = 0;
    this.canvas_y_offset = 0;
    this.setState(
      produce((draft) => {
        draft.scale = 1;
      })
    );
    this.scale = 1;
    this.updateCanvasTransform();
  }

  render() {
    let analyticsPath =
      '/project/' + this.context.projectId + '/flow/' + this.context.appId + '/analytics';
    let backPath = '/project/' + this.context.projectId + '/crm/Flows';

    return (
      <NodeUpdater.Consumer>
        {({ nodes }) => {
          // whenever we have more than 100 nodes, we want to have a hard redirect when navigating away from the flow.
          // this is needed as the JS main thread would be blocked for some seconds while having the GarbageCollector run for
          // the removed nodes.
          const shouldHardRedirect = size(nodes) > 100;
          const focusedNode = nodes[this.props.focusedNodeId];
          this.initialNodeX = focusedNode?.x;
          this.initialNodeY = focusedNode?.y;
          return (
            <div
              id="_root"
              style={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                flexFlow: 'column nowrap',
                overflow: 'hidden',
              }}
            >
              <NavBar
                shouldHardRedirect={shouldHardRedirect}
                title={this.context.app_name}
                retrainStatus={this.state.retrainStatus}
                projectId={this.context.projectId}
                analyticsPath={analyticsPath}
                backPath={backPath}
                hasRightMenu={true}
              />

              <div
                id="_canvas_container"
                ref={(el) => (this.container = el)}
                style={this.canvas_container_style}
                onWheel={this.onWheel}
                onMouseDown={this.onCanvasMouseDown}
              >
                <div id="_canvas" ref={(el) => (this.canvas = el)} style={this.state.canvas_style}>
                  <LinesRenderer />
                  <NodesRenderer scale={this.state.scale} />
                </div>

                <div
                  onMouseMove={(e) => {
                    e.stopPropagation();
                  }}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                  }}
                  onMouseUp={(e) => {
                    e.stopPropagation();
                  }}
                  style={{
                    position: 'absolute',
                    left: 20,
                    bottom: '50vh',
                    display: 'block',
                    userSelect: 'none',
                    MozUserSelect: 'none',
                    msUserSelect: 'none',
                  }}
                >
                  <CreateEntryPoint />
                </div>

                <div
                  id="_zoom_container"
                  onMouseMove={(e) => {
                    e.stopPropagation();
                  }}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                  }}
                  onMouseUp={(e) => {
                    e.stopPropagation();
                  }}
                  style={{
                    left: 20,
                    bottom: 20,
                    backgroundColor: '#EEEEEE',
                    position: 'absolute',
                    boxShadow: '0px 1px 5px 1px gray',
                    borderRadius: '50px',
                    padding: 8,
                    verticalAlign: 'middle',
                    display: 'block',
                    userSelect: 'none',
                    MozUserSelect: 'none',
                    msUserSelect: 'none',
                  }}
                >
                  <span
                    className="material-icons"
                    onClick={() => this.onResetLocationClick()}
                    style={{
                      position: 'relative',
                      display: 'block',
                      marginBottom: 8,
                    }}
                  >
                    my_location
                  </span>
                  <span
                    className="material-icons"
                    onClick={() => this.canvasZoom('in')}
                    style={{
                      position: 'relative',
                      display: 'block',
                      marginBottom: 8,
                    }}
                  >
                    zoom_in
                  </span>
                  <span
                    className="material-icons"
                    onClick={() => this.canvasZoom('out')}
                    style={{ position: 'relative', display: 'block' }}
                  >
                    zoom_out
                  </span>
                </div>
              </div>
            </div>
          );
        }}
      </NodeUpdater.Consumer>
    );
  }
}
